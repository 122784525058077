angular.module('deitz').controller('reporterController',[

    '$scope', '$rootScope', '$state', 'representativeFactory', 'commonFactory', 'Notification', '$filter', '$sce', 'SweetAlert', '$uibModal', '$http', 'apiUrl','$window', 'DTOptionsBuilder','DTColumnBuilder','$compile',
    function(
        $scope, $rootScope, $state, representativeFactory, commonFactory, Notification, $filter, $sce, SweetAlert, $uibModal, $http, apiUrl ,$window,DTOptionsBuilder,DTColumnBuilder,$compile
    ) {

        //Model object that contains model data
        $scope.validations = representativeFactory.validations;
        $scope.messages = representativeFactory.messages; 
        $scope.reporterData = {};
        $scope.reporterForm = {};
        $scope.reptypehistory = [];
        $scope.showUnpaidJobsMenuActive = false;
        $scope.showPaidJobsMenuActive= false;
        $scope.showCheckReporterMenuActive = false;
        $scope.showPrivateTab = false;
        $scope.onlyViewData = true;
        $scope.reporterData.start_date = moment().format('MM/DD/YYYY');
        $scope.AvailableDateRangeSingle = {startDate :  moment().format('MM/DD/YYYY'),endDate :  moment().format('MM/DD/YYYY')};
        $scope.NotAvailableDateRangeSingle= {startDate :  moment().format('MM/DD/YYYY'),endDate :  moment().format('MM/DD/YYYY')};
        const timeoff = {
            start_date: '',
            off_type:'F',
            start_time:'',
            end_time:'',
            notes:'',
            day_count: 1
        }
        $scope.timeoff = timeoff;
        $scope.userIdWithSameEmail = '';

        $scope.isRateChanged = false;
        $scope.alternateEmailValid = true;
        var id = $state.params ? $state.params.id : '';
        $scope.reporterData.NotAvailableDateRange = [];
        $scope.reporterData.AvailableDateRange = [];
        $scope.save_and
        var open_time = moment().set({h: 9, m: 00});
        var close_time = moment().set({h: 17, m: 00});
        $scope.workingDays = [
            {
                day:'Monday',is_working:false,open_time:open_time,close_time:close_time,day_of_week:1,id:''
            },
            {
                day:'Tuesday',is_working:false,open_time:open_time,close_time:close_time,day_of_week:2,id:''
            },
            {
                day:'Wednesday',is_working:false,open_time:open_time,close_time:close_time,day_of_week:3,id:''
            },
            {
                day:'Thursday',is_working:false,open_time:open_time,close_time:close_time,day_of_week:4,id:''
            },
            {
                day:'Friday',is_working:false,open_time:open_time,close_time:close_time,day_of_week:5,id:''
            },
            {
                day:'Saturday',is_working:false,open_time:open_time,close_time:close_time,day_of_week:6,id:''
            },
            {
                day:'Sunday',is_working:false,open_time:open_time,close_time:close_time,day_of_week:7,id:''
            },    
            ]
        //get all BussinesUnit 
        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
        });
        if(localStorage.getItem('representative_email')){
            $scope.reporterData.Emai_Address = localStorage.getItem('representative_email')
            localStorage.removeItem("representative_email");
        }
        if(localStorage.getItem('representative_name')){
            $scope.reporterData.first_name = localStorage.getItem('representative_name')
            localStorage.removeItem("representative_name");
        }
        if (localStorage.getItem('representative_last_name')) {
            $scope.reporterData.last_name = localStorage.getItem('representative_last_name')
            localStorage.removeItem("representative_last_name");
        }
        if (localStorage.getItem('representative_phone')) {
            $scope.reporterData.phone = localStorage.getItem('representative_phone')
            localStorage.removeItem("representative_phone");
        }
        if(localStorage.getItem('representative_user_id')){
            $scope.reporterData.link_user_id = localStorage.getItem('representative_user_id')
            localStorage.removeItem("representative_user_id");
        }

        $scope.grades_arr = [];
        /*Use for get grades */
        $http.get(apiUrl + '/api/get_grades').then(function(response) {            
            $scope.grades_arr = response.data.data;            
        });

        
         $http.get(apiUrl + '/api/specialties_list').then(function(response) { 
            $scope.specialties_list = response.data.result.data;  
        });
        
         $http.get(apiUrl + '/api/modality_list').then(function(response) { 
            $scope.modality_list = response.data.result.data;  
            $scope.modality_list.unshift({id:null,name:""});
        });
       
         $http.get(apiUrl + '/api/capability_list').then(function(response) { 
            $scope.capability_list = response.data.result.data;  
        });

        $http.get(apiUrl + '/api/prioragency_list').then(function(response) { 
            $scope.prioragency_list = response.data.result.data;  
        });
        $http.get(apiUrl + '/api/prioragency_list').then(function(response) { 
            $scope.prioragency_list = response.data.result.data;  
        });
       
        $http.get(apiUrl + '/api/additionalinfo_list').then(function(response) { 
            $scope.additionalinfo_list = response.data.result.data;  
        });
        $http.get(apiUrl + '/api/repoter_availability_types_list').then(function(response) { 
           
             $scope.repoter_availability_types_list = response.data.result.data;  
             $scope.repoter_availability_types_list.unshift({id:null,name:""});  
        });

        $scope.skillsList = [];
        $http.get(apiUrl + '/api/resource-skills').then( function (response) { 
            $scope.skillsList = response.data.result.resourcesSkills;
        });
        $scope.resourceTypesSkill =[];

        $scope.disableOtherSkills = function () {
            $scope.resourceTypesSkill =[];
            $rootScope.showLoader(true);
            commonFactory.post('/api/get-resource-skills-by-resource-types', { resourceTypeIds: $scope.reporterData.additionalinfoIds })
            .success(function(response) {
                $rootScope.showLoader(false);
                angular.forEach($scope.skillsList, function(value, key) {
                    if(response.result.resourceSkillsIds.includes(value.id)){
                        $scope.resourceTypesSkill.push(value);
                    }
                   
                });
            })
            .error(function(response){
                $rootScope.showLoader(false);
                Notification.error(response.result.message);
            })
        };

        $scope.venueList = [];
        $http.get(apiUrl + '/api/getLocation').then( function (response) { 
            $scope.venueList = response.data.result.data;  
        });

        $scope.maxCoverageNotesLength = 150;
        $scope.coverageRadiusList = [
            { label: "25 miles", value: "25" },
            { label: "50 miles", value: "50" },
            { label: "75 miles", value: "75" },
            { label: "100+ miles", value: "100" } // Value 100 means 100+ miles
        ]

        $scope.setPageTitle = function () {
            if($scope.reporterData?.first_name){
                $state.$current.data.pageTitle = $scope.reporterData.first_name + " " + $scope.reporterData.last_name;
                $rootScope.$broadcast('changeTitle', $state);
            }
        };
        $scope.stepOneInit = function () {
            $scope.setPageTitle();
        };

        $scope.options = {
            settings: {
                visibility: {
                    theme: 'light',
                },
                iso8601: false,
                selection: {
                    day: false,
                },
            }
        };

        $scope.stepTwoInit = function () {
            $scope.setPageTitle();
            var id = $state.params ? $state.params.id : '';
            if (!id) {
                if ($scope.reporterData.business_unit_id == 14) {
                    $scope.reporterData.override_appear_rate_fee = 'Yes';
                    $scope.reporterData.pay_stips = 'Yes';
                    $scope.reporterData.override_deduction = 'Yes';
                    $scope.reporterData.rate_2 = 0;
                    $scope.reporterData.appear = 0;
                    $scope.reporterData.hourly_pay = 0;
                    $scope.reporterData.rate_e = 0;
                    $scope.reporterData.copy_rt = 0;
                    $scope.reporterData.rate_d = 0;
                    $scope.reporterData.SOR = 0;
                    $scope.reporterData.SORr = 0;
                    $scope.reporterData.SORn = 0;
                    $scope.reporterData.medicalrate = 0;
                    $scope.reporterData.bust_fee = 0;
                    $scope.reporterData.hearing_rate = 0;
                    $scope.reporterData.late_app = 0;
                    $scope.reporterData.expidite_1_2_days_rate = 0;
                    $scope.reporterData.workers_comp_over_min_page_rate = 0;
                    $scope.reporterData.dwi_flat_rate = 0;
                    $scope.reporterData.workers_comp_exp_rate = 0;
                    $scope.reporterData.workers_comp_flat_pages_go_over = 0;
                    $scope.reporterData.rate_i = 0;
                }
            }
        };
        $scope.stepThreeInit = function () {
            $scope.setPageTitle();
        };
        $scope.stepGIInit = function () {
            $scope.setPageTitle();
            $scope.reloadTimeOffs();
            $scope.refreshSoftware();
            $scope.refreshJobtype();
            $scope.refreshWitnessTypes();
            $scope.refreshCaseType();
        };

        $scope.softwareModal = function () {
            $rootScope.showLoader(true);

            var modalInstance = $uibModal.open({
                templateUrl: 'modules/code_tables/software/add/softwareModal.html',
                controller: 'addSoftwareController',
                resolve: {
                    fromModal: function () {
                        return true;
                    }
                }
            });
            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });

            modalInstance.result.then(function (id) {
                if (id) {
                    $scope.refreshSoftware();
                    $scope.reporterData.software_id = [id];
                }

            }, function () {
                console.log("Modal Dismissed!!!");
            });
        };

        //Getting all Software refresh.
        $scope.refreshSoftware = function () {
            $rootScope.showLoader(true);
            commonFactory.get('/api/get-software')
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response.result && response.result.software) {
                        $scope.software = response.result.software || '';
                        for (var i = 0; i < $scope.software.length; i++) {
                            if ($scope.software[i].isDefault == 1) {
                                if (!($scope.reporterData.software_id && $scope.reporterData.software_id.length)) {
                                    $scope.reporterData.software_id = $scope.reporterData.software_id.length ? $scope.reporterData.software_id : [$scope.software[i].id];
                                }
                            }
                        }
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        };

        $scope.jobtypeModal = function (id) {
            $rootScope.showLoader(true);
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/code_tables/job_type/add/jobtypeModal.html',
                controller: 'addjobtypeController',
                resolve: {
                    fromModal: function () {
                        return true;
                    }
                }
            });
            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });

            modalInstance.result.then(function (id) {
                if (id) {
                    $scope.refreshJobtype();
                    $scope.reporterData.job_type_id = [id];
                }

            }, function () {
                console.log("Modal Dismissed!!!");
            });
        };

        //Getting all jobtypes refresh.
        $scope.refreshJobtype = function () {
            $rootScope.showLoader(true);
            commonFactory.get('/api/jobtypes')
                .success(function (response) {
                    if (response.result && response.result.jobtype) {
                        $scope.jobtype = response.result.jobtype || '';
                        for (var i = 0; i < $scope.jobtype.length; i++) {
                            if ($scope.jobtype[i].isDefault == 1) {
                                if (!($scope.reporterData.job_type_id && $scope.reporterData.job_type_id.length)) {
                                    $scope.reporterData.job_type_id = $scope.reporterData.job_type_id.length ? $scope.reporterData.job_type_id : [$scope.jobtype[i].id];
                                }
                            }
                        }
                    }
                })
                .error(function (err) {
                });
        };

        $scope.refreshWitnessTypes = function(){
            commonFactory.get('/api/all-active-inactive-witness-types')
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response.result && response.result.witness_types) {
                        $scope.witness_types = response.result.witness_types || [];
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        }


        $scope.caseTypeModal = function (id) {
            $rootScope.showLoader(true);
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/code_tables/case_type/add/casetype_modal.html',
                controller: 'addcasetypeController',
                resolve: {
                    fromModal: function () {
                        return true;
                    }
                }
            });
            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });
            modalInstance.result.then(function (id) {
                if (id) {
                    $scope.refreshCaseType();
                    $scope.reporterData.case_type_id = [id];
                }
            });
        };

        //Getting All caseType refresh.
        $scope.refreshCaseType = function () {
            $rootScope.showLoader(true);
            commonFactory.get('/api/casetypes')
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response && response.result && response.result.casetype) {
                        $scope.casetype = response.result.casetype || '';
                        for (var i = 0; i < $scope.casetype.length; i++) {
                            if ($scope.casetype[i].isDefault == 1) {
                                if (!($scope.reporterData.case_type_id && $scope.reporterData.case_type_id.length)) {
                                    $scope.reporterData.case_type_id = $scope.reporterData.case_type_id.length ? $scope.reporterData.case_type_id : [$scope.casetype[i].id];
                                }
                            }
                        }
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        };

        $scope.open_not_available_daterange = function () {
           document.getElementById('NotAvailableDateRangeSingle').focus();
        }
        $scope.open_available_daterange = function () {
            document.getElementById('AvailableDateRangeSingle').focus();
         }
        $scope.add_not_available_daterange = function (data) {
           
            console.log(data);
            startDate = moment(data.startDate).format('MM/DD/YYYY')
            endDate = moment(data.endDate).format('MM/DD/YYYY')
            console.log(startDate+'-'+endDate)
            $scope.reporterData.NotAvailableDateRange.push({id:$scope.reporterData.NotAvailableDateRange.length+10,date:startDate+' - '+endDate
            ,startDate:startDate,endDate:endDate})
            $scope.reporterData.NotAvailableDateRangeSingle = {}
            
         }
         $scope.add_available_daterange = function (data) {
           
            console.log(data);
            startDate = moment(data.startDate).format('MM/DD/YYYY')
            endDate = moment(data.endDate).format('MM/DD/YYYY')
            console.log(startDate+'-'+endDate)
            $scope.reporterData.AvailableDateRange.push({id:$scope.reporterData.AvailableDateRange.length+10,date:startDate+' - '+endDate
            ,startDate:startDate,endDate:endDate})
            $scope.reporterData.AvailableDateRangeSingle = {}
            
         }
        //List Refresh
        $scope.refresh_repoter_availability_types_list = function () {
            $http.get(apiUrl + '/api/repoter_availability_types_list').then(function(response) { 
                $scope.repoter_availability_types_list = response.data.result.data;  
                $scope.repoter_availability_types_list.unshift({id:null,name:""});            });
        }

        $scope.refresh_additionalinfo_list = function () {
            $http.get(apiUrl + '/api/additionalinfo_list').then(function(response) { 
                $scope.additionalinfo_list = response.data.result.data;  
            });
        }

        $scope.refresh_capability_list = function () {
            $http.get(apiUrl + '/api/capability_list').then(function(response) { 
                $scope.capability_list = response.data.result.data;  
            });
        }

        $scope.refresh_specialties_list = function () {
            $http.get(apiUrl + '/api/specialties_list').then(function(response) { 
                $scope.specialties_list = response.data.result.data;  
            });
        }
        $scope.refresh_prioragency_list = function () {
            $http.get(apiUrl + '/api/prioragency_list').then(function(response) { 
                $scope.prioragency_list = response.data.result.data;  
            });
        }
        $scope.refresh_modality_list = function () {
            $http.get(apiUrl + '/api/modality_list').then(function(response) { 
                $scope.modality_list = response.data.result.data;  
                $scope.modality_list.unshift({id:null,name:""});
            });
        }

        $scope.refresh_prioragency_list = function () {
            $http.get(apiUrl + '/api/prioragency_list').then(function(response) { 
                $scope.prioragency_list = response.data.result.data;  
            });
        }

        $scope.saveDayOff = function () {
            let timeOff = Object.assign({}, $scope.timeoff);
            timeOff.reptyp_id = $state.params.id;

            let startdate = '';
            if (typeof timeOff.start_date != "undefined" && timeOff.start_date != "") {
                startdate = timeOff.start_date = moment(timeOff.start_date).format('YYYY-MM-DD');
            }

            let enddate = '';
            if (timeOff.off_type == 'F') {
                enddate = timeOff.end_date = moment(timeOff.start_date).format('YYYY-MM-DD');
                if (timeOff.day_count > 1) {
                    enddate = timeOff.end_date = moment(timeOff.start_date).add(timeOff.day_count - 1, 'days').format('YYYY-MM-DD');
                }
            }
            if (timeOff.off_type == 'P') {
                enddate = timeOff.end_date = moment(timeOff.start_date).format('YYYY-MM-DD');
            }
            let starttime = '';
            if (typeof timeOff.start_time != "undefined" && timeOff.start_time != "") {
                starttime = timeOff.start_time = moment(timeOff.start_time).format('HH:mm:ss');
            }
            let endtime = '';
            if (typeof timeOff.end_time != "undefined" && timeOff.end_time != "") {
                endtime = timeOff.end_time = moment(timeOff.end_time).format('HH:mm:ss');
            }
            if (timeOff.off_type == 'F') {
                starttime = '00:00:00';
                timeOff.start_time = '00:00:00';
                endtime = '23:59:59';
                timeOff.end_time = '23:59:59';
            }

            startdate = moment(startdate + ' ' + starttime).format('YYYY-MM-DD HH:mm:ss');
            enddate = moment(enddate + ' ' + endtime).format('YYYY-MM-DD HH:mm:ss');
            let currentdate = moment().format('YYYY-MM-DD HH:mm:ss');
            if (currentdate > startdate) {
                Notification.error('Date must be from future.');
                return false;
            }
            if (timeOff.start_date == '' || timeOff.notes == '') {
                Notification.error('Please fill all details.');
                return false;
            }

            if (startdate >= enddate) {
                Notification.error('End time must greater than start time.');
                return false;
            }

            $rootScope.showLoader(true);

            commonFactory.post('/api/reporter-dayoffs', timeOff)
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response) {
                        if (response.status == 200) {
                            Notification.success("TimeOff added successfully");
                            $scope.reloadTimeOffs();
                            $scope.timeoff = {
                                start_date: '',
                                off_type: 'F',
                                start_time: '',
                                end_time: '',
                                notes: '',
                                day_count: 1
                            };
                            $('#collapseExample').collapse('hide');
                        } else if (response.status == 400) {
                            Notification.error(response.result.message);
                        }
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });

        }

        commonFactory.get('/api/reporter-work-types')
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                var reporter_work_type = response.result.resources;
                reporter_work_type.unshift({'id' : 0, 'name' : 'N/A'});
                $scope.reporterWorkTypes = reporter_work_type;
            }
        });
        $scope.refresh_work_type_list = function () {
            commonFactory.get('/api/reporter-work-types')
            .success(function(response) {
                if(response.error){
                    Notification.error(response.result.message || 'Something went wrong!');
                } else{
                    var reporter_work_type = response.result.resources;
                    reporter_work_type.unshift({'id' : 0, 'name' : 'N/A'});
                    $scope.reporterWorkTypes = reporter_work_type;
                }
            });
        }
        $scope.reloadGeneralAvailability = function(){
            $rootScope.showLoader(true);
            var id = $state.params ? $state.params.id : '';
            if(id){
                $http.get(apiUrl + '/api/get-day-availability-by-reporter', {
                    params: {
                        reporter_id: id
                    }
                }).then(function(response) {

                    if (response && response.data && response.data.result.data) {  
                        $scope.reporterData.woking_days = response.data.result.data.woking_days;

                        $scope.woking_days_logs = response.data.result.data.woking_days_logs;
                        if($scope.reporterData.woking_days.length) {
                            const curruntDate = new Date();
                            $scope.reporterData.woking_days.forEach(element => {
                                var index = 0;
                                $scope.workingDays.forEach(data => {
                                    if(element.day_of_week == data.day_of_week){
                                        $scope.workingDays[index].is_working = true;
                                        $scope.workingDays[index].open_time = element.open_time;
                                        $scope.workingDays[index].close_time =element.close_time;
                                        
                                        /*if(data.day_of_week == curruntDate.getDay()){
                                            $scope.workingDays[index].id = 'activerow'
                                        }else{
                                            $scope.workingDays[index].id = ''
                                        }*/
                                    }else{
                                        var isWorkingDays =  $scope.reporterData.woking_days.map(function(o) { return o.day_of_week; }).indexOf(data.day_of_week);
                                    
                                        if(isWorkingDays == -1){
                                            $scope.workingDays[index].is_working = false
                                        }
                                    }
                                    if(data.day_of_week == curruntDate.getDay()){
                                        $scope.workingDays[index].id = 'activerow'
                                    }else{
                                        $scope.workingDays[index].id = ''
                                    }
                                    index++;
                                    
                                });
                                
                            });
                        }
                    } else {
                        console.log("Not get any response")
                    }

                    $rootScope.showLoader(false);
                });
            }
        }

        $scope.reloadCertificates = function() {
            $rootScope.showLoader(true);
            var id = $state.params ? $state.params.id : '';
            if(id){
                $http.get(apiUrl + '/api/get-certificates-by-reporter', {
                    params: {
                        reporter_id: id
                    }
                }).then(function(response) {
                    $scope.certificates = response.data.result.data;
                    $rootScope.showLoader(false);
                });
            }
        };

        $scope.filter_timeoff = {};
        $scope.filter_timeoff.start_date = moment().startOf('month').format('MM/DD/YYYY');
        $scope.filter_timeoff.end_date = moment().endOf('month').format('MM/DD/YYYY');
        
        $scope.reloadTimeOffs = function() {
            $rootScope.showLoader(true);
            var id = $state.params ? $state.params.id : '';
            if(id){
            
                $http.get(apiUrl + '/api/get-timeoff-by-reporter', {
                    params: {
                        reporter_id : id,
                        start_date : moment($scope.filter_timeoff.start_date).format('MM/DD/YYYY'),
                        end_date : moment($scope.filter_timeoff.end_date).format('MM/DD/YYYY'),
                    }
                }).then(function(response) {
                    $scope.timeOffs = response.data.result.data;
                    $rootScope.showLoader(false);
                });
            }
            $scope.reloadDayOffs();
        };
        $scope.reloadDayOffs = function () {
            setTimeout(function () {
                $rootScope.showLoader(true);
                const calendar = new VanillaCalendar(document.getElementById('calendar'), $scope.options);
                calendar.init();
                var id = $state.params ? $state.params.id : '';
                if (id) {
                    $http.get(apiUrl + '/api/get-dayoff-by-reporter', {
                        params: {
                            reporter_id: id
                        }
                    }).then(function (response) {
                        if (!response.data.error) {
                            $scope.options.popups = response.data.result.data;
                            const calendar = new VanillaCalendar(document.getElementById('calendar'), $scope.options);
                            calendar.init();
                        } else {
                            Notification.error('Something went wrong!');
                        }
                        $rootScope.showLoader(false);
                    });
                }

            })
        };

        $scope.availabilityAuditModal = function(record){
            $rootScope.showLoader(true);
            
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/representative/addreporter/attr_audit_logs.html',
                controller: function($scope, params, $uibModalInstance){
                    $scope.modal_title = 'Availability Audit Logs';
                    $scope.modal_subtitle = '';
                    $scope.audit_logs = record;
                    $scope.cancel = function(isChanged) {
                        $uibModalInstance.dismiss({ isChanged : isChanged});
                    };

                    $scope.openUpdatedValues = function(val){
                        $scope.updatedValues = JSON.parse(val);
                    }
                },
                resolve : {
                    params : function(){
                        return record;
                    }
                },
                size: 'lg',
            });
            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });
        };

        $scope.certificateAuditModal = function(record){
            $rootScope.showLoader(true);
            
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/representative/addreporter/attr_audit_logs.html',
                controller: function($scope, params, $uibModalInstance){
                    $scope.modal_title = 'Certificate Audit Logs';
                    $scope.modal_subtitle = record.certificate_type.type + '-' + record.certificate_type.state_code;
                    $scope.audit_logs = record.audit;
                    $scope.cancel = function(isChanged) {
                        $uibModalInstance.dismiss({ isChanged : isChanged});
                    };

                    $scope.openUpdatedValues = function(val){
                        $scope.updatedValues = JSON.parse(val);
                    }
                },
                resolve : {
                    params : function(){
                        return record;
                    }
                },
                size: 'lg',
            });
            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });
        };

        $scope.timeOffAuditModal = function(record){
            $rootScope.showLoader(true);
            
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/representative/addreporter/attr_audit_logs.html',
                controller: function($scope, params, $uibModalInstance){
                    $scope.modal_title = 'Timeoff Audit Logs';
                    $scope.modal_subtitle = record.start_date + ' ' + record.start_time + ' - ' + record.end_date + ' ' + record.end_time;
                    $scope.audit_logs = record.audit;
                    $scope.cancel = function(isChanged) {
                        $uibModalInstance.dismiss({ isChanged : isChanged});
                    };

                    $scope.openUpdatedValues = function(val){
                        $scope.updatedValues = JSON.parse(val);
                    }
                },
                resolve : {
                    params : function(){
                        return record;
                    }
                },
                size: 'lg',
            });
            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });
        };

        $scope.viewCertificatePdf = function(id){
            if(id){
                $http.get(apiUrl + '/api/get-certificate-url', {
                    params: {
                        id: id
                    }
                }).then(function(response) {
                    if(!response.data.error){
                        window.open(response.data.result,'_blank');
                    }else{
                        Notification.error(response.data.message);
                    }
                });
            }
        }

        $scope.experinceYearList = [{id:null,name:''}];
        
        //get experinceYearList
        const curruntDate = new Date();
        var experince = 0
        for (let year = curruntDate.getFullYear(); year > 1970; year--) {
            $scope.experinceYearList[experince+1] ={id:year,name:year+" ("+experince+" Year Experience)"};
            experince++
        }
        $scope.ageYearList = [{id:null,name:''}];
        //get ageYearList
       
        var age = 0
        
        var ageIndex = 1
        for (let year = curruntDate.getFullYear(); year > 1910; year--) {
            if(year <= curruntDate.getFullYear()-15){
            $scope.ageYearList[ageIndex] ={id:year,name:year+" ("+age+" Year Age)"};
            ageIndex++
        }
        age++
        }

       
        if ($state.params && $state.params.id) {
            $scope.title = 'Edit Resource';
        } else {
            $scope.title = 'Add Resource';
            $http.get(apiUrl + '/api/get-user-roles-businessunit').then(function(response) { 
                $scope.rolesWithBusinessUnits = response.data.data;  
                $scope.reporterData.business_unit_id = $scope.rolesWithBusinessUnits[0].business_unit_id
            });
        }
        $scope.attorneyslist = function(val) {
            // $rootScope.showLoader(true);
            $scope.firmSearching = true;
            return $http.get(apiUrl + '/api/attorneySearchByName', {
                params: {
                    name: val
                }
            }).then(function(response) {
                // $rootScope.showLoader(false);
                $scope.firmSearching = false;
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $scope.removeMask = function(){
           $scope.reporterData.soc_sec_no_mask =$scope.reporterData.soc_sec_no;
        }

        $scope.removeRoutingMask = function(){
            $scope.reporterData.RoutingNumberMask =$scope.reporterData.RoutingNumber;
         }

         $scope.removeAccountMask = function(){
            $scope.reporterData.AccountNumberMask =$scope.reporterData.AccountNumber;
         }



        $scope.showW9ConfirmationAndSave = function(){
            swal({
                title: "Are you sure ?",
                text: 'Changes have been made to the resource’s Tax ID. By continuing, you confirm that you will make sure a new W9 is being sent to accounting for tax reporting purposes.',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Do it!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if(isConfirm){
                    $scope.save();
                }
            });
        }

        $scope.changeSecurityNumberFormat =function(){
            if($scope.reporterData.security_number_type=='SSN'){
                $scope.reporterData.soc_sec_no =  $scope.reporterData.soc_sec_no.replace(/-/g, "");
                $scope.reporterData.soc_sec_no = $scope.reporterData.soc_sec_no.replace(/(\d{3})(\d{2})(\d+)/, '$1-$2-$3');
            }
            else if($scope.reporterData.security_number_type=='FEIN'){

                $scope.reporterData.soc_sec_no =  $scope.reporterData.soc_sec_no.replace(/-/g, "");
                $scope.reporterData.soc_sec_no = $scope.reporterData.soc_sec_no.replace(/(\d{2})(\d+)/, '$1-$2');
            }
            $scope.maskSecurityNumber();
        }

        $scope.updateOriginalNumber = function (){
            $scope.reporterData.soc_sec_no= $scope.reporterData.soc_sec_no_mask ;
        }

        $scope.updateRoutingNumber = function (){
            $scope.reporterData.RoutingNumber= $scope.reporterData.RoutingNumberMask ;
        }

        $scope.updateAccountNumber = function (){
            $scope.reporterData.AccountNumber= $scope.reporterData.AccountNumberMask ;
        }

        $scope.maskSecurityNumber = function(){
            if($scope.reporterData.security_number_type=='SSN' && $scope.reporterData.soc_sec_no){
                $scope.reporterData.soc_sec_no_mask = $scope.reporterData.soc_sec_no.replace(/^.{6}/g, 'XXX-XX');
            }
            else if($scope.reporterData.security_number_type=='FEIN' && $scope.reporterData.soc_sec_no){
                $scope.reporterData.soc_sec_no_mask = $scope.reporterData.soc_sec_no.replace(/^.{6}/g, 'XX-XXX'); 
            }
        }

        $scope.maskAcccountInfo = function(){
            if( $scope.reporterData &&  $scope.reporterData.RoutingNumber)
            $scope.reporterData.RoutingNumberMask = $scope.reporterData.RoutingNumber.replace(/./g, 'X');

            if( $scope.reporterData &&  $scope.reporterData.AccountNumber)
            $scope.reporterData.AccountNumberMask = $scope.reporterData.AccountNumber.replace(/./g, 'X');
        }

        $scope.sendDirectDepositForm = function(){
            if($scope.reporterData.Emai_Address){
                $rootScope.showLoader(true);
                commonFactory.post('/api/reptype/send-direct-deposit-form', {id:$scope.reporterData.id})
                .success(function(response) {
                    $rootScope.showLoader(false);
                    Notification.success(response.result.message);
                })
                .error(function(response){
                    $rootScope.showLoader(false);
                    Notification.error(response.result.message);
                })
            }
            else{
                Notification.error("This Resources does't have Email");
            }
        }
 
         
        $scope.validateEmails = function(data) {
            var emails = data.target.value.split(/,|;/);
            if(emails == ""){
                $scope.alternateEmailValid = true;
                $scope.alternate_emails_error = '';
                return
            }
            
            var isValid = true;
            for (var i = 0; isValid && i < emails.length; i++) {
              if (!validateEmail(emails[i])) {
                isValid = false;
                $scope.alternateEmailValid = false;
                $scope.alternate_emails_error = 'Email ' + emails[i] + ' does not appear to be a proper email';
                console.log($scope.alternate_emails_error,"E");
              }
              else{
                $scope.alternateEmailValid = true;
                $scope.alternate_emails_error = '';
              }
            }
          
          }
          function validateEmail(email) {
            var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*$/i;
            return EMAIL_REGEXP.test(email.trim());
          }

        $scope.tooltip = $sce.trustAsHtml('In Order To Print A Physical Check Print Check Needs To be Set To - YES' + '<br>' + '-------------------' + '<br>' + 'In Order To Create An Electronic Payment Print Check Needs To be Set To - NO');
      
        $scope.todayDae = $filter('date')(new Date(), 'yyyy-MM-dd');
        $scope.save_and_goto_general_info = false;
        $scope.save_and_general_info = function() {
            $scope.save_and_goto_general_info =true
            $scope.save();
        }
        //Previous and Next page button control
        if ($state.params && $state.params.id) {
            $scope.showPrivateTab = true;
            $scope.goto_rate = function() {
                $state.go('representative.reporter.step_two', { id: $state.params.id });
            }
            $scope.goto_other = function() {
                $state.go('representative.reporter.step_three', { id: $state.params.id });
            }
          
            $scope.goto_address = function() {
                $state.go('representative.reporter.step_one', { id: $state.params.id });
            }
        } else {
            $scope.goto_rate = function() {
                $state.go('representative.reporter.step_two');
            }
            $scope.goto_other = function() {
                $state.go('representative.reporter.step_three');
            }
            $scope.goto_address = function() {
                $state.go('representative.reporter.step_one');
            }
           

            if($state.current.name !== 'representative.reporter.step_one' && ( typeof $scope.reporterData.first_name == 'undefined' || $scope.reporterData.first_name=='' )){
                $state.go('representative.reporter.step_one');
            }

        }

        //Save method for saving (REPORTER) data into database.
        //UPdate method for updating (REPORTER) data into DB if get ID.
        if ($state.params && $state.params.id) {
            $scope.save = function() {

                if($scope.isRateChanged && (!$scope.reporterData.rate_change_reason || $scope.reporterData.rate_change_reason  == '' )){
                    swal({
                            title: 'Reporter rates changed - please provide a reason.',
                            html: true,
                            type: "info", 
                            confirmButtonColor: "#09375f",
                            confirmButtonText: "Change to OK only",
                            closeOnConfirm: true
                        },
                        function (isConfirm) {
                            if(isConfirm) {
                                return;
                            }
                        }); 
                }else{ 

                    $scope.signupForm = true;
                    console.log($scope.reporterData);
                    //Add Reporte type 'R' For Representative type Reporter into DB
                    $scope.reporterData.rep_typ = 'R';
                    $scope.reporterData.notary_expiration_date = moment($scope.reporterData.notary_expiration_date).format('YYYY-MM-DD');
                    $scope.reporterData.curr_paydt = moment($scope.reporterData.curr_paydt).format('YYYY-MM-DD');
                    $scope.reporterData.prev_paydt = moment($scope.reporterData.prev_paydt).format('YYYY-MM-DD');
                    
                    if($scope.reporterData.do_not_use != '0' && typeof($scope.reporterData.do_not_use) != 'undefined'){
                        $scope.reporterData.reason_do_not_use = $scope.reporterData.reason_do_not_use;
                    }else{
                        $scope.reporterData.reason_do_not_use = '';
                    }
                    
                    var id = $state.params ? $state.params.id : '';
                    $scope.reporterData.dep_attorney.perms = 'reporter';
                    if($scope.reporterData && $scope.reporterData.dep_attorney && $scope.reporterData.dep_attorney.password_expiration_date) {
                        $scope.reporterData.password_expiration_date = moment($scope.reporterData.dep_attorney.password_expiration_date).format('YYYY-MM-DD');
                    }
                    $rootScope.showLoader(true);
                    commonFactory.put('/api/reptype/' + id, $scope.reporterData)
                        .success(function(response) {
                            if (response && response.result && response.result.reptypeUpdate) {
                                $rootScope.showLoader(false);
                                $scope.privateTabAuthenticated = false;
                                Notification.success("Reporter updated successfully.");
                                //commented this code b'cus of client need.
                                // if($rootScope.hideCloseTabButton){
                                //     $rootScope.close_window();
                                // }
                                // else{
                                    
                                    // $state.go("representative.browse");
                                // }
                                if($scope.reporterData.link_user_id){
                                    $state.go("users.edit",{ id: $scope.reporterData.link_user_id });
                        
                                }else{
                                    if($scope.save_and_goto_general_info){
                                        $scope.save_and_goto_general_info =false
                                        $state.go("representative.reporter.general_information",{ id: $state.params.id });
                                        
                                    }else{
                                        $state.go("representative.browse");
                                    }
                                }
                            } else {
                                $rootScope.showLoader(false);
                            }
                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                            console.log(err);
                        });
                }
            }
        }
        //Add method for adding (REPORTER) data into DB if not Getting ID.
        else {
            $scope.reporterData.SORn = 2 ;
            $scope.save = function() {
                $scope.reporterForm = true;
                //Add Reporte type 'R' For Representative type Reporter into DB
                $scope.reporterData.rep_typ = 'R';
                $scope.reporterData.notary_expiration_date = moment($scope.reporterData.notary_expiration_date).format('YYYY-MM-DD');
                $scope.reporterData.curr_paydt = moment($scope.reporterData.curr_paydt).format('YYYY-MM-DD');
                $scope.reporterData.prev_paydt = moment($scope.reporterData.prev_paydt).format('YYYY-MM-DD');
                $scope.reporterData.dep_attorney.perms = 'reporter';

                $rootScope.showLoader(true);
                commonFactory.post('/api/reptype', $scope.reporterData)
                    .success(function(response) {
                        if (response && response.result && response.result.reptype) {
                            $rootScope.showLoader(false);
                            Notification.success("Reporter added successfully.");
                            if($scope.reporterData.link_user_id){
                                $state.go("users.edit",{ id: $scope.reporterData.link_user_id });
                    
                            }else{
                                if($scope.save_and_goto_general_info){
                                    $scope.save_and_goto_general_info =false
                                    $state.go("representative.reporter.general_information",{ id: response.result.reptype.id }, {reload: true});
                                    // $state.go("representative.reporter.general_information");
                                }else{
                                    $state.go("representative.browse");
                                }
                            }
                        } else {
                            $rootScope.showLoader(false);
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                        console.log(err);
                    });
            }
        } 
        
        //Get Method for Getting (REPORTER) data into EDIT MODE.
        $rootScope.getReptype = function(){
        var id = $state.params ? $state.params.id : '';
        if (id) // Make sure we are in edit mode.
        {
            commonFactory.get('/api/reptype/' + id + '/edit')
                .success(function(response) {

                    if(response && response.result && response.result.userWithSameEmail){
                        $scope.userIdWithSameEmail = response.result.userWithSameEmail.id;
                    }

                    if (response && response.result && response.result.reptypeInfo) {  
                        $scope.reloadTimeOffs();
                        $scope.reloadCertificates();
                        $scope.reloadGeneralAvailability();

                        // $scope.reporterData.totalUnpaidAmount = response.result.reptypeInfo.totalUnpaidAmount;
                        $scope.reporterData.id = response.result.reptypeInfo.id;
                        $scope.reporterData.title = response.result.reptypeInfo.TITLE;
                        $scope.reporterData.NAME_KEY = response.result.reptypeInfo.NAME_KEY;
                        $scope.reporterData.first_name = response.result.reptypeInfo.FIRST_NAME;
                        $scope.reporterData.last_name = response.result.reptypeInfo.LAST_NAME;
                        $scope.setPageTitle();
                        $scope.reporterData.person_first_name = response.result.reptypeInfo.person_first_name;
                        $scope.reporterData.person_last_name = response.result.reptypeInfo.person_last_name;
                        $scope.reporterData.Emai_Address = response.result.reptypeInfo.Emai_Address;
                        $scope.reporterData.alternate_emails = response.result.reptypeInfo.alternate_emails;
                        $scope.reporterData.phone = response.result.reptypeInfo.PHONE;
                        $scope.reporterData.addr_line1 = response.result.reptypeInfo.ADDR_LINE1;
                        $scope.reporterData.city = response.result.reptypeInfo.CITY;
                        $scope.reporterData.state = response.result.reptypeInfo.STATE;
                        $scope.reporterData.zip = response.result.reptypeInfo.ZIP;
                        $scope.reporterData.soc_sec_no = response.result.reptypeInfo.SOC_SEC_NO;
                        $scope.reporterData.security_number_type = response.result.reptypeInfo.security_number_type;
                        $scope.maskSecurityNumber();
                        $scope.reporterData.cell = response.result.reptypeInfo.CELL;
                        $scope.reporterData.addr_line2 = response.result.reptypeInfo.ADDR_LINE2;

                        $scope.reporterData.rate_1 = response.result.reptypeInfo.RATE_1;
                        $scope.reporterData.rate_2 = response.result.reptypeInfo.RATE_2;
                        $scope.reporterData.medicalrate = response.result.reptypeInfo.MedicalRate;
                        $scope.reporterData.copy_rt = response.result.reptypeInfo.COPY_RT;
                        $scope.reporterData.appear = response.result.reptypeInfo.APPEAR;
                        $scope.reporterData.hourly_pay = response.result.reptypeInfo.hourly_pay;

                        $scope.reporterData.pay_stips = response.result.reptypeInfo.PAY_STIPS;
                        $scope.reporterData.override_appear_rate_fee = response.result.reptypeInfo.override_appear_rate_fee || 'No';
                        $scope.reporterData.override_deduction = response.result.reptypeInfo.override_deduction || 'No';

                        $scope.reporterData.rate_e = response.result.reptypeInfo.RATE_E;
                        $scope.reporterData.rate_d = response.result.reptypeInfo.RATE_D;
                        $scope.reporterData.rate_i = response.result.reptypeInfo.RATE_I;
                        $scope.reporterData.beeper = response.result.reptypeInfo.BEEPER;
                        $scope.reporterData.late_app = response.result.reptypeInfo.LATE_APP;
                        $scope.reporterData.payment_type = response.result.reptypeInfo.payment_type;
                        $scope.reporterData.ns_internal_vendor_id = response.result.reptypeInfo.ns_internal_vendor_id;
                        $scope.reporterData.gl_internal_vendor_id = response.result.reptypeInfo.gl_internal_vendor_id;
                        $scope.reporterData.notary_number = response.result.reptypeInfo.NOTARY_NUMBER;
                        $scope.reporterData.notary_expiration_date = response.result.reptypeInfo.NOTARY_EXPIRATION_DATE || $scope.todayDae ;
                        $scope.reporterData.rate_l = response.result.reptypeInfo.RATE_L;
                        $scope.reporterData.date_added = response.result.reptypeInfo.DATE_ADDED;
                        $scope.reporterData.last_chgd = response.result.reptypeInfo.LAST_CHGD;
                        $scope.reporterData.notes_1 = response.result.reptypeInfo.NOTES_1;
                        $scope.reporterData.RoutingNumber = response.result.reptypeInfo.RoutingNumber;
                        $scope.reporterData.AccountNumber = response.result.reptypeInfo.AccountNumber;
                        $scope.maskAcccountInfo();

                        $scope.reporterData.p1099 = response.result.reptypeInfo.P1099;
                        $scope.reporterData.curr_pay = response.result.reptypeInfo.CURR_PAY;
                        $scope.reporterData.curr_paydt = response.result.reptypeInfo.CURR_PAYDT;
                        $scope.reporterData.prev_pay = response.result.reptypeInfo.PREV_PAY;

                        $scope.reporterData.prior_agency_experience = response.result.reptypeInfo.prior_agency_experience;
                        $scope.reporterData.join_years = response.result.reptypeInfo.join_years;
                        
                        $scope.reporterData.grade_id = response.result.reptypeInfo.grade_id;
                        // if(response.result.reptypeInfo.work_type)
                        $scope.reporterData.work_type = response.result.reptypeInfo.work_type.toString();

                        $scope.reporterData.modality_id = response.result.reptypeInfo.modality_id;
                        $scope.reporterData.birth_year = response.result.reptypeInfo.birth_year;
                        $scope.reporterData.can_drive = response.result.reptypeInfo.can_drive;
                        $scope.reporterData.special_notes = response.result.reptypeInfo.special_notes;

                        $scope.reporterData.prev_paydt = response.result.reptypeInfo.PREV_PAYDT || '';
                        $scope.reporterData.ytd_pay = response.result.reptypeInfo.YTD_PAY;
                        $scope.reporterData.ytd_bill = response.result.reptypeInfo.YTD_BILL;

                        $scope.reporterData.yearToDateCOGSPercentage = null;
                        $scope.reporterData.yearToDateGMPercentage = null;
                        if(response.result.reptypeInfo.YTD_PAY && response.result.reptypeInfo.YTD_BILL) {
                            $scope.reporterData.yearToDateCOGSPercentage = ((response.result.reptypeInfo.YTD_PAY / response.result.reptypeInfo.YTD_BILL) * 100).toFixed(2) + '%';
                            $scope.reporterData.yearToDateGMPercentage = (((response.result.reptypeInfo.YTD_BILL - response.result.reptypeInfo.YTD_PAY) / response.result.reptypeInfo.YTD_BILL) * 100).toFixed(2) + '%';
                        }
                        
                        $scope.reporterData.ytd_pgs = response.result.reptypeInfo.YTD_PGS;
                        $scope.reporterData.status = response.result.reptypeInfo.STATUS;
                        $scope.reporterData.notes_2 = response.result.reptypeInfo.NOTES_2;
                        $scope.reporterData.rate_changed_by = response.result.reptypeInfo.RATE_CHANGED_BY;
                        $scope.reporterData.rate_change_date = response.result.reptypeInfo.RATE_CHANGE_DATE;
                        $scope.reporterData.rate_change_reason = response.result.reptypeInfo.RATE_CHANGE_REASON;

                        // Skills
                        if(response.result.reptypeInfo.resource_skills.length) {
                            $scope.reporterData.resourceSkillsIds = []
                            index = 0
                            response.result.reptypeInfo.resource_skills.forEach(item => {
                                $scope.reporterData.resourceSkillsIds[index] = item.resource_skill_id
                                index++
                            });
                        }
                        // Coverage 
                        if(response.result.reptypeInfo.coverage_area.length) {
                            $scope.reporterData.coverageAreaIds = []
                            index = 0
                            response.result.reptypeInfo.coverage_area.forEach(item => {
                                $scope.reporterData.coverageAreaIds[index] = item.venue_id
                                index++
                            });
                        }
                        $scope.reporterData.coverage_radius = response.result.reptypeInfo.coverage_radius;
                        $scope.reporterData.coverage_notes = response.result.reptypeInfo.coverage_notes;
                        // End Coverage
                        
                        $scope.reporterData.bust_fee = response.result.reptypeInfo.bust_fee;
                        $scope.reporterData.is_active = response.result.reptypeInfo.is_active;
                        $scope.reporterData.SOR = response.result.reptypeInfo.SOR;
                        $scope.reporterData.SORr = response.result.reptypeInfo.SORr;
                        $scope.reporterData.SORn = Number(response.result.reptypeInfo.SORn) > 0 ? Number(response.result.reptypeInfo.SORn) : 2;
                        if (response.result.reptypeInfo.business_unit_id == 14){
                            $scope.reporterData.SORn = Number(response.result.reptypeInfo.SORn);
                        }
                        $scope.reporterData.start_date = response.result.reptypeInfo.created_at ? moment(response.result.reptypeInfo.created_at).format('MM/DD/YYYY hh:mm:ss a') : '--/--/---';
                        $scope.reporterData.dep_attorney = response.result.reptypeInfo.dep_attorney;
                        $scope.reporterData.business_unit_id = response.result.reptypeInfo.business_unit_id; 
                        $scope.reporterData.woking_days = response.result.reptypeInfo.woking_days
                        $scope.reporterData.repoter_availability_type_id = response.result.reptypeInfo.repoter_availability_type_id
                        if(response.result.reptypeInfo.internal_rating)
                        $scope.reporterData.internal_rating = response.result.reptypeInfo.internal_rating.toString()
                        $scope.reporterData.do_not_use = response.result.reptypeInfo.do_not_use;
                        $scope.reporterData.reason_do_not_use = response.result.reptypeInfo.reason_do_not_use;
                        $scope.reporterData.expidite_1_2_days_rate = response.result.reptypeInfo.expidite_1_2_days_rate;
                        $scope.reporterData.workers_comp_exp_rate = response.result.reptypeInfo.workers_comp_exp_rate;
                        $scope.reporterData.workers_comp_over_min_page_rate = response.result.reptypeInfo.workers_comp_over_min_page_rate;
                        $scope.reporterData.hearing_rate = response.result.reptypeInfo.hearing_rate;
                        $scope.reporterData.dwi_flat_rate = response.result.reptypeInfo.dwi_flat_rate;
                        $scope.reporterData.workers_comp_flat_pages_go_over = response.result.reptypeInfo.workers_comp_flat_pages_go_over;
                        $scope.reporterData.is_payment_required = response.result.reptypeInfo.is_payment_required.toString();
                        $scope.reporterData.allow_invite_exhibits = response.result.reptypeInfo.allow_invite_exhibits.toString();
                        $scope.reporterData.allow_invite_pre_exhibits = response.result.reptypeInfo.allow_invite_pre_exhibits.toString();
                        $scope.reporterData.is_job_sheet_view = response.result.reptypeInfo.is_job_sheet_view.toString();

                        $scope.reporterData.rca = response.result.reptypeInfo.rca;
                        $scope.reporterData.software_id = response.result.reptypeInfo.software.map((software) => software.id);
                        $scope.reporterData.job_type_id = response.result.reptypeInfo.job_types.map((job_type) => job_type.id);
                        $scope.reporterData.witness_type_id = response.result.reptypeInfo.witness_types.map((witness_type) => witness_type.id);
                        $scope.reporterData.case_type_id = response.result.reptypeInfo.case_types.map((case_type) => case_type.id);

                        // $scope.certificates = response.result.reptypeInfo.certificates;

                        $scope.payment_date =  response.result.reptypeInfo.businessunit.pay_date
                        // History
                        if (response.result.reptypeInfo.reptypehistory) {
                            $scope.reptypehistory = response.result.reptypeInfo.reptypehistory || '';
                        }
                        if(response.result.reptypeInfo.specialties.length){
                           
                           
                            
                            $scope.reporterData.specialtiesIds = []
                            inx = 0
                            response.result.reptypeInfo.specialties.forEach(element => {
                                $scope.reporterData.specialtiesIds[inx] =element.specialtie_id
                                inx++
                            });
                            
                            
                        }
                        if(response.result.reptypeInfo.prioragency.length){
                            
                            $scope.reporterData.prioragencyIds = []
                            inx = 0
                            response.result.reptypeInfo.prioragency.forEach(element => {
                                $scope.reporterData.prioragencyIds[inx] =element.prior_agency_id
                                inx++
                            }); 
                        }
                        if(response.result.reptypeInfo.prioragency.length){
                           
                           
                            
                            $scope.reporterData.prioragencyIds = []
                            inx = 0
                            response.result.reptypeInfo.prioragency.forEach(element => {
                                $scope.reporterData.prioragencyIds[inx] =element.prior_agency_id
                                inx++
                            });
                            
                            
                        }
                        if(response.result.reptypeInfo.additionalinfo.length){
                           
                           
                            
                            $scope.reporterData.additionalinfoIds = []
                            inx = 0
                            response.result.reptypeInfo.additionalinfo.forEach(element => {
                                $scope.reporterData.additionalinfoIds[inx] =element.additional_info_id
                                inx++
                            });
                            
                            $scope.disableOtherSkills();
                        }
                        if(response.result.reptypeInfo.capabilitys.length){
                            $scope.reporterData.capabilityIds = []
                            inx = 0
                            response.result.reptypeInfo.capabilitys.forEach(element => {
                                $scope.reporterData.capabilityIds[inx] =element.capability_id
                                inx++
                            });
                             console.log($scope.reporterData.capabilityIds)
                        }
                        if(response.result.reptypeInfo.clientpreference.length){
                            var preference_index = 0
                            var avoid_index = 0
                            $scope.reporterData.ClientPreferenceAvoidIds = []
                            $scope.reporterData.ClientPreferenceIds = []
                            response.result.reptypeInfo.clientpreference.forEach(element => {
                                if(element.flag == 'P'){
                                $scope.reporterData.ClientPreferenceIds[preference_index] =element.firm
                                preference_index++
                                }
                                if(element.flag == 'A'){
                                    $scope.reporterData.ClientPreferenceAvoidIds[avoid_index] =element.firm
                                    avoid_index++
                                }
                            });
                             
                        }
                        if(response.result.reptypeInfo.availability_date.length){
                            var avalable_index = 0
                            var not_avalable_index = 0
                            $scope.reporterData.NotAvailableDateRange = []
                            $scope.reporterData.AvailableDateRange = []
                            response.result.reptypeInfo.availability_date.forEach(element => {
                                if(element.flag == 1){
                                    $scope.reporterData.AvailableDateRange.push({
                                        id:avalable_index,
                                        date:element.start_date+' - '+element.end_date,
                                        startDate:element.start_date,
                                        endDate:element.end_date
                                    })
                                avalable_index++
                                }
                                if(element.flag == 2){
                                    
                                    $scope.reporterData.NotAvailableDateRange.push({
                                        id:not_avalable_index,
                                        date:element.start_date+' - '+element.end_date,
                                        startDate:element.start_date,
                                        endDate:element.end_date
                                    })
                                   
                                    not_avalable_index++
                                }
                            });
                             
                        }
                        
                        $scope.reporterData.availableafter = response.result.availableafter;
                        $scope.reporterData.time = response.result.time;
                        $scope.reporterData.timeZone = response.result.timeZone;

                        //dep_attorney default values 
                        $scope.reporterData.dep_attorney.view_enotary = $scope.reporterData.dep_attorney.view_enotary ?  $scope.reporterData.dep_attorney.view_enotary.toString() : '0';
                        $scope.reporterData.dep_attorney.availableafter = 
                        ($scope.reporterData.dep_attorney.availableafter && $scope.reporterData.dep_attorney.availableafter !== "") 
                        ? $scope.reporterData.dep_attorney.availableafter 
                        : Object.keys($scope.reporterData.availableafter)[0];
                    
                        $scope.reporterData.dep_attorney.time = 
                        ($scope.reporterData.dep_attorney.time && $scope.reporterData.dep_attorney.time !== "") 
                        ? $scope.reporterData.dep_attorney.time 
                        : Object.keys($scope.reporterData.time)[0];
                        $scope.reporterData.dep_attorney.send_job_confirmation_sms = $scope.reporterData.dep_attorney.send_job_confirmation_sms ?? "Y";
                        $scope.reporterData.dep_attorney.send_job_confirmation = $scope.reporterData.dep_attorney.send_job_confirmation ?? "Y";
                        $scope.reporterData.dep_attorney.is_reset_password_enabled = $scope.reporterData.dep_attorney.is_reset_password_enabled ?? "Y";
                        $scope.reporterData.dep_attorney.password_expiration_date = 
                            $scope.reporterData.dep_attorney.password_expiration_date  && $scope.reporterData.dep_attorney.password_expiration_date != '0000-00-00'
                                ? moment($scope.reporterData.dep_attorney.password_expiration_date).format("MM-DD-YYYY")
                                : moment().add(180, 'days').format('MM-DD-YYYY');
                        $scope.reporterData.dep_attorney.timezone = $scope.reporterData.dep_attorney.timezone != null ? $scope.reporterData.dep_attorney.timezone.toString() : '';

                        //toggle to the Notary Number & Date fields
                        $scope.reporterData.notary_expiration_date = $scope.reporterData.notary_expiration_date 
                        ? moment($scope.reporterData.notary_expiration_date).format("MM-DD-YYYY")
                        : null;

                        $scope.reporterData.dep_attorney.dte_notary_expire = $scope.reporterData.dep_attorney.dte_notary_expire 
                        ? moment($scope.reporterData.dep_attorney.dte_notary_expire).format("MM-DD-YYYY")
                        : null;

                        if ($scope.reporterData.notary_expiration_date && $scope.reporterData.dep_attorney.dte_notary_expire) {
                            if(moment($scope.reporterData.notary_expiration_date, "MM-DD-YYYY")
                                .isAfter(moment($scope.reporterData.dep_attorney.dte_notary_expire, "MM-DD-YYYY"))){
                                    $scope.reporterData.notary_expiration_date = $scope.reporterData.notary_expiration_date;
                                    $scope.reporterData.notary_number = $scope.reporterData.notary_number ?? $scope.reporterData.dep_attorney.notary_num ;
                            }else{
                                $scope.reporterData.notary_expiration_date = $scope.reporterData.dep_attorney.dte_notary_expire;
                                $scope.reporterData.notary_number = $scope.reporterData.dep_attorney.notary_num ?? $scope.reporterData.notary_number ;
                            }
                        } else if ($scope.reporterData.notary_expiration_date) {
                            $scope.reporterData.notary_expiration_date = $scope.reporterData.notary_expiration_date;
                            $scope.reporterData.notary_number = $scope.reporterData.notary_number ?? $scope.reporterData.dep_attorney.notary_num ;
                        } else if ($scope.reporterData.dep_attorney.dte_notary_expire) {
                            $scope.reporterData.notary_expiration_date = $scope.reporterData.dep_attorney.dte_notary_expire;
                            $scope.reporterData.notary_number = $scope.reporterData.dep_attorney.notary_num ?? $scope.reporterData.notary_number ;
                        } else {
                            $scope.reporterData.notary_expiration_date = moment().format("MM-DD-YYYY");
                        } 
                        
                    } else {
                        console.log("Not get any response")
                    }
                })
                .error(function(err) {
                    console.log(err);
                });
        }else{
            commonFactory.get('/api/reptype/create')
            .success(function(response) {
                if (response && response.result) {  
                    $scope.reporterData.availableafter = response.result.availableafter;
                    $scope.reporterData.time = response.result.time;
                    $scope.reporterData.timeZone = response.result.timeZone;
                    $scope.reporterData.dep_attorney = {
                        view_enotary : '0',
                        availableafter :  Object.keys($scope.reporterData.availableafter)[0],
                        time : Object.keys($scope.reporterData.time)[0],
                        send_job_confirmation_sms : "Y",
                        send_job_confirmation : "Y",
                    }
                    $scope.reporterData.dep_attorney.password_expiration_date = moment().add(180, 'days').format('MM-DD-YYYY');
                } else {
                    console.log("Not get any response")
                }
            })
            .error(function(err) {
                console.log(err);
            });
        }
    }
    $rootScope.getReptype();
        //Disabled Rate change Reason related fields.
        if ($state.params && $state.params.id) { //EDIT MODE
            
            $scope.isDisabled = true;
            
            if($rootScope.havePermission('allow_access_without_password','allow.resource.rate.change')){
                $scope.isDisabled = false;
            }

            $scope.$watch('reporterData.rate_change_reason', function(newValue, oldValue) {
                if (newValue == null || newValue == '' || newValue == 'undefined' || newValue == undefined || (newValue && newValue.length == 0)) {
                    // $scope.isDisabled = true;

                } else {
                    $scope.reporterData.rate_changed_by = $scope.Authuser.name;
                    $scope.reporterData.rate_change_date = $scope.todayDae;
                    // $scope.isDisabled = false;
                }
            });
        } else {
            $scope.isDisabled = false;
            $scope.$watch('reporterData.rate_change_reason', function(newValue, oldValue) { //ADD MODE
                if (newValue != null || newValue != '' || newValue != 'undefined' || newValue != undefined || (newValue && newValue.length != 0)) {
                    $scope.reporterData.rate_changed_by = $scope.Authuser.name;
                    $scope.reporterData.rate_change_date = $scope.todayDae;
                }
            });
        }

        $rootScope.redirectDepoEdit = function(data){
            var enscript = encodeURIComponent('auth_user_edit.dtop?key='+data.uid);
            var auth_user = $rootScope.auth_user;
            var givenUrl = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;
    
            $window.open(givenUrl, '_blank');
        };
    
        $rootScope.redirectDepoLaunch = function(uid){
           
            var enscript = 'launch_user.dtop?uid='+uid+'&actionfrom=backend';
            var auth_user = $rootScope.auth_user;
            
            $http.post(apiUrl + '/api/auth-user-get-token', {uid : uid}).then(function(response) { 
                if(response.data.status){
                    var givenUrl = $rootScope.depoHost +"/"+enscript+"&access_token="+response.data.token;
                    $window.open(givenUrl, '_blank');
                }else{
                    Notification.error('Something went wrong!'); 
                }
            });
        };
        $rootScope.redirectDepoLaunchAsRepoter = function(uid){
           
            var enscript = 'launch_user.dtop?uid='+uid+'&actionfrom=backend&toggle_to_repoter=1';
            var auth_user = $rootScope.auth_user;
            
            $http.post(apiUrl + '/api/auth-user-get-token', {uid : uid}).then(function(response) { 
                if(response.data.status){
                    var givenUrl = $rootScope.depoHost +"/"+enscript+"&access_token="+response.data.token;
                    $window.open(givenUrl, '_blank');
                }else{
                    Notification.error('Something went wrong!'); 
                }
            });
        };
        $rootScope.redirectAddEdit = function(data){
           
            var enscript = encodeURIComponent('auth_user_edit.dtop?emailData='+data.Emai_Address+'&repId='+data.NAME_KEY+'&first_name='+data.first_name+'&last_name='+data.last_name);
            
            
             var auth_user = $rootScope.auth_user;
             var givenUrl = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;
     
             $window.open(givenUrl, '_blank');
         };
        $rootScope.redirectWorkFlow = function(id){
           
            var enscript = encodeURIComponent('reporter_workflow_reminder.dtop?repoter_code='+id);

            var auth_user = $rootScope.auth_user;
                       var givenUrl = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;
               
            $window.open(givenUrl, '_blank');
        };
        $scope.unlockReporter = function() {
            // $scope.lock_screen = false;
            SweetAlert.swal({
                title: "PLEASE ENTER PASSWORD TO UNLOCK SCREEN",
                // text: "Please enter password to unlock fields.",
                type: "input",
                inputType: "password",
                showCancelButton: true,
                closeOnConfirm: true
                // inputPlaceholder: "password"
            }, function(inputValue) {
                if (inputValue === false) return false;
                if (inputValue === "") {
                    swal.showInputError("You need to write something!");
                    return false
                }
                else {
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/authpayroll', { 'password': inputValue })
                        .success(function(response) {
                            if (response['result']) {
                                if (response['result']['auth']) {
                                    // SweetAlert.swal("Nice !", "You are authenticated user !", "success");
                                    $scope.isDisabled = false;
                                    $rootScope.showLoader(false);
                                } else {
                                    $rootScope.showLoader(false);
                                    SweetAlert.swal("Error !", "Wrong Password!", "error");
                                }
                            } else {
                                $rootScope.showLoader(false);
                                SweetAlert.swal("Oops !", "Something went wrong!", "error");
                            }
                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                            SweetAlert.swal("Oops !", "Something went wrong!", "error");
                        });

                }
                // swal("Nice!", "You wrote: " + inputValue, "success");
            });
        };

        $(window).bind('keydown', function(event) {
            if($rootScope.havePermission('representative_types','update',$scope.reporterData.business_unit_id) || !$state.params.id){
                if (event.ctrlKey || event.metaKey) {

                    switch (String.fromCharCode(event.which).toLowerCase()) {

                        case 's':

                            event.preventDefault();

                            if($state.current.name == "representative.reporter.step_one" && !$scope.reporterForm.$invalid ){
                                $scope.goto_rate();
                            }
                            if($state.current.name == "representative.reporter.step_two" && !$scope.reporterForm.$invalid ){
                                $scope.goto_other();
                            }
                            if($state.current.name == "representative.reporter.step_three" && !$scope.reporterForm.$invalid ){
                                $scope.save();
                            }
                            // $scope.invoiceGenerateForm.$setSubmitted();
                            $scope.$apply();
                            // $scope.saveJobInvoice($scope.invoiceGenerateForm.$valid,false,false,false);
                            break;

                        case 'p':
                            event.preventDefault();

                            if($state.current.name == "representative.reporter.step_two" && !$scope.reporterForm.$invalid ){
                                $scope.goto_address();
                            }
                            if($state.current.name == "representative.reporter.step_three" && !$scope.reporterForm.$invalid ){
                                $scope.goto_rate();
                            }
                            // $scope.invoiceGenerateForm.$setSubmitted();
                            $scope.$apply();
                            // $scope.saveJobInvoice($scope.invoiceGenerateForm.$valid,false,true,false);
                            break;
                    }
                }
            }
        });

        $scope.showUnpaidJobOfResource = function () {

            $scope.showUnpaidJobsMenuActive = true;
            async.series([
                    // function(callback){
                    //     $rootScope.showLoader(true);
                        // do some stuff ...
                        // get the last check number and payment date
                        // $http.get(apiUrl + '/api/config', {
                        //     params: {
                        //         'test': 'test'
                        //     }
                        // }).then(function(response) {
                        //     $scope.configs = response.data.result.config;
                        //     angular.forEach($scope.configs, function(value, key) {
                        //         if (value.key == "pay_date") {
                        //             $scope.payment_date = value.value;
                        //             // var parts = $scope.operation.payment_date.split('/');
                        //             // $scope.operation.payment_date = $filter('date')(new Date(parts[2], parts[1] - 1, parts[0]), "MM/dd/yyyy");
                        //         }
                        //     });
                        //     $rootScope.showLoader(false);
                        //     callback(null, 'one');
                        // });
                        // $scope.payment_date = $scope.reporterData.businessunit.pay_date
                    // },
                    function(callback){
                        // do some more stuff ...
                        if($state.params && $state.params.id){
                            var jobModal = $uibModal.open({
                                templateUrl: "modules/payroll/view_resource_jobs.html",
                                controller: 'viewResourceUnpaidJobsController',
                                resolve: {
                                    resource_id: function () {
                                        return $state.params.id;
                                    },
                                    resource_type : function(){
                                        return 'B';
                                    },
                                    payment_date : function(){
                                        // return $scope.operation.payment_date;
                                        return moment($scope.payment_date).format('MM/DD/YYYY');
                                    },
                                    selectedBusinessUnitIDs : function(){
                                        return [];
                                    }
                                },
                                windowClass: 'full-width-model',
                                keyboard: false,
                                backdrop: false,
                                size: 'lg',
                                close: function () {

                                }
                            });


                            jobModal.result.then(function (is_date_changed) {
                                $scope.showUnpaidJobsMenuActive = false;
                                //console.log(is_date_changed,invoices);
                                if (is_date_changed) {
                                    // var data = {invoice_ids : invoices};
                                    // console.log(data);
                                    // need to update the flag for print
                                    // $scope.getTotalPendingAmount();
                                }
                            }, function () {
                                console.log("Modal Dismissed!!!");
                            });
                        }
                        callback(null, 'two');
                    }
                ],
                // optional callback
                function(err, results){
                    // results is now equal to ['one', 'two']
                    console.log(results);
                });




        }

        $scope.showPaidJobOfResource = function () {

            $rootScope.showPaidJobsMenuActive = true;
            $rootScope.showLoader(true);
            $uibModal.open({
                templateUrl: "modules/representative/addreporter/reporter_paid_jobs.html",
                controller: function($scope,resource_id, $uibModalInstance,commonFactory){


                    $scope.report = {
                        startDate : moment().startOf('year').format('YYYY-MM-DD'),
                        endDate : moment().endOf('year').format('YYYY-MM-DD')
                    };

                    $scope.itemsPerPage = 10;
                    $scope.currentPage = 1;
        
        
                    $scope.resetPagination = function (number=10){
                        $scope.currentPage = 1; //reset to first paghe
                        $scope.itemsPerPage = number;
                        $scope.viewPaidJob();
                    };
                    $scope.numberWithCommas = function(x) {
                        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                    }


                    $scope.viewPaidJob = function() {
                        $rootScope.showLoader(true);

                        var offset = ($scope.currentPage - 1) * $scope.itemsPerPage;
                        var limit = $scope.itemsPerPage;


                        var form_data = {};
                        form_data.startDate = moment($scope.report.startDate).format('YYYY-MM-DD');
                        form_data.endDate = moment($scope.report.endDate).format('YYYY-MM-DD');
                        form_data.offset = offset;
                        form_data.limit=limit;


                        commonFactory.post('/api/reptype/get-reporter-paid-job/' + resource_id,form_data).then(function (response) {
                            $rootScope.showLoader(false);
                            $scope.repCheckData = response.data.result;
                            $scope.totalRecords = response.data.totalRecords;
                            $scope.totalpayment = response.data.totalpayment;
                            

                            // Add the Charge of Complete additional Charge
                            $scope.totalReporterAmount = $scope.totalpayment.reduce(function (pv, cv) {
                                if (cv.REP_PAYAMT) {
                                    return pv + parseFloat(cv.REP_PAYAMT);
                                }
                                else {
                                    return pv;
                                }
                            }, 0);


                            $scope.isRec = false;
                            if ($scope.repCheckData.length > 0) {
                                $scope.isRec = true;
                            }
                        });
                    }
                    $scope.closePopupWindow = function () {
                        $rootScope.showPaidJobsMenuActive = false;
                        $uibModalInstance.close();                     
                    }

                    // Added to sort the record by any field from the table.
                    $scope.predicate = 'REP_PAYDT';
                    $scope.reverse = true;
                    $scope.order = function(predicate) {
                        $scope.reverse = ($scope.predicate === predicate) ? !$scope.reverse : false;
                        $scope.predicate = predicate;
                    };

                    $scope.viewPaidJob();
                    $scope.getjobresourceid = function(jobres_id){ 
                        $rootScope.showLoader(true);
                        $http.get(apiUrl + '/api/get-job-resource', {
                            params: {
                                job_no:jobres_id
                            }
                        }).then(function(response) {                            
                            if(response.error){
                                Notification.error(response.data.result.message | 'Error while performing this operation.');
                            } else{                              
                                $rootScope.showLoader(false);
                             if(response.data.result.data.length==1){  
                                var url = $state.href('payroll.view',{ id : response.data.result.data[0].id});
                                $window.open(url,'_blank');
                            }
                            else{                              
                                var url = $state.href('payroll.resourceslist',{ job_no : jobres_id});
                                $window.open(url,'_blank');
                            }
                        }
                        });                        
                    }

                    $scope.sendPaystubEmail = function(checkno, email){
                        $uibModal.open({
                            templateUrl: "modules/representative/SendEmail.html",
                            controller: function($scope, $uibModalInstance){
             
                                $scope.title = "Send Email";
                                $scope.emails = '';
                                $rootScope.showLoader(true);

                                commonFactory.get('/api/reptype/' + id + '/edit')
                                    .success(function(response) {
                                    if (response && response.result && response.result.reptypeInfo) {  
                                        $scope.emails = response.result.reptypeInfo.Emai_Address;
                                    }
                                    $rootScope.showLoader(false);
                                });
                                
                                $scope.cancel = function(isChanged) {
                                    $uibModalInstance.dismiss({ isChanged : isChanged});
                                };
             
                                $scope.sendEmail = function(){
                                    if($scope.emails){
                                        function validateEmail(email) {
                                            var EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                            return EMAIL_REGEXP.test(email.trim());
                                        }
                                        var isValid = true
                                        var emails = $scope.emails.split(/,|;/);
                                        for (var i = 0; isValid && i < emails.length; i++) {
                                            if (!validateEmail(emails[i])) {
                                                isValid = false;
                                            }
                                        }
                                        if(isValid){
                                            $rootScope.showLoader(true);
                                            commonFactory.post('/api/check/auth_get_check_paid_job_print', {'resource_id': id, 'check_no': checkno, 'type': 'reporter', 'emails': emails})
                                                .success(function(response) {
                                                    $rootScope.showLoader(false);
                                                    Notification.success("Email sent successfully");
                                                    $scope.cancel();
                                                })
                                                .error(function(err) {
                                                    $rootScope.showLoader(false);
                                                    Notification.error("Oops !, Something went wrong!");
                                                });
                                        }else{
                                            Notification.error('Please enter valid To Emails.');
                                        }
                                    }else{
                                        Notification.error('To Emails must be required.');
                                    }
                                }
                            },
                            keyboard: false,
                            backdrop: false,
                            close: function () {
             
                            }
                        });
                    }

                    $scope.paystub = function(checkno){
                      
                        $uibModal.open({
                            templateUrl: "modules/representative/addreporter/paystub.html",
                            controller: function($scope,resource_id, $uibModalInstance,commonFactory,parentscope){

                                $rootScope.showLoader(true);
                                commonFactory.post('/api/check/auth_get_check_paid_job_print', {'resource_id': id, 'check_no': checkno, 'type': 'reporter'})
                                    .success(function(response) {
                                        $rootScope.showLoader(false);
                                        $scope.paystubs = response.result;
                                        $scope.resource_type = 'R';
                                        $scope.checkno = checkno;
                                        $scope.totalPaystubAmount = $scope.paystubs.reduce(function (pv, cv) {
                                            if(cv.REP_PAYAMT){
                                                return pv + parseFloat(cv.REP_PAYAMT);
                                            }
                                            else{
                                                return pv;
                                            }
                                        }, 0);
                                        $scope.closePopupWindowSimple = function(){
                                            $uibModalInstance.close(true);
                                        };
                                        $scope.getjobresource = function(job_no){                                           
                                            parentscope.getjobresourceid(job_no);
                                            
                                        };
                                        $scope.sendPaystubEmail = function(){                
                                            parentscope.sendPaystubEmail(checkno);
                                        };
                                    })
                                    .error(function(err) {
                                        $rootScope.showLoader(false);
                                        SweetAlert.swal("Oops !", "Something went wrong!", "error");
                                    });

                            },
                            resolve : {
                                resource_id : function(){
                                    return id;
                                },                               
                                parentscope :function (){
                                    return $scope;
                                }                                 
                            },
                            windowClass: '',
                            keyboard: false,
                            backdrop: false,
                            size: 'lg',
                            close: function () {
            
                            }
                        });
                    }

                    $scope.last_check_number = 0;
                    $scope.isCheckChange = function(index){
                        var checkRec = $scope.repCheckData[index];
                        if(index ==0){
                            $scope.last_check_number = checkRec.REP_CHECK;
                            return false;
                        }
                        if($scope.last_check_number != checkRec.REP_CHECK){
                            $scope.last_check_number = checkRec.REP_CHECK;
                            return true;
                        }else{
                            return false;
                        }
                    };

                    $scope.printPaidJobs = function(divName){
                        $("#" + divName).show();
                        var printContents = $("#" + divName);
                        printContents.find('#nivoice_button').remove();
                        printContents = printContents.html();
                        var popupWin = window.open('', '_blank');
                        popupWin.document.open();
                        popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="css/common.css"><link rel="stylesheet" type="text/css" href="font-awesome/css/font-awesome.css"><style type="text/css" media="print"> @media print { div {height:100%;overflow:visible;}}</style></head><body onload="window.print()">' + printContents + '</body></html>');
                        popupWin.document.close();
                        $("#" + divName).hide();
                    }

                },
                size:'lg',
                backdrop:false,
                resolve : {
                    resource_id : function(){
                        return id;
                    }
                }
            });
 
             
        } 
       

        $scope.showCheckOfReporter = function(){
            $rootScope.showLoader(true);
            $rootScope.showCheckReporterMenuActive = true;

            $uibModal.open({
                templateUrl: "modules/representative/addreporter/reporter_checks.html",
                controller: function($scope,$uibModalInstance,commonFactory,resource_id){


                    $scope.report = {
                        startDate : moment().startOf('year').format('YYYY-MM-DD'),
                        endDate : moment().endOf('year').format('YYYY-MM-DD')
                    };

                    $scope.searchCheck = function(){

                        var form_data = {};
                        form_data.startDate = moment($scope.report.startDate).format('YYYY-MM-DD');
                        form_data.endDate = moment($scope.report.endDate).format('YYYY-MM-DD');
                        form_data.type = 'R';


                        $rootScope.showLoader(true);
                        commonFactory.post('/api/reptype/get-checks/'+resource_id,form_data).then(function (response) {
                            $rootScope.showLoader(false);
                            $scope.repCheckData = response.data.result;
                            // Add the Charge of Complete additional Charge
                            $scope.totalReporterAmount = $scope.repCheckData.reduce(function (pv, cv) {
                                if(cv.CHECK_AMT){
                                    return pv + parseFloat(cv.CHECK_AMT);
                                }
                                else{
                                    return pv;
                                }
                            }, 0);

                            $scope.isRec =false;
                            if($scope.repCheckData.length > 0){
                                $scope.isRec = true;
                            }
                        });
                    }
                    $scope.closePopupWindow = function () {
                        $rootScope.showCheckReporterMenuActive = false;
                        $uibModalInstance.close();
                    }


                    // call for first time
                    $scope.searchCheck();


                    $scope.printChecks = function(divName){


                        $("#" + divName).show();
                        var printContents = $("#" + divName);
                        printContents.find('#nivoice_button').remove();
                        printContents = printContents.html();
                        var popupWin = window.open('', '_blank');
                        popupWin.document.open();
                        popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="css/common.css"><link rel="stylesheet" type="text/css" href="font-awesome/css/font-awesome.css"><style type="text/css" media="print"> @media print { div {height:100%;overflow:visible;}}</style></head><body onload="window.print()">' + printContents + '</body></html>');
                        popupWin.document.close();
                        $("#" + divName).hide();
                    }

                },
                resolve : {
                    resource_id : function(){
                        return id;
                    }
                }
            });
        }

        $scope.getStatementWorkInProgress = function(){

            var reportModal = $uibModal.open({
                templateUrl: "modules/firms/browse/statement_modal.html",
                controller: function($scope,NAME_KEY,$uibModalInstance,$sce,$rootScope){

                    var auth_user = $rootScope.auth_user;
                    $scope.givenUrl = $sce.trustAsResourceUrl( $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script=reporter_workflow_reminder.dtop?r_tablename=cache_workflow_reporter_templod2qstkltgic1un4tpnpluft0&r_searchfield=REPORTER&r_search="+NAME_KEY+"&searchbutton=Go");

                    $scope.spinnerLoader= true;
                    $scope.closePopupWindow = function(){
                        $uibModalInstance.close(true);
                    };
                    $scope.iframeLoadedCallBack = function(){
                        // do stuff
                        $scope.spinnerLoader= false;
                        $scope.$apply();
                        console.log("loading Complete");
                    }


                },
                resolve: {
                    NAME_KEY : function(){
                        return $scope.reporterData.NAME_KEY;
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

            reportModal.result.then(function () {
            }, function () {
                console.log("Modal Dismissed!!!");
            });
        }

        $scope.askPrivateTabPass = function(){
            if($rootScope.havePermission('allow_access_without_password','allow.resource.private.info')){
                $scope.setPageTitle();
                
                $scope.privateTabAuthenticated = true;
                $state.go("representative.reporter.step_four", {id : $state.params.id});
                return;

            }else if($scope.privateTabAuthenticated){
                $state.go("representative.reporter.step_four", {id : $state.params.id});
                return;
            } else{
                SweetAlert.swal({
                    title: "PLEASE ENTER PASSWORD TO UNLOCK SCREEN",
                    // text: "Please enter password to unlock fields.",
                    type: "input",
                    inputType: "password",
                    showCancelButton: true,
                    closeOnConfirm: true
                    // inputPlaceholder: "password"
                }, function(inputValue) {
                    if (inputValue === false){
                        $state.go("representative.reporter.step_one", {id : $state.params.id});
                        return false;
                    }
                    if (inputValue === "") {
                        swal.showInputError("You need to write something!");
                        return false
                    }
                    else {
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/authPrivateInfoPass', { 'password': inputValue })
                            .success(function(response) {
                                if (response['result']) {
                                    if (response['result']['auth']) {
                                        $state.go("representative.reporter.step_four", {id : $state.params.id});
                                        $scope.privateTabAuthenticated = true;
                                        $rootScope.showLoader(false);
                                    } else {
                                        $rootScope.showLoader(false);
                                        $state.go("representative.reporter.step_one", {id : $state.params.id});
                                        SweetAlert.swal("Error !", "Wrong Password!", "error");
                                    }
                                } else {
                                    $rootScope.showLoader(false);
                                    SweetAlert.swal("Oops !", "Something went wrong!", "error");
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                                SweetAlert.swal("Oops !", "Something went wrong!", "error");
                            });
                    }
                });
            }
        }

        $scope.reporterRateChanged = function(){
            $scope.isRateChanged = true;
        }
        $scope.addWorkingDay= function (attorney_id) {

            // invoice = $scope.invData.filter(function(e) {
            //     return e.attorney_id == attorney_id;
            //   });
            $scope.reporterData.id = $state.params.id;
              reptyp_data =  $scope.reporterData;
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/representative/addreporter/working_hour_popup.html",
                controller: 'WorkingHourPopupController',
                resolve: {
                    reptyp_data_id: function () {
                        return reptyp_data;
                    },
                      loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                       
                      
                       
                    ]);
                }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

        }

        $scope.isCertificateExpired = function(expiration_date){
            let today = new Date();
            let expired = new Date(expiration_date);
            return today <= expired;
        }
        
        $scope.addCertificates= function (certificate_id = null) {

            $scope.reporterData.id = $state.params.id
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/representative/addreporter/add_certificate_modal.html",
                controller: 'AddCertificatesController',
                resolve: {
                    reporter_certificate_id: function () {
                        return certificate_id;
                    },
                    loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                        ]);
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

        }
        
        $scope.addTimeOffs= function (id = null) {

            $scope.reporterData.id = $state.params.id
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/representative/addreporter/manage_timeoffs.html",
                controller: 'AddTimeOffsController',
                resolve: {
                    reptyp_availability_date_id: function () {
                        return id;
                    },
                    loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                        ]);
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

        }

        $scope.actionOnTimeoff = function(id){
            swal({
                title: "Are you sure want to delete this timeoff?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Do it!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader(true);
                    commonFactory.delete('/api/reporter-timeoffs/'+ id)
                    .success(function (response) {
                        if(response.error){
                            Notification.error(response.result.message | 'Error while performing this operation.');
                        } else{
                            Notification.success(response.result.message || 'Something went wrong.');
                            $scope.reloadTimeOffs();
                            $rootScope.showLoader(false);
                        }
                    })
                    .error(function (err) {
                        Notification.error(response.result.message | 'Error while performing this operation.');
                    });
                } else {
                    return false;
                }
            });
        };

        $scope.conflictsJobParamsModal = function(params){
            $rootScope.showLoader(true);
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/representative/addreporter/conflicts_jobs.html',
                controller: function($scope, params, $uibModalInstance){
                    $scope.modal_title = 'Conflicted Jobs';
                    $scope.diaries = params.scheduled_job;
                    console.log($scope.diaries)
                    $scope.cancel = function(isChanged) {
                        $uibModalInstance.dismiss({ isChanged : isChanged});
                    };
                },
                resolve : {
                    params : function(){
                        return params;
                    }
                },
                size: 'md',
            });
            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });
        };

        $scope.changeCertificateStatus = function (certificate_id, status) {
            let newStatus = status == '1' ? '0' : '1';
            var data = {id : certificate_id, status : newStatus};
            commonFactory.post('/api/reporter-certificate/update-status', data).success(function(){
                Notification.success("Status Updated Successfilly.");
                $scope.reloadCertificates();
            }).error(function(){
                $scope.reloadCertificates();
            });
        };

        $scope.actionOnCertificate = function(id, operation){
            swal({
                title: "Are you sure want to "+operation+" this certificate?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Do it!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader(true);
                    commonFactory.delete('/api/reporter-certificates/'+ id)
                    .success(function (response) {
                        if(response.error){
                            Notification.error(response.result.message | 'Error while performing this operation.');
                        } else{
                            Notification.success(response.result.message || 'Something went wrong.');
                            $scope.reloadCertificates();
                            $rootScope.showLoader(false);
                        }
                    })
                    .error(function (err) {
                        Notification.error(response.result.message | 'Error while performing this operation.');
                    });
                } else {
                    return false;
                }
            });
        };

        
        $scope.dtInstanceInvoices = {};
        $scope.dtInstanceCallback = function(dtInstance) {
            $scope.dtInstanceInvoices=dtInstance;
        }
        $scope.reloadInvoicesData = function() {
            $scope.dtInstanceInvoices.rerender();
        };


        $scope.createdRow = function (row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };


        $scope.dtInvoicesOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                data.resource_id  = $state.params ? $state.params.id : '';
                commonFactory.post('/api/resource-notes-datatable', data).success(function (res) {
                    if (res.error) {
                        $scope.reloadDatacheck();
                    }
                    else {
                        callback(res);
                    }
            }).error(function (err) {
                if (err.error !== "token_not_provided") {
                    $scope.reloadDatacheck();
                }
            });
        })
        .withOption('processing', true)
        .withLanguage({
            "sProcessing": "<img src='img/loading_bar.gif'/>"
        })
        .withOption('serverSide', true)
        .withOption('searchDelay', 500)
        .withOption('order', [0, 'desc'])
        .withPaginationType('simple_numbers')
        .withDisplayLength(10)
        .withOption('lengthMenu', [
            [5, 10, 25, 50],
            [5, 10, 25, 50]
        ])
        .withOption('createdRow', $scope.createdRow)
        .withOption('headerCallback', function (header) {
            if (!$scope.headerCompiled) {
                $compile(angular.element(header).contents())($scope);
            }
        });

        $scope.dtInvoicesColumns = [];
        $scope.dtInvoicesColumns.push(
            DTColumnBuilder.newColumn('notes').withOption('defaultContent', '').withTitle('Note').withOption('width','60%'),
            DTColumnBuilder.newColumn('note_date').withOption('defaultContent', '').withTitle('Note Date').renderWith(function (data, type, full, meta) {
                if(data) {
                    var dte = moment(data).format('MM/DD/YYYY');
                    if(dte == 'Invalid date'){
                        return '-';
                    }else{
                        return dte;
                    }
                } 
                else {
                    return '-';
                }

            }),
            DTColumnBuilder.newColumn('create_user.name').withOption('defaultContent', '').withTitle('Created By'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().withOption('searchable',false)
            .renderWith(function(data, type, full, meta) {
                return '<button class="btn btn-primary btn-circle" ng-click="addExtraNote('+full.id+')" uib-tooltip="Edit"  >' +
                        '<i class="fa fa-pencil"></i>' +
                        '</button>&nbsp;&nbsp'+
                        '<a class="btn btn-circle btn-danger" ng-click="deleteNote('+full.id+')" ><i class="fa fa-trash"></i></a>';

            })
        );

        $scope.deleteNote = function(id){

            swal({
                    title: "Are you sure ?",
                    text: "You are going to delete the note.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Do it",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.delete('/api/resource-note/'+id)
                            .success(function(response) {
                                if (response && response.result) {
                                    Notification.success(response.result.message || 'Note deleted successfully.');
                                    $scope.reloadInvoicesData();
                                    $rootScope.showLoader(false);
                                }else{
                                    Notification.error(response.result.message || 'Something went wrong!');
                                    $rootScope.showLoader(false);
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                            });
                    }
                });
        };


        $scope.addExtraNote =function(id=null){
            var resource_id  = $state.params ? $state.params.id : '';

            var invoiceModal = $uibModal.open({
                templateUrl: "modules/representative/addreporter/add_note_modal.html",
                controller: 'addResourceNotesController',
                resolve: {
                    resource_id: function () {
                        return resource_id;
                    },
                    id: function(){
                        return id
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

            invoiceModal.result.then(function (cbData) {
            }, function (cbData) {

                if(cbData.isChanged){
                    $scope.reloadInvoicesData();
                    $rootScope.showLoader(false);
                }

            });
        }
    }]);
