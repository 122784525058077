angular.module('deitz')
            .controller('editcaseController', [
                '$scope', 
                '$rootScope',
                '$sce',
                '$state',
                '$http',
                'apiUrl',
                'diaryFactory',
                'commonFactory',
                '$timeout',
                'Notification',
                '$uibModal',
                '$window',
                'JOB_NO',
                'invoiceFactory',
                'DTOptionsBuilder',
                'DTColumnBuilder',
                '$compile',
                    function (
                        $scope,
                        $rootScope,
                        $sce,
                        $state,
                        $http,
                        apiUrl,
                        diaryFactory,
                        commonFactory,
                        $timeout,
                        Notification,
                        $uibModal,
                        $window,
                        JOB_NO,
                        invoiceFactory,
                        DTOptionsBuilder,
                        DTColumnBuilder,
                        $compile
                    ) {

        // Set the default location because we are facing a undefined option in select drop down
        $scope.formData = {};
        $scope.diaryData = {};
        $scope.generalData = {};
        $scope.otherData = {};
        $scope.freeindicationData = {};
        $scope.postEntryData = {};
        $scope.otherSideFirmSelected = true;
        $scope.heldAtLocationSelected = false;
        $scope.stillLoadingInEditModeAction = false;
        $scope.isSelectOrdFirm = true;
        $scope.isSelectCase = true;
        $scope.isZoomLink = false;
        $scope.openAttendeeType = false;

        $scope.reporterType = { 'R': 'Reporter', 'T': 'Typist', 'SC': 'Scopist', 'S': 'Account Executive' };

        $scope.title = "Add/Edit A Case To The Calendar";
        $scope.reporters = [];
        $scope.typists = [];
        $scope.casetype = [];
        $scope.typist = [];
        $scope.witnesstype = [];
        $scope.jobtype = [];
        $scope.jobcategories = [];
        $scope.oursuites = [];
        $scope.translator = [{ id: null, company_name: 'Please select Interpreter' }];
        $scope.claim_representative = [];
        $scope.attorney = [];
        $scope.ebt = [];
        $scope.our_suites = [];
        $scope.status = [];
        $scope.claimrep_name = [];
        $scope.orderingFirm = '';
        $scope.isZoomShow = true;
        $scope.isItRescheduled = false;
        $scope.batch=null;
        $scope.batchJob=null;
        $scope.isBrandInterCompany=false;
        $scope.isShowNotifyOption = false;
        $scope.maxProductionNotesLength = 1000;
        $scope.maxLength = 2000;
        $scope.populated_resouce_names = '';
        
        $scope.characterCount = {};
        $scope.remainingCharacters = {};

        $scope.turnaroundOptions = [
             {id: 'Normal', label: 'Normal'},
             {id: 'Expedite', label: 'Expedite'},
             {id: 'PD', label: 'PD'},
             {id: 'TA', label: 'TA'},
        ];
        $scope.turnaround = "";

        $scope.countCharacters = function(label) {
            
            var maxLength = $scope.maxLength;
            $scope.characterCount[label] = $scope.diarynotes[label].length;
            $scope.remainingCharacters[label] = maxLength - $scope.characterCount[label];
        };
            $scope.numberWithCommas = function (x) {
                return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            }

        //Checking user role.
        if($rootScope.havePermission('allow_access_without_password','allow.diary.account.executive.lock.popup')){
            $scope.salesperson_source_locked = false;
        }else{
            $scope.salesperson_source_locked = true;
        }
        $scope.onremove_requested_resorces = function(deselectedItem) {
            var rmid =  deselectedItem.substring(7);
            console.log(rmid);
           
        };
        //$scope.diaryForm = false;
        $scope.generalData.DATE_TAKEN = moment().add(1, 'days').format('L'); //getting tomorrow's day for date taken field.
        $scope.time = ['09:00 AM', '09:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM', '01:00 PM', '01:30 PM', '02:00 PM', '02:30 PM', '03:00 PM', '03:30 PM', '04:00 PM', '04:30 PM', '05:00 PM'];
        $scope.end_time = ['09:00 AM', '09:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM', '01:00 PM', '01:30 PM', '02:00 PM', '02:30 PM', '03:00 PM', '03:30 PM', '04:00 PM', '04:30 PM', '05:00 PM','05:30 PM', '06:00 PM', '06:30 PM', '07:00 PM', '07:30 PM', '08:00 PM', '08:30 PM', '09:00 PM', '09:30 PM', '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM'];
         //get Turn Around list
         commonFactory.get('/api/all-active-turn-around').success(function (response) {
            if (response.result && response.result.turnAround) {
                $scope.turn_around = response.result.turnAround.map(function (item) {
                    return {
                        id: item.days,     
                        label: item.name 
                    };
                }) || [];
            }
        }).error(function (err) {});
        /* $scope.turn_around = [
            { id: 11, label: '11th' },
            { id: 10, label: '10th' },
            { id: 0, label: 'Same Day' },
            { id: 1, label: 'Next Day' },
            { id: 2, label: '2nd' },
            { id: 3, label: '3rd' },
            { id: 4, label: '4th' },
            { id: 5, label: '5th' },
            { id: 6, label: '6th' },
            { id: 7, label: '7th' },
            { id: 8, label: '8th' },
            { id: 9, label: '9th' }
        ];
        $scope.turn_around_labels = $scope.turn_around.reduce((a,c) => { a[c.id] = c.label; return a;},{}) */
        // $scope.turn_around = [{ id: 10, label: '10th Day' }, { id: 0, label: 'Same Day' }, { id: 1, label: 'Next Day' }, { id: 2, label: '2nd Day' }, { id: 3, label: '3rd Day' }, { id: 4, label: '4th Day' }, { id: 5, label: '5th Day' }, { id: 6, label: '6th Day' }, { id: 7, label: '7th Day' }, { id: 8, label: '8th Day' }, { id: 9, label: '9th Day' }];
        $scope.formSubmit = false;
        $scope.validations = diaryFactory.validations;
        $scope.messages = diaryFactory.messages;
        // $scope.LevelOfPayment_arr = [
        //     { id: 0, label: '0' },
        //     { id: 1, label: '1' },
        //     { id: 3, label: '3' },
        //     { id: 4, label: '4' },
        //     { id: 5, label: '5' },
        //     { id: 6, label: '6' },
        //     { id: 7, label: '7' },
        //     { id: 8, label: '8' },
        //     { id: 9, label: '9' }
        // ];

        $scope.currencyFormat = function(amt){
            if(amt == null){
                return '$0.00';
            }
            if(parseFloat(amt) >= 0){
                return '$'+parseFloat(amt).toFixed(2);
            }
            if(parseFloat(amt) < 0){
                return '-$'+ parseFloat(Math.abs(amt)).toFixed(2);
            }
        };

        $scope.insuranceRates = [];

        $scope.setPageTitle = function(){
            $state.$current.data.pageTitle = $state.params.id;
            $rootScope.$broadcast('changeTitle', $state);
        };

        $scope.formatDate = function(date){
            return moment(date).format('MM/DD/YYYY hh:mm:ss A');
        }
        
        $scope.calculateJobDuration = function() {
            if ($scope.diaryData.TIME_TAKEN && $scope.diaryData.End_Time) {
                const startTime = moment($scope.diaryData.TIME_TAKEN).format("HH:mm");
                const endTime = moment($scope.diaryData.End_Time).format("HH:mm");
                
                var start = moment(startTime, "HH:mm");
               
                var end = moment(endTime, "HH:mm");
                
                
                
                const startTimeMoment = moment(start);
                const endTimeMoment = moment(end);
                
                const differenceInMinutes = endTimeMoment.diff(startTimeMoment, 'minutes');
                $scope.endTimeError=false;
                
                if (differenceInMinutes <= 0 && $scope.diaryData.End_Time !=null) {
                    $scope.endTimeError= 'Must greater than Job time';
                    return;
                }
                const differenceHours = Math.floor(differenceInMinutes / 60);
                const differenceMinutes = differenceInMinutes % 60;
                
                $scope.diaryData.JOB_LENGTH= differenceHours+'h:'+differenceMinutes+'m';

            } else {
                $scope.diaryData.JOB_LENGTH = '';
            }
        };
        
        
        $scope.calculateEndTime = function() {
            if ($scope.diaryData.TIME_TAKEN && $scope.diaryData.JOB_LENGTH) {
                const startTime = moment($scope.diaryData.TIME_TAKEN).format("HH:mm");
                var start = moment(startTime, "HH:mm");
                const startTimeMoment = moment(start);
                $scope.endTimeError=false;
                if(!$scope.diaryData.JOB_LENGTH.match(/(\d+)h:(\d+)m/)){
                    $scope.format = 'Enter duration as 02h:30m';
                    return;
                }
                const jobDuration = moment($scope.diaryData.JOB_LENGTH,"HH:mm");
                const jobDurationMoment = moment(jobDuration);
                $scope.format=false;
                const endTimeMoment = startTimeMoment.clone().add(jobDurationMoment);

                const totalDurationHours = jobDurationMoment.hours() + jobDurationMoment.minutes() /60;
               const startTimeDuration = startTimeMoment.hours()+startTimeMoment.minutes()/60;

               const sumTotal = startTimeDuration+totalDurationHours;
               if(jobDuration <1705429800000 && sumTotal >24){
                $scope.format="Hours some must be less then 24hrs";
                $scope.diaryData.End_Time='';
                return;
               }

                $scope.diaryData.End_Time = endTimeMoment;
            
            } else {
                // Handle cases where TIME_TAKEN or JOB_LENGTH is not provided
                console.log("TIME_TAKEN or JOB_LENGTH is not provided.");
                // You can set an error message or take other actions as needed
            }
        };

        // Getting all rates card group
        $scope.refreshRateCardGroup = function () {
            $rootScope.showLoader(true);
            commonFactory.get('/api/rateCardGroups').success(function (response) {
                if (response.result) {
                    $scope.rateCardGroups = response.result.rateCardGroup;
                }
                $rootScope.showLoader(false);
            }).error(function (err) {
                $rootScope.showLoader(false);
            });
        }
        $scope.refreshRateCardGroup();

        $scope.groupChange = function () {
            // Get rates based on rate group
            if ($scope.diaryData.rate_card_group_id) {
                commonFactory.post('/api/rate/getRateGroupActiveRate', {rate_card_group: [$scope.diaryData.rate_card_group_id]})
                    .success(function (response) {
                        if (response.result && response.result.rates) {
                            $scope.insuranceRates = response.result.rates;
                        }
                    })
                    .error(function (err) {
                    });
            } else {
                $scope.getInsuranceRateTable();
            }
        };
        
         //Getting All caseType
         commonFactory.get('/api/casetypes')
         .success(function (response) {
             if (response && response.result && response.result.casetype) {
                 $scope.casetype = response.result.casetype || '';

                 for (var i = 0; i < $scope.casetype.length; i++) {
                     if ($scope.casetype[i].isDefault == 1) {
                         if (!$scope.generalData.case_type_id) {
                             $scope.generalData.case_type_id = $scope.casetype[i].id;
                         }
                         $scope.default_case_type = $scope.casetype[i].id;
                     }
                 }

             }
         })
         .error(function (err) {
             $rootScope.showLoader(false);
         });

     //Getting all witnesstypes.
     commonFactory.get('/api/witnesstypes')
         .success(function (response) {
             if (response.result && response.result.witnesstype) {
                 $scope.witnesstype = response.result.witnesstype || '';
             }
         })
         .error(function (err) {
         });


        //get all BussinesUnit for diary
        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
        });

        commonFactory.get('/api/additionalinfo_list').then(function(response) { 
            $scope.additionalinfo_list = response.data.result.data;  
        });

        //get all job status
        $http.get(apiUrl + '/api/getJobStatus').then(function(response) { 
            $scope.JobStatusList = response.data.result.data;  
        });

        //get all job status
        $http.get(apiUrl + '/api/get-active-assignment-status').then(function(response) { 
            $scope.assignmentStatuses = response.data.result.assignment_status;  
        });

        //get all biller
        $http.get(apiUrl + '/api/get-biller-list').success(function(response) {
            $scope.BillerList=response.result
        });
        
        $scope.LevelOfPayment_arr = [];
         /*Use for get client level */
         $http.get(apiUrl + '/api/get_client_level').then(function(response) {            
            $scope.LevelOfPayment_arr = response.data.data;            
         }); 

        $scope.firm_designation_arr, $scope.firm_group_arr, $scope.firm_sort1_arr, $scope.firm_sort2_arr  = [];
        /*Use for get firm Designation, group and sort */
        $http.get(apiUrl + '/api/get_designation_group_sort').then(function(response) {    
        $scope.firm_designation_arr = response.data.firm_designation;
        $scope.firm_group_arr = response.data.firm_group;
        $scope.firm_sort1_arr = response.data.firm_sort1;
        $scope.firm_sort2_arr = response.data.firm_sort2;            
        });
        $scope.JOB_NO = JOB_NO ? JOB_NO : $state.params.id;
        
        $http.get(apiUrl + '/api/get-pre-deposition-exhibits-by-id/'+$scope.JOB_NO).then(function(response) {   
            if(response.data.result){
                $scope.depoExibits = "true";  
            }    
            // $scope.depoExibits = "true";  
            $scope.depoUrl =  $rootScope.depoHost;
         }); 

         $http.get(apiUrl + '/api/get-mark-ready-audit-log-by-job/'+$state.params.id).success(function (response) {
                        $scope.markReadyAuditLogForAJob = response.result.data;
                   });
        //Getting all claim representative company addresses.
        commonFactory.get('/api/getallClaimrep')
            .success(function (response) {
                if (response.result && response.result.claimrepcompany) {
                    $scope.claimrepcompany = response.result.claimrepcompany || '';
                }
            })
            .error(function (err) {
            });

        //get all sources for diary
        $http.get(apiUrl + '/api/get_source').then(function (response) {
            $scope.attorneySources = response.data.data;
        });

        // Get the auto Partner
        commonFactory.get('/api/config/get-auto-partner').success(function (response) {
            if (response.result) {
                $scope.auto_partners = response.result;
            }
        });

        //Getting all status from statues table.
        commonFactory.get('/api/getStatus').success(function (response) {
            if (response.result && response.result.data) {
                $scope.status = response.result.data || '';
            }
        })
        .error(function (err) {
        });

        //get Timezone list
        commonFactory.get('/api/timezoneListUsa').success(function (response) {
            if (response.result) {
                $scope.timezoneList = response.result ;
            }
        })
        .error(function (err) {
        });
        //getting witness reminder information for a job.
        $scope.getWitnessReminderInfoForJob = function(){
            commonFactory.post('/api/getWitnessReminderInfoForJob', {JOB_NO : $scope.JOB_NO}).success(function (response) {
                if (response.result) {
                    $scope.witnessReminderInfo = response.result.reminder;
                }
            });
        }

        $scope.getWitnessReminderInfoForJob();

        //geting audit diary logs
        $scope.getDiaryAuditLogs = function(){
            $scope.diaryAuditLogs = [];
            $http.post(apiUrl + '/api/get-diary-audit-logs',{JOB_NO : $state.params.id}).success(function (response) {
                $scope.diaryAuditLogs = response.result.data;

                 //check Zoom Link Send Or Not
                //  if($scope.diaryOriginalData.schedule && $scope.diaryOriginalData.schedule.meeting_host_type == 'Z'){
                //      if($scope.diaryOriginalData.is_zoom_link_send == 1){
                //         $scope.isZoomLinkSend = true;
                //      }else{
                //         $scope.isZoomLinkSend = false;

                //      }
                //  }
            });
            
           
        }

        $scope.timeoffConflicts = function(){
            swal('Time Off Conflicted Job / Not Working Day', 'They’ve applied for time off request or not worked on that day. Thank you.');
        }

        //getting batch detail of job
        $scope.searchingBatchJob=true;
        $scope.getProductionBatchData = function(){
            commonFactory.get('/api/get-job-batch-data/'+ $scope.JOB_NO).success(function (response) {
                $scope.searchingBatchJob=false;
                    if (response.result && response.result.batch && response.result.job) {
                        $scope.batch=response.result.batch;
                        $scope.batchJob=response.result.job;
                        $scope.batchJob.assign_date_time= moment($scope.batchJob.assign_date + ' ' + $scope.batchJob.assign_time, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY hh:mm A');
                        if($scope.batchJob.status==1){
                            $scope.batchJob.status_text="ASSIGNED"
                        }
                        else if($scope.batchJob.status==2){
                            $scope.batchJob.status_text="IN-PROGRESS"
                        }
                        else if($scope.batchJob.status==3){
                            $scope.batchJob.status_text="COMPLETED"
                        }
                    }
            }).error(function (error,status){
                Notification.error(error);
                $scope.searchingBatchJob=false;
            });
           
        }
        $scope.changeBatchJobStatus= function(id,status){
            if(status==3){
                return false;
            }
            var swltext = (status == 1) ? 'You are going to check in this job.' : 'You are going to checkout this job.';
            swal({
                    title: "Are you sure ?",
                    text: swltext,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Do it",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                         $rootScope.showLoader(true);
                         var requestUrl = (status== 1) ? '/api/update_job_checkin' : '/api/update_job_checkout';
                         commonFactory.post(requestUrl,{id:id,status:status})
                            .success(function(response) {
                                if (response && response.result && !response.error) {
                                    if(status==1){
                                        Notification.success("Job check in successfully");
                                    }   
                                    else{
                                        Notification.success("Job check out successfully");
                                    }
                                    $rootScope.showLoader(false);
                                    $scope.getProductionBatchData();
                                }else{
                                    Notification.error(response.result.message);
                                    $rootScope.showLoader(false);
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                            });
                    }
                });
        }
        $scope.getProductionBatchData();
        
        $scope.openAssignBatchModal= function(){    
     
        var batchModal = $uibModal.open({
                templateUrl: "modules/diary/addcase/assign_batch_popup.html",
                controller: function($scope,$rootScope,$uibModalInstance,commonFactory,parentscope){
                  
       
                        $scope.JOB_NO=parentscope.JOB_NO;
                        $scope.selectedbatchId=null;
                        $scope.oldBatch=null;
                        $scope.getProductionBatchData = parentscope.getProductionBatchData;
                        $scope.getDiaryAuditLogs = parentscope.getDiaryAuditLogs;
        
                        if(parentscope.batch){
                            $scope.selectedbatchId=parentscope.batch.id;
                            $scope.oldBatch=parentscope.batch.id;
                        }
                        $scope.batches={};
                        $scope.closePopupWindow = function(){
                                $uibModalInstance.close(true);
                            };
                        
                        $scope.getBatches = function (){
                            $rootScope.showLoader(true);
                            commonFactory.post('/api/get_all_batches', { 
                                batch_status : [1,2] , 
                                business_unit_ids : [parentscope.diaryData.business_unit_id]
                                }).success(function (response) {
                                    $scope.batches=response.batches;
                                $rootScope.showLoader(false);
                                }).error(function () {
                                    $rootScope.showLoader(false);
                                });
                        },
                        $scope.getBatches();
                        $scope.assignBatch = function(isValid){
                            $scope.formSubmit = true;
                            if (isValid) {
                                 // assign batch
                                $rootScope.showLoader(true);
                                commonFactory.post('/api/assign-job-to-batch/'+ $scope.JOB_NO, { 
                                    new_batch_id: $scope.selectedbatchId , 
                                    old_batch_id: $scope.oldBatch , 
                                    }).success(function (response) {
                                        Notification.success(response.message);
                                       
                                        $scope.getProductionBatchData();
                                        $scope.getDiaryAuditLogs()
                                        $rootScope.showLoader(false);
                                        $uibModalInstance.close(true);
                                    }).error(function (err) {
                                     
                                        if(err.message){
                                            Notification.error(err.message);
                                        }
                                        else{
                                            Notification.error('Something went wrong!');
                                        }
                                        $rootScope.showLoader(false);
                                    });
                            }
                        
                        }
                    },
                        resolve:{
                                   parentscope :function (){
                                    return $scope;
                                   }
                                },
                        windowClass: 'small-width-model',
                        keyboard: false,
                        backdrop: false,
                        size: 'm',
                           });
                      
        }

        //getting witness reminder information for a job.
        $scope.getMeetingAttendees = function(){
            commonFactory.get('/api/get-meeting-attendees-for-job/'+ $scope.JOB_NO).success(function (response) {
                if (response.result) {
                    $scope.meetingAttendees = response.result.data;
                }
            });
        }

        $scope.getMeetingAttendees();

        $scope.showJobInvoices = function(jobno){  
            invoiceFactory.searchData = {
                searchValue : jobno,
                selectedValue : "INVOICE_tbl.JOB_NO"
            };
            $state.go('invoice.browse');
        }

        //Getting all resources by type.
        commonFactory.post('/api/resource-list-by-type',{rep_typ:'S'})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.salesPersonsList = response.result.resources;
            }
        });

        commonFactory.get('/api/connection-methods')
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.connectionMethods = response.result.resources;
            }
        });

        $scope.searchClaimRepName = function (val) {
            return $http.post(apiUrl + '/api/search-claimrep-name', { query_string: val }).then(function (response) {
                return response.data.result.map(function (item) {
                    return item;
                });
            });
        };

        $scope.selectClaimFromlist = false;

        $scope.selectClaimRepName = function ($item, $model, $label, $event) {
            $scope.selectClaimFromlist = true;
            // set the insurance company
            $scope.generalData.claim_representative_id = parseInt($item.claimrepresentativeaddress_id);
        };

        //Refresh the Claim name rep if edited
        $scope.refreshOtherClaimRep = function (value) {
            $rootScope.showLoader(true);
            var data = {};
            data.id = value;
            commonFactory.get('/api/claimrepresentativename/' + value + '/edit')
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response && response.result && response.result.claim_rep_name) {

                        $scope.selectClaimFromlist = true;
                        $scope.generalData.claim_representative_id = parseInt(response.result.claim_rep_name.claimrepresentativeaddress_id);
                        // $scope.generalData.CLM_NAME = parseInt(response.result.claim_rep_name.ClaimRepID);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        };

        $scope.refreshCallBy = function(id){
            $rootScope.showLoader(true);
            $http.post(apiUrl+'/api/get-job-called-in-by-list', { attorney_id : id }).success(function(response){
                $rootScope.showLoader(false);
                $scope.job_called_in_by_list = response.result.data;
            }).then(function () {
                $rootScope.showLoader(false);
            });
        }

        $scope.refreshAttorney = function (id) {
            $http.post(apiUrl + '/api/get-attorney-list', {attorney_id: id}).success(function (response) {
                $scope.attornay_list = response.result.data;
                if ($scope.attornay_list.dep_attorney) {
                    $scope.attornay_list.dep_attorney.push({attorney_no: -1, name: "To Be Determined"});
                }
            });
        }
        
        $scope.jobtypeModal = function (id) {
            $rootScope.showLoader(true);
            if (id) {
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/code_tables/job_type/add/jobtypeModal.html',
                    controller: 'editjobtypeController',
                    resolve: {
                        fromModal: function () {
                            return true;
                        },
                        jobtypeId: function () {
                            return id;
                        }
                    }
                });
            }
            else {
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/code_tables/job_type/add/jobtypeModal.html',
                    controller: 'addjobtypeController',
                    resolve: {
                        fromModal: function () {
                            return true;
                        }
                    }
                });
            }
            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });

            modalInstance.result.then(function (id) {
                if (id) {
                    $scope.refreshJobtype();
                    $scope.generalData.job_type_id = id;
                }

            }, function () {
                console.log("Modal Dismissed!!!");
            });
        };

        $scope.jobcategoryModal = function (id) {
            $rootScope.showLoader(true);
            if (id) {
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/code_tables/job_category/add/jobcategoryModal.html',
                    controller: 'editjobcategoryController',
                    resolve: {
                        fromModal: function () {
                            return true;
                        },
                        jobcategoryId: function () {
                            return id;
                        }
                    }
                })
            }
            else {
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/code_tables/job_category/add/jobcategoryModal.html',
                    controller: 'addjobcategoryController',
                    resolve: {
                        fromModal: function () {
                            return true;
                        }
                    }
                });
            }
            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });
            modalInstance.result.then(function (id) {
                if (id) {
                    $scope.refreshJobcategory();
                    $scope.generalData.job_category_id = id;
                }
            });
        };

        $scope.caseTypeModal = function (id) {
            $rootScope.showLoader(true);
            if (id) {
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/code_tables/case_type/add/casetype_modal.html',
                    controller: 'editcasetypeController',
                    resolve: {
                        fromModal: function () {
                            return true;
                        },
                        casetypeId: function () {
                            return id;
                        }
                    }
                })
            }
            else {
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/code_tables/case_type/add/casetype_modal.html',
                    controller: 'addcasetypeController',
                    resolve: {
                        fromModal: function () {
                            return true;
                        }
                    }
                });
            }
            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });
            modalInstance.result.then(function (id) {
                if (id) {
                    $scope.refreshCasetype();
                    $scope.generalData.case_type_id = id;
                }
            });
        };

        $scope.plaintiffModal = function (id) {
            $rootScope.showLoader(true);
            if (id) {
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/code_tables/witness_type/add/plaintiff_modal.html',
                    controller: 'editwitnesstypeController',
                    resolve: {
                        fromModal: function () {
                            return true;
                        },
                        plaintiffId: function () {
                            return id;
                        }
                    }
                });
            }
            else {
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/code_tables/witness_type/add/plaintiff_modal.html',
                    controller: 'addwitnesstypeController',
                    resolve: {
                        fromModal: function () {
                            return true;
                        }
                    }
                });
            }
            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });

            modalInstance.result.then(function (id) {
                if (id) {
                    $scope.refreshWitnesstype();
                    $scope.generalData.witness_type_id = id;
                }

            }, function () {
                console.log("Modal Dismissed!!!");
            });
        };

        $scope.diaryHistoryParamsModal = function(params){
            $rootScope.showLoader(true);
            
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/diary/addcase/diary_logs.html',
                controller: function($scope, params, $uibModalInstance){
                    $scope.modal_title = 'Updated Fields';
                    $scope.updated_fields_list = JSON.parse(params);
                    $scope.cancel = function(isChanged) {
                        $uibModalInstance.dismiss({ isChanged : isChanged});
                    };
                },
                resolve : {
                    params : function(){
                        return params;
                    }
                },
                size: 'lg',
            });
            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });
        };

        $scope.venueModal = function (id) {
            $rootScope.showLoader(true);
            if (id) {
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/code_tables/locations/add/venue_modal.html',
                    controller: 'editlocationController',
                    resolve: {
                        fromModal: function () {
                            return true;
                        },
                        venueId: function () {
                            return id;
                        }
                    }
                });
            }
            else {
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/code_tables/locations/add/venue_modal.html',
                    controller: 'addlocationController',
                    resolve: {
                        fromModal: function () {
                            return true;
                        }
                    }
                });
            }
            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });

            modalInstance.result.then(function (id) {
                if (id) {
                    $scope.refreshLocations();
                    $scope.diaryData.location_id = id;
                }

            }, function () {
                console.log("Modal Dismissed!!!");
            });
        };

        $scope.claimrepModal = function (id) {
            $rootScope.showLoader(true);
            if (id) {
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/insurance/claim_rep_name/add/claim_rep_modal.html',
                    controller: 'editClaimrepnameController',
                    windowClass: 'full-width-model',
                    resolve: {
                        fromModal: function () {
                            return true;
                        },
                        claimrepId: function () {
                            return id;
                        },
                        claim_rep_data: function () {
                            return null;
                        },
                        loadPlugin:function($ocLazyLoad){
                            return $ocLazyLoad.load([
                                {
                                    name: 'ngPatternRestrict',
                                    files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                                }]);
                        }
                    }
                });
            }
            else {
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/insurance/claim_rep_name/add/claim_rep_modal.html',
                    controller: 'addClaimrepnameController',
                    windowClass: 'full-width-model',
                    resolve: {
                        fromModal: function () {
                            return true;
                        },
                        loadPlugin:function($ocLazyLoad){
                            return $ocLazyLoad.load([
                                {
                                    name: 'ngPatternRestrict',
                                    files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                                }]);
                        }
                    }
                });
            }
            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });

            modalInstance.result.then(function (claimrepresentative) {
                if (claimrepresentative) {
                    $scope.refreshOtherClaimRep();
                    $scope.generalData.CLM_REP_NAME = claimrepresentative ? claimrepresentative : '';
                    $scope.generalData.CLM_REP_NAME.claimRepName = claimrepresentative ? claimrepresentative.ClaimRepFirstName + ' ' + claimrepresentative.ClaimRepLastName + ' | ' + claimrepresentative.CR_CompanyName + ' | ' + claimrepresentative.CR_Phone : '';

                }

            }, function () {
                console.log("Modal Dismissed!!!");
            });
        };

        $scope.editclaimrepModal = function (data, index) {
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/code_tables/claim_rep_name/browse/claim_rep_modal.html',
                controller: 'editClaimrepnameController',
                resolve: {
                    claim_rep_data: function claimrepData() {
                        return data;
                    }
                }
            });
        };

        $scope.foundFromElaw = false;

        $scope.indexData = function (val) {
            return $http.get(apiUrl + '/api/caseByIndex', {
                params: {
                    index: val
                }
            }).then(function (response) {

                $scope.foundFromElaw = false;
                if (response.data.foundFromElaw) {
                    $scope.foundFromElaw = true;
                }
                return response.data.result.map(function (item) {
                    return item;
                });
            });
        };

        //Show and hide with clockpicker toggle.
        $scope.IsVisibleStartTime = false;
        $scope.ShowHide_StartTime = function () {
            //If DIV is visible it will be hidden and vice versa.
            $scope.IsVisibleStartTime = $scope.IsVisibleStartTime ? false : true;
        };

        $scope.IsVisibleEndTime = false;
        $scope.ShowHide_EndTime = function () {
            //If DIV is visible it will be hidden and vice versa.
            $scope.IsVisibleEndTime = $scope.IsVisibleEndTime ? false : true;
        };

        $scope.IsVisibleTimeofExam = true;
        $scope.ShowHide_TimeofExam = function () {
            //If DIV is visible it will be hidden and vice versa.
            $scope.IsVisibleTimeofExam = $scope.IsVisibleTimeofExam ? false : true;
        };

        var diaryDetails = {};
        diaryDetails.id = $state.params.id || JOB_NO;

        // Call for populating the attorney values for drop down while user on witness information tab
        $scope.$on('$stateChangeSuccess',
            function (event, toState, toParams, fromState, fromParams) {
                if ($scope.diaryOriginalData && toState.name == 'diary.editcase.step_one') {
                    $scope.fetchData();
                }

                if ($scope.diaryOriginalData && toState.name == 'diary.editcase.step_five') {
                    // for updating the existing scope if changes is made on attorney in witness information section
                    $timeout(function () {
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/getdiarydetails', diaryDetails)
                            .success(function (response) {
                                $rootScope.showLoader(false);
                                if (response && response.result && response.result.diaryDetails) {
                                    $scope.diaryOriginalData = angular.copy(response.result.diaryDetails);
                                    //GET data for "$scope.freeindicationData" SCOPE.
                                    freeindicationDataScope();
                                }
                            })
                            .error(function (err) {
                                $rootScope.showLoader(false);
                            });
                    }, 1000);
                }

            });

        $scope.fetchData = function () {            
            $scope.JOB_NO = $state.params.id || JOB_NO;
            //Getting edit data from DIARY_tbl and CASE_tbl
            $scope.isZoomLink = false;

            var diaryDetails = {};
            diaryDetails.id = $state.params.id || JOB_NO;
            $timeout(function () {
                $rootScope.showLoader(true);
                commonFactory.post('/api/getdiarydetails', diaryDetails)
                    .success(function (response) {
                        $rootScope.showLoader(false);
                        if (response && response.result && response.result.diaryDetails) {

                            $scope.diaryOriginalData = angular.copy(response.result.diaryDetails);


                            var yesterday = new Date();
                            yesterday.setDate(yesterday.getDate() - 1);
                            if(new Date($scope.diaryOriginalData.DATE_TAKEN) < yesterday){
                                $scope.isZoomShow = false;
                            }

                            $scope.getDiaryAuditLogs();
                            
                            //GET data for "$scope.diaryData" SCOPE.
                            diaryDataScope();
                            //GET data for "$scope.generalData" SCOPE.
                            generalDataScope();
                            //GET data for "$scope.otherData" SCOPE.
                            otherDataScope();
                            //GET data for "$scope.freeindicationData" SCOPE.
                            freeindicationDataScope();
                            //GET data for "$scope.postEntryData" SCOPE.
                            postentryDataScope();

                            
               // generalData.DATE_TAKEN = moment(generalData.DATE_TAKEN).format('MM/DD/YYYY');
               // generalData.DUE_DATE = moment(generalData.DATE_TAKEN, 'MM/DD/YYYY').businessAdd($scope.generalData.turn_around_info)._d;
               // generalData.DUE_DATE = moment(diaryOriginalData.DUE_DATE).format('MM/DD/YYYY');

                            $scope.generalData.DUE_DATE = moment($scope.diaryOriginalData.DUE_DATE).format('MM/DD/YYYY');
                            console.log('test 1');

                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            }, 1000);
        };
        $scope.fetchData();

        $scope.dateOfExam = function () {
            
            if ($state.current.name === "diary.editcase.step_two") {
                var id = 'JOB_LENGTH';
                var element = $window.document.getElementById(id);
            } else {
                var id = "TIME_TAKEN_chosen";
                var element = $('#' + id).find('input[type="text"]');
            }
            element.focus();

            swal('Are you sure?', 'Did you double check Job Date?');

            // recalculate due date
            job_date = moment($scope.generalData.DATE_TAKEN).format('MM/DD/YYYY');            
            $scope.diaryData.DATE_TAKEN=job_date;
            $scope.generalData.DUE_DATE = moment(job_date, 'MM/DD/YYYY').businessAdd($scope.generalData.turn_around_info)._d;
            $scope.diaryData.DUE_DATE=$scope.generalData.DUE_DATE;
            $scope.generalData.DUE_DATE=$scope.generalData.DUE_DATE;         
        };

        $scope.dateOfExamstep2 = function () {   
                     
            if ($state.current.name === "diary.editcase.step_two") {
                var id = 'JOB_LENGTH';
                var element = $window.document.getElementById(id);
            } else {
                var id = "TIME_TAKEN_chosen";
                var element = $('#' + id).find('input[type="text"]');
            }
            element.focus();
            // swal('Are you sure?', 'Did you double check Job Date?');
            swal({
                title: 'Are you sure?',
                text: 'Did you double check the Job Date?',
                buttons: true,
                confirmButtonText: "Yes",
            },
            function(isConfirm){
                if (isConfirm){
                    $scope.confirmRescheduleOrAdjustment();
                }
            });

            // recalculate due date
            job_date = moment($scope.diaryData.DATE_TAKEN).format('MM/DD/YYYY');
            $scope.generalData.DATE_TAKEN=job_date;
            $scope.generalData.DUE_DATE = moment(job_date, 'MM/DD/YYYY').businessAdd($scope.generalData.turn_around_info)._d;
            $scope.diaryData.DUE_DATE=$scope.generalData.DUE_DATE;
            $scope.generalData.DUE_DATE=$scope.generalData.DUE_DATE;


        };

        $scope.confirmRescheduleOrAdjustment = function() {
            setTimeout(function(){
                swal({
                    title: "Is It?",
                    text: '<button type="button" id="btnRescheduled" style="background-color: #5cb85c;">Reschedule</button> ' +
                    '<button type="button" id="btnDateTimeAdjust" style="background-color: #f0ad4e;">Date/Time Adjustment</button>',
                    html: true,
                    showConfirmButton: false
                });
            },200)

            $(document).off('click', "#btnRescheduled");
            $(document).on('click', "#btnRescheduled", function() {
                $scope.isItRescheduled = true;
            });

            $(document).off('click', "#btnDateTimeAdjust");
            $(document).on('click', "#btnDateTimeAdjust", function() {
                $scope.isItRescheduled = false;
            });
        }
        //Assigning DATA for "$scope.diaryData" SCOPE.
        function diaryDataScope() {
            $rootScope.showLoader(true);

            var diaryScope = $scope.diaryOriginalData;
            $scope.stillLoadingInEditModeAction = true;


            // Attorney Location variable set after getting Scheduling Firm
            // $scope.diaryData.location_action = diaryScope.location_action;

            //$scope.diaryData.attorney_id = parseInt(diaryScope.attorney_id) || '';
            $scope.diaryData.ebt_contact_id = parseInt(diaryScope.ebt_contact_id) || '';
            $scope.diaryData.attname_id = parseInt(diaryScope.attname_id) || '';
            //$scope.diaryData.otherfirmlocation = diaryScope.otherfirmlocation || '';
            if (diaryScope.DATE_TAKEN && diaryScope.DATE_TAKEN != null && diaryScope.DATE_TAKEN != '') {
                $scope.diaryData.DATE_TAKEN = diaryScope.DATE_TAKEN || '';
            } else {
                $scope.diaryData.DATE_TAKEN = moment().add(1, 'days').format('L'); //getting tomorrow's day for date taken field.
            }
            //Toggle of actual start time
            $scope.IsVisibleTimeofExam = true;

            $scope.diaryData.reschedule_cnt = diaryScope.reschedule_cnt || 0;  
            $scope.diaryData.TIME_TAKEN = diaryScope.TIME_TAKEN || '';  

            if ($scope.diaryData.TIME_TAKEN) {
                $scope.diaryData.TIME_TAKEN = moment($scope.diaryData.TIME_TAKEN, 'hh:mm A');
            }

            $scope.diaryData.NOTES = diaryScope.NOTES || '';

            // We need to put the condition here because in different location the held data come first and location action set after that then held at data goes away https://dtopinc.atlassian.net/browse/DEITZBO-388
            if (diaryScope.location_action === 'diff_location') {
                $scope.diaryData.location_action = diaryScope.location_action;
            }


            $scope.diaryData.JOB_NO = diaryScope.JOB_NO;
            $scope.diaryData.NOTES2 = diaryScope.NOTES2 || '';
            $scope.diaryData.NOTES3 = diaryScope.NOTES3 || '';
            $scope.diaryData.NOTES_1 = diaryScope.NOTES_1 || '';
            $scope.diaryData.NOTES_2 = diaryScope.NOTES_2 || '';
            $scope.diaryData.sort1 = diaryScope.sort1 || '';
            $scope.diaryData.sort2 = diaryScope.sort2 || '';
            $scope.diaryData.LevelOfPayment = diaryScope.LevelOfPayment;
            $scope.diaryData.source_id = diaryScope.source_id;
            $scope.diaryData.legacy_job_no = diaryScope.legacy_job_no;
            $scope.diaryData.IndexNumber = diaryScope.IndexNumber || '';
            $scope.diaryData.CAPTION = diaryScope.CAPTION || '';
            $scope.diaryData.job_status_id = diaryScope.job_status_id || '';
            $scope.diaryData.Biller_by = diaryScope.Biller_by || '';
            $scope.diaryData.created_by = diaryScope.created_by ? diaryScope.created_by.name : diaryScope.createdBys;
            $scope.diaryData.created_at = (diaryScope.created_at && diaryScope.created_at !== '0000-00-00 00:00:00' && diaryScope.created_at !== '-0001-11-30 00:00:00') ? moment(diaryScope.created_at).format('MM/DD/YYYY hh:mm:ss A') : '--/--/----';            
            $scope.diaryData.timezone = parseInt(diaryScope.timezone);
            $scope.diaryData.business_unit_id = diaryScope.business_unit_id;
            $scope.diaryData.held_at_flag = diaryScope.held_at_flag;
            $scope.diaryData.JOB_LENGTH = diaryScope.JOB_LENGTH;
            $scope.diaryData.covering_business_unit_id = diaryScope.covering_business_unit_id;
            $scope.diaryData.production_queue = diaryScope.production_queue;
            $scope.diaryData.is_ready_to_release_to_bu = diaryScope.is_ready_to_release_to_bu;
            if($scope.diaryData.production_queue =='BU'){
                $scope.diaryData.production_queue_toggle = true;
            }
            else{
                $scope.diaryData.production_queue_toggle = false;
            }
            
            $scope.diaryData.End_Time = diaryScope.End_Time || '';  

            if ($scope.diaryData.End_Time) {
                $scope.diaryData.End_Time = moment($scope.diaryData.End_Time,"hh:mm A");
            }else{
                $scope.diaryData.End_Time = moment(null);
            }
            
            

            $scope.diaryData.job_called_in_by = diaryScope.job_called_in_by || '';

            $scope.diaryData.job_resources= diaryScope.job_resources;
            $scope.diaryData.resource_typists = {};
            angular.forEach(diaryScope.resource_typists, function (value, key) {
                $scope.diaryData.resource_typists[value.id] = value.name;
            });
            
            $scope.diaryData.job_attorney = diaryScope.job_attorney || '';
            //Assining all emails from database;
            var emails = [];
            if(diaryScope.send_job_confirmation_on){
               emails = diaryScope.send_job_confirmation_on.replace(/;/g,',').split(',');
            }
            if(emails.length){
            $scope.diaryData.send_job_confirmation_on = emails;
            }
            $scope.diaryData.language = diaryScope.language || null;
            $scope.diaryData.translator_id = parseInt(diaryScope.translator_id) || null;
            $scope.diaryData.translator_time = diaryScope.translator_time || null;
            $scope.diaryData.firm_specific_reporter_note = diaryScope.firm_specific_reporter_note || null;

            $scope.diaryData.insurance_rate_id = diaryScope.insurance_rate_id || null;
            $scope.diaryData.rate_card_group_id = diaryScope.rate_card_group_id || null;
            if ($scope.diaryData.rate_card_group_id) {
                $scope.groupChange();
            }

            if ($scope.diaryData.translator_time) {
                $scope.diaryData.translator_time = moment($scope.diaryData.translator_time, 'hh:mm A');
            }

            $scope.refreshLanguages();

            $scope.formData.mark_ready = diaryScope.mark_ready == "1" ? true : undefined;

            $scope.formData.send_exhibit_link = diaryScope.send_exhibit_link == "1" ? true : undefined;

            if(diaryScope.diary_reporting_salesman.length){
                var reportingSalesmanIds = [];
                angular.forEach(diaryScope.diary_reporting_salesman,function(value, key){
                    reportingSalesmanIds[key] = value.sales_reptyp_id;
                });
                $scope.diaryData.reporting_sales_ids = reportingSalesmanIds;
                // Adding Send Job Confirmation To emails for existing salesman
                if($scope.salesPersonsList){
                    $scope.salesPersonsList.filter(function (item) {
                        var IDs = $scope.diaryData.reporting_sales_ids;
                        if (IDs.includes(item.id) && item.auth_user && item.Emai_Address) {
                            var is_email_exists = false;
                            $scope.diaryData.send_job_confirmation_on.filter(function (conf) {
                                if (conf == item.Emai_Address) {
                                    is_email_exists = true;
                                }
                            });
                            if (!is_email_exists) {
                                $scope.diaryData.send_job_confirmation_on.push(item.Emai_Address);
                            }
                        }
                    });
                }else{
                    //wait until api get data for salesPersonsList
                    setTimeout(function () {
                        $scope.salesPersonsList.filter(function (item) {
                            var IDs = $scope.diaryData.reporting_sales_ids;
                            if (IDs.includes(item.id) && item.auth_user && item.Emai_Address) {
                                var is_email_exists = false;
                                $scope.diaryData.send_job_confirmation_on.filter(function (conf) {
                                    if (conf == item.Emai_Address) {
                                        is_email_exists = true;
                                    }
                                });
                                if (!is_email_exists) {
                                    $scope.diaryData.send_job_confirmation_on.push(item.Emai_Address);
                                }
                            }
                        });
                    }, 2000)
                }
            }
            $scope.diaryData.service_request = diaryScope.service_request
            if($scope.diaryData.service_request.length){
                var resource_type_ids = [];
                angular.forEach($scope.diaryData.service_request,function(value, key){
                    resource_type_ids[key] = value.resource_type_id;
                });
                $scope.diaryData.resource_type_ids = resource_type_ids;
            }
            if($scope.diaryData.job_resources.length){
                var resource_type_ids = [];
                angular.forEach($scope.diaryData.job_resources,function(value, key){
                    if(value.service_request && value.service_request.resource_type_id){
                        resource_type_ids[key] = value.resource_type_id;
                        if($scope.diaryData.resource_type_ids && $scope.diaryData.resource_type_ids.length){
                            $scope.diaryData.resource_type_ids.push(value.resource_type_id)
                        }else{
                            $scope.diaryData.resource_type_ids = resource_type_ids;
                        }
                    }
                });
                
                $scope.populated_resouce_names = $scope.diaryData.job_resources.filter(item => item.additional_resource_type?.is_translator).map(item => item.resource.name).join(', ');
               
            }
            if(diaryScope.diary_records_salesman.length){
                var recordsSalesmanIds = [];
                angular.forEach(diaryScope.diary_records_salesman,function(value, key){
                    recordsSalesmanIds[key] = value.sales_reptyp_id;
                });
                $scope.diaryData.records_sales_ids = recordsSalesmanIds;
            }

            if (diaryScope && diaryScope.attorney_id) {
                $timeout(function () {
                    var data = { attorney_id: diaryScope.attorney_id }
                    commonFactory.post('/api/getAttorneyfirmdata', data)
                        .success(function (response) {
                            $scope.diaryData.attorney_id = response.result.attorney_data || '';
                            //$scope.diaryData.location_action = diaryScope.location_action || '';
                            //Ordering Firm auto populate data(params).
                            $scope.orderingFirm = response.result.attorney_data || '';
                            $scope.diaryData.firm_HELD_AT2 = response.result.attorney_data.ADDR_LINE1 || '';
                            $scope.diaryData.firm_HELD_AT3 = response.result.attorney_data.ADDR_LINE2 || '';
                            $scope.diaryData.firm_HELD_AT_CITY = response.result.attorney_data.CITY || '';
                            $scope.diaryData.firm_HELD_AT_STATE = response.result.attorney_data.STATE || '';
                            $scope.diaryData.firm_HELD_AT_ZIPCODE = response.result.attorney_data.ZIP || '';
                            $scope.diaryData.firm_HA_PHONE = response.result.attorney_data.PHONE || '';
                            $scope.diaryData.firm_CON_PHONE = response.result.attorney_data.CELL || '';
                            $scope.diaryData.firm_LOCATION = parseInt(response.result.attorney_data.location_id) || '';
                            $scope.diaryData.firm_CONTACT = response.result.attorney_data.CONTACT || '';
                            $scope.diaryData.claim_number_mask = response.result.attorney_data.claim_number_mask || '';
                            $scope.firm_status = response.result.attorney_data.STATUS;
                            $scope.firm_beeper = '';
                            angular.forEach($scope.auto_partners,function(value, key){
                                if(value.code == response.result.attorney_data.BEEPER){                
                                    $scope.firm_beeper = value.text;
                                }
                            });

                            if (diaryScope.location_action === 'attorneyfirm') {
                                $scope.diaryData.location_action = diaryScope.location_action;
                            }
                        })
                        .error(function (err) {
                            $rootScope.showLoader(false);
                        });

                    // $scope.getRateTable(diaryScope.attorney_id);
                    
                    $timeout(function(){
                        $http.post(apiUrl+'/api/get-job-called-in-by-list', { attorney_id : diaryScope.attorney_id }).success(function(response){
                            $scope.job_called_in_by_list = response.result.data;

                            if($scope.diaryData.job_called_in_by){
                                $scope.ScheduledBySelect($scope.diaryData.job_called_in_by);
                            }
                        });
                        $http.post(apiUrl+'/api/get-attorney-list', { attorney_id : diaryScope.attorney_id }).success(function(response){
                            $scope.attornay_list = response.result.data;
                            if ($scope.attornay_list.dep_attorney) {
                                $scope.attornay_list.dep_attorney.push({attorney_no: -1, name: "To Be Determined"});
                            }
                            if($scope.diaryData.job_attorney){
                                $scope.AttorneySelect($scope.diaryData.job_attorney);
                            }
                        });
                    },50);

                    $timeout(function () {
                        var databyfirm = { firm_id: diaryScope.attorney_id }
                        commonFactory.post('/api/attorneybyfirm', databyfirm)
                            .success(function (response) {
                                if (response) {
                                    if (response && response.result && response.result.attorney_data) {
                                        $scope.attorney = response.result.attorney_data || '';
                                    }
                                }
                            })
                            .error(function (err) {
                                $rootScope.showLoader(false);
                            });
                    }, 50);

                    $timeout(function () {
                        var databyebt = { firm_id: diaryScope.attorney_id }
                        commonFactory.post('/api/ebtbyfirm', databyebt)
                            .success(function (response) {
                                if (response) {
                                    if (response && response.result && response.result.ebt_data) {
                                        $scope.ebt = response.result.ebt_data || '';
                                    }
                                }
                            })
                            .error(function (err) {
                                $rootScope.showLoader(false);
                            });
                    }, 50);

                }, 100);
            }
            if (diaryScope && diaryScope.job_case_id) {
                $timeout(function () {
                    
                    commonFactory.get('/api/case/'+diaryScope.job_case_id+'/edit')
                        .success(function (response) {
                           $scope.diaryData.job_case_id = response.result.case || '';
                        })
                        .error(function (err) {
                            $rootScope.showLoader(false);
                        });

                }, 100);
            }

            if (diaryScope && diaryScope.otherfirmlocation) {
                var data = { attorney_id: diaryScope.otherfirmlocation }
                commonFactory.post('/api/getAttorneyfirmdata', data)
                    .success(function (response) {
                        $scope.diaryData.otherfirmlocation = response.result.attorney_data || '';
                        $scope.otherSideFirmSelected = response.result.attorney_data ? true : false;

                        //Ordering Firm auto populate data(params).
                        $scope.diaryData.firm_HELD_AT2 = response.result.attorney_data.ADDR_LINE1 || '';
                        $scope.diaryData.firm_HELD_AT3 = response.result.attorney_data.ADDR_LINE2 || '';
                        $scope.diaryData.firm_HELD_AT_CITY = response.result.attorney_data.CITY || '';
                        $scope.diaryData.firm_HELD_AT_STATE = response.result.attorney_data.STATE || '';
                        $scope.diaryData.firm_HELD_AT_ZIPCODE = response.result.attorney_data.ZIP || '';
                        $scope.diaryData.firm_HA_PHONE = response.result.attorney_data.PHONE || '';
                        $scope.diaryData.firm_CON_PHONE = response.result.attorney_data.CELL || '';
                        $scope.diaryData.firm_LOCATION = parseInt(response.result.attorney_data.location_id) || '';
                        $scope.diaryData.firm_CONTACT = response.result.attorney_data.CONTACT || '';
                        $scope.diaryData.claim_number_mask = response.result.attorney_data.claim_number_mask || '';

                        if (diaryScope.location_action === 'heldatfirm') {
                            $scope.diaryData.location_action = diaryScope.location_action;
                        }

                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            }



            $timeout(function () {

                if (diaryScope.location_action === 'our_suites' || diaryScope.location_action === 'other' || diaryScope.location_action === 'diff_location' || diaryScope.location_action === 'heldatfirm' || diaryScope.location_action === 'attorneyfirm') {
                    $scope.diaryData.location_action = diaryScope.location_action;
                    $scope.diaryData.ToBeHeldAt = parseInt(diaryScope.ToBeHeldAt) || '';
                    $scope.diaryData.location_id = parseInt(diaryScope.location_id) || '';
                    $scope.diaryData.HELD_AT1 = diaryScope.HELD_AT1 || '';
                    $scope.diaryData.HELD_AT2 = diaryScope.HELD_AT2 || '';
                    $scope.diaryData.HELD_AT3 = diaryScope.HELD_AT3 || '';
                    $scope.diaryData.HELD_AT_CITY = diaryScope.HELD_AT_CITY || '';
                    $scope.diaryData.HELD_AT_STATE = diaryScope.HELD_AT_STATE || '';
                    $scope.diaryData.HELD_AT_ZIPCODE = diaryScope.HELD_AT_ZIPCODE || '';
                    $scope.diaryData.HA_PHONE = diaryScope.HA_PHONE || '';
                    $scope.diaryData.CONTACT = diaryScope.CONTACT || '';
                    $scope.diaryData.CON_PHONE = diaryScope.CON_PHONE || '';
                    $scope.diaryData.held_at_flag = diaryScope.held_at_flag || '';
                    $scope.stillLoadingInEditModeAction = false;
                }


            }, 500);

            // check if location_action is null and we have data into held at 1 which is fatch from access sysmte then we need to directly set to respected files
            if (diaryScope.location_action == null || diaryScope.location_action === 'sync_data') {

                $scope.diaryData.location_action = $scope.diaryOriginalData.location_action;
                $scope.diaryData.ToBeHeldAt = parseInt($scope.diaryOriginalData.ToBeHeldAt) || '';
                $scope.diaryData.location_id = parseInt($scope.diaryOriginalData.location_id) || '';
                $scope.diaryData.HELD_AT1 = $scope.diaryOriginalData.HELD_AT1 || '';
                $scope.diaryData.HELD_AT2 = $scope.diaryOriginalData.HELD_AT2 || '';
                $scope.diaryData.HELD_AT3 = $scope.diaryOriginalData.HELD_AT3 || '';
                $scope.diaryData.HELD_AT_CITY = $scope.diaryOriginalData.HELD_AT_CITY || '';
                $scope.diaryData.HELD_AT_STATE = $scope.diaryOriginalData.HELD_AT_STATE || '';
                $scope.diaryData.HELD_AT_ZIPCODE = $scope.diaryOriginalData.HELD_AT_ZIPCODE || '';
                $scope.diaryData.HA_PHONE = $scope.diaryOriginalData.HA_PHONE || '';
                $scope.diaryData.CONTACT = $scope.diaryOriginalData.CONTACT || '';
                $scope.diaryData.CON_PHONE = $scope.diaryOriginalData.CON_PHONE || '';
                $scope.diaryData.LOCATION = '';
            }


            $rootScope.showLoader(false);
        }

        function generalDataScope() {
            $rootScope.showLoader(true);
            
            var generalScope = $scope.diaryOriginalData;

            $scope.generalData.feature_number = generalScope.feature_number || '';
            $scope.generalData.WITNESS_NO = parseInt(generalScope.job.WITNESS_NO) || null;
            $scope.generalData.FILE_NO = generalScope.FILE_NO || '';
            $scope.generalData.matter_id = generalScope.matter_id || null;
            $scope.generalData.CLM_NO = generalScope.CLM_NO || '';
            $scope.generalData.CLM_NOTE = generalScope.CLM_NOTE || '';
            $scope.generalData.productionnote = generalScope.productionnote || '';
            $scope.generalData.special_instructions = generalScope.special_instructions || '';
            $scope.generalData.direct_billing_notes = generalScope.direct_billing_notes || '';
            $scope.generalData.CLM_REP_NAME = generalScope.claim_representative ? generalScope.claim_representative : '';
            $scope.generalData.CLM_REP_NAME.claimRepName = generalScope.claim_representative ? generalScope.claim_representative.ClaimRepFirstName + ' ' + generalScope.claim_representative.ClaimRepLastName + ' | ' + generalScope.claim_representative.CR_CompanyName + ' | ' + generalScope.claim_representative.CR_Phone : '';

            // get the insurance company. if its selected then its fine otherwise bring from claim_representative employee
            $scope.generalData.claim_representative_id = parseInt(generalScope.claim_representative_id) ? parseInt(generalScope.claim_representative_id) : generalScope.claim_representative ? parseInt(generalScope.claim_representative.claimrepresentativeaddress_id) : '';

            // $scope.generalData.CLM_NAME = generalScope.CLM_NAME || '';




            //Toggle of actual start time
            $scope.IsVisibleTimeofExam = true;
            // if ($scope.time.indexOf(generalScope.TIME_TAKEN) !== -1) {
            //     $scope.IsVisibleTimeofExam = false;
            // } else {
            //     $scope.IsVisibleTimeofExam = true;
            // }
            $scope.generalData.TIME_TAKEN = generalScope.TIME_TAKEN || '';
            $scope.diaryData.End_Time = generalScope.End_Time || '';

            $scope.diaryData.End_Time = generalScope.End_Time? moment(generalScope.End_Time,"hh:mm A") :null;
            $scope.diaryData.JOB_LENGTH = generalScope.JOB_LENGTH || '';
            if (generalScope.DATE_TAKEN && generalScope.DATE_TAKEN != null && generalScope.DATE_TAKEN != '') {
                $scope.generalData.DATE_TAKEN = generalScope.DATE_TAKEN || '';
            } else {
                $scope.generalData.DATE_TAKEN = moment().add(1, 'days').format('L'); //getting tomorrow's day for date taken field.
            }

            if ($scope.generalData.TIME_TAKEN) {
                $scope.generalData.TIME_TAKEN = moment($scope.generalData.TIME_TAKEN, 'hh:mm A');
            }

            $scope.generalData.JOB_LENGTH = generalScope.JOB_LENGTH || '';

            
            $scope.generalData.job_type_id = parseInt(generalScope.job_type_id) || $scope.default_job_type;    // Set default to EBT
            $scope.generalData.job_category_id = parseInt(generalScope.job_category_id) || $scope.default_job_category;
            $scope.generalData.case_type_id = parseInt(generalScope.case_type_id) || $scope.default_case_type;
            $scope.generalData.witness_type_id = parseInt(generalScope.witness_type_id) || '';

            $scope.generalData.LOSS_DT = generalScope.LOSS_DT || '';
            //console.log(generalScope.turn_around_days);
            
            if(generalScope.turn_around_info != null){
                $scope.generalData.turn_around_info = parseInt(generalScope.turn_around_info);
            }else{
                $scope.generalData.turn_around_info =  parseInt(generalScope.turn_around_days);
            }
           
            $scope.generalData.di_job_summary = generalScope.di_job_summary;
            $rootScope.showLoader(false);
        }

        function otherDataScope() {
            $rootScope.showLoader(true);

            var otherScope = $scope.diaryOriginalData;

            //Toggle of actual start time
            if ($scope.time.indexOf(otherScope.TIME_ST) !== -1) {
                $scope.IsVisibleStartTime = false;
            } else {
                $scope.IsVisibleStartTime = true;
            }
            $scope.otherData.TIME_ST = otherScope.TIME_ST || '';
            //Toggle of actual end time
            if ($scope.time.indexOf(otherScope.TIME_END) !== -1) {
                $scope.IsVisibleEndTime = false;
            } else {
                $scope.IsVisibleEndTime = true;
            }
            $scope.otherData.TIME_END = otherScope.TIME_END || '';
            $scope.otherData.VIDEO = otherScope.VIDEO || '';
            $scope.otherData.videography = otherScope.videography || '';
            $scope.otherData.telephonic = otherScope.telephonic || '';
            $scope.otherData.call_in_phone = otherScope.call_in_phone || '';
            $scope.otherData.host = otherScope.host || '';
            $scope.otherData.participant = otherScope.participant || '';
            $scope.otherData.videoconfnote = otherScope.videoconfnote || '';
            $scope.otherData.videographynote = otherScope.videographynote || '';
            $scope.otherData.teleconfnote = otherScope.teleconfnote || '';
            $scope.otherData.vc_link = otherScope.vc_link || '';
            $scope.otherData.webex_meeting_number = otherScope.schedule ? otherScope.schedule.webex_meeting_number : '';
            $scope.otherData.webex_password = otherScope.schedule ? otherScope.schedule.webex_password : '';
            $scope.otherData.webex_host_key = otherScope.schedule ? otherScope.schedule.webex_host_key : '';
            $scope.otherData.exhibit_link = otherScope.exhibit_link || '';
            
            $scope.otherData.zoom_join_url = otherScope.schedule ? otherScope.schedule.zoom_join_url : '';
            $scope.otherData.zoom_meeting_password = otherScope.schedule ? otherScope.schedule.zoom_meeting_password : '';
            $scope.otherData.zoom_meeting_id = otherScope.schedule ? otherScope.schedule.zoom_meeting_id : '';
            $scope.otherData.zoom_start_url = otherScope.schedule ? otherScope.schedule.zoom_start_url : '';
            $scope.otherData.meeting_host_key =  otherScope.meeting_host_key || '';

            $scope.otherData.team_meeting_id = otherScope.schedule ? otherScope.schedule.team_meeting_id : '';
            $scope.otherData.team_meeting_password = otherScope.schedule ? otherScope.schedule.team_meeting_password : '';
            $scope.otherData.team_host_key = otherScope.schedule ? otherScope.schedule.team_host_key : '';

            //$scope.otherData.NOTES4 = otherScope.NOTES4 || '';
            $scope.otherData.call_in_notes = otherScope.call_in_notes || '';
            $scope.otherData.LOCN = otherScope.LOCN || '';
            // $scope.otherData.NOTES = otherScope.NOTES || '';
            $scope.otherData.HELD_AT_NOTE = otherScope.HELD_AT_NOTE || '';
            $rootScope.showLoader(false);
        }

        function freeindicationDataScope() {
            $rootScope.showLoader(true);

            var freeindicationScope = $scope.diaryOriginalData;


            $scope.freeindicationData.Claim_Rep1 = parseInt(freeindicationScope.Claim_Rep1) || '';
            $scope.freeindicationData.Claim_Rep2 = parseInt(freeindicationScope.Claim_Rep2) || '';
            $scope.freeindicationData.Claim_Rep3 = parseInt(freeindicationScope.Claim_Rep3) || '';
            $scope.freeindicationData.Claim_Rep4 = parseInt(freeindicationScope.Claim_Rep4) || '';
            $scope.freeindicationData.Claim_Rep5 = parseInt(freeindicationScope.Claim_Rep5) || '';


            //  Parse the Claim number for File number and flag of Free web display, Because in Database its storing both combine.
            $scope.freeindicationData.freeWebDisplay1 = (freeindicationScope.Claim_No1) ? freeindicationScope.Claim_No1.includes('FREE') : false;
            $scope.freeindicationData.freeWebDisplay2 = (freeindicationScope.Claim_No2) ? freeindicationScope.Claim_No2.includes('FREE') : false;
            $scope.freeindicationData.freeWebDisplay3 = (freeindicationScope.Claim_No3) ? freeindicationScope.Claim_No3.includes('FREE') : false;
            $scope.freeindicationData.freeWebDisplay4 = (freeindicationScope.Claim_No4) ? freeindicationScope.Claim_No4.includes('FREE') : false;
            $scope.freeindicationData.freeWebDisplay5 = (freeindicationScope.Claim_No5) ? freeindicationScope.Claim_No5.includes('FREE') : false;

            $scope.freeindicationData.fileNumber1 = (freeindicationScope.Claim_No1) ? freeindicationScope.Claim_No1.replace('FREE', '').replace('-', '') : '';
            $scope.freeindicationData.fileNumber2 = (freeindicationScope.Claim_No2) ? freeindicationScope.Claim_No2.replace('FREE', '').replace('-', '') : '';
            $scope.freeindicationData.fileNumber3 = (freeindicationScope.Claim_No3) ? freeindicationScope.Claim_No3.replace('FREE', '').replace('-', '') : '';
            $scope.freeindicationData.fileNumber4 = (freeindicationScope.Claim_No4) ? freeindicationScope.Claim_No4.replace('FREE', '').replace('-', '') : '';
            $scope.freeindicationData.fileNumber5 = (freeindicationScope.Claim_No5) ? freeindicationScope.Claim_No5.replace('FREE', '').replace('-', '') : '';

            $scope.freeindicationData.autoPartner1 = freeindicationScope.autoPartner1 || 'none';
            $scope.freeindicationData.autoPartner2 = freeindicationScope.autoPartner2 || 'none';
            $scope.freeindicationData.autoPartner3 = freeindicationScope.autoPartner3 || 'none';
            $scope.freeindicationData.autoPartner4 = freeindicationScope.autoPartner4 || 'none';
            $scope.freeindicationData.autoPartner5 = freeindicationScope.autoPartner5 || 'none';


            $rootScope.showLoader(true);
            $scope.refreshOTHATTY(freeindicationScope.OTH_ATTY1, 'OTH_ATTY1');
            $scope.refreshOTHATTY(freeindicationScope.OTH_ATTY2, 'OTH_ATTY2');
            $scope.refreshOTHATTY(freeindicationScope.OTH_ATTY3, 'OTH_ATTY3');
            $scope.refreshOTHATTY(freeindicationScope.OTH_ATTY4, 'OTH_ATTY4');
            $scope.refreshOTHATTY(freeindicationScope.OTH_ATTY5, 'OTH_ATTY5');
        }

        function postentryDataScope() {
            $rootScope.showLoader(true);

            var postentryScope = $scope.diaryOriginalData;

            $scope.postEntryData.INS_NAME = postentryScope.INS_NAME || '';        
            $scope.generalData.DUE_DATE = postentryScope.DUE_DATE || '';           
            $scope.postEntryData.EXPEDITED = postentryScope.EXPEDITED || '';
            $scope.postEntryData.TAPES_NO = postentryScope.TAPES_NO || '';
            $scope.postEntryData.EST_PAGES = postentryScope.EST_PAGES || '';
            $scope.postEntryData.CNCL_TIME = postentryScope.CNCL_TIME ? moment(postentryScope.CNCL_TIME) : '';
            $scope.postEntryData.CNCL_DATE = postentryScope.CNCL_DATE ? moment(postentryScope.CNCL_DATE) : '';
            $scope.postEntryData.CNCL_CALLR = postentryScope.CNCL_CALLR || '';
            $scope.postEntryData.CNCL_STAFF = postentryScope.CNCL_STAFF || '';
            $scope.postEntryData.CNCL_NAME = postentryScope.CNCL_NAME || '';
            $scope.postEntryData.STATUS = postentryScope.STATUS || '';
            $scope.postEntryData.disposition = postentryScope.disposition || '';
            $scope.postEntryData.disposed_date = postentryScope.disposed_date || null;

            $scope.postEntryData.attname_id = parseInt(postentryScope.attname_id) || '';
            $scope.postEntryData.job_resources=postentryScope.job_resources;
            $scope.postEntryData.job_resources.forEach(function (element, i) {
                $scope.postEntryData.job_resources[i].is_payment_required = element.is_payment_required.toString()
            });
            $rootScope.showLoader(false);
        }
        var addTomorrowDay = 1;

        if(moment().format('dddd') === 'Friday')
            addTomorrowDay = 3;

        if(moment().format('dddd') === 'Saturday')
            addTomorrowDay = 2;
        
        $scope.formData={
            tomorrowsRecord: true,
            today: moment().format('MM/DD/YYYY'),
            tomorrow: moment().add(addTomorrowDay, 'days').format('MM/DD/YYYY'),
            todayText : moment().format('dddd'),
            tomorrowText : moment().add(addTomorrowDay, 'days').format('dddd')
        }
        $scope.assignResource = function(jobNo){
            var assignResourceModal = $uibModal.open({
                templateUrl: "modules/diary/caselist/assignReporter.html",
                controller: 'assignReporterController',
                resolve: {
                    JOB: function () {
                        return jobNo;
                    },
                    resources: function () {
                        return null;
                    },
                    formDate : function(){
                        return $scope.formData;
                    }
                    
                },
                keyboard: false,
                backdrop: false,
                close: function () {

                },
                 size: 'lg'
            });

            assignResourceModal.result.then(function (cbData) {
            }, function (cbData) {
               
                if(cbData.isChanged) {
                    $scope.getDiaryAuditLogs();
                    $scope.diaryData.service_request=cbData.service_request;
                    $scope.postEntryData.service_request=cbData.service_request;
                    $scope.diaryData.job_resources=cbData.job_resources;
                    $scope.postEntryData.job_resources=cbData.job_resources;

                    // Interpreter populate
                    $scope.populated_resouce_names = $scope.diaryData.job_resources.filter(item => item.additional_resource_type?.is_translator).map(item => item.resource.name).join(', ');

                    $rootScope.showLoader(false);

                    if($scope.diaryData.service_request.length){
                        var resource_type_ids = [];
                        angular.forEach($scope.diaryData.service_request,function(value, key){
                            resource_type_ids[key] = value.resource_type_id;
                        });
                        $scope.diaryData.resource_type_ids = resource_type_ids;
                    }
                    if($scope.diaryData.job_resources.length){
                        var resource_type_ids = [];
                        angular.forEach($scope.diaryData.job_resources,function(value, key){
                            if(value.service_request && value.service_request.resource_type_id){
                                resource_type_ids[key] = value.resource_type_id;
                                if($scope.diaryData.resource_type_ids && $scope.diaryData.resource_type_ids.length){
                                    $scope.diaryData.resource_type_ids.push(value.resource_type_id)
                                }else{
                                    $scope.diaryData.resource_type_ids = resource_type_ids;
                                }
                            }
                        });
                       
                    }

                    $scope.postEntryData.job_resources.forEach(function (element, i) {
                        $scope.postEntryData.job_resources[i].is_payment_required = element.is_payment_required.toString()
                    });
                }
                if(cbData.job_status_id){
                    $scope.diaryData.job_status_id = cbData.job_status_id || '';
                }

            });
        };
        $scope.assignServiceRequest = function(jobNo){
            var assignServiceRequestModal = $uibModal.open({
                templateUrl: "modules/diary/caselist/assignServiceRequest.html",
                controller: 'assignServiceRequestController',
                resolve: {
                    JOB: function () {
                        return jobNo;
                    },
                    resources: function () {
                        return null;
                    },
                    formDate : function(){
                        return $scope.formData;
                    }
                    
                },
                keyboard: false,
                backdrop: false,
                close: function () {

                },
                 size: 'lg'
            });

            assignServiceRequestModal.result.then(function (cbData) {
            }, function (cbData) {
               
                if(cbData.isChanged){
                    $scope.getDiaryAuditLogs();
                    $scope.diaryData.service_request=cbData.service_request;
                    $scope.postEntryData.service_request=cbData.service_request;
                    $rootScope.showLoader(false);
                }
                

            });
        };
        $scope.assignSingleResource = function(jobNo,resources){
            var assignResourceModal = $uibModal.open({
                templateUrl: "modules/diary/caselist/assignSingleResource.html",
                controller: 'assignSingleResourceController',
                resolve: {
                    JOB: function () {
                        return jobNo;
                    },
                    resources: function () {
                        return resources;
                    },
                    formDate : function(){
                        return $scope.formData;
                    }
                    
                },
                keyboard: false,
                backdrop: false,
                close: function () {

                },
                 size: 'lg'
            });

            assignResourceModal.result.then(function (cbData) {
            }, function (cbData) {
               
                if(cbData.isChanged){
                    $scope.getDiaryAuditLogs();
                    $scope.diaryData.service_request=cbData.service_request;
                    $scope.postEntryData.service_request=cbData.service_request;
                    $scope.diaryData.job_resources=cbData.job_resources;
                    $scope.postEntryData.job_resources=cbData.job_resources;
                    $rootScope.showLoader(false);

                    $scope.postEntryData.job_resources.forEach(function (element, i) {
                        $scope.postEntryData.job_resources[i].is_payment_required = element.is_payment_required.toString()
                    });

                    if($scope.diaryData.service_request.length){
                        var resource_type_ids = [];
                        angular.forEach($scope.diaryData.service_request,function(value, key){
                            resource_type_ids[key] = value.resource_type_id;
                        });
                        $scope.diaryData.resource_type_ids = resource_type_ids;
                    }
                    if($scope.diaryData.job_resources.length){
                        var resource_type_ids = [];
                        angular.forEach($scope.diaryData.job_resources,function(value, key){
                            if(value.service_request && value.service_request.resource_type_id){
                                resource_type_ids[key] = value.resource_type_id;
                                if($scope.diaryData.resource_type_ids && $scope.diaryData.resource_type_ids.length){
                                    $scope.diaryData.resource_type_ids.push(value.resource_type_id)
                                }else{
                                    $scope.diaryData.resource_type_ids = resource_type_ids;
                                }
                            }
                        });
                       
                    }
                }
                if(cbData.job_status_id){
                    $scope.diaryData.job_status_id = cbData.job_status_id || '';
                }

            });
        };

        //On page loading method called.
        $scope.AttorneyFirminit = function () {
            $scope.setPageTitle();
            $rootScope.showLoader(true);
            $scope.loadClaimRep();
            $scope.refreshTranslator();
            
            //Getting Inoursuites data
            commonFactory.get('/api/oursuites')
                .success(function (response) {
                    if (response.result && response.result.our_suites) {
                        $scope.our_suites = response.result.our_suites || '';
                    }
                })
                .error(function (err) {
                });

            $scope.loadLocation();
            $rootScope.showLoader(false);
        };

        $scope.loadClaimRep = function () {
            //Getting all Claim Representative.
            commonFactory.get('/api/claimrep')
                .success(function (response) {
                    if (response.result && response.result.claim_representative) {
                        $scope.claim_representative = response.result.claim_representative || '';
                    }
                })
                .error(function (err) {
                });
        };

        $scope.loadLocation = function () {
            //Getting all locations from locations table.
            commonFactory.get('/api/getLocation')
                .success(function (response) {
                    if (response.result && response.result.data) {
                        $scope.locations = response.result.data || '';
                    }
                })
                .error(function (err) {
                });
        };

        //Getting all Translator.
        $scope.refreshTranslator = function () {
            $rootScope.showLoader(true);
            commonFactory.get('/api/translators')
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response.result && response.result.translator) {
                        $scope.translator = $scope.translator.concat(response.result.translator || []);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        };

         //Getting all languages.
        $scope.refreshLanguages = function () {
            $rootScope.showLoader(true);
            var params = {};
            params.includeId = $scope.diaryData.language;

            commonFactory.getWithParams('/api/all-active-languages', params)
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response.result && response.result.language) {
                        $scope.language = response.result.language || [];
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        };

        $scope.notesInit = function(){
            $state.$current.data.pageTitle = $state.params.id + ' | Notes';
            $rootScope.$broadcast('changeTitle', $state);
            $scope.getJobNotes();
        }
        $scope.diarynotes = [];

        $scope.getJobNotes = function(){
            $rootScope.showLoader(true);
            $scope.job_notes = [];
            var data = {job_no: $state.params.id};
            $scope.diarynotes = [];
            commonFactory.post('/api/job-notes', data).success(function (response) {
                if (response.status == '200') {
                    $scope.job_notes = response.result.job_notes;

                    angular.forEach($scope.job_notes,function(value, key){
                        $scope.diarynotes[key] = value.notes;
                    });
                }
                $rootScope.showLoader(false);
            }).error(function (error) {
                $rootScope.showLoader(false);
            });
        }

        $scope.saveNotes = function(){
            var data = Object.assign({}, $scope.diarynotes);
            data.attorneyId = $scope.diaryData.attorney_id.id;
            if (Object.values($scope.remainingCharacters).some(value => value < 0)) {
                Notification.error("You have exceeded the character limit!");
            } else {
                $rootScope.showLoader(true);
                commonFactory.post('/api/job-notes/' + $state.params.id, data)
                .success(function(response) {
                    if(response.error){
                        $rootScope.showLoader(false);
                        Notification.error(response.result.message);
                    }else{
                        $rootScope.showLoader(false);
                        $scope.getJobNotes();
                        $scope.getDiaryAuditLogs();
                        Notification.success(response.result.message);
                    }
                });
            }
        }

        $scope.uploadFiles = function(files, label, type) {
            if(files.length >0) {
                var formData = new FormData();
                angular.forEach(files, function(file) {
                    formData.append('files[]', file);
                });
                formData.append('type', label);
                formData.append('attorneyId', $scope.diaryData.attorney_id.id);
                formData.append('JOB_NO', $state.params.id);
                var config = {
                    headers: {
                        'Content-Type': undefined
                    }
                };
                
                $rootScope.showLoader(true); 

                let url = '';

                if(type == 'diary'){
                    url = '/api/add-job-notes-docs'
                }else{
                    url = '/api/add_notes_docs';
                }
                $http.post(apiUrl + url ,formData, config)
                .success(function(response) {
                    $scope.getJobNotes();
                    $scope.getDiaryAuditLogs();
                    if(response.message){
                        Notification.success(response.message);
                    }
                    $rootScope.showLoader(false);
                })
                .error(function(error) {
                    $rootScope.showLoader(false);
                    Notification.error(error.error);  
                });
            }
        };
        $scope.openUploadFile = function(label){
            $("#upload-link_" + label).trigger('click');
        };
          
        $(document).ready(function() {
            $("html").on("dragover", function(e) { e.preventDefault(); e.stopPropagation(); });  
            $("html").on("drop", function(e) { e.preventDefault(); e.stopPropagation(); });
        });
        
        $scope.downloadFile = function(id, type) {
            if(id){
                let url = '';
                if(type == 'diary'){
                    url = '/api/get-job-notes-url'
                }else{
                    url = '/api/get-notes-url';
                }
                $http.get(apiUrl + url, {
                    params: {
                        id: id
                    }
                }).then(function(response) {
                    if(!response.data.error){
                        window.open(response.data.result,'_blank');
                    }else{
                        Notification.error(response.data.message);
                    }
                });
            }
        };

        $scope.deletefiles = function(id, type){
            var data = {
                id : id
            }
            swal({
                title: "Are you sure",
                text: "You want to delete?",
                type: "warning",
                html: true,
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
                
            },
            function (isConfirm) {
                if (isConfirm) { 
                    let url = '';
                    if(type == 'diary'){
                        url = '/api/delete-job-notes-docs'
                    }else{
                        url = '/api/delete_notes_docs';
                    }

                    $rootScope.showLoader(true); 
                    $http.post(apiUrl+url, data)
                    .success(function(res){
                        $scope.getJobNotes();
                        $scope.getDiaryAuditLogs();
                        $rootScope.showLoader(false);
                        if(res.success){
                            Notification.success(res.message);
                        }else{
                            Notification.error(res.message);
                        }
                    });
                }
            });
        }

        $scope.documentsInit = function(){
            $state.$current.data.pageTitle = $state.params.id + ' | Documents';
            $rootScope.$broadcast('changeTitle', $state);
            $rootScope.showLoader(true);
            $scope.getJobInvoices();
            $scope.witnesslist();
            $scope.getJobTranscripts();
            $scope.getJobInternalDocuments();
            $scope.getJobProofOfRequestDocuments();
            $scope.getResourceDoucments();
            $scope.getMarkedExhibits();
            $scope.getPreDepoExhibits();
            $rootScope.showLoader(false);
        }

        $scope.getJobInvoices = function(){
            $rootScope.showLoader(true);
            $scope.job_invoices = [];
            var data = {job_no: $state.params.id};
            commonFactory.post('/api/job-documents/get-invoices', data).success(function (response) {
                if (response.status == '200') {
                    $scope.job_invoices = response.result.invoices;
                }
                $rootScope.showLoader(false);
            }).error(function (error) {
                $rootScope.showLoader(false);
            });
        }

        $scope.getJobTranscripts = function(){
            $rootScope.showLoader(true);
            $scope.job_transcripts = [];
            var data = {job_no: $state.params.id};
            commonFactory.post('/api/job-documents/get-transcripts', data).success(function (response) {
                if (response.status == '200') {
                    $scope.job_transcripts = response.result.transcripts;
                }
                $rootScope.showLoader(false);
            }).error(function (error) {
                $rootScope.showLoader(false);
            });
        }

        $scope.showMarkedExhibits = function(witness_id){
            let show = false;
            $scope.marked_exhibits_witness.forEach(function(item) {
                 if (!show){
                     show = item.id == witness_id;;
                 }
            });
            return show;
        }

        $scope.getMarkedExhibits = function(){
            $rootScope.showLoader(true);
            $scope.marked_exhibits = [];
            $scope.marked_exhibits_witness = [];
            var data = {job_no: $state.params.id};
            commonFactory.post('/api/job-documents/get-marked-exhibits', data).success(function (response) {
                if (response.status == '200') {
                    $scope.marked_exhibits = response.result.marked_exhibits;
                    if($scope.marked_exhibits.length > 0) {
                        $scope.marked_exhibits.forEach(function(item) {
                            item.selected = false;
                        });
                    }
                    $scope.marked_exhibits_witness = response.result.marked_exhibits_witness;
                    $scope.getJobTranscripts();
                }
                $rootScope.showLoader(false);
            }).error(function (error) {
                $rootScope.showLoader(false);
            });
        }

        // Select Marked Exhibits / Email All Exhibits logic
        $scope.selectAllExhibits = false;
        $scope.isAnySelectedExhibit = false;
        // Toggles the selection of all checkboxes
        $scope.toggleSelectAllExhibits = function(isChecked) {
            $scope.marked_exhibits.forEach(function(item) {
                item.selected = isChecked;
            });
            $scope.isAnySelectedExhibit = $scope.marked_exhibits.some(item => item.selected);            
        };

        // Checks if all items are selected to update the "Select All" state
        $scope.toggleSelectExhibit = function() {
            $scope.selectAllExhibits = $scope.marked_exhibits.every(function(item) {
                return item.selected;
            });

            $scope.isAnySelectedExhibit = $scope.marked_exhibits.some(item => item.selected);
        };

        $scope.downloadMarkedExhibits = function() {
            let markedExhibitIds = []
            $scope.marked_exhibits.forEach(function(item) {
                if(item.selected) {
                    markedExhibitIds.push(item.id);
                }
            });

            if(markedExhibitIds.length > 0) {
                var data = {job_no: $state.params.id, exhibitIds: markedExhibitIds};
                commonFactory.post('/api/download-exhibits-files', data).success(function (response) {
                    if(response.error){
                        Notification.error(response.error || "Something went wrong!");
                    } else {
                        var fileUrl = apiUrl + "/" + $scope.JOB_NO + ".zip";
                        var fileName = $scope.JOB_NO + ".zip";

                        var downloadLink = document.createElement("a");
                        downloadLink.style.display = "none";

                        downloadLink.href = fileUrl;
                        downloadLink.download = fileName;

                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                        Notification.success("Zip file downloaded successfully");
                    }
                    $scope.marked_exhibits.forEach(function(item) {
                        item.selected = false;
                    });
                    $scope.selectAllExhibits = false;
                    angular.element('input[ng-model="selectAllExhibits"]').prop('checked', false);
                    $rootScope.showLoader(false);
                }).error(function (error) {
                    $rootScope.showLoader(false);
                    Notification.error("Oops ! Something went wrong");
                });
            } else {
                Notification.error("Please select file!");
            }           
        };

        $scope.sendEmailToExhibit = function (exhibit) {
            exhibitIds = [];
            var JOB_NO = $state.params.id;
            if(exhibit == 'all') {
                $scope.marked_exhibits.forEach(function(item) {
                    exhibitIds.push(item.id)
                });
            } else {
                exhibitIds.push(exhibit);
            }

            var sendEmailToExhibitVar = $uibModal.open({
                templateUrl: "modules/diary/addcase/email_popup_send_exhibit_email.html",
                controller: 'sendExhibitEmailController',
                resolve: {
                    JOB_NO: function () {
                        return JOB_NO;
                    },
                    exhibitIds: function () {
                        return exhibitIds;
                    },
                    caption: function(){
                        return $scope.diaryData.CAPTION
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });
        }

        $scope.getPreDepoExhibits = function(){
            $rootScope.showLoader(true);
            $scope.predepo_exhibits = [];
            var data = {job_no: $state.params.id};
            commonFactory.post('/api/job-documents/get-predepo-exhibits', data).success(function (response) {
                if (response.status == '200') {
                    $scope.predepo_exhibits = response.result.predepo_exhibits;
                }
                $rootScope.showLoader(false);
            }).error(function (error) {
                $rootScope.showLoader(false);
            });
        }

        $scope.getResourceDoucments = function(){
            $rootScope.showLoader(true);
            $scope.resource_docs = [];
            var data = {job_no: $state.params.id};
            commonFactory.post('/api/job-documents/get-resource-documents', data).success(function (response) {
                if (response.status == '200') {
                    $scope.resource_docs = response.result.resource_documents;
                }
                $rootScope.showLoader(false);
            }).error(function (error) {
                $rootScope.showLoader(false);
            });
        }

        $scope.getJobInternalDocuments = function(){
            $rootScope.showLoader(true);
            $scope.internal_docs = [];
            var data = {job_no: $state.params.id};
            commonFactory.post('/api/job-documents/get-internal-documents', data).success(function (response) {
                if (response.status == '200') {
                    $scope.internal_docs = response.result.documents;
                }
                $rootScope.showLoader(false);
            }).error(function (error) {
                $rootScope.showLoader(false);
            });
        }

        $scope.getJobProofOfRequestDocuments = function () {
            $rootScope.showLoader(true);
            $scope.proof_of_request_docs = [];
            var data = {job_no: $state.params.id};
            commonFactory.post('/api/job-documents/get-proof-of-request-documents', data).success(function (response) {
                if (response.status == '200') {
                    $scope.proof_of_request_docs = response.result.documents;
                }
                $rootScope.showLoader(false);
            }).error(function (error) {
                $rootScope.showLoader(false);
            });
        }

        $scope.redirectDepoLaunchRepoter = function(uid, file_cnt){
           // if files already exists for job then redierct to reupload
            let script = 'jobupload.php';
            if(file_cnt > 0){
                script = 'reupload.php';
            }

            var enscript = 'launch_user.dtop?uid='+uid+'&actionfrom=backend&toggle_to_repoter=1&script='+script+'?job_no='+$state.params.id;
            
            $http.post(apiUrl + '/api/auth-user-get-token', {uid : uid}).then(function(response) { 
                if(response.data.status){
                    var givenUrl = $rootScope.depoHost +"/"+enscript+"&access_token="+response.data.token;
                    $window.open(givenUrl, '_blank');
                }else{
                    Notification.error('Something went wrong!'); 
                }
            });
        };

        $scope.showInvoicePrint = function (invoice_id) {

            if (angular.isArray(invoice_id)) {
                var invoices = invoice_id;
            }
            else {
                var invoices = [{text: invoice_id}];
            }
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/view_popup.html",
                controller: 'viewPrintInvoiceController',
                resolve: {
                    invoice_id: function () {
                        return invoices;
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });


            invoiceModal.result.then(function (is_invoice_printed) {
                if (is_invoice_printed) {
                    var data = {invoice_ids: invoices};
                    commonFactory.post('/api/invoice/mark-as-printed', data).success(function (response) {
                    }).error(function () {
                    });
                }
            }, function () {});

        }

        $scope.redirectMarkedExhibits = function(id){
            // let url = $rootScope.depoHost+"/upload/exhibits/"+($state.params.id).substr(0,4)+"/"+filename;
            // $window.open(url, '_blank');

            if(id){
                $http.get(apiUrl + '/api/get-marked-exhibit-url', {
                    params: {
                        id: id
                    }
                }).then(function(response) {
                    if(!response.data.error){
                        window.open(response.data.result,'_blank');
                    }else{
                        Notification.error(response.data.message);
                    }
                });
            }
        }

        $scope.redirectPreDepoExhibits = function(id){
            // let url = $rootScope.depoHost+"/upload/raw_exhibits/"+($state.params.id).substr(0,4)+"/"+filename;
            // $window.open(url, '_blank');

            if(id){
                $http.get(apiUrl + '/api/get-predepo-exhibit-url', {
                    params: {
                        id: id
                    }
                }).then(function(response) {
                    if(!response.data.error){
                        window.open(response.data.result,'_blank');
                    }else{
                        Notification.error(response.data.message);
                    }
                });
            }
        }

        $scope.redirectInternalDocuments = function(id){
            // let url = $rootScope.depoHost+"/upload/internal_documents/"+($state.params.id).substr(0,4)+"/"+filename;
            // $window.open(url, '_blank');

            if(id){
                $http.get(apiUrl + '/api/get-internal-document-url', {
                    params: {
                        id: id
                    }
                }).then(function(response) {
                    if(!response.data.error){
                        window.open(response.data.result,'_blank');
                    }else{
                        Notification.error(response.data.message);
                    }
                });
            }
        }

        $scope.redirectProofOfRequestDocuments = function (id) {
            if (id) {
                $http.get(apiUrl + '/api/get-proof-of-request-document-url', {
                    params: {
                        id: id
                    }
                }).then(function (response) {
                    if (!response.data.error) {
                        window.open(response.data.result, '_blank');
                    } else {
                        Notification.error(response.data.message);
                    }
                });
            }
        }

        var req_data = {};
        req_data.email = $rootScope.auth_user.email;
        $scope.depoUserId = null;

        $http.post(apiUrl + '/api/users/depositionnet-user', req_data)
        .success(function(depo_user) { 
            $scope.depo_user = depo_user.user;    
            if($scope.depo_user){
                $scope.depoUserId = $scope.depo_user.uid;
            }
        });

        $scope.addPreDepoExhibits = function () {
            var JOB_NO = $state.params.id;
            var modal = $uibModal.open({
                templateUrl: "modules/diary/addcase/add_predepo_exhibits_modal.html",
                controller: 'addPredepoExhibitsController',
                resolve: {
                    JOB_NO: function () {
                        return JOB_NO;
                    },
                    depoUserId: function () {
                        return $scope.depoUserId;
                    },
                    loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                        ]);
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

            modal.result.then(function (cbData) {
            }, function (cbData) {
                $scope.getPreDepoExhibits();
            });
        }

        $scope.editPreDepoExhibits = function (id) {
            var JOB_NO = $state.params.id;
            var modal = $uibModal.open({
                templateUrl: "modules/diary/addcase/edit_predepo_exhibits_modal.html",
                controller: 'editPredepoExhibitsController',
                resolve: {
                    id: function () {
                        return id;
                    },
                    JOB_NO: function () {
                        return JOB_NO;
                    },
                    depoUserId: function () {
                        return $scope.depoUserId;
                    },
                    loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([]);
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

            modal.result.then(function (cbData) {
            }, function (cbData) {
                $scope.getPreDepoExhibits();
            });
        }

        $scope.onTabPress =function(event){
            if (event.key === "Tab" || event.keyCode === 9) {
                event.preventDefault(); 
                if(!$scope.diaryData.job_case_id.id){
                    $scope.openAddCasePopup();
                } else if($scope.diaryData.job_case_id.id){
                    // Allow tabbing to the next element
                    let currentElement = event.target;
                    let formElements = Array.from(document.querySelectorAll("input, select, textarea, button, a"))
                        .filter(el => el.tabIndex >= 0 && !el.disabled && el.offsetParent !== null);
                    
                    let currentIndex = formElements.indexOf(currentElement);
                    if (currentIndex !== -1 && currentIndex < formElements.length - 1) {
                        formElements[currentIndex + 1].focus();
                    }
        
                    event.preventDefault();
                }
            }
        };
        
        $scope.deletePreDepoExhibits = function (id) {
            var JOB_NO = $state.params.id;
            swal({
                    title: "Alert",
                    text: "Are you sure want to delete this?",
                    type: "error",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes!",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.delete('/api/predepo-exhibits/' + id + '?JOB_NO=' + JOB_NO + '&depoUserId=' + $scope.depoUserId).success(function (res) {
                            $rootScope.showLoader(false);
                            if (res.error) {
                                Notification.error(res.result.message);
                            } else {
                                Notification.success(res.result.message);
                            }
                            $scope.getPreDepoExhibits();
                        }).error(function (err) {
                            $rootScope.showLoader(false);
                            Notification.error(err.result.message);
                            $scope.getPreDepoExhibits();
                        });
                    }
                });
        }

        $scope.addDeliverables = function () {
            var JOB_NO = $state.params.id;
            var modal = $uibModal.open({
                templateUrl: "modules/diary/addcase/add_deliverable_documents_modal.html",
                controller: 'addDeliverableDocumentsController',
                resolve: {
                    JOB_NO: function () {
                        return JOB_NO;
                    },
                    depoUserId: function () {
                        return $scope.depoUserId;
                    },
                    job_transcripts: function () {
                        return $scope.job_transcripts;
                    },
                    loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                        ]);
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

            modal.result.then(function (cbData) {
            }, function (cbData) {
                $scope.getJobTranscripts();
            });
        }

        $scope.openDeleteDocumentModal = function (description) {
            var JOB_NO = $state.params.id;
            var modal = $uibModal.open({
                templateUrl: "modules/diary/addcase/delete_deliverable_documents_modal.html",
                controller: 'deleteDeliverableDocumentsController',
                resolve: {
                    JOB_NO: function () {
                        return JOB_NO;
                    },
                    description: function () {
                        return description;
                    },
                    type : function(){
                        return null
                    },
                    title : function(){
                        return null;
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

            modal.result.then(function (cbData) {
            }, function (cbData) {
                $scope.getJobTranscripts();
            });
        }

        $scope.downloadVideo = function(document_no){
            $http.get(apiUrl + '/api/get-deliverable-document-url', {
                params: {
                    document_no: document_no
                }
            }).then(function (response) {
                if (!response.data.error) {
                    window.open(response.data.result, '_blank');
                } else {
                    Notification.error(response.data.message);
                }
            });
        }



        $scope.listVideos= function(witnessId,witnessName,type){
            var JOB_NO = $state.params.id;
            var modal = $uibModal.open({
                templateUrl: "modules/diary/addcase/delete_deliverable_documents_modal.html",
                controller: 'deleteDeliverableDocumentsController',
                resolve: {
                    JOB_NO: function () {
                        return JOB_NO;
                    },
                    description: function () {
                        return witnessName;
                    },
                    type : function(){
                        return type
                    },
                    title : function(){
                        return 'VIDEO DELIVERABLES OF';
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

        }


        $scope.viewAuditLog = function (type) {
            var JOB_NO = $state.params.id;
            var auth_user = $rootScope.auth_user;
            // var givenUrl = $rootScope.depoHost + "/autologin.php?userid=" + auth_user.email + "&password=" + auth_user.depo_psd;

            // $rootScope.launchIFrame("http://localhost/court-reporting/view_exhibit_audit_log.php?job_no=600343", function(){});
            $uibModal.open({
                templateUrl: "modules/diary/addcase/view_audit_logs.html",
                controller: function ($scope, JOB_NO, type, $uibModalInstance, commonFactory) {
                    $scope.search = "";
                    let pre = "";
                    if (type === 'internal') {
                        pre = "INTERNAL DOCUMENTS - "
                    } else if (type === 'proof') {
                        pre = "PROOF OF ORDER - "
                    }

                    $scope.$watch('search', function (search) {
                        if (search.length > 2) {
                            $scope.viewAuditLogApi();
                        }
                        if (search.length === 0) {
                            $scope.resetPagination();
                        }
                    });

                    $scope.title = pre + "AUDIT(S) FOR JOB - " + JOB_NO


                    $scope.report = {
                        startDate: moment().startOf('year').format('YYYY-MM-DD'),
                        endDate: moment().endOf('year').format('YYYY-MM-DD')
                    };

                    $scope.itemsPerPage = 10;
                    $scope.currentPage = 1;


                    $scope.resetPagination = function (number = 10) {
                        $scope.currentPage = 1; //reset to first paghe
                        $scope.itemsPerPage = number;
                        $scope.viewAuditLogApi();
                    };
                    $scope.numberWithCommas = function (x) {
                        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                    }


                    $scope.viewAuditLogApi = function () {
                        $rootScope.showLoader(true);

                        var offset = ($scope.currentPage - 1) * $scope.itemsPerPage;
                        var limit = $scope.itemsPerPage;


                        var form_data = {};
                        form_data.JOB_NO = JOB_NO;
                        form_data.type = type;
                        form_data.offset = offset;
                        form_data.search = $scope.search;
                        form_data.limit = limit;


                        commonFactory.post('/api/job-documents/get-document-audit-logs', form_data).then(function (response) {
                            $rootScope.showLoader(false);
                            $scope.repCheckData = response.data.result;
                            $scope.totalRecords = response.data.totalRecords;
                            $scope.isRec = false;
                            if ($scope.repCheckData.length > 0) {
                                $scope.isRec = true;
                            }
                        });
                    }

                    $scope.closePopupWindow = function () {
                        $uibModalInstance.close();
                    }

                    // Added to sort the record by any field from the table.
                    $scope.predicate = 'REP_PAYDT';
                    $scope.reverse = true;
                    $scope.order = function (predicate) {
                        $scope.reverse = ($scope.predicate === predicate) ? !$scope.reverse : false;
                        $scope.predicate = predicate;
                    };

                    $scope.last_check_number = 0;
                    $scope.isCheckChange = function (index) {
                        var checkRec = $scope.repCheckData[index];
                        if (index == 0) {
                            $scope.last_check_number = checkRec.REP_CHECK;
                            return false;
                        }
                        if ($scope.last_check_number != checkRec.REP_CHECK) {
                            $scope.last_check_number = checkRec.REP_CHECK;
                            return true;
                        } else {
                            return false;
                        }
                    };

                },
                size: 'lg',
                backdrop: false,
                resolve: {
                    JOB_NO: function () {
                        return JOB_NO;
                    },
                    type: function () {
                        return type;
                    }
                }
            });
        }

        $scope.addMarkedExhibits = function(){
            var JOB_NO = $state.params.id;
            var modal = $uibModal.open({
                templateUrl: "modules/diary/addcase/add_marked_exhibits_modal.html",
                controller: 'addMarkedExhibitsController',
                resolve: {
                    JOB_NO: function () {
                        return JOB_NO;
                    },
                    depoUserId: function () {
                        return $scope.depoUserId;
                    },
                    loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                        ]);
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

            modal.result.then(function (cbData) {
            }, function (cbData) {
                $scope.getMarkedExhibits();
            });
        }

        $scope.editMarkedExhibits = function (id) {
            var JOB_NO = $state.params.id;
            var modal = $uibModal.open({
                templateUrl: "modules/diary/addcase/edit_marked_exhibits_modal.html",
                controller: 'editMarkedExhibitsController',
                resolve: {
                    id: function () {
                        return id;
                    },
                    JOB_NO: function () {
                        return JOB_NO;
                    },
                    depoUserId: function () {
                        return $scope.depoUserId;
                    },
                    loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([]);
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

            modal.result.then(function (cbData) {
            }, function (cbData) {
                $scope.getMarkedExhibits();
            });
        }

        $scope.deleteMarkedExhibits = function (id) {
            var JOB_NO = $state.params.id;
            swal({
                    title: "Alert",
                    text: "Are you sure want to delete this?",
                    type: "error",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes!",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.delete('/api/marked-exhibits/' + id + '?JOB_NO=' + JOB_NO+ '&depoUserId=' + $scope.depoUserId).success(function (res) {
                            $rootScope.showLoader(false);
                            if (res.error) {
                                Notification.error(res.result.message);
                            } else {
                                Notification.success(res.result.message);
                            }
                            $scope.getMarkedExhibits();
                        }).error(function (err) {
                            $rootScope.showLoader(false);
                            Notification.error(err.result.message);
                            $scope.getMarkedExhibits();
                        });
                    }
                });
        }

        $scope.addInternalDocuments = function () {
            var JOB_NO = $state.params.id;
            var modal = $uibModal.open({
                templateUrl: "modules/diary/addcase/add_internal_documents_modal.html",
                controller: 'addInternalDocumentsController',
                resolve: {
                    JOB_NO: function () {
                        return JOB_NO;
                    },
                    depoUserId: function () {
                        return $scope.depoUserId;
                    },
                    loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                        ]);
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

            modal.result.then(function (cbData) {
            }, function (cbData) {
                $scope.getJobInternalDocuments();
            });
        }
        $scope.editInternalDocuments = function (id) {
            var JOB_NO = $state.params.id;
            var modal = $uibModal.open({
                templateUrl: "modules/diary/addcase/edit_internal_documents_modal.html",
                controller: 'editInternalDocumentsController',
                resolve: {
                    id: function () {
                        return id;
                    },
                    JOB_NO: function () {
                        return JOB_NO;
                    },
                    depoUserId: function () {
                        return $scope.depoUserId;
                    },
                    loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([]);
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

            modal.result.then(function (cbData) {
            }, function (cbData) {
                $scope.getJobInternalDocuments();
            });
        }
        $scope.deleteInternalDocuments = function (id) {
            var JOB_NO = $state.params.id;
            swal({
                    title: "Alert",
                    text: "Are you sure want to delete this?",
                    type: "error",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes!",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.delete('/api/internal-documents/' + id + '?JOB_NO=' + JOB_NO + '&depoUserId=' + $scope.depoUserId).success(function (res) {
                            $rootScope.showLoader(false);
                            if (res.error) {
                                Notification.error(res.result.message);
                            } else {
                                Notification.success(res.result.message);
                            }
                            $scope.getJobInternalDocuments();
                        }).error(function (err) {
                            $rootScope.showLoader(false);
                            Notification.error(err.result.message);
                            $scope.getJobInternalDocuments();
                        });
                    }
                });
        }
        $scope.addProofOfRequestDocuments = function () {
            var JOB_NO = $state.params.id;
            var modal = $uibModal.open({
                templateUrl: "modules/diary/addcase/add_proof_of_request_documents_modal.html",
                controller: 'addProofOfRequestDocumentsController',
                resolve: {
                    JOB_NO: function () {
                        return JOB_NO;
                    },
                    depoUserId: function () {
                        return $scope.depoUserId;
                    },
                    loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([]);
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

            modal.result.then(function (cbData) {
            }, function (cbData) {
                $scope.getJobProofOfRequestDocuments();
            });
        }

        $scope.editProofOfRequestDocument = function (id) {
            var JOB_NO = $state.params.id;
            var modal = $uibModal.open({
                templateUrl: "modules/diary/addcase/edit_proof_of_request_documents_modal.html",
                controller: 'editProofOfRequestDocumentsController',
                resolve: {
                    JOB_NO: function () {
                        return JOB_NO;
                    },
                    id: function () {
                        return id;
                    },
                    depoUserId: function () {
                        return $scope.depoUserId;
                    },
                    loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([]);
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

            modal.result.then(function (cbData) {
            }, function (cbData) {
                $scope.getJobProofOfRequestDocuments();
            });
        }

        $scope.deleteProofDoc = function (id) {
            var JOB_NO = $state.params.id;
            swal({
                    title: "Alert",
                    text: "Are you sure want to delete this?",
                    type: "error",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes!",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.delete('/api/proof-of-request-documents/' + id + '?JOB_NO=' + JOB_NO+ '&depoUserId=' + $scope.depoUserId).success(function (res) {
                            $rootScope.showLoader(false);
                            if (res.error) {
                                Notification.error(res.result.message);
                            } else {
                                Notification.success(res.result.message);
                            }
                            $scope.getJobProofOfRequestDocuments();
                        }).error(function (err) {
                            $rootScope.showLoader(false);
                            Notification.error(err.result.message);
                            $scope.getJobProofOfRequestDocuments();
                        });
                    }
                });
        }

        //On page loading method called.
        $scope.Generalinit = function () {
            $rootScope.showLoader(true);
            $scope.loadClaimRep();
            $scope.loadLocation();
            $scope.endTime = '';
            $scope.setPageTitle();

            //Getting all jobtypes.
            commonFactory.get('/api/jobtypes')
                .success(function (response) {
                    if (response.result && response.result.jobtype) {
                        $scope.jobtype = response.result.jobtype || '';
                        for (var i = 0; i < $scope.jobtype.length; i++) {
                            if ($scope.jobtype[i].isDefault == 1) {
                                if (!$scope.generalData.job_type_id) {
                                    $scope.generalData.job_type_id = $scope.generalData.job_type_id || $scope.jobtype[i].id;
                                }
                                $scope.default_job_type = $scope.jobtype[i].id;
                            }
                        }
                    }
                })
                .error(function (err) {
                });

            //Getting all jobcategory.
            commonFactory.get('/api/jobcategories')
                .success(function (response) {
                    if (response.result && response.result.jobcategories) {
                        $scope.jobcategories = response.result.jobcategories || '';
                        for (var i = 0; i < $scope.jobcategories.length; i++) {
                            if ($scope.jobcategories[i].isDefault == 1) {
                                if (!$scope.generalData.job_category_id) {
                                    $scope.generalData.job_category_id = $scope.jobcategories[i].id;
                                }
                                $scope.default_job_category = $scope.jobcategories[i].id;
                            }
                        }
                    }
                })
                .error(function (err) {
                });

           
            $rootScope.showLoader(false);
        }
        //On page loading method called.
        $scope.Otherinfoinit = function () {
            $scope.setPageTitle();
        };

        $scope.Freecopyinit = function () {
            $rootScope.showLoader(true);

            $rootScope.showLoader(false);
        };

        $scope.Postentryinit = function () {
            $rootScope.showLoader(true);
            $scope.setPageTitle();
            if ($scope.diaryData.attname_id) {
                $scope.postEntryData.attname_id = $scope.diaryData.attname_id;
            }
            
            $scope.refreshReporters();
            $scope.refreshTypists();

            $rootScope.showLoader(false);
        };

        //Getting Attorney Firm office_code
        $scope.attorneyofficedata = function (val) {
            return $http.get(apiUrl + '/api/getattorneyfirm', {
                params: {
                    name: val
                }
            }).then(function (response) {
                $scope.foundFromElaw = false;
                if (response.data.foundFromElaw) {
                    $scope.foundFromElaw = true;
                }
                return response.data.result.map(function (item) {
                    return item;
                });
            });
        };

        //Refreshing locations
        $scope.refreshLocations = function () {
            commonFactory.get('/api/getLocation')
                .success(function (response) {
                    if (response.result && response.result.data) {
                        $scope.locations = response.result.data || '';
                    }
                })
                .error(function (err) {
                });
        };

        //Refreshing our suites.
        $scope.refreshOursuites = function () {
            $rootScope.showLoader(true);
            commonFactory.get('/api/oursuites')
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response.result && response.result.our_suites) {
                        $scope.our_suites = response.result.our_suites || '';
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        };

        //Refreshing all reporters
        $scope.refreshReporters = function () {
            $scope.fetchingReporter = true;
            commonFactory.post('/api/resource-list-by-type',{rep_typ:'R'})
                .success(function (response) {
                    if (response && response.result && response.result.resources) {
                        $scope.reporters = response.result.resources || '';
                    }
                    $scope.fetchingReporter = false;
                })
                .error(function (err) {
                    $scope.fetchingReporter = false;
                }); 
        };

        //Refreshing all typists
        $scope.refreshTypists = function () {
            $scope.fetchingTypists = true;
            commonFactory.post('/api/resource-list-by-type',{rep_typ:'T'})
                .success(function (response) {
                    if (response && response.result && response.result.resources) {
                        $scope.typists = response.result.resources || '';
                    }
                    $scope.fetchingTypists = false;
                })
                .error(function (err) {
                    $scope.fetchingTypists = false;
                });
        };

        //Getting All caseType refresh.
        $scope.refreshCasetype = function () {
            $rootScope.showLoader(true);
            commonFactory.get('/api/casetypes')
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response && response.result && response.result.casetype) {
                        $scope.casetype = response.result.casetype || '';
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        };

        //Getting All Claim Representative Company refresh.
        $scope.refreshClaimRepCompany = function () {
            $rootScope.showLoader(true);
            commonFactory.get('/api/claimrep')
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response.result && response.result.claim_representative) {
                        $scope.claim_representative = response.result.claim_representative || '';
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        };

        //Getting All Claim Representative Name refresh.
        $scope.refreshClaimRepName = function (value) {
            $rootScope.showLoader(true);
            var data = {};
            data.id = value;
            commonFactory.post('/api/getClaimrepname', data)
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response && response.result && response.result.claimrep_name) {
                        $scope.claimrep_name = response.result.claimrep_name || '';
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        };

        //Getting all witnesstypes.
        $scope.refreshWitnesstype = function () {
            $rootScope.showLoader(true);
            commonFactory.get('/api/witnesstypes')
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response.result && response.result.witnesstype) {
                        $scope.witnesstype = response.result.witnesstype || '';
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        };

        //Getting all Suffixs.
        commonFactory.get('/api/getsuffixs')
            .success(function(response) {
                if (response.result && response.result.suffixs) {
                    $scope.witness_suffix = response.result.suffixs || '';
                }
            })
            .error(function(err) {});

        //Getting all jobtypes refresh.
        $scope.refreshJobtype = function () {
            $rootScope.showLoader(true);
            commonFactory.get('/api/jobtypes')
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response.result && response.result.jobtype) {
                        $scope.jobtype = response.result.jobtype || '';
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        };

        //Getting all jobcategory refresh.
        $scope.refreshJobcategory = function () {
            $rootScope.showLoader(true);
            commonFactory.get('/api/jobcategories')
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response.result && response.result.jobcategories) {
                        $scope.jobcategories = response.result.jobcategories || '';
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        };

        //Refreshing Firm and get related firm information
        $scope.refreshFirm = function (id) {
            if (id) {
                $rootScope.showLoader(true);
                var data = { attorney_id: id };
                $scope.refreshDiaryData(id, 'attorney_id');

                var firmdata = { firm_id: id }
                $timeout(function () {
                    commonFactory.post('/api/attorneybyfirm', firmdata)
                        .success(function (response) {
                            if (response) {
                                if (response && response.result && response.result.attorney_data) {
                                    $scope.attorney = response.result.attorney_data || '';
                                }
                            }
                        })
                        .error(function (err) {
                            $rootScope.showLoader(false);
                        });
                }, 500);

                var ebtdata = { firm_id: id }
                $timeout(function () {
                    commonFactory.post('/api/ebtbyfirm', ebtdata)
                        .success(function (response) {
                            if (response) {
                                if (response && response.result && response.result.ebt_data) {
                                    $scope.ebt = response.result.ebt_data || '';
                                }
                            }
                        })
                        .error(function (err) {
                            $rootScope.showLoader(false);
                        });
                }, 500);
                $rootScope.showLoader(false);
            } else {
                $rootScope.showLoader(false);
            }
        };

        $scope.refreshDiaryData = function (id, key) {
            if (id) {
                $rootScope.showLoader(true);
                var data = { attorney_id: id }
                commonFactory.post('/api/getAttorneyfirmdata', data)
                    .success(function (response) {
                        $rootScope.showLoader(false);
                        $scope.diaryData[key] = response.result.attorney_data || '';
                        
                        //assigning status and beeper(autopartner) of firm.
                        $scope.firm_status = response.result.attorney_data.STATUS;
                        angular.forEach($scope.auto_partners,function(value, key){
                            if(value.code == response.result.attorney_data.BEEPER){                
                                $scope.firm_beeper = value.text;
                            }
                        });
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
                $rootScope.showLoader(false);
            } else {
                $rootScope.showLoader(false);
            }
        };

        //Refreshing other attorney firm1
        $scope.refreshOTHATTY = function (NAME_KEY, key, refresh = null) {
            if (NAME_KEY && (NAME_KEY != null || NAME_KEY != undefined)) {

                $rootScope.showLoader(true);
                var data = { NAME_KEY: NAME_KEY }
                commonFactory.post('/api/getAttorneyfirmdata', data)
                    .success(function (response) {
                        $rootScope.showLoader(false);
                        $scope.freeindicationData[key] = response.result.attorney_data || '';
                        if(refresh){
                            if(key == 'OTH_ATTY1'){
                                $scope.freeindicationData.autoPartner1 = response.result.attorney_data.BEEPER || 'none';
                            }else if(key == 'OTH_ATTY2'){
                                $scope.freeindicationData.autoPartner2 = response.result.attorney_data.BEEPER || 'none';
                            }else if(key == 'OTH_ATTY3'){
                                $scope.freeindicationData.autoPartner3 = response.result.attorney_data.BEEPER || 'none';
                            }else if(key == 'OTH_ATTY4'){
                                $scope.freeindicationData.autoPartner4 = response.result.attorney_data.BEEPER || 'none';
                            }else if(key == 'OTH_ATTY5'){
                                $scope.freeindicationData.autoPartner5 = response.result.attorney_data.BEEPER || 'none';
                            }
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
                $rootScope.showLoader(false);
            } else {
                $rootScope.showLoader(false);
            }
        };

        $scope.onselectdisable = true;

        $scope.onSelectIndex = function($item, $model, $label){
            if($item.from_elaw==0){
                $scope.diaryData.job_case_id =$item;
                $scope.onSelectCase($item,$model,$label)
            }
            else{
                $scope.diaryData.IndexNumber = $item.IndexNumber;
                $scope.diaryData.job_case_id = $item.CAPTION.trim();
                $scope.openAddCasePopup();
            }
        };

        $scope.currencyFormat = function(data, type, meta, meta){
            if(parseFloat(data) >= 0){
                return '$'+parseFloat(data).toFixed(2);
            }else if(parseFloat(data) < 0){
                return '-$'+ parseFloat(Math.abs(data)).toFixed(2);
            }else{
                return '$'+ '0.00';
            }
        };

        $scope.formatLabel = function (firm) {
            if(firm)
            {
                var label = firm.NAME +' | ('+ firm.PHONE +') | '+ firm.ADDR_LINE1 +' '+ firm.ADDR_LINE2 +' | '+ firm.CITY +' | '+ firm.source.name +' '+ firm.source.name_2 +' | YTD BILLED ('+ $scope.currencyFormat(firm.YTD_BILLED) + ')';

                if(firm.businessunit)
                {
                    label = label + ' | '+firm.businessunit.name;
                }

                return label;
            }
        };


        $scope.formatItem = function (jobcase) {
            if(jobcase){
                return jobcase.CAPTION;
            }
        };
        
        $scope.clearOrdFirm = function () {
            if (!$scope.isSelectOrdFirm) {
                $scope.diaryData.attorney_id = null;
            }
        };
        
        $scope.resetOrdFirm = function () {
            if ($scope.isSelectOrdFirm) {
                $scope.diaryData.attorney_id = null;
                $scope.isSelectOrdFirm = false;
                $scope.diaryData.firm_HELD_AT2 = '';
                $scope.diaryData.firm_HELD_AT3 = '';
                $scope.diaryData.firm_HELD_AT_CITY = '';
                $scope.diaryData.firm_HELD_AT_STATE = '';
                $scope.diaryData.firm_HELD_AT_ZIPCODE = '';
                $scope.diaryData.firm_HA_PHONE = '';
                $scope.diaryData.firm_CON_PHONE = '';
                $scope.diaryData.firm_LOCATION = '';
                $scope.diaryData.firm_CONTACT = '';
                $scope.diaryData.NOTES_1 = '';
                $scope.diaryData.NOTES_2 = '';
                $scope.diaryData.sort1 = '';
                $scope.diaryData.sort2 = '';
                $scope.diaryData.source_id = '';
                $scope.diaryData.LevelOfPayment = ''
                $scope.onselectdisable = false;
                $scope.diaryData.send_job_confirmation_on = [];
                $scope.firm_status = '';
                $scope.firm_beeper = '';
                $scope.ebt = [];
                $scope.diaryData.ebt_contact_id = null;
                $scope.job_called_in_by_list = [];
                $scope.attornay_list = [];
                $scope.diaryData.job_called_in_by = null;
                $scope.diaryData.job_attorney = null;
                $scope.diaryData.reporting_sales_ids = [];
                $scope.diaryData.records_sales_ids = [];
                $scope.salesman_commission_type = '';
                $scope.diaryData.business_unit_id = null;
                $scope.diaryData.held_at_flag = null;
                $scope.insuranceRates = [];
            }
        };


        $scope.getInsuranceRateTable = function (attorney_id) {
            commonFactory.get('/api/rates')
                .success(function (response) {
                    if (response.result && response.result.rates) {
                        $scope.insuranceRates = response.result.rates;
                    }
                })
                .error(function (err) {
                });
        }
        $scope.getInsuranceRateTable();

        $scope.getRateTable = function (attorney_id) {
            let params = {};
            params.attorneyId = attorney_id;

            commonFactory.getWithParams('/api/getFirmRateTable', params)
                .success(function (response) {
                    if (response.result && response.result.rates) {
                        $scope.insuranceRates = response.result.rates;
                    }
                })
                .error(function (err) {
                });
        }

        //After selecting Firm assign value to this field.
        $scope.onSelect = function ($item, $model, $label, tab) {            
            $scope.isSelectOrdFirm = true;
            if (!$scope.foundFromElaw) {
                $rootScope.showLoader(true);
                //$scope.diaryData.HELD_AT1 = $item.NAME || '';
                $scope.orderingFirm = $item;
                $scope.diaryData.firm_HELD_AT2 = $item.ADDR_LINE1 || '';
                $scope.diaryData.firm_HELD_AT3 = $item.ADDR_LINE2 || '';
                $scope.diaryData.firm_HELD_AT_CITY = $item.CITY || '';
                $scope.diaryData.firm_HELD_AT_STATE = $item.STATE || '';
                $scope.diaryData.firm_HELD_AT_ZIPCODE = $item.ZIP || '';
                $scope.diaryData.firm_HA_PHONE = $item.PHONE || '';
                $scope.diaryData.firm_CON_PHONE = $item.PHONE || '';
                $scope.diaryData.firm_LOCATION = $item.LOCATION || '';
                $scope.diaryData.firm_CONTACT = $item.CONTACT || '';
                $scope.diaryData.NOTES_1 = $item.NOTES_1 || '';
                $scope.diaryData.NOTES_2 = $item.NOTES_2 || '';
                $scope.diaryData.sort1 = $item.Sort1 || '';
                $scope.diaryData.sort2 = $item.Sort2 || '';
                $scope.diaryData.source_id = $item.source_id || '';
                $scope.diaryData.LevelOfPayment = $item.LevelOfPayment;
                $scope.diaryData.turn_around_info = $item.turn_around_days;
                $scope.generalData.turn_around_info = $item.turn_around_days;
                // recalculate due date
            job_date = moment($scope.diaryData.DATE_TAKEN).format('MM/DD/YYYY');            
            $scope.diaryData.DATE_TAKEN=job_date;
            $scope.generalData.DUE_DATE = moment(job_date, 'MM/DD/YYYY').businessAdd($scope.generalData.turn_around_info)._d;
            $scope.diaryData.DUE_DATE=$scope.generalData.DUE_DATE;
            $scope.generalData.DUE_DATE=$scope.generalData.DUE_DATE;



                $scope.firm_status = $item.STATUS;
                angular.forEach($scope.auto_partners,function(value, key){
                    if(value.code == $item.BEEPER){                
                        $scope.firm_beeper = value.text;
                    }
                });
                $scope.salesman_commission_type = $item.salesman_commission_type;
                $scope.onselectdisable = true; //Onselect Ordering Firm disable fields.
                $scope.diaryData.business_unit_id = $item.businessunit.id || '';
                $scope.diaryData.held_at_flag = $item.held_at_flag;
                    // check firm comission type
                    if($item.reporting_salesman.length){
                        $scope.reportingSalesmanIds = [];
                        angular.forEach($item.reporting_salesman,function(value, key){
                            $scope.reportingSalesmanIds[key] = value.sales_reptyp_id;
                        });
                        if($item.salesman_commission_type!='CIP'){
                         $scope.diaryData.reporting_sales_ids = $scope.reportingSalesmanIds;
                        }
                    }

                if($item.records_salesman.length){
                    var recordsSalesmanIds = [];
                    angular.forEach($item.records_salesman,function(value, key){
                        recordsSalesmanIds[key] = value.sales_reptyp_id;
                    });
                    $scope.diaryData.records_sales_ids = recordsSalesmanIds;
                }

                // $scope.getRateTable($item.id);

                $http.post(apiUrl+'/api/get-job-called-in-by-list', { attorney_id : $item.id }).success(function(response){
                    $scope.job_called_in_by_list = response.result.data;
                    if($scope.job_called_in_by_list){
                        var i = 0;
                        var emails = [];
                        // If Account Executive email exist in auth user table with active and job confirmation = 'Y' flag.
                        // CHANGE AS PER FRANK Account Executive GET EVERY TIME EMAIL FROM BACKEND
                        if($item.salesman_commission_type!='CIP'){
                        if($scope.job_called_in_by_list.sales_auth_user){ 
                            angular.forEach($scope.job_called_in_by_list.sales_auth_user, function(value, key){
                                if(value.email){
                                    emails[i] = value.email;
                                    i++;
                                }
                            });
                        }
                    }
                        // If send job confirmation type selected to multiple then and then only read attorney(employee of firm) emails.And those should have entry in auth_user table and also have active ='Y' and send_job_confirmation = 'Y'.
                        if($item.send_job_confirmation_to == 'multiple' &&  $scope.job_called_in_by_list.dep_attorney && $scope.job_called_in_by_list.dep_attorney.length > 0){
                            angular.forEach($scope.job_called_in_by_list.dep_attorney,function(value, key){
                                if(value.email){
                                    emails[i] = value.email;
                                    i++;
                                }
                            });
                        }
                        if(emails.length){
                            const unique = (array) => [...new Set(array)];
                            $scope.diaryData.send_job_confirmation_on =  unique(emails);
                            // $scope.diaryData.send_job_confirmation_on =  emails;
                        }
                    }
                });

                $http.post(apiUrl+'/api/get-attorney-list', { attorney_id : $item.id }).success(function(response){
                    $scope.attornay_list = response.result.data;
                    if ($scope.attornay_list.dep_attorney) {
                        $scope.attornay_list.dep_attorney.push({attorney_no: -1, name: "To Be Determined"});
                    }
                });

                var data = {};
                data.firm_id = $item.id;

                $timeout(function () {
                    commonFactory.post('/api/attorneybyfirm', data)
                        .success(function (response) {
                            if (response) {
                                if (response && response.result && response.result.attorney_data) {
                                    $scope.attorney = response.result.attorney_data || '';
                                }
                            }
                        })
                        .error(function (err) {
                            $rootScope.showLoader(false);
                        });
                }, 1000);

                $timeout(function () {
                    commonFactory.post('/api/ebtbyfirm', data)
                        .success(function (response) {
                            if (response) {
                                if (response && response.result && response.result.ebt_data) {
                                    $scope.ebt = response.result.ebt_data || '';
                                }
                            }
                        })
                        .error(function (err) {
                            $rootScope.showLoader(false);
                        });
                }, 1000);
                $rootScope.showLoader(false);
            } else {
                swal({
                    title: "Are you sure?",
                    text: "You are about to add new firm!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    closeOnConfirm: true
                },
                    function (isConfirm) {
                        // $state.go('firms.add.step_one_add', { elawId : $item.id});
                        if (isConfirm) {
                            $scope.diaryData.attorney_id = null;
                            $scope.$apply();
                            var url = $state.href('firms.add.step_one_add', { elawId: $item.id });
                            window.open(url, '_blank');
                        }
                        else {
                            $scope.diaryData.attorney_id = null;
                            $scope.$apply();
                        }
                    });
            }

        };

        $scope.exportDiaryAuditLogs = function(){
            $http.post(apiUrl + '/api/get-diary-audit-logs',{JOB_NO : $state.params.id, downloadExcel : true}).success(function (response) {
                $window.open(apiUrl +'/api/download-job-audit-logs-excel', '_blank'); 
            });
        }
        
        $scope.entityReportingSalesmanIds=[];
        $scope.onSelectChangeSalesperson = function(){
            
            var indexNo = 0
            var isSalesman = false
            angular.forEach($scope.job_called_in_by_list.dep_attorney,function(value, key){              
               
                if($scope.salesman_commission_type == 'CIP' && value.commission_salesman_id && value.attorney_no == $scope.diaryData.job_called_in_by){
                   $scope.diaryData.reporting_sales_ids = [];
                   isSalesman = true
                    $scope.diaryData.reporting_sales_ids.push(value.commission_salesman_id);
                }
                indexNo++
              
                if($scope.salesman_commission_type == 'CIP' && isSalesman == false && indexNo == $scope.job_called_in_by_list.dep_attorney.length ){
               
                   $scope.diaryData.reporting_sales_ids = $scope.reportingSalesmanIds
                }
           });

           $scope.entityReportingSalesmanIds = [];
            $scope.insuranceRates.filter(function(item){  
                if($scope.diaryData.insurance_rate_id == item.InsuranceRateID ) {
                    if(item.account_executives && item.account_executives.length > 0){
                        angular.forEach(item.account_executives,function(value, key){
                            $scope.entityReportingSalesmanIds.push(value.salesperson_id);
                        });
                    }
                    if(item.allow_rep=='1' && $scope.entityReportingSalesmanIds.length !=0){
                        $scope.diaryData.reporting_sales_ids =  $scope.entityReportingSalesmanIds;
                    }
                    else{
                        var old = angular.copy($scope.diaryData.reporting_sales_ids);
                        angular.forEach($scope.entityReportingSalesmanIds,function(value, key){
                            old.push(value);
                        });
                        $scope.diaryData.reporting_sales_ids = old;
                    }
                    
                }
            });

            $scope.onSelectSalesperson();
        }

        $scope.onSelectSalesperson = function ($item, $model, $label) {
            
            //First removing all reporting salesperson email address.
            $scope.salesPersonsList.filter(function(item){
                 $scope.diaryData.send_job_confirmation_on = $scope.diaryData.send_job_confirmation_on.filter(function(conf){
                    return conf.text != item.Emai_Address;
                });
            });

            //Inserting reporting salesperson email address.
            $scope.salesPersonsList.filter(function(item){
                var IDs = $scope.diaryData.reporting_sales_ids;
                if(IDs.includes(item.id) && item.auth_user && item.Emai_Address){
                    var is_email_exists = false; 
                    $scope.diaryData.send_job_confirmation_on.filter(function(conf){
                        if(conf.text == item.Emai_Address){
                            is_email_exists = true;
                        }
                    });
                    if(!is_email_exists){
                        $scope.diaryData.send_job_confirmation_on.push({text: item.Emai_Address});
                    }
                }
            });            
        };
 
        //In ACTION WHERN SELECT DIFFERENT LOCATION POPULATE THIS FIELD.
        $scope.onSelectHeldat = function ($item, $model, $label) {
            $scope.diaryData.HELD_AT2 = $item.ADDR_LINE1 || '';
            $scope.diaryData.HELD_AT3 = $item.ADDR_LINE2 || '';
            $scope.diaryData.HELD_AT_CITY = $item.CITY || '';
            $scope.diaryData.HELD_AT_STATE = $item.STATE || '';
            $scope.diaryData.HELD_AT_ZIPCODE = $item.ZIP || '';
            $scope.diaryData.HA_PHONE = $item.PHONE || '';
            $scope.diaryData.CON_PHONE = $item.PHONE || '';
            $scope.diaryData.CONTACT = $item.CONTACT || '';
        };

        //Select Action Dropdown for Auto populate Location Fields.
        $scope.changedLocationAction = function () {
            $scope.preventToInput = true;
            var newValue = $scope.diaryData.location_action;
            if (newValue && newValue != undefined) {
                $timeout(function () {

                    if (newValue !== 'our_suites') {
                        $scope.diaryData.ToBeHeldAt = "";
                    }

                    if (newValue === 'attorneyfirm' && ($scope.diaryData.attorney_id == undefined || $scope.diaryData.otherfirmlocation === "")) {
                        Notification.error("Please select scheduling firm.");

                        $scope.diaryData.location_action = '';
                        clearActionfield();
                    }
                    if (newValue === 'heldatfirm' && ($scope.diaryData.otherfirmlocation == undefined || $scope.diaryData.otherfirmlocation === "")) {
                        Notification.error("Please select otherside firm.");

                        $scope.diaryData.location_action = '';
                        clearActionfield();

                    }
                    if (newValue === 'our_suites' && ($scope.diaryData.ToBeHeldAt == undefined || $scope.diaryData.ToBeHeldAt === "")) {
                        Notification.error("Please select our suites.");

                        $scope.diaryData.location_action = '';
                        clearActionfield();
                    }
                }, 3000);
                if (newValue === 'attorneyfirm') {
                    $rootScope.showLoader(true);
                    if ($scope.diaryData && $scope.diaryData.attorney_id) {
                        $timeout(function () {
                            var data = { attorney_id: $scope.diaryData.attorney_id }
                            commonFactory.post('/api/getAttorneyfirmdata', data)
                                .success(function (response) {
                                    $scope.diaryData.attorney_id = response.result.attorney_data || '';

                                    $scope.diaryData.HELD_AT1 = response.result.attorney_data.NAME || '';
                                    $scope.diaryData.HELD_AT2 = response.result.attorney_data.ADDR_LINE1 || '';
                                    $scope.diaryData.HELD_AT3 = response.result.attorney_data.ADDR_LINE2 || '';
                                    $scope.diaryData.HELD_AT_CITY = response.result.attorney_data.CITY || '';
                                    $scope.diaryData.HELD_AT_STATE = response.result.attorney_data.STATE || '';
                                    $scope.diaryData.HELD_AT_ZIPCODE = response.result.attorney_data.ZIP || '';
                                    $scope.diaryData.HA_PHONE = response.result.attorney_data.PHONE || '';
                                    $scope.diaryData.CON_PHONE = response.result.attorney_data.PHONE || '';
                                    $scope.diaryData.LOCATION = response.result.attorney_data.LOCATION || '';
                                    $scope.diaryData.CONTACT = response.result.attorney_data.CONTACT || '';
                                    $scope.diaryData.claim_number_mask = response.result.attorney_data.claim_number_mask || '';
                                })
                                .error(function (err) {
                                    $rootScope.showLoader(false);
                                });
                        }, 100);
                    }
                    $rootScope.showLoader(false);
                }
                else if (newValue === 'heldatfirm') {
                    $rootScope.showLoader(true);
                    if ($scope.diaryData && $scope.diaryData.otherfirmlocation) {
                        $timeout(function () {
                            var data = { attorney_id: $scope.diaryData.otherfirmlocation }
                            commonFactory.post('/api/getAttorneyfirmdata', data)
                                .success(function (response) {
                                    $scope.diaryData.otherfirmlocation = response.result.attorney_data || '';

                                    $scope.diaryData.HELD_AT1 = response.result.attorney_data.NAME || '';
                                    $scope.diaryData.HELD_AT2 = response.result.attorney_data.ADDR_LINE1 || '';
                                    $scope.diaryData.HELD_AT3 = response.result.attorney_data.ADDR_LINE2 || '';
                                    $scope.diaryData.HELD_AT_CITY = response.result.attorney_data.CITY || '';
                                    $scope.diaryData.HELD_AT_STATE = response.result.attorney_data.STATE || '';
                                    $scope.diaryData.HELD_AT_ZIPCODE = response.result.attorney_data.ZIP || '';
                                    $scope.diaryData.HA_PHONE = response.result.attorney_data.PHONE || '';
                                    $scope.diaryData.CON_PHONE = response.result.attorney_data.PHONE || '';
                                    $scope.diaryData.LOCATION = response.result.attorney_data.LOCATION || '';
                                    $scope.diaryData.CONTACT = response.result.attorney_data.CONTACT || '';
                                    $scope.diaryData.claim_number_mask = response.result.attorney_data.claim_number_mask || '';
                                })
                                .error(function (err) {
                                    $rootScope.showLoader(false);
                                });
                        }, 100);
                    }
                    $rootScope.showLoader(false);
                }
                else if (newValue === 'our_suites') {
                    $rootScope.showLoader(true);
                    if ($scope.diaryData && $scope.diaryData.ToBeHeldAt) {
                        var id = $scope.diaryData.ToBeHeldAt;
                        commonFactory.get('/api/suites/' + id + '/edit')
                            .success(function (response) {
                                $rootScope.showLoader(false);
                                if (response.status == 200 && response.result && response.result.our_suites) {
                                    $scope.diaryData.HELD_AT1 = response.result.our_suites.held_at || '';
                                    $scope.diaryData.HELD_AT_CITY = response.result.our_suites.city || '';
                                    $scope.diaryData.HELD_AT_STATE = response.result.our_suites.state || '';
                                    $scope.diaryData.HELD_AT_ZIPCODE = response.result.our_suites.zipcode || '';
                                    $scope.diaryData.HA_PHONE = response.result.our_suites.phone_number || '';
                                    $scope.diaryData.HELD_AT2 = response.result.our_suites.address1 || '';
                                    $scope.diaryData.HELD_AT3 = response.result.our_suites.address2 || '';
                                    $scope.diaryData.CON_PHONE = '';
                                    $scope.diaryData.LOCATION = '';
                                    $scope.diaryData.CONTACT = '';
                                }
                            })
                            .error(function (err) {
                                $rootScope.showLoader(false);
                            });
                    }
                    $rootScope.showLoader(false);
                }
                else {

                    if (!$scope.stillLoadingInEditModeAction) {

                        $scope.diaryData.ToBeHeldAt = parseInt($scope.diaryOriginalData.ToBeHeldAt) || '';
                        $scope.diaryData.location_id = parseInt($scope.diaryOriginalData.location_id) || '';
                        $scope.diaryData.HELD_AT1 = $scope.diaryOriginalData.HELD_AT1 || '';
                        $scope.diaryData.HELD_AT2 = $scope.diaryOriginalData.HELD_AT2 || '';
                        $scope.diaryData.HELD_AT3 = $scope.diaryOriginalData.HELD_AT3 || '';
                        $scope.diaryData.HELD_AT_CITY = $scope.diaryOriginalData.HELD_AT_CITY || '';
                        $scope.diaryData.HELD_AT_STATE = $scope.diaryOriginalData.HELD_AT_STATE || '';
                        $scope.diaryData.HELD_AT_ZIPCODE = $scope.diaryOriginalData.HELD_AT_ZIPCODE || '';
                        $scope.diaryData.HA_PHONE = $scope.diaryOriginalData.HA_PHONE || '';
                        $scope.diaryData.CONTACT = $scope.diaryOriginalData.CONTACT || '';
                        $scope.diaryData.CON_PHONE = $scope.diaryOriginalData.CON_PHONE || '';
                        $scope.diaryData.LOCATION = '';
                    }

                    if (newValue === 'other') {
                        $scope.preventToInput = false;
                    }
                }
            }
        };

        //When select Held at our suites ACTION will automatically populate and data select.
        $scope.$watch('diaryData.ToBeHeldAt', function (newValue, oldValue) {
            if (newValue && newValue != 'undefined') {
                $rootScope.showLoader(true);
                $scope.diaryData.location_action = "our_suites";
                //if () {
                var id = newValue;
                commonFactory.get('/api/suites/' + id + '/edit')
                    .success(function (response) {
                        $rootScope.showLoader(false);
                        if (response.status == 200 && response.result && response.result.our_suites) {
                            $scope.diaryData.HELD_AT1 = response.result.our_suites.held_at || '';
                            $scope.diaryData.HELD_AT_CITY = response.result.our_suites.city || '';
                            $scope.diaryData.HELD_AT_STATE = response.result.our_suites.state || '';
                            $scope.diaryData.HELD_AT_ZIPCODE = response.result.our_suites.zipcode || '';
                            $scope.diaryData.HA_PHONE = response.result.our_suites.phone_number || '';
                            $scope.diaryData.HELD_AT2 = response.result.our_suites.address1 || '';
                            $scope.diaryData.HELD_AT3 = response.result.our_suites.address2 || '';
                            $scope.diaryData.CON_PHONE = '';
                            $scope.diaryData.LOCATION = '';
                            $scope.diaryData.CONTACT = '';
                            
                            // $scope.diaryData.held_at_flag = response.result.our_suites.held_at_flag || '';
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
                //}
                $rootScope.showLoader(false);
            }
        });

        $scope.notifyDiaryUpdates = function(JOB_NO){
            var attendeeNotifyModal = $uibModal.open({
                templateUrl: "modules/diary/caselist/attendeeNotify.html",
                controller: 'attendeeNotifyController',
                resolve: {
                    JOB_NO: function () {
                        return JOB_NO;
                    }
                },
                keyboard: false,
                backdrop: false,
                close: function () {

                },
                 size: 'lg'
            });

            attendeeNotifyModal.result.then(function(response) {
                //get refreshed audit logs for diary
                $scope.getDiaryAuditLogs();
                if(response.send_job_confirmation_on){
                    emails = response.send_job_confirmation_on.replace(/;/g,',').split(',');
                    if(emails.length){
                        $scope.diaryData.send_job_confirmation_on = emails;
                    }
                }
                if(response.result.resource_assignment_status){
                    angular.forEach(response.result.resource_assignment_status,function(value, key){
                        const index =   $scope.diaryData.job_resources.findIndex(o =>
                            o.id == value.job_resource_id
                        );
                        console.log(index);
                        if(index!= -1){
                            $scope.diaryData.job_resources[index].service_request.assignment_status_id = value.assignment_status_id;
                        }
                    });
                }
            })
        };

        //Action fields needs to clear when other action is choose.
        function clearActionfield() {
            $scope.diaryData.HELD_AT1 = '';
            $scope.diaryData.HELD_AT2 = '';
            $scope.diaryData.HELD_AT3 = '';
            $scope.diaryData.HELD_AT_CITY = '';
            $scope.diaryData.HELD_AT_STATE = '';
            $scope.diaryData.HELD_AT_ZIPCODE = '';
            $scope.diaryData.HA_PHONE = '';
            $scope.diaryData.CON_PHONE = '';
            $scope.diaryData.LOCATION = '';
            $scope.diaryData.CONTACT = '';
        }
        $scope.zoomGenrate = function(isValid){
            $scope.isZoomLink = true;
            $scope.saveDiarydata(isValid)
        };
        //Save data into CASE_tbl and DIARY_tbl "ATTORNEY FIRM INFORMATION" TAB.
        $scope.saveDiarydata = function (isValid, redirect_to_payment, moveToGeneralInformation, redirectToNewCase, saveAsWaitList, isNotify) {

         
            //$scope.formSubmit = true;
            if (isValid) {
                // check production queue and covering bu validation
                if($scope.diaryData.production_queue=='CBU' && $scope.diaryData.business_unit_id == $scope.diaryData.covering_business_unit_id){
                    Notification.error('Please Select Covering Businessunit Other Then Businessunit');
                    return false;
                }
                if($scope.diaryData.production_queue=='BU' && $scope.diaryData.business_unit_id != $scope.diaryData.covering_business_unit_id){
                    Notification.error('Please Select Covering Businessunit Same As Businessunit');
                    return false;
                }
                //Add case to The Diary
                // $rootScope.showLoader(true);
                var diaryData = angular.fromJson(angular.toJson($scope.diaryData));
                if (diaryData.IndexNumber) {
                    if (typeof diaryData.IndexNumber !== 'string') {
                        diaryData.IndexNumber = diaryData.IndexNumber.IndexNumber;
                    }
                }
                diaryData.DATE_TAKEN = moment(diaryData.DATE_TAKEN).format('MM/DD/YYYY');                
                diaryData.DUE_DATE = moment(diaryData.DATE_TAKEN, 'MM/DD/YYYY').businessAdd($scope.generalData.turn_around_info)._d;
                diaryData.DUE_DATE = moment(diaryData.DUE_DATE).format('MM/DD/YYYY');                
                diaryData.dte_taken = moment(diaryData.DATE_TAKEN).format('YYYY-MM-DD');
                console.log($scope.isZoomLink,"data")
                if($scope.isZoomLink){
                    diaryData.CreateZoomLink = true;
    
                }
                // console.log(moment(diaryData.TIME_TAKEN).format('hh:mm A'));
                diaryData.TIME_TAKEN = moment(diaryData.TIME_TAKEN).format('hh:mm A');
                
                if (diaryData.End_Time === "Invalid date" || !diaryData.End_Time) {
                    diaryData.End_Time = null;
                    diaryData.JOB_LENGTH = null;
                }else{
                    diaryData.End_Time = moment(diaryData.End_Time).format('hh:mm A');
                    diaryData.JOB_LENGTH = diaryData.JOB_LENGTH;
                }
                
                // return;
                diaryData.attorney_id = diaryData.attorney_id ? diaryData.attorney_id.id : null;
                diaryData.job_case_id = diaryData.job_case_id ? diaryData.job_case_id.id : null;
                diaryData.ATTORNEY = $scope.diaryData.attorney_id ? $scope.diaryData.attorney_id.NAME_KEY : '';
                diaryData.otherfirmlocation = diaryData.otherfirmlocation ? diaryData.otherfirmlocation.id : null;
                diaryData.attname_id = diaryData.attname_id ? diaryData.attname_id : null;
                diaryData.location_id = diaryData.location_id ? diaryData.location_id : null;
                diaryData.location_action = diaryData.location_action ? diaryData.location_action : "sync_data"; //defaut action is other
                
                // Set the Claim Note here also, As Mark suggested that claim note need to all steps. So on every page it needs to save as well.
                diaryData.CLM_NOTE = $scope.generalData.CLM_NOTE;
                diaryData.productionnote = $scope.generalData.productionnote;
                diaryData.direct_billing_notes = $scope.generalData.direct_billing_notes;
                diaryData.special_instructions = $scope.generalData.special_instructions || '';

                if (diaryData.location_action && diaryData.location_action === "diff_location") {
                    diaryData.HELD_AT1 = diaryData.HELD_AT1 ? diaryData.HELD_AT1.NAME : null;
                }

                var i = 0;
                angular.forEach($scope.diaryData.send_job_confirmation_on,function(value, key){
                    diaryData.send_job_confirmation_on[i] = typeof(value.text) != 'undefined' ? value.text : value;
                    i++;
                });

                
                diaryData.translator_id = diaryData.translator_id ? diaryData.translator_id : null;
                diaryData.translator_time = diaryData.translator_time ? diaryData.translator_time : null;

                if (diaryData.translator_time) {
                    diaryData.translator_time = moment(diaryData.translator_time).format('hh:mm A');
                }

                var job_id = JOB_NO ? JOB_NO : $state.params.id;
                if (($scope.isItRescheduled && (!redirect_to_payment && !moveToGeneralInformation && !redirectToNewCase && !saveAsWaitList))) {
                    swal({
                            title: "Are you sure?",
                            text: "Is job being rescheduled?",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#DD6B55",
                            confirmButtonText: "Yes",
                            cancelButtonText: "No",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        function (isConfirm) {
                            if (isConfirm) {
                                diaryData.isJobRescheduled = $scope.isItRescheduled;
                            }
                            $scope.putDiaryRequest(job_id, diaryData, redirect_to_payment, moveToGeneralInformation, redirectToNewCase, saveAsWaitList, isNotify);
                        });
                } else {
                    $scope.putDiaryRequest(job_id, diaryData, redirect_to_payment, moveToGeneralInformation, redirectToNewCase, saveAsWaitList, isNotify);
                }

            } else {
                $scope.formSubmit =true
                swal('Cancelled', 'Please enter all required fields.', 'error');
            }
        };

        $scope.putDiaryRequest = function(job_id, diaryData, redirect_to_payment, moveToGeneralInformation, redirectToNewCase, saveAsWaitList, isNotify){
            $rootScope.showLoader(true);
            commonFactory.put('/api/diary/' + job_id, diaryData)
                .success(function (response) {
                    if (response && response.result && response.result.diary_update) {
                        //get refreshed audit logs for diary
                        $scope.getDiaryAuditLogs();
                        $scope.fetchData();
                        $scope.getMeetingAttendees();
                        $rootScope.showLoader(false);
                        $scope.isItRescheduled = false;
                        Notification.success(response.result.message);
                        $scope.generalData.DATE_TAKEN = response.result.diary_update.DATE_TAKEN;
                        $scope.diaryData.reschedule_cnt = response.result.diary_update.reschedule_cnt || 0;  

                        if (response.result.diary_update.TIME_TAKEN) {
                            $scope.generalData.TIME_TAKEN = moment(response.result.diary_update.TIME_TAKEN, 'hh:mm A');
                        }
                        if(response.result.diary_update.End_Time){
                            $scope.diaryData.End_Time = moment(response.result.diary_update.End_Time,'hh:mm A');
                        }else{
                            $scope.diaryData.End_Time=null;
                        }

                        $scope.postEntryData.disposition = response.result.diary_update.disposition;
                        $scope.postEntryData.disposed_date = response.result.diary_update.disposed_date;

                        if (redirectToNewCase == true) {
                            $state.go('diary.addcase.step_one');
                        }
                        else if (redirect_to_payment) {
                            if($scope.diaryData.job_resources.length==1){
                                $state.go('payroll.edit', { id: $scope.diaryData.job_resources[0].id });
                            }
                            else{
                                $state.go('payroll.resourceslist', { job_no: $state.params.id });
                            }
                        } else {
                            if (JOB_NO) {
                                $scope.activeTab = "tab-2";
                            }
                            if ($scope.goToHome == true) {
                                $state.go('index.home');
                            } else if (moveToGeneralInformation) {
                                $state.go("diary.editcase.step_two", { id: $state.params ? $state.params.id : '' }); // Here state params is JOB id
                            }
                        }
                        if(isNotify){
                            $scope.notifyDiaryUpdates(job_id);
                        }

                    } else {
                        Notification.error(response.result.message);
                        $rootScope.showLoader(false);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                    console.log(err);
                });
        }
        
        $scope.tmp = {};
        //Save data into CASE_tbl and DIARY_tbl "GENERAL INFORMATION" TAB.
        $scope.saveGeneraldata = function (isValid, redirect_to_payment, moveToOtherInformation, redirectToNewCase, isNotify) {       
            if (isValid) {
                var generalData = angular.fromJson(angular.toJson($scope.generalData));
                //Add case to The Diary
                $rootScope.showLoader(true);
                generalData.DATE_TAKEN = moment(generalData.DATE_TAKEN).format('MM/DD/YYYY');
                generalData.DUE_DATE = moment(generalData.DATE_TAKEN, 'MM/DD/YYYY').businessAdd($scope.generalData.turn_around_info)._d;
                generalData.DUE_DATE = moment(generalData.DUE_DATE).format('MM/DD/YYYY');
                generalData.TIME_TAKEN = moment(generalData.TIME_TAKEN).format('hh:mm A');
                if (generalData.LOSS_DT === "Invalid date" || !generalData.LOSS_DT) {
                    generalData.LOSS_DT = null;
                }
                else {
                    generalData.LOSS_DT = moment(generalData.LOSS_DT).format('MM/DD/YYYY');
                }
                generalData.claim_representative_id = generalData.claim_representative_id ? generalData.claim_representative_id : null;

               
                generalData.location_id = $scope.diaryData.location_id ? $scope.diaryData.location_id : null;
                generalData.NOTES2 = $scope.diaryData.NOTES2;
                generalData.NOTES3 = $scope.diaryData.NOTES3;
                generalData.firm_specific_reporter_note = $scope.diaryData.firm_specific_reporter_note;

                if (!$scope.generalData.CLM_REP_NAME) {
                    generalData.CLM_REP_NAME = 'NULL';
                }
                
                
                
                if ($scope.diaryData.End_Time === "Invalid date" || !$scope.diaryData.End_Time) {
                    generalData.End_Time = null;
                    generalData.JOB_LENGTH = null;
                }else{
                    generalData.End_Time = moment($scope.diaryData.End_Time).format("hh:mm A");
                    generalData.JOB_LENGTH = $scope.diaryData.JOB_LENGTH;
                }

               
                var job_id = JOB_NO ? JOB_NO : $state.params.id;
                var JobData = {};
                JobData.DATE_TAKEN = moment(generalData.DATE_TAKEN).format('MM/DD/YYYY');
                commonFactory.put('/api/diary/' + job_id, generalData)
                    .success(function (response) {
                        if (response && response.result && response.result.diary_update) {
                            commonFactory.put('/api/updateJob/' + job_id, JobData)
                                .success(function (resonse) {
                                    $rootScope.showLoader(false);
                                    Notification.success(response.result.message);
                                    //get refreshed audit logs for diary
                                    $scope.getDiaryAuditLogs();

                                    $scope.diaryData.TIME_TAKEN = $scope.generalData.TIME_TAKEN;
                                    $scope.diaryData.DATE_TAKEN = $scope.generalData.DATE_TAKEN;


                                    if (redirectToNewCase) {
                                        $state.go('diary.addcase.step_one');
                                    }
                                    else if (moveToOtherInformation) {
                                        $state.go("diary.editcase.step_three", { id: $state.params ? $state.params.id : '' }); // Here state params is JOB id
                                    }
                                    else if (redirect_to_payment) {
                                        if($scope.diaryData.job_resources.length==1){
                                            $state.go('payroll.edit', { id: $scope.diaryData.job_resources[0].id });
                                        }
                                        else{
                                            $state.go('payroll.resourceslist', { job_no: $state.params.id });
                                        }
                                    } else {

                                        if ($scope.goToHome == true) {
                                            $state.go('index.home');
                                        } else if (JOB_NO) {
                                            $scope.activeTab = "tab-3";
                                        }
                                    }
                                })
                                .error(function (err) {
                                    $rootScope.showLoader(false);
                                });

                            if(isNotify){
                                $scope.notifyDiaryUpdates(job_id);
                            }
                        } else {
                            $rootScope.showLoader(false);
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                        console.log(err);
                    });
            } else {
                if($scope.diaryForm.feature_number.$error.pattern){
                    swal('Error!', 'Feature Number Should Be 3 Digits Only.', 'error');
                }else{
                    swal('Cancelled', 'Please enter all required fields.', 'error');
                }
            }
        };

        /**
         *function updateBillingNotesOnly
         * @param
         * @description This function used to update the billings notes only, because its available in witness and post entry tabs
         */
        $scope.updateBillingNotesOnly = function (redirect_to_payment, isNotify) {

            var job_id = JOB_NO ? JOB_NO : $state.params.id;
            commonFactory.put('/api/diary/' + job_id, { CLM_NOTE: $scope.generalData.CLM_NOTE })
                .success(function (response) {
                    if (response && response.result && response.result.diary_update) {
                        $rootScope.showLoader(false);
                        Notification.success("Billing note updated successfully");
                        //get refreshed audit logs for diary
                        $scope.getDiaryAuditLogs();

                        if ($scope.goToHome == true) {
                            $state.go('index.home');
                        } else if (redirect_to_payment) {
                            if($scope.diaryData.job_resources.length==1){
                                $state.go('payroll.edit', { id: $scope.diaryData.job_resources[0].id });
                            }
                            else{
                                $state.go('payroll.resourceslist', { job_no: $state.params.id });
                            }
                        }
                        if(isNotify){
                            $scope.notifyDiaryUpdates(job_id);
                        }
                    } else {
                        $rootScope.showLoader(false);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                    console.log(err);
                });
        };

        //Save data into CASE_tbl and DIARY_tbl "OTHER INFORMATION" TAB.mm
        $scope.saveOtherdata = function (isValid, redirect_to_payment, moveTowitnessInformation, redirectToNewCase, isNotify) {
            if (isValid) {
                //Add case to The Diary
                $rootScope.showLoader(true);
                $scope.otherData.LOSS_DT = moment($scope.generalData.LOSS_DT).format('MM/DD/YYYY');

                // Set the Claim Note here also, As Mark suggested that claim note need to all steps. So on every page it needs to save as well.
                $scope.otherData.CLM_NOTE = $scope.generalData.CLM_NOTE;

                $scope.otherData.di_job_summary = $scope.generalData.di_job_summary;                
                var job_id = JOB_NO ? JOB_NO : $state.params.id;
                commonFactory.put('/api/diary/' + job_id, $scope.otherData)
                    .success(function (response) {
                        if (response && response.result && response.result.diary_update) {
                            $rootScope.showLoader(false);
                            Notification.success(response.result.message);
                            //get refreshed audit logs for diary
                            $scope.getDiaryAuditLogs();

                            if (redirectToNewCase) {
                                $state.go('diary.addcase.step_one');
                            }
                            else if (moveTowitnessInformation) {
                                $state.go("diary.editcase.step_five", { id: $state.params ? $state.params.id : '' }); // Here state params is JOB id
                            }
                            else if ($scope.goToHome == true) {
                                $state.go('index.home');
                            } else if (redirect_to_payment) {
                                if($scope.diaryData.job_resources.length==1){
                                    $state.go('payroll.edit', { id: $scope.diaryData.job_resources[0].id });
                                }
                                else{
                                    $state.go('payroll.resourceslist', { job_no: $state.params.id });
                                }
                            } else {

                                if ($scope.goToHome == true) {
                                    $state.go('index.home');
                                } else if (JOB_NO) {
                                    $scope.activeTab = "tab-4";
                                }
                            }

                            if(isNotify){
                                $scope.notifyDiaryUpdates(job_id);
                            }
                        } else {
                            $rootScope.showLoader(false);
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                        console.log(err);
                    });
            } else {
                swal('Cancelled', 'Please enter all required fields.', 'error');
            }
        };

        //Save data into DIARY_tbl "FREE COPY INDICATION" TAB.
        $scope.saveFreecopyindication = function (isNotify) {

            var params = {};
            //Add case to The Diary
            //$rootScope.showLoader(true);
            params.OTH_ATTY1 = $scope.freeindicationData.OTH_ATTY1 ? $scope.freeindicationData.OTH_ATTY1.NAME_KEY : null;
            params.OTH_ATTY2 = $scope.freeindicationData.OTH_ATTY2 ? $scope.freeindicationData.OTH_ATTY2.NAME_KEY : null;
            params.OTH_ATTY3 = $scope.freeindicationData.OTH_ATTY3 ? $scope.freeindicationData.OTH_ATTY3.NAME_KEY : null;
            params.OTH_ATTY4 = $scope.freeindicationData.OTH_ATTY4 ? $scope.freeindicationData.OTH_ATTY4.NAME_KEY : null;
            params.OTH_ATTY5 = $scope.freeindicationData.OTH_ATTY5 ? $scope.freeindicationData.OTH_ATTY5.NAME_KEY : null;

            // Add "FREE" keyword in claim no if Web Display is checked, So Claim no = FREE + file number
            params.Claim_No1 = (($scope.freeindicationData.freeWebDisplay1) ? "FREE" : "") + (($scope.freeindicationData.fileNumber1 && $scope.freeindicationData.freeWebDisplay1) ? "-" : "") + $scope.freeindicationData.fileNumber1.toString();
            params.Claim_No2 = (($scope.freeindicationData.freeWebDisplay2) ? "FREE" : "") + (($scope.freeindicationData.fileNumber2 && $scope.freeindicationData.freeWebDisplay2) ? "-" : "") + $scope.freeindicationData.fileNumber2.toString();
            params.Claim_No3 = (($scope.freeindicationData.freeWebDisplay3) ? "FREE" : "") + (($scope.freeindicationData.fileNumber3 && $scope.freeindicationData.freeWebDisplay3) ? "-" : "") + $scope.freeindicationData.fileNumber3.toString();
            params.Claim_No4 = (($scope.freeindicationData.freeWebDisplay4) ? "FREE" : "") + (($scope.freeindicationData.fileNumber4 && $scope.freeindicationData.freeWebDisplay4) ? "-" : "") + $scope.freeindicationData.fileNumber4.toString();
            params.Claim_No5 = (($scope.freeindicationData.freeWebDisplay5) ? "FREE" : "") + (($scope.freeindicationData.fileNumber5 && $scope.freeindicationData.freeWebDisplay5) ? "-" : "") + $scope.freeindicationData.fileNumber5.toString();

            params.autoPartner1 = $scope.freeindicationData.autoPartner1;
            params.autoPartner2 = $scope.freeindicationData.autoPartner2;
            params.autoPartner3 = $scope.freeindicationData.autoPartner3;
            params.autoPartner4 = $scope.freeindicationData.autoPartner4;
            params.autoPartner5 = $scope.freeindicationData.autoPartner5;

            params.Claim_Rep1 = $scope.freeindicationData.Claim_Rep1;
            params.Claim_Rep2 = $scope.freeindicationData.Claim_Rep2;
            params.Claim_Rep3 = $scope.freeindicationData.Claim_Rep3;
            params.Claim_Rep4 = $scope.freeindicationData.Claim_Rep4;
            params.Claim_Rep5 = $scope.freeindicationData.Claim_Rep5;

            var job_id = $state.params ? $state.params.id : JOB_NO;            
            commonFactory.put('/api/diary/' + job_id, params)
                .success(function (response) {
                    if (response && response.result && response.result.diary_update) {
                        $rootScope.showLoader(false);
                        Notification.success(response.result.message);
                        //get refreshed audit logs for diary
                        $scope.getDiaryAuditLogs();

                        if ($scope.goToHome == true) {
                            $state.go('index.home');
                        } else {
                            $state.go("diary.editcase.step_six", { id: $state.params ? $state.params.id : '' }); // Here state params is JOB id

                        }
                        if(isNotify){
                            $scope.notifyDiaryUpdates(job_id);
                        }
                    } else {
                        $rootScope.showLoader(false);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                    console.log(err);
                });
        };

        //Save data into DIARY_tbl "POST ENTRY" TAB.
        $scope.savePostentry = function (redirect_to_payment, redirectToNewCase, isNotify) {
            //Add case to The Diary
            $rootScope.showLoader(true);
            var ATT = $scope.attorney.filter(function (obj) {
                return obj.id == $scope.postEntryData.attname_id;
            });             
            //$scope.generalData.DUE_DATE = $scope.generalData.DUE_DATE ? moment($scope.generalData.DUE_DATE).format('MM/DD/YYYY') : '';            
            $scope.postEntryData.CNCL_DATE = $scope.postEntryData.CNCL_DATE ? moment($scope.postEntryData.CNCL_DATE).format('MM/DD/YYYY') : '';
            $scope.postEntryData.TAPES_NO = $scope.postEntryData.TAPES_NO ? $scope.postEntryData.TAPES_NO : null;
            $scope.postEntryData.EST_PAGES = $scope.postEntryData.EST_PAGES ? $scope.postEntryData.EST_PAGES : null;
            $scope.postEntryData.attname_id = $scope.postEntryData.attname_id ? $scope.postEntryData.attname_id : null;
            $scope.postEntryData.ATTNM_KEY = ATT[0] ? ATT[0].ATTNM_KEY : null;    
            $scope.postEntryData.CLM_NOTE = $scope.generalData.CLM_NOTE;
            $scope.postEntryData.direct_billing_notes = $scope.generalData.direct_billing_notes;

            $scope.postEntryData.disposed_date = $scope.postEntryData.disposed_date ? moment($scope.postEntryData.disposed_date).format('YYYY-MM-DD') : null;
            var job_id = JOB_NO ? JOB_NO : $state.params.id;
            commonFactory.put('/api/diary/' + job_id, $scope.postEntryData)
                .success(function (response) {
                    if (response && response.result && response.result.diary_update) {
                        $rootScope.showLoader(false);
                        Notification.success(response.result.message);
                        //get refreshed audit logs for diary
                        $scope.getDiaryAuditLogs();

                        if(response.result.diary_update.job_status_id){
                            $scope.diaryData.job_status_id = response.result.diary_update.job_status_id;
                        }
                        if (redirectToNewCase) {
                            $state.go('diary.addcase.step_one');
                        }
                        else if ($scope.goToHome == true) {
                            $state.go('index.home');
                        } else if (redirect_to_payment) {
                            if($scope.diaryData.job_resources.length==1){
                                $state.go('payroll.edit', { id: $scope.diaryData.job_resources[0].id });
                            }
                            else{
                                $state.go('payroll.resourceslist', { job_no: $state.params.id });
                            }
                        }
                        if(isNotify){
                            $scope.notifyDiaryUpdates(job_id);
                        }

                    } else {
                        $rootScope.showLoader(false);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                    console.log(err);
                });
        };

        //Witness
        $scope.witness_title = [{ id: 0, label: 'Dr' }, { id: 1, label: 'Mr' }, { id: 2, label: 'Mrs' }, {
            id: 3,
            label: 'Ms'
        }];
        $scope.witness_suffix = [];
        $scope.witness_name = [];
        $scope.diarywitnesstype = [];

        //Getting all witnessname.
        commonFactory.get('/api/getWitnessname')
            .success(function (response) {
                if (response.result && response.result.witness_name) {
                    $scope.witness_name = response.result.witness_name || '';
                }
            })
            .error(function (err) {
            });

        //Getting Witnessmodal for adding a data.
        $scope.WitnessModal = function () {
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/diary/addcase/witness/witness_modal.html',
                controller: 'witnessController',
                resolve: {
                    witnessdata: function ebtFactory() {
                        return $scope.witness_info;
                    },
                    JOB_NO: function () {
                        return JOB_NO ? JOB_NO : $state.params.id;
                    },
                    loadPlugin:function($ocLazyLoad){
                        return $ocLazyLoad.load([
                            {
                                name: 'ngPatternRestrict',
                                files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                            }]);
                    }
                }
            });

            modalInstance.result.then(function() {
                //get refreshed audit logs for diary
                $scope.getDiaryAuditLogs();
                $scope.getWitnessReminderInfoForJob();
                $scope.witnesslist();
                $scope.getJobTranscripts();
            })
        };

        $scope.WitnessPriorityModal = function () {
            let turnaround = $scope.freeindicationData.turnaround;
            $scope.witnesslist(turnaround);
        };

        $scope.witness_info = {};
        //Getting list of witnesses for the same JOB_NO
        $scope.witnesslist = function (priority="") {
            priority = priority === null ? "" : priority;
            var data = {};
            data.id = JOB_NO ? JOB_NO : $state.params.id;
            if (data.id) {
                $state.$current.data.pageTitle = data.id;
                $rootScope.$broadcast('changeTitle', $state);

                $rootScope.showLoader(true);
                commonFactory.get('/api/getwitnessfordiary/' + data.id+'?priority='+priority)
                    .success(function (response) {
                        if (response.result && response.result.witnessDetails) {
                            $rootScope.showLoader(false);
                            $scope.witness_info = response.result.witnessDetails;   
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            }
        };

        $scope.showAction = function (value){
            return value.txt || value.txt || value.txt || value.pdf || value.mini || value.linked_pdf
                || value.linkedmini_pdf || value.rtf || value.ptx || value.mp4 || value.zip || value.png
                || value.mp3 || value.summary;
        };

        //Edit witness detail info.
        $scope.editWitness = function (data, index) {
            //console.log('data: ', data);
            $rootScope.showLoader(true);
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/diary/addcase/witness/witness_modal.html',
                controller: 'editwitnessController',
                resolve: {
                    witness_value: function witnessFactory() {
                        return commonFactory.get('/api/editwitnesstodiary/' + data.id);
                    },
                    index: function witnessIndex() {
                        return index;
                    },
                    witness_data: function witnessData() {
                        return $scope.witness_info;
                    },
                    loadPlugin:function($ocLazyLoad){
                        return $ocLazyLoad.load([
                            {
                                name: 'ngPatternRestrict',
                                files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                            }]);
                    }
                }
            });

            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });

            modalInstance.result.then(function() {
                //get refreshed audit logs for diary
                $scope.getDiaryAuditLogs();
                $scope.getWitnessReminderInfoForJob();
            })
        };

    //notary witness detail info.
    $scope.notaryWitness = function (data, index) {
        $rootScope.showLoader(true);
        var modalInstance = $uibModal.open({
            templateUrl: 'modules/diary/addcase/witness/witness_modal_notary.html',
            controller: 'notarywitnessController',
            resolve: {
                witness_value: function witnessFactory() {
                    return commonFactory.get('/api/editwitnesstodiary/' + data.id);
                },
                index: function witnessIndex() {
                    return index;
                },
                witness_data: function witnessData() {
                    return $scope.witness_info;
                },
                depoHost: function witnessData() {
                    return $rootScope.depoHost;
                }
            }
        });

    modalInstance.opened.then(function () {
        $rootScope.showLoader(false);
    });

    // modalInstance.result.then(function() {
    //     //get refreshed audit logs for diary
    //     $scope.getDiaryAuditLogs();
    //     $scope.getWitnessReminderInfoForJob();
    // })
};

        //Delete witness detail info.
        $scope.deleteWitness = function (data) {
            swal({
                title: "Are you sure?",
                text: "you want to delete this witness!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel witness!",
                closeOnConfirm: false,
                closeOnCancel: false
            },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/deletewitnesstodiary', data)
                            .success(function (response) {
                                if (response.status == 200) {
                                    $rootScope.showLoader(false);
                                    $scope.getDiaryAuditLogs();
                                    $scope.getWitnessReminderInfoForJob();
                                    var index = -1;
                                    var comArr = eval($scope.witness_info);
                                    for (var i = 0; i < comArr.length; i++) {
                                        if (comArr[i].id === data.id) {
                                            index = i;
                                            break;
                                        }
                                    }
                                    if (index === -1) {
                                        $rootScope.showLoader(false);
                                        swal('Cancelled', 'Something gone wrong', 'error');
                                    }
                                    $scope.witness_info.splice(index, 1);
                                	swal('Deleted!', 'Witness deleted successfully.', 'success');
                                }else{
                                    $rootScope.showLoader(false);
                                    swal('Cancelled', response.result.message, 'error');
                                }
                            })
                            .error(function (err) {
                                $rootScope.showLoader(false);
                                swal('Cancelled', 'Something gone wrong', 'error');
                            });
                    } else {
                        $rootScope.showLoader(false);
                        swal("Cancelled", "Your Witness is safe :)", "error");
                    }
                });
        };

        $scope.undoWitness = function (data) {
            swal({
                title: "Are you sure?",
                text: "you want to undo this remove!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, undo it!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/undoremovewitnesstodiary', data)
                            .success(function (response) {
                                if (response.status == 200) {
                                    $rootScope.showLoader(false);
                                    $scope.getDiaryAuditLogs();
                                    $scope.witnesslist();
                                    swal('Undo!', 'Remove Request Undo Successfully.', 'success');
                                }else{
                                    $rootScope.showLoader(false);
                                    swal('Cancelled', response.result.message, 'error');
                                }
                            })
                            .error(function (err) {
                                $rootScope.showLoader(false);
                                swal('Cancelled', 'Something gone wrong', 'error');
                            });
                    }
                });
        };

        $scope.updateWitness = function (data) {
            if(data.is_billable == 1){
                var txtMsg = "you want to remove this witness from billing?"
            }else{
                var txtMsg = "you want to mark this witness as billable?"
            }
            swal({
                title: "Are you sure?",
                text: txtMsg,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: false,
                closeOnCancel: false
            },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/updatewitnessbillabletodiary', data)
                            .success(function (response) {
                                if (response.status == 200) {
                                    $rootScope.showLoader(false);
                                    $scope.witnesslist();
                                   
                                 
                                	swal('Updated!', 'Witness updated successfully.', 'success');
                                }else{
                                    $rootScope.showLoader(false);
                                    swal('Cancelled', response.result.message, 'error');
                                }
                            })
                            .error(function (err) {
                                $rootScope.showLoader(false);
                                swal('Cancelled', 'Something gone wrong', 'error');
                            });
                    } else {
                        $rootScope.showLoader(false);
                        swal("Cancelled", "Your Witness is safe :)", "error");
                    }
                });
        };
        $scope.closeDiaryModal = function ($close) {

            swal({
                title: "Are you sure ?",
                text: "You are about to leave the edit view. Any changes you have not saved will be lost.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, close it!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        $close(JOB_NO);
                    } else {
                        return false;
                    }
                });

        };

        $scope.onSelectOtherSideFirm = function ($item, $model, $label) {
            $scope.otherSideFirmSelected = true;

            if ($scope.foundFromElaw) {

                swal({
                    title: "Are you sure?",
                    text: "You are about to add new firm!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    closeOnConfirm: true
                },
                    function (isConfirm) {

                        if (isConfirm) {
                            $scope.diaryData.otherfirmlocation = null;
                            $scope.$apply();
                            var url = $state.href('firms.add.step_one_add', { elawId: $item.id });
                            window.open(url, '_blank');
                        } else {
                            $scope.diaryData.otherfirmlocation = null;
                            $scope.$apply();

                        }
                    });
            }

        };

        $scope.unSelectOtherSideFirm = function () {
            $scope.otherSideFirmSelected = false;
        };

        $scope.onSelectAttorneyFirm = function ($item, $model, $label, firmNo) {
            
            if(firmNo == 'OTH_ATTY1'){
                $scope.freeindicationData.freeWebDisplay1 = true;
                $scope.freeindicationData.autoPartner1 = $item.BEEPER || 'none';
            }else if(firmNo == 'OTH_ATTY2'){
                $scope.freeindicationData.freeWebDisplay2 = true;
                $scope.freeindicationData.autoPartner2 = $item.BEEPER || 'none';
            }else if(firmNo == 'OTH_ATTY3'){
                $scope.freeindicationData.freeWebDisplay3 = true;
                $scope.freeindicationData.autoPartner3 = $item.BEEPER || 'none';
            }else if(firmNo == 'OTH_ATTY4'){
                $scope.freeindicationData.freeWebDisplay4 = true;
                $scope.freeindicationData.autoPartner4 = $item.BEEPER || 'none';
            }else if(firmNo == 'OTH_ATTY5'){
                $scope.freeindicationData.freeWebDisplay5 = true;
                $scope.freeindicationData.autoPartner5 = $item.BEEPER || 'none';
            }

            if ($scope.foundFromElaw) {

                swal({
                    title: "Are you sure?",
                    text: "You are about to add new firm!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    closeOnConfirm: true
                },
                    function (isConfirm) {
                        
                        if (firmNo == 'OTH_ATTY1') {
                            $scope.freeindicationData.OTH_ATTY1 = null;
                        }
                        else if (firmNo == 'OTH_ATTY2') {
                            $scope.freeindicationData.OTH_ATTY2 = null;
                        }
                        else if (firmNo == 'OTH_ATTY3') {
                            $scope.freeindicationData.OTH_ATTY3 = null;
                        }
                        else if (firmNo == 'OTH_ATTY4') {
                            $scope.freeindicationData.OTH_ATTY4 = null;
                        }
                        else if (firmNo == 'OTH_ATTY5') {
                            $scope.freeindicationData.OTH_ATTY5 = null;
                        }
                        $scope.$apply();
                        if (isConfirm) {
                            var url = $state.href('firms.add.step_one_add', { elawId: $item.id });
                            window.open(url, '_blank');
                        }
                    });
            }
        };

        $scope.onselectHeldAtLocation = function () {
            if ($scope.diaryData.ToBeHeldAt > 1) {
                $scope.heldAtLocationSelected = true;
            }
            else {
                $scope.heldAtLocationSelected = true;
            }
        };

        $scope.fillTheCancelDate = function () {
            if ($scope.postEntryData.STATUS === 'X') {
                $scope.postEntryData.CNCL_DATE = moment().format('L');
                $scope.postEntryData.CNCL_TIME = moment();
                $scope.postEntryData.CNCL_NAME = $scope.Authuser.name;
                $scope.postEntryData.CNCL_STAFF = $scope.Authuser.id;
            }
            else {
                $scope.postEntryData.CNCL_DATE = '';
                $scope.postEntryData.CNCL_TIME = '';
                $scope.postEntryData.CNCL_NAME = '';
                $scope.postEntryData.CNCL_STAFF = '';
            }
        };

        $scope.copyValueScheduledBy = '';
        $scope.ScheduledBySelect = function(id){
            let email = '';
            if($scope.job_called_in_by_list){
                angular.forEach($scope.job_called_in_by_list.dep_attorney,function(value, key){
                    if(value.attorney_no == id){
                        if(value.email){
                           email = value.email.replace(/;/g,',');
                        }
                    }
                });
            }
            
            $scope.copyValueScheduledBy = email;
        }

        $scope.copyValueAttorney = '';
        $scope.AttorneySelect = function(id){
            let email = '';
            if($scope.attornay_list){
                angular.forEach($scope.attornay_list.dep_attorney,function(value, key){
                    if(value.attorney_no == id){
                        if(value.email){
                           email = value.email.replace(/;/g,',');
                        }
                    }
                });
            }
            
            $scope.copyValueAttorney = email;
        }

        $scope.copyText = function(text){
            navigator.clipboard.writeText(text);
            Notification.success('Email Copied!');
        }

        $scope.onSelectCalledInBy = function(called_in_by_id){
            // If send job confirmation type selected to single then and then only we listing job called in by emails. Only one email can be selected of them. 
            if($scope.orderingFirm && ($scope.orderingFirm.send_job_confirmation_to == 'single' || $scope.orderingFirm.send_job_confirmation_to == null)){
               
                // On select any attorney from list we are going to remove all selected emails of old attorney
                angular.forEach($scope.job_called_in_by_list.dep_attorney,function(value, key){             
                    var emails = [];
                    if(value.email){
                        emails = value.email.replace(/;/g,',').split(',');
                        angular.forEach(emails,function(value, key){
                            // $scope.diaryData.send_job_confirmation_on = $scope.diaryData.send_job_confirmation_on.filter(function(item){
                            //     return item.text != value;
                            // });         
                        });
                    }
                });

                // Add email address of selected attorney if exists.If already exists then nothing to do.
                if(called_in_by_id){
                    angular.forEach($scope.job_called_in_by_list.dep_attorney,function(value, key){
                        if(value.attorney_no == called_in_by_id){
                            var emails = [];
                            if(value.email){
                               emails = value.email.replace(/;/g,',').split(',');
                            }

                            angular.forEach(emails,function(email, key){
                                var is_email_exists = false; 
                                $scope.diaryData.send_job_confirmation_on.filter(function(item){
                                    if(item.text == email){
                                        is_email_exists = true;
                                    }
                                });
                                if(!is_email_exists){
                                    // $scope.diaryData.send_job_confirmation_on.push({text: email});
                                } 
                            });
                        }
                    });
                }
            }
           /* var indexNo = 0
            var isSalesman = false
            angular.forEach($scope.job_called_in_by_list.dep_attorney,function(value, key){              
               
                 if($scope.salesman_commission_type == 'CIP' && value.commission_salesman_id && value.attorney_no == called_in_by_id){
                    $scope.diaryData.reporting_sales_ids = [];
                    isSalesman = true
                     $scope.diaryData.reporting_sales_ids.push(value.commission_salesman_id);
                 }
                 indexNo++
               
                 if($scope.salesman_commission_type == 'CIP' && isSalesman == false && indexNo == $scope.job_called_in_by_list.dep_attorney.length ){
                
                    $scope.diaryData.reporting_sales_ids = $scope.reportingSalesmanIds
                 }
            });*/

            $scope.onSelectChangeSalesperson();
            $scope.ScheduledBySelect(called_in_by_id);
        }

        //Unlock salesperson field.
        $scope.unlockSalesPersonAndSource = function(){
            
            if($rootScope.havePermission('allow_access_without_password','allow.diary.account.executive.lock.popup')){
                return;
            }else if($scope.salesperson_source_locked){
                swal({
                    title: "PLEASE ENTER PASSWORD TO UNLOCK",
                    type: "input",
                    inputType: "password",
                    showCancelButton: true,
                    closeOnConfirm: true
                }, function(inputValue) { 
                    if (inputValue === false){
                        return false;
                    } 
                    if (inputValue === "") {
                        Notification.error("You need to write something!");
                        return false
                    }
                    else {
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/auth-salesperson-source-pass', { 'password': inputValue })
                            .success(function(response) {
                                if (response['result']) {
                                    if (response['result']['auth']) {

                                        $rootScope.showLoader(false);
                                        $scope.salesperson_source_locked = false;
                                        
                                    }else {
                                        $rootScope.showLoader(false);
                                        Notification.error("Error ! Wrong Password");
                                    }
                                }else{
                                    $rootScope.showLoader(false);
                                    Notification.error("Oops ! Something went wrong");
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                                Notification.error("Oops ! Something went wrong");
                            });

                    }
                });        
            }else{
                $scope.salesperson_source_locked = true;
            }
        }


        $scope.$watch('postEntryData.STATUS', function (newId, oldId) {
            angular.forEach($scope.status,function(value, key){
                if(value.slug == newId){
                    $scope.job_status = value.name;
                }
            });
        });


        $scope.updateTurnAround= function(){
                DATE_TAKEN = moment($scope.generalData.DATE_TAKEN).format('MM/DD/YYYY');
                $scope.generalData.DUE_DATE = moment(DATE_TAKEN, 'MM/DD/YYYY').businessAdd($scope.generalData.turn_around_info)._d;
                $scope.generalData.DUE_DATE=$scope.generalData.DUE_DATE;
        }

        $scope.updateDueDate= function(){
            newId =moment($scope.generalData.DUE_DATE).format('MM/DD/YYYY');
            DATE_TAKEN = moment($scope.generalData.DATE_TAKEN).format('MM/DD/YYYY');
            var diff = moment(newId, 'MM/DD/YYYY').businessDiff(moment(DATE_TAKEN, 'MM/DD/YYYY'), true);
            if(diff >=10){
                $scope.generalData.turn_around_info=10;
                $scope.postEntryData.turn_around_info=10;
            }
            else if(diff <=0){
                $scope.generalData.turn_around_info=0;
                $scope.postEntryData.turn_around_info=0;
            }
            else{
                $scope.generalData.turn_around_info=diff;
                $scope.postEntryData.turn_around_info=diff;
            }
        }

        $scope.$watch('diaryData.job_status_id', function (newId, oldId) {
            angular.forEach($scope.JobStatusList,function(value, key){
                if(value.id == newId){
                    $scope.job_status_name = value.name;
                }
            });
            if($rootScope.StatusIDForEmailAvailable.includes(newId)){
                $scope.isShowNotifyOption = true;
            }else{
                $scope.isShowNotifyOption = false;
            }
        });

        $scope.resendWitnessReminder = function(data){
            swal({
                title: "Are you sure?",
                text: "you want to send reminder to this witness?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#104a7b",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            },
            function (isConfirm) {
                if (isConfirm){
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/sendDiaryReminderNotification', data)
                        .success(function (response) {
                            $rootScope.showLoader(false);                                
                            if (response.status == 200) {
                                Notification.success(response.result.message);
                                $scope.getWitnessReminderInfoForJob();
                            }else{
                                Notification.error(response.result.message);
                                $scope.getWitnessReminderInfoForJob();
                            }
                        });
                }
            });
        }

        $scope.orderTranscript = function(){
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/diary/orderTranscript/order_transcript_modal.html',
                controller: function($scope, commonFactory, $state, Notification, $uibModalInstance, diaryData,diaryFactory){
                    
                    $scope.validations = diaryFactory.validations;
                    $scope.messages = diaryFactory.messages;
                    $scope.formSubmit = false;
                    $scope.formData = {
                        JOB_NO : diaryData.JOB_NO,
                        company_name : diaryData.attorney_id.NAME,
                        address1 : diaryData.attorney_id.ADDR_LINE1,
                        address2 : diaryData.attorney_id.ADDR_LINE2,
                        city : diaryData.attorney_id.CITY,
                        state : diaryData.attorney_id.STATE,
                        zip : diaryData.attorney_id.ZIP,
                        tournaround_request : "",
                        quantity : "",
                        shipping_option : ""
                    }

                    $scope.order = function(isValid){
                        $scope.formSubmit = true;
                        if(isValid){
                            swal({
                                title: "Are you sure?",
                                text: "you want to request transcript from recording?",
                                type: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#104a7b",
                                confirmButtonText: "Yes",
                                cancelButtonText: "No",
                            },
                            function (isConfirm) {
                                if (isConfirm){
                                    $rootScope.showLoader(true);
                                    commonFactory.post('/api/orderTranscript', $scope.formData)
                                        .success(function (response) {
                                            $rootScope.showLoader(false);
                                            if (response.status == 200) {
                                                Notification.success(response.result.message);
                                                $scope.cancel();
                                            }else{
                                                Notification.error(response.result.message);
                                            }
                                        });
                                }
                            });
                        }
                    }
                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    }
                },
                keyboard: false,
                backdrop: false,
                resolve: {
                    diaryData: function() {
                        return $scope.diaryData;
                    },
                    loadPlugin:function($ocLazyLoad){
                        return $ocLazyLoad.load([
                            {
                                name: 'ngPatternRestrict',
                                files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                            }]);
                    }
                }
            });
        }


        $scope.jobCaseData = function(val){
            return $http.get(apiUrl + '/api/find-case', {
                params: {
                    searchBy:'CAPTION',
                    search : val,
                    limit :40 
                }
            }).then(function(response) {
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        }

        $scope.onSelectCase = function($item, $model, $label){
            $scope.isSelectCase = true;
            $scope.diaryData.CAPTION = $item.CAPTION;
        }

        $scope.clearCase = function(){
            if(!$scope.isSelectCase){ 
                $scope.diaryData.CAPTION = null;
            }
        };

        $scope.resetCase = function(){
            if($scope.isSelectCase){
                $scope.diaryData.job_case_id = null;
                $scope.diaryData.CAPTION = null;
                $scope.isSelectCase = false;
            }
        };

        $scope.getDiaryCommission = function(){

            if ($scope.JOB_NO) {
                $scope.reporting_sales_ids = [];
                $scope.records_sales_ids = [];

                $rootScope.showLoader(true);
                commonFactory.get('/api/get-diary-commission/'+ $scope.JOB_NO)
                    .success(function(response) {
                
                        $rootScope.showLoader(false);
                        if (response && response.result) {
                            $scope.reportingSalesman = response.result.reportingSalesman;
                            $scope.recordsSalesman = response.result.recordsSalesman;

                            if($scope.reportingSalesman.length){
                                var reportingSalesmanIds = [];
                                angular.forEach($scope.reportingSalesman,function(value, key){
                                    reportingSalesmanIds[key] = value.sales_reptyp_id;
                                });
                                $scope.reporting_sales_ids = reportingSalesmanIds;
                            }
                            
                            if($scope.recordsSalesman.length){
                                var recordsSalesmanIds = [];
                                angular.forEach($scope.recordsSalesman,function(value, key){
                                    recordsSalesmanIds[key] = value.sales_reptyp_id;
                                });
                                $scope.records_sales_ids = recordsSalesmanIds;
                            }

                        }else{
                            Notification.error('Something went wrong.');
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

        $scope.saveDiaryCommission = function(){

            var data = {
                JOB_NO : $scope.JOB_NO,
                reportingSalesman : $scope.reportingSalesman,
                recordsSalesman : $scope.recordsSalesman
            }

            swal({
                title: "Are you sure",
                text: "You want to save?",
                type: "warning",
                html: true,
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) { 
                    $rootScope.showLoader(true); 
                    $http.post(apiUrl+'/api/update-diary-commission', data).success(function(res){
                        $rootScope.showLoader(false);
                        if(!res.error){
                            Notification.success(res.result.message);
                        }else{
                            Notification.error(res.result.message);
                        }
                    });
                }
            });
        }

        $scope.askDiaryCommissionTabPass = function(){
            $scope.setPageTitle();
            if($rootScope.havePermission('allow_access_without_password','allow.attorney.commission')){                
                $scope.diaryCommissionTabAuthenticated = true;
                $scope.getDiaryCommission();
                return;
            }else if($scope.diaryCommissionTabAuthenticated){
                $scope.getDiaryCommission();
                return;
            }else{
                swal({
                    title: "PLEASE ENTER PASSWORD TO UNLOCK COMMISSION SCREEN",
                    type: "input",
                    inputType: "password",
                    showCancelButton: true,
                    closeOnConfirm: true
                }, function(inputValue) {
                    if (inputValue === false){
                        $state.go("diary.editcase.step_one", {id : $state.params.id});
                        return false;
                    }
                    if (inputValue === "") {
                        Notification.error("You need to write something!");
                        $state.go("diary.editcase.step_one", {id : $state.params.id});
                        return false
                    }
                    else {
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/authPrivateInfoPass', { 'password': inputValue })
                            .success(function(response){
                                $rootScope.showLoader(false);
                                if(response['result']){
                                    if (response['result']['auth']){
                                        $scope.getDiaryCommission();
                                        $scope.diaryCommissionTabAuthenticated = true;
                                    }else{
                                        Notification.error("Error !, Wrong Password!");
                                        $state.go("diary.editcase.step_one", {id : $state.params.id});
                                    }
                                }else{
                                    Notification.error("Oops !, Something went wrong!");
                                    $state.go("diary.editcase.step_one", {id : $state.params.id});
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                                Notification.error("Oops !, Something went wrong!");
                                $state.go("diary.editcase.step_one", {id : $state.params.id});
                            });
                    }
                });
            }
        }

        $scope.markReadyToInvoice = function(){
            var mark = $scope.formData.mark_ready == true ? 'mark ready to invoice' : 'remove mark';
            swal({
                title: "Are you sure",
                text: "You want to "+ mark+"?",
                type: "warning",
                html: true,
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(isConfirm){
                if(isConfirm){
                    var mark_ready = $scope.formData.mark_ready == true ? 1 : 0;
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/mark-ready-to-invoice', { JOB_NO : $state.params.id, mark_ready : mark_ready })
                        .success(function (response) {
                            if (response && response.result) {
                                $rootScope.showLoader(false);
                                
                                //get refreshed audit logs for diary
                                $scope.getDiaryAuditLogs();
                                Notification.success("updated successfully");
                                if(!response.error){
                                    $scope.diaryData.job_status_id = response.result.updated_status;
                                }
                            } else {
                                $rootScope.showLoader(false);
                            }
                        })
                        .error(function (err) {
                            $rootScope.showLoader(false);
                            console.log(err);
                        });
                }else{
                    $scope.formData.mark_ready = $scope.formData.mark_ready == true ? undefined : true;
                    $scope.$apply();
                }
            });
            
        }

        $scope.saveExhibitFlag = function(){
            var send_exhibit_link = $scope.formData.send_exhibit_link == true ? 1 : 0;
            $rootScope.showLoader(true);
            commonFactory.post('/api/save-exhibit-flag', { JOB_NO : $state.params.id, send_exhibit_link : send_exhibit_link })
                .success(function (response) {
                    if (response && response.result) {
                        //update for textbox 
                        $scope.diaryOriginalData.send_exhibit_link = send_exhibit_link;
                        $rootScope.showLoader(false);
                        Notification.success("Updated successfully");
                    } else {
                        $rootScope.showLoader(false);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                    console.log(err);
                });
        }

        $scope.changeJobStatus = function(){
            var status_id = $scope.diaryData.job_status_id;
            $rootScope.showLoader(true);
            commonFactory.post('/api/save-job-status', { JOB_NO : $state.params.id, job_status_id : status_id })
                .success(function (response) {
                    if (response && response.result) {
                        //update for textbox 
                        $scope.diaryOriginalData.job_status_id = status_id;
                        if(response.result.is_job_cancelled){
                            $scope.diaryData.job_resources.forEach(function (element, i) {
                                if($scope.diaryData.job_resources[i].service_request){
                                    $scope.diaryData.job_resources[i].service_request.assignment_status_id = response.result.assignment_status_id;
                                }
                            });

                            $scope.diaryData.service_request.forEach(function (element, i) {
                                if($scope.diaryData.service_request[i].service_request){
                                    $scope.diaryData.service_request[i].assignment_status_id = response.result.assignment_status_id;
                                }
                            });
                        }
                        $rootScope.showLoader(false);
                        $scope.getDiaryAuditLogs();
                        if(!response.error){
                            $scope.postEntryData.STATUS = response.result.diary.STATUS;
                            $scope.formData.mark_ready = response.result.diary.mark_ready == "1" ? true : undefined;
                            if($rootScope.StatusIDForEmailAvailable.includes(status_id)){
                                $scope.notifyDiaryUpdates($state.params.id);                                
                            }
                        }
                        Notification.success("Job Status Updated successfully");
                    } else {
                        $rootScope.showLoader(false);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                    console.log(err);
                });
        }

        $scope.assignResorceService = function(service_type_id,job_resource_id){
            var status_id = $scope.diaryData.job_status_id;
            $rootScope.showLoader(true);
            commonFactory.post('/api/assignResorceService', { service_type_id : service_type_id, job_resource_id : job_resource_id,JOB_NO : $state.params.id })
                .success(function (response) {
                    if (response && response.result) {
                        $scope.getDiaryAuditLogs();
                        $scope.diaryData.service_request=response.service_request;
                        $scope.postEntryData.service_request=response.service_request;
                        $scope.diaryData.job_resources=response.job_resources;
                        $scope.postEntryData.job_resources=response.job_resources;
                        $rootScope.showLoader(false);
                        Notification.success("Service Assign Succesfully");
                        $scope.postEntryData.job_resources.forEach(function (element, i) {
                            $scope.postEntryData.job_resources[i].is_payment_required = element.is_payment_required.toString()
                        });
                      
                    } else {
                        $rootScope.showLoader(false);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                    console.log(err);
                });
        }
        $scope.removeResorceService = function(id){
                swal({
                    title: "Are you sure",
                    text: "You want to delete?",
                    type: "warning",
                    html: true,
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) { 
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/removeResorceService', { id : id, JOB_NO : $state.params.id })
                            .success(function (response) {
                                if (response && response.result) {
                                    $scope.getDiaryAuditLogs();
                                    $scope.diaryData.service_request=response.service_request;
                                    $scope.postEntryData.service_request=response.service_request;
                                    $scope.diaryData.job_resources=response.job_resources;
                                    $scope.postEntryData.job_resources=response.job_resources;
                                    $rootScope.showLoader(false);
                                    Notification.success("Service Remove Succesfully");
                                    $scope.postEntryData.job_resources.forEach(function (element, i) {
                                        $scope.postEntryData.job_resources[i].is_payment_required = element.is_payment_required.toString()
                                    });

                                    if($scope.diaryData.service_request.length){
                                        var resource_type_ids = [];
                                        angular.forEach($scope.diaryData.service_request,function(value, key){
                                            resource_type_ids[key] = value.resource_type_id;
                                        });
                                        $scope.diaryData.resource_type_ids = resource_type_ids;
                                    }
                                    if($scope.diaryData.job_resources.length){
                                        var resource_type_ids = [];
                                        angular.forEach($scope.diaryData.job_resources,function(value, key){
                                            if(value.service_request && value.service_request.resource_type_id){
                                                resource_type_ids[key] = value.resource_type_id;
                                                if($scope.diaryData.resource_type_ids && $scope.diaryData.resource_type_ids.length){
                                                    $scope.diaryData.resource_type_ids.push(value.resource_type_id)
                                                }else{
                                                    $scope.diaryData.resource_type_ids = resource_type_ids;
                                                }
                                            }
                                        });
                                       
                                    }
                                  
                                } else {
                                    $rootScope.showLoader(false);
                                }
                            })
                            .error(function (err) {
                                $rootScope.showLoader(false);
                                console.log(err);
                            });
                    }
                });
        }
        $scope.removeResource = function(id,ServiceRequestId=null){
            var removeResourceServiceRequestId = [];
            if(ServiceRequestId){
                removeResourceServiceRequestId.push(ServiceRequestId);
            }
            swal({
                title: "Are you sure",
                text: "You want to delete?",
                type: "warning",
                html: true,
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) { 
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/removeResource', { id : id, JOB_NO : $state.params.id,removeResourceServiceRequestId:removeResourceServiceRequestId })
                        .success(function (response) {
                            if (response && response.result) {
                                if(response.error == true){
                                    $rootScope.showLoader(false);
                                    Notification.error(response.result.message);
                                } else{
                                    $scope.getDiaryAuditLogs();
                                    $scope.diaryData.service_request=response.service_request;
                                    $scope.postEntryData.service_request=response.service_request;
                                    $scope.diaryData.job_resources=response.job_resources;
                                    $scope.postEntryData.job_resources=response.job_resources;
                                    $rootScope.showLoader(false);
                                    Notification.success("Resource Remove Succesfully");
                                    $scope.postEntryData.job_resources.forEach(function (element, i) {
                                        $scope.postEntryData.job_resources[i].is_payment_required = element.is_payment_required.toString()
                                    });

                                    if($scope.diaryData.service_request.length){
                                        var resource_type_ids = [];
                                        angular.forEach($scope.diaryData.service_request,function(value, key){
                                            resource_type_ids[key] = value.resource_type_id;
                                        });
                                        $scope.diaryData.resource_type_ids = resource_type_ids;
                                    }
                                    if($scope.diaryData.job_resources.length){
                                        var resource_type_ids = [];
                                        angular.forEach($scope.diaryData.job_resources,function(value, key){
                                            if(value.service_request && value.service_request.resource_type_id){
                                                resource_type_ids[key] = value.resource_type_id;
                                                if($scope.diaryData.resource_type_ids && $scope.diaryData.resource_type_ids.length){
                                                    $scope.diaryData.resource_type_ids.push(value.resource_type_id)
                                                }else{
                                                    $scope.diaryData.resource_type_ids = resource_type_ids;
                                                }
                                            }
                                        });
                                       
                                    }
                                }
                              
                            } else {
                                $rootScope.showLoader(false);
                            }
                        })
                        .error(function (err) {
                            $rootScope.showLoader(false);
                            console.log(err);
                        });
                }
            });
    }
        $scope.updateServiceStatus = function(id,service_status_id){
           
            $rootScope.showLoader(true);
            commonFactory.post('/api/updateServiceStatus', { id : id, service_status_id : service_status_id })
                .success(function (response) {
                    if (response && response.result) {
                        $rootScope.showLoader(false);
                        $scope.getDiaryAuditLogs();
                        Notification.success("Service Status Update Succesfully.");
                      
                    } else {
                        $rootScope.showLoader(false);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                    console.log(err);
                });
        }

        $scope.updateAssignmentStatus = function(id,assignment_status_id,job_resource_id){
            
            
            $rootScope.showLoader(true);
            commonFactory.post('/api/updateAssignmentStatus', { id : id, assignment_status_id : assignment_status_id,job_resource_id:job_resource_id })
                .success(function (response) {
                    if (response && response.result) {
                        $rootScope.showLoader(false);
                        $scope.getDiaryAuditLogs();
                        Notification.success("Assignment Status Update Succesfully.");

                        // if cancelled notify user 
                        if(response.result.is_cancelled && response.result.is_have_resource && response.result.resource.Emai_Address ){
                            $scope.notifyResource(response.result.resource.Emai_Address );
                        }
                      
                    } else {
                        $rootScope.showLoader(false);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                    console.log(err);
                });
        }
        $scope.notifyResource = function(email){
            swal({
                title: "Are you sure ?",
                text: "You want to send assignment cancellation notice?",
                type: "warning",
                html: true,
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) { 
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/SendJobConfirmationTriggerToEmail', { JOB_NO: $state.params.id ,email_address : [email] })
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if(response.error){
                            Notification.error(response.result.message || "Something went wrong while sending Email!");
                        }else{
                            Notification.success(response.result.message || "Email successfully sent.");
                            $uibModalInstance.close(response);
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                        Notification.error("Oops !", "Something went wrong!", "error");
                    });
                }
            });
        }
        $scope.changeBillerBy = function(){
            var biller_id = $scope.diaryData.Biller_by;
            $rootScope.showLoader(true);
            commonFactory.post('/api/save-biller-by', { JOB_NO : $state.params.id, Biller_by : biller_id })
                .success(function (response) {
                    if (response && response.result) {
                        //update for textbox 
                        $scope.diaryOriginalData.Biller_by = biller_id;
                        $rootScope.showLoader(false);
                        $scope.getDiaryAuditLogs();
                        Notification.success("Biller Updated successfully");
                    } else {
                        $rootScope.showLoader(false);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                    console.log(err);
                });
        }


        $scope.addAttendeeInformation = function(){
            $rootScope.showLoader(true);
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/diary/addAttendee/attendee_modal.html',
                controller: 'addAttendeeController',
                keyboard: false,
                backdrop: false,
                resolve: {
                    JOB_NO: function() {
                        return $scope.JOB_NO;
                    },
                    email: function() {
                        return null;
                    },
                    attendee_type: function() {
                        return null;
                    }
                }
            });

            modalInstance.opened.then(function () {
                $timeout(function(){
                    $rootScope.showLoader(false);
                },1000);
            });

            modalInstance.result.then(function () {
                $scope.getMeetingAttendees();
                $scope.getDiaryAuditLogs();
            }, function () {
                console.log("Modal Dismissed!!!");
            });
        }

        $scope.editMeetingAttendee = function(id){
            $rootScope.showLoader(true);
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/diary/addAttendee/attendee_modal.html',
                controller: 'editAttendeeController',
                keyboard: false,
                backdrop: false,
                resolve: {
                    id: function() {
                        return id;
                    },
                    attendee_type: function() {
                        return null;
                    }
                }
            });

            modalInstance.opened.then(function () {
                $timeout(function(){
                    $rootScope.showLoader(false);
                },1000);
            });

            modalInstance.result.then(function () {
                $scope.getMeetingAttendees();
                $scope.getDiaryAuditLogs();
            }, function () {
                console.log("Modal Dismissed!!!");
            });
        }

        $scope.deleteMeetingAttendee = function(id){
            swal({
                title: "Are you sure",
                text: "You want to delete?",
                type: "warning",
                html: true,
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) { 
                    $rootScope.showLoader(true);
                    commonFactory.delete('/api/meeting-attendee-info/'+ id).success(function (response) {
                        $rootScope.showLoader(false);
                        if (!response.error) {
                            Notification.success(response.result.message);
                            $scope.getMeetingAttendees();
                            $scope.getDiaryAuditLogs();
                        }else{
                            Notification.error(response.result.message);
                        }
                    });
                }
            });
        }

        $scope.sendInvitation = function(){
            swal({
                title: "Are you sure",
                text: "You want to send invitation?",
                type: "warning",
                html: true,
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) { 
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/send-invitation', { JOB_NO : $scope.JOB_NO }).success(function (response) {
                        $rootScope.showLoader(false);
                        if (!response.error) {
                            Notification.success(response.result.message);
                            $scope.getDiaryAuditLogs();
                        }else{
                            Notification.error(response.result.message);
                        }
                    });
                }
            });
        }
        $scope.sendInvitationById = function(id){
            swal({
                title: "Are you sure",
                text: "You want to send invitation?",
                type: "warning",
                html: true,
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) { 
                    $rootScope.showLoader(true);
                    commonFactory.get('/api/send-meeting-attendees-email/'+ id).success(function (response) {
                        $rootScope.showLoader(false);
                        if (!response.error) {
                            Notification.success(response.result.message);
                            $scope.getDiaryAuditLogs();
                        }else{
                            Notification.error(response.result.message);
                        }
                    });
                }
            });
        }
        $scope.sendInvitationZoom = function(){
            swal({
                title: "Are you sure",
                text: "You want to send invitation?",
                type: "warning",
                html: true,
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) { 
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/send-invitation-zoom', { JOB_NO : $scope.JOB_NO }).success(function (response) {
                        $rootScope.showLoader(false);
                        if (!response.error) {
                            Notification.success(response.result.message);
                           
                            $scope.fetchData();
                        }else{
                            Notification.error(response.result.message);
                        }
                    });
                }
            });
        }

        $scope.zoomReplace = function(){
            swal({
                title: "Are you sure",
                text: "You want to replace zoom link (it will delete old link)?",
                type: "warning",
                html: true,
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) { 
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/replace-zoom-link', { JOB_NO : $scope.JOB_NO }).success(function (response) {
                        $rootScope.showLoader(false);
                        if (!response.error) {
                            Notification.success(response.result.message);
                            $scope.fetchData();
                        }else{
                            Notification.error(response.result.message);
                        }
                    });
                }
            });
        }
        $scope.zoomDelete = function(){
            swal({
                title: "Are you sure",
                text: "You want to delete zoom link (no one will able to join using this link)?",
                type: "warning",
                html: true,
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) { 
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/delete-zoom-link', { JOB_NO : $scope.JOB_NO }).success(function (response) {
                        $rootScope.showLoader(false);
                        if (!response.error) {
                            Notification.success(response.result.message);
                            $scope.fetchData();
                        }else{
                            Notification.error(response.result.message);
                        }
                    });
                }
            });
        }
        $scope.copyToClipboard = function(attendee_type){
            $rootScope.showLoader(true);
            commonFactory.post('/api/get-meeting-attendees-email-by-type',{JOB_NO: $scope.JOB_NO, attendee_type : attendee_type}).success(function (response) {
                if (response.result) {
                    var emails = [];
                    angular.forEach(response.result.data, function(value, key){
                        emails[key] = value.email;
                    });

                    emails = emails.join(',');

                    var copyElement = document.createElement("textarea");
                    copyElement.style.position = 'fixed';
                    copyElement.style.opacity = '0';
                    copyElement.textContent =  emails;
                    var body = document.getElementsByTagName('body')[0];
                    body.appendChild(copyElement);
                    copyElement.select();
                    document.execCommand('copy');
                    body.removeChild(copyElement);
                    Notification.success('Email(s) copied to clipboard');
                    $rootScope.showLoader(false);
                }
            });
        }

        $scope.sendWorkInProgressReminder = function(){
            /*swal({
                title: "Are you sure",
                text: "You want to send?",
                type: "warning",
                html: true,
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) { 
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/send-work-in-progress-reminder', { JOB_NO : $scope.JOB_NO }).success(function (response) {
                        $rootScope.showLoader(false);
                        if (!response.error) {
                            Notification.success(response.result.message);
                            $scope.getDiaryAuditLogs();
                        }else{
                            Notification.error(response.result.message);
                        }
                    });
                }
            });*/
            // show modal
                var sendWIPModal = $uibModal.open({
                    templateUrl: "modules/diary/addcase/send_wip_to_resource.html",
                    controller:'sendWipToResourceController',
                    resolve: {
                        jobNo: function () {
                            return $scope.JOB_NO
                        },
                        resources: function(){
                            return $scope.diaryData.job_resources
                        },
                        caption: function(){
                            return $scope.diaryData.CAPTION
                        }
                    },
                    keyboard: false,
                    backdrop: false,
                    close: function () {
    
                    }
                });

                sendWIPModal.result.then(function (cbData) {
                }, function (cbData) {
                    if(cbData.isChanged){ 
                        $scope.reloadData();
                        $rootScope.showLoader(false); 
                    }
                });
        }




        $scope.processEmailGroup = function(JOB_NO){
            $rootScope.showLoader(true);
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/diary/addAttendee/process_email_group.html',
                controller: function($scope,commonFactory,Notification,$uibModalInstance,JOB_NO){
                    
                    $scope.formSubmit = false;
                    $scope.formData = {};
                    $scope.notFoundEmails = {};

                    $scope.submit = function(isValid){
                        
                        $scope.formSubmit = true;
                        
                        if(isValid){
                            swal({
                                title: "Are you sure",
                                text: "You want to process email group?",
                                type: "warning",
                                html: true,
                                showCancelButton: true,
                                confirmButtonColor: "#09375f",
                                confirmButtonText: "Yes",
                                cancelButtonText: "No",
                                closeOnConfirm: true,
                                closeOnCancel: true
                            },
                            function (isConfirm) {
                                if (isConfirm) { 
                                    $rootScope.showLoader(true);
                                    const NewLineRegEx = new RegExp('\n', "g");
                                    var emailsList = ''
                                    $scope.formData.emails  = $scope.formData.emails.split('\n');
                                    if($scope.formData.emails.length){
                                        $scope.formData.emails.forEach(element => {
                                            const regEx = new RegExp('<', "g");
                                            let emails = element.replace(regEx , ''); 
                                            const regEx2 = new RegExp('>', "g");  
                                             emails = emails.replace(regEx2 , '');
                                             emailsList = emailsList+emails+'\n'

                                        });
                                    }else{
                                        emailsList =  $scope.formData.emails
                                    }
                                  console.log(emailsList)
                                  
                                    commonFactory.post('/api/process-email-group', { JOB_NO : JOB_NO, emails : emailsList }).success(function (response) {
                                        $rootScope.showLoader(false);
                                        if (!response.error) {
                                            if(response.result.message){
                                                Notification.success(response.result.message);
                                            }
                                            $scope.notFoundEmails = response.result.notFoundEmails;
                                            if(!$scope.notFoundEmails.length){
                                                $scope.closeModal();
                                            }
                                        }else{
                                            Notification.error(response.result.message);
                                        }
                                    });
                                }
                            });
                        }
                    }

                    $scope.AddMultipleAtendee = function(){
                        
                            
                            $scope.formData.email = $scope.notFoundEmails;
                            $scope.formData.JOB_NO = JOB_NO;
                            $scope.formData.attendee_type = $scope.formData.new_attendee_type;
                            if(!$scope.formData.attendee_type[$scope.notFoundEmails.length-1]){
                                Notification.error("Please select attendee type.");
                            }else{ 
                                $rootScope.showLoader(true);
                                commonFactory.post('/api/meeting-attendee-info/add-multiple',$scope.formData).success(function(response){
                                $rootScope.showLoader(false);
                                if(!response.error){
                                    $scope.notFoundEmails = [];
                                    $scope.formData.new_attendee_type = null;
                                    $scope.closeModal();
                                    Notification.success(response.result.message);
                                    // $scope.cancel(true);
                                }else{
                                    Notification.error(response.result.message);
                                }
                            });
                        }
                        
                    }

                    $scope.addAttendee = function(email, attendee_type,$index){
                        if(!attendee_type){
                            Notification.error("Please select attendee type.");
                        }else{
                            $rootScope.showLoader(true);
                            var modalInstance = $uibModal.open({
                                templateUrl: 'modules/diary/addAttendee/attendee_modal.html',
                                controller: 'addAttendeeController',
                                keyboard: false,
                                backdrop: false,
                                resolve: {
                                    JOB_NO: function() {
                                        return JOB_NO;
                                    },
                                    email : function() {
                                        return email;
                                    },
                                    attendee_type : function() {
                                        return attendee_type;
                                    }
                                }
                            });

                            modalInstance.opened.then(function () {
                                $timeout(function(){
                                    $rootScope.showLoader(false);
                                },1000);
                            });

                            modalInstance.result.then(function (isAdded) {
                                if(isAdded){
                                    $scope.removeEmail($index);
                                }
                            }, function () {
                                console.log("Modal Dismissed!!!");
                            });
                        }
                    }

                    $scope.closeModal = function(){
                        $uibModalInstance.close();
                    }

                    $scope.removeEmail = function($index){
                        $scope.notFoundEmails.splice($index, 1);
                        $scope.formData.new_attendee_type[$index] = null;
                        if(!$scope.notFoundEmails.length){
                            $scope.closeModal();
                        }
                    }

                },
                keyboard: false,
                backdrop: false,
                resolve: {
                    JOB_NO: function() {
                        return JOB_NO;
                    },
                    loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([{
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }]);
                    }
                }
            });

            modalInstance.opened.then(function () {
                $timeout(function(){
                    $rootScope.showLoader(false);
                },1000);
            });

            modalInstance.result.then(function () {
                $scope.getMeetingAttendees();
                $scope.getDiaryAuditLogs();
            }, function () {
                console.log("Modal Dismissed!!!");
            });
        }
        $scope.addUser= function (attorney_id) {
            var Modal = $uibModal.open({
            templateUrl: "modules/diary/addUser/add_user.html",
            controller: 'AddUserCallByControllers',
            resolve: {
                attorney_id: function () {
                    return attorney_id;
                }
            },
            windowClass: 'full-width-model',
            keyboard: false,
            backdrop: false,
            size: 'lg',
            close: function () {

            }
        });

    }
        $scope.fillTheDisposedDate = function () {
            if ($scope.postEntryData.disposition === 'DISPOSED') {
                $scope.postEntryData.disposed_date = moment().format('L');
            }else {
                $scope.postEntryData.disposed_date = null;
            }
        };

       
        $scope.ChangeResorcePaymentValue =  function(id,value) {
           
            if(value == '0'){
            swal({
                title: "Payment Not Required",
                text: 'Enter Reasons Why Payment Not Required:',
                type: 'input',
                showCancelButton: false,
                closeOnConfirm: true,
                confirmButtonText: "Submit",
                animation: "slide-from-top"
              }, function(inputValue){
               
                if(inputValue){
                    $rootScope.showLoader(true);  
                    var postEntryData = {is_payment_required:value,id:id,payment_required_reason:inputValue} 
                    $http.post(apiUrl+'/api/updateJobResorcePayment',postEntryData).success(function(res){
                    $rootScope.showLoader(false);
                    if(res.error){
                        Notification.error(res.result.message);
                    }else{
                        Notification.success(res.result.message);
                        $scope.diaryData.job_resources.forEach(function (element, i) {
                            if(element.id == id){
                            $scope.diaryData.job_resources[i].is_payment_required = '0'
                            }
                        });
                    }
                    }).error(function(err){
                        $rootScope.showLoader(false);
                        Notification.error(err);
                    });
                }else{
                    document.getElementById("ResorcePaymentValueYes"+id).selected=true;
                    $scope.postEntryData.job_resources.forEach(function (element, i) {
                        if(element.id == id){
                        $scope.postEntryData.job_resources[i].is_payment_required = '1'
                        }
                    });
                }
              });
            }else{
                $rootScope.showLoader(true);  
                var postEntryData = {is_payment_required:value,id:id} 
                $http.post(apiUrl+'/api/updateJobResorcePayment',postEntryData).success(function(res){
                $rootScope.showLoader(false);
                if(res.error){
                    Notification.error(res.result.message);
                }else{
                    $scope.diaryData.job_resources.forEach(function (element, i) {
                        if(element.id == id){
                        $scope.diaryData.job_resources[i].is_payment_required = '1'
                        }
                    });
                    Notification.success(res.result.message);
                }
                }).error(function(err){
                    $rootScope.showLoader(false);
                    Notification.error(err);
                });
            }
      
        }

        $scope.sendNewAssignmentReceipt = function(JOB_NO){
            swal({
                title: "Are you sure?",
                text: "You about to re-send assignment receipt.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm){
                if(isConfirm){
                    $rootScope.showLoader(true);   
                    $http.post(apiUrl+'/api/send-new-assignment-receipt',{JOB_NO : $state.params.id, link: $scope.otherData.exhibit_link }).success(function(res){
                        $rootScope.showLoader(false);
                        if(res.error){
                            Notification.error(res.result.message);
                        }else{
                            Notification.success(res.result.message);
                        }
                    }).error(function(err){
                        $rootScope.showLoader(false);
                        Notification.error(err);
                    });
                }
            });
        }

        $(window).bind('keydown', function (event) {
            $scope.goToHome = false;

            if($rootScope.havePermission('diary','update',$scope.diaryData.business_unit_id)){
                if (event.ctrlKey || event.metaKey) {

                    console.log(String.fromCharCode(event.which).toLowerCase());

                    switch (String.fromCharCode(event.which).toLowerCase()) {

                        case 's':

                            event.preventDefault();

                            if ($state.current.name === "diary.editcase.step_one") {
                                $scope.saveDiarydata($scope.diaryForm.$valid);
                            }

                            if ($state.current.name === "diary.editcase.step_two") {
                                $scope.saveGeneraldata($scope.diaryForm.$valid);
                            }

                            if ($state.current.name === "diary.editcase.step_three") {
                                $scope.saveOtherdata($scope.diaryForm.$valid);
                            }

                            if ($state.current.name === "diary.editcase.step_four") {
                                $scope.saveFreecopyindication();
                            }

                            if ($state.current.name === "diary.editcase.step_five") {
                                $scope.updateBillingNotesOnly();
                            }

                            if ($state.current.name === "diary.editcase.step_six") {
                                $scope.savePostentry();
                            }

                            $scope.formSubmit = true;
                            $scope.$apply();
                            break;

                        case 'g':
                            event.preventDefault();

                            if ($state.current.name === "diary.editcase.step_one") {
                                $scope.saveDiarydata($scope.diaryForm.$valid, false, true);
                            }

                            $scope.formSubmit = true;
                            $scope.$apply();
                            break;

                        case 'p':
                            event.preventDefault();

                            if ($state.current.name === "diary.editcase.step_one") {
                                $scope.saveDiarydata($scope.diaryForm.$valid, true);
                            }

                            if ($state.current.name === "diary.editcase.step_two") {
                                $scope.saveGeneraldata($scope.diaryForm.$valid, true);
                            }

                            if ($state.current.name === "diary.editcase.step_three") {
                                $scope.saveOtherdata($scope.diaryForm.$valid, true);
                            }

                            if ($state.current.name === "diary.editcase.step_five") {
                                $scope.updateBillingNotesOnly(true);
                            }

                            if ($state.current.name === "diary.editcase.step_six") {
                                $scope.savePostentry(true);
                            }

                            break;

                        case 'd':
                            event.preventDefault();

                            $scope.goToHome = true;

                            if ($state.current.name === "diary.editcase.step_one") {
                                $scope.saveDiarydata($scope.diaryForm.$valid);
                            }

                            if ($state.current.name === "diary.editcase.step_two") {
                                $scope.saveGeneraldata($scope.diaryForm.$valid);
                            }

                            if ($state.current.name === "diary.editcase.step_three") {
                                $scope.saveOtherdata($scope.diaryForm.$valid);
                            }

                            if ($state.current.name === "diary.editcase.step_four") {
                                $scope.saveFreecopyindication();
                            }

                            if ($state.current.name === "diary.editcase.step_five") {
                                $scope.updateBillingNotesOnly();
                            }

                            if ($state.current.name === "diary.editcase.step_six") {
                                $scope.savePostentry();
                            }

                            $scope.formSubmit = true;
                            $scope.$apply();
                            break;
                            case 'q':
                                event.preventDefault();
                                if(!$scope.isSelectCase){
                                    $scope.openAddCasePopup();
                                }
                                $scope.$apply();
                                break;
        
                            case 'l':
                                event.preventDefault();
                                if(!$scope.isSelectCase){
                                    $scope.findCasePopup();
                                }
                                $scope.$apply();
                                break;
                    }
                }    
            }
            
        });



        $scope.changeProductionQueue = function (production_queue,is_ready_to_release_to_bu){
            // validate production queue
            if(production_queue=='CBU' && $scope.diaryData.business_unit_id == $scope.diaryData.covering_business_unit_id){
                Notification.error('Please Select Covering Businessunit Other Then Businessunit');
                return false;
            }
            if(production_queue=='BU' && $scope.diaryData.business_unit_id != $scope.diaryData.covering_business_unit_id){
                Notification.error('Please Select Covering Businessunit Same As Businessunit');
                return false;
            }
            $scope.diaryData.production_queue=production_queue;
            $scope.diaryData.is_ready_to_release_to_bu=is_ready_to_release_to_bu;
            // only update if metch with original
            if($scope.diaryOriginalData.business_unit_id == $scope.diaryData.business_unit_id){
                $scope.updateDiaryAfterChangeProductionQueueOrCoveringBu();
            }
        }

        $scope.changeCoveringBu = function(){
            if($scope.diaryData.covering_business_unit_id == $scope.diaryData.business_unit_id){
                $scope.diaryData.production_queue='BU';
                $scope.diaryData.is_ready_to_release_to_bu ='0';
            }
            else{
                $scope.diaryData.production_queue='CBU';
                $scope.diaryData.production_queue_toggle =false;
            }
            if($scope.diaryData.business_unit_id == $scope.diaryOriginalData.business_unit_id){
                $scope.updateDiaryAfterChangeProductionQueueOrCoveringBu();
            }
            
        }

        $scope.updateDiaryAfterChangeProductionQueueOrCoveringBu = function (){
            $rootScope.showLoader(true);

            commonFactory.post('/api/save-covering-business-unit/'+$state.params.id, { covering_business_unit_id : $scope.diaryData.covering_business_unit_id ,production_queue:$scope.diaryData.production_queue,is_ready_to_release_to_bu:$scope.diaryData.is_ready_to_release_to_bu })
            .success(function (response) {
                if (response && response.result) {
                    $rootScope.showLoader(false);
                    $scope.getDiaryAuditLogs();
                    Notification.success(response.result.message);
                } else {
                    $rootScope.showLoader(false);
                }
            })
            .error(function (err) {
                $rootScope.showLoader(false);
                Notification.error(err.result.message);
            });
        }

        $scope.dtInstanceInvoices = {};
        $scope.dtInstanceCallback = function(dtInstance) {
            $scope.dtInstanceInvoices=dtInstance;
        }
        $scope.reloadInvoicesData = function() {
            $scope.dtInstanceInvoices.rerender();
        };


        $scope.createdRow = function (row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };


        $scope.dtInvoicesOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
            data.job_no = $scope.JOB_NO;
            commonFactory.post('/api/job-notes-datatable', data).success(function (res) {
                if (res.error) {
                    $scope.reloadDatacheck();
                }
                else {
                    callback(res);
                }
            }).error(function (err) {
                if (err.error !== "token_not_provided") {
                    $scope.reloadDatacheck();
                }
            });
        })
        .withOption('processing', true)
        .withLanguage({
            "sProcessing": "<img src='img/loading_bar.gif'/>"
        })
        .withOption('serverSide', true)
        .withOption('searchDelay', 500)
        .withOption('order', [1, 'desc'])
        .withPaginationType('simple_numbers')
        .withDisplayLength(10)
        .withOption('lengthMenu', [
            [5, 10, 25, 50],
            [5, 10, 25, 50]
        ])
        .withOption('createdRow', $scope.createdRow)
        .withOption('headerCallback', function (header) {
            if (!$scope.headerCompiled) {
                $compile(angular.element(header).contents())($scope);
            }
        });

        $scope.dtInvoicesColumns = [];
        $scope.dtInvoicesColumns.push(
            DTColumnBuilder.newColumn('notes').withOption('defaultContent', '').withTitle('Note').withOption('width','60%'),
            DTColumnBuilder.newColumn('note_date').withOption('defaultContent', '').withTitle('Note Date').renderWith(function (data, type, full, meta) {
                if(data) {
                    var dte = moment(data).format('MM/DD/YYYY');
                    if(dte == 'Invalid date'){
                        return '-';
                    }else{
                        return dte;
                    }
                } 
                else {
                    return '-';
                }

            }),
            DTColumnBuilder.newColumn('note_type').withOption('defaultContent', '').withTitle('Note Type').renderWith(function (data, type, full, meta) {
                if(data=='job_notes') {
                   return 'Job Note';
                }
                else if(data=='order_notes'){
                    return 'Order Note';
                } 
                else {
                    return '-';
                }

            }),
            DTColumnBuilder.newColumn('create_user.name').withOption('defaultContent', '').withTitle('Created By'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().withOption('searchable',false)
            .renderWith(function(data, type, full, meta) {
                return '<button class="btn btn-primary btn-circle" ng-click="addExtraNote('+full.id+')" uib-tooltip="Edit"  >' +
                        '<i class="fa fa-pencil"></i>' +
                        '</button>&nbsp;&nbsp'+
                        '<a class="btn btn-circle btn-danger" ng-click="deleteNote('+full.id+')" ><i class="fa fa-trash"></i></a>';

            })
        );

        $scope.deleteNote = function(id){

            swal({
                    title: "Are you sure ?",
                    text: "You are going to delete the note.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Do it",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.delete('/api/extra-job-note/'+id)
                            .success(function(response) {
                                if (response && response.result) {
                                    Notification.success(response.result.message || 'Note deleted successfully.');
                                    $scope.reloadInvoicesData();
                                    $rootScope.showLoader(false);
                                }else{
                                    Notification.error(response.result.message || 'Something went wrong!');
                                    $rootScope.showLoader(false);
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                            });
                    }
                });
        };


        $scope.addExtraNote =function(id=null){
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/diary/addcase/add_extra_notes_modal.html",
                controller: 'addExtraNotesController',
                resolve: {
                    JOB_NO: function () {
                        return $scope.JOB_NO;
                    },
                    id: function(){
                        return id
                    },
                    inv_no: function(){
                        return null
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

            invoiceModal.result.then(function (cbData) {
            }, function (cbData) {

                if(cbData.isChanged){
                    $scope.reloadInvoicesData();
                    $rootScope.showLoader(false);
                }

            });
        }

        /**
         *function updateProductionNotes
         * @param
         * @description This function used to update the production notes, because its available in witness and post entry tabs
         */
         $scope.updateProductionNotes = function () {

            var job_id = JOB_NO ? JOB_NO : $state.params.id;
            commonFactory.put('/api/diary/' + job_id, { productionnote: $scope.generalData.productionnote })
                .success(function (response) {
                    if (response && response.result && response.result.diary_update) {
                        $rootScope.showLoader(false);
                        Notification.success("Production note updated successfully");
                        //get refreshed audit logs for diary
                        $scope.getDiaryAuditLogs();

                        if ($scope.goToHome == true) {
                            $state.go('index.home');
                        }
                    } else {
                        $rootScope.showLoader(false);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                    console.log(err);
                });
        };

        $scope.getAttorneys = function (id, callback) {
            var data = {};
            data.firm_id = id;
            $rootScope.showLoader(true);
            $timeout(function () {
                commonFactory.post('/api/attorneybyfirm', data)
                    .success(function (response) {
                        if (response) {
                            if (response && response.result && response.result.attorney_data) {
                                $scope.attorney = response.result.attorney_data || '';
                                $scope.attorneyData = angular.copy($scope.attorney);

                                if (typeof callback == "function") {
                                    // console.log("TRACK-1");
                                    callback($scope.attorney);
                                }
                            }
                        }
                        $rootScope.showLoader(false);
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            }, 1000);
        };

        /**
         *@function addAttorney
         */
         $scope.addAttorney = function(firmId) {

            $rootScope.showLoader(true);
            var addAttorneyModal = $uibModal.open({
                templateUrl: "modules/addattorneytofirm/add_attorney_modal.html",
                data: {pageTitle: 'Add Attorney'},
                controller: 'addattorneytofirmController',
                keyboard: false,
                backdrop: false,
                size: 'md',
                close: function () {
                    console.log("function called");
                },
                resolve : {
                    attorneyFirmId : function(){
                        return firmId || null;
                    },
                    email : function(){
                        return null;
                    }
                }
            });


            addAttorneyModal.result.then(function (attorneyData) {
                //console.log(is_invoice_printed,invoices);
                if (!angular.equals({}, attorneyData)) {
                    var attorney_id =angular.isObject(attorneyData.attorney_id)? attorneyData.attorney_id.id : attorneyData.attorney_id;
                    $scope.getAttorneys(attorney_id, function (attorneysData) {
                        $scope.postEntryData.attname_id = attorneyData.id || null;
                    });
                }
            }, function () {
                console.log("Modal Dismissed!!!");
            });

        };

        // Job Sheet
        $scope.jobSheetUrl = null;
        $scope.spinnerLoader= false;
        $scope.jobSheetInIt = function() {
            $scope.setPageTitle();
            $scope.spinnerLoader= true;
            const enscript  = encodeURIComponent("job_sheet.php?viewFrom=backend&job_no="+$scope.JOB_NO);
            const auth_user = $rootScope.auth_user;
            // $scope.jobSheetUrl = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;
            $scope.jobSheetUrl = $sce.trustAsResourceUrl($rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript);  
        }

        $scope.iframeLoadedCallBack = function() {
            $scope.spinnerLoader= false;
            $scope.$apply();
            console.log("Job Sheet Loading Completed");
        }

        /**
         *@function editAttorney
         */
         $scope.editAttorney = function(attorneyId){

            $rootScope.showLoader(true);
            var editAttorneyModal = $uibModal.open({
                templateUrl: "modules/addattorneytofirm/add_attorney_modal.html",
                data: {pageTitle: 'edit Attorney'},
                controller: 'editattorneytofirmController',
                keyboard: false,
                backdrop: false,
                size: 'md',
                close: function () {
                    console.log("function called");
                },
                resolve : {
                    attorneyId : function(){
                        return attorneyId || null;
                    }
                }
            });


            editAttorneyModal.result.then(function (attorneyData) {
                //console.log(is_invoice_printed,invoices);
                if (!angular.equals({}, attorneyData)) {
                    var attorney_id =angular.isObject(attorneyData.attorney_id)? attorneyData.attorney_id.id : attorneyData.attorney_id;
                    $scope.getAttorneys(attorney_id, function (attorneysData) {
                        $scope.postEntryData.attname_id = attorneyId || null;
                    });
                }
            }, function () {
                console.log("Modal Dismissed!!!");
            });

        };

        $scope.compileDatatable = function (row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.dtInstance = {};

            $scope.reloadData = function() {
                $scope.dtInstance.rerender();
            };
            $scope.resetSearch = function(){
                $scope.dtInstance.DataTable.state.clear();
                $scope.reloadData();
            };
            $scope.dtOptions = DTOptionsBuilder.newOptions()
                .withDataProp('data')
                .withOption('ajax', function (data, callback, settings) {

                    commonFactory.showDataTable('/api/getEmailLogs',{data:data,job_no:$state.params.id})
                        .success(function(res) {
                            if (res) {
                                callback(res);
                            }
                        })
                        .error(function(err) {
                        });
                })
                .withOption('stateSave',true)
                .withOption('processing', true)
                .withOption('serverSide', true)
                .withOption('createdRow', $scope.compileDatatable)
                .withLanguage({
                    "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                    "sProcessing": "<img src='img/loading_bar.gif'/>",
                    "sSearch": "",
                })
                .withPaginationType('simple_numbers')
                .withOption('searchDelay', 500)
                .withOption('order', [3, 'desc'])
            $scope.dtColumns = [

                DTColumnBuilder.newColumn('subject').withOption('defaultContent', '-').withTitle('Template').withOption('searchable',false).withOption('width','10%').renderWith(function(data, type, full, meta){
                    var row = '';

                    if(full.is_have_attachment=='1'){
                        row += '<a href="javascript:void(0);"  ng-click="getEmailLogAttachmentUrl('+full.id+')" ><i uib-tooltip="Document was included." class="fa fa-paperclip" aria-hidden="true"></i></a> &nbsp;';
                        // row += '<span>Click me!</span> &nbsp;';
                    }
                    let subject = '<a href="javascript:void(0);"  ng-click="viewHtml('+full.id+')" >'+data+'</a>'
                    return row += subject;
                }),
                DTColumnBuilder.newColumn('created_by').withOption('defaultContent', '-').withTitle('Method').withOption('searchable',false).withOption('width','10%').renderWith(function(data, type, full, meta){
                    let method = 'Email';

                    if(full.method == 'sms'){
                        method = 'SMS';
                    }
                    return method;
                }),
                DTColumnBuilder.newColumn('created_by').withOption('defaultContent', '-').withTitle('Sent By').withOption('searchable',false).withOption('width','10%').renderWith(function(data, type, full, meta){
                    let created_by = 'By System';

                    if(full.created_by){
                        created_by = full.created_by.name;
                    }
                    return created_by;
                }),
                DTColumnBuilder.newColumn('received_by').withOption('defaultContent', '-').withTitle('Received By').renderWith(function (data, type, full, meta) {
                    let html = '';
                    html += '<span>' + data + '</span> ';
                    var textclass = 'warning';
                        if (full.last_event) {
                            switch (full.last_event.event) {
                                case 'delivered':
                                case 'transmitting_page':
                                    textclass = 'warning'
                                    break;
                                case 'open':
                                case 'fax_completed':
                                    textclass = 'success'
                                    break;
                                case 'bounce':
                                    textclass = 'danger'
                                    break;
                                case 'spamreport':
                                case 'retry_scheduled':
                                    textclass = 'secondary'
                                    break;
                                    break;
                                default:
                                    textclass = 'warning'
                                    break;
                            }
                            html += '<td><a class="text-' + textclass + '" href="javascript:void(0);" ng-click="viewEventLog(' + full.id + ')"><b><i class="fa fa-eye"></i></b></a><td>';
                        }
                        return html;
                }).withOption('searchable',false).withOption('width','10%'),
                // DTColumnBuilder.newColumn('cc_email').withOption('defaultContent', '-').withTitle('CC Email').withOption('searchable',false).withOption('width','10%'),
                DTColumnBuilder.newColumn('created_at').withOption('defaultContent', '-').withTitle('Sent Date').withOption('searchable',false).renderWith(function(data, type, full, meta){
                    if(data){

                        return created_at = moment(data).format('MM/DD/YYYY hh:mm A');

                    }else{
                        return '-';
                    }

                }).withOption('width','15%'),
            ];

            $scope.viewEventLog = function(id){

                var viewEventModal = $uibModal.open({
                    templateUrl: "modules/accountReceivableNotes/ar_notes_email_events.html",
                    controller : function($scope, $uibModalInstance,$http,apiUrl){
                        $scope.closePopupWindow = function(){
                            $uibModalInstance.close();
                        }
                        $scope.getDateFormat= function(date){
                            var dte = moment(date).format('MM/DD/YYYY hh:mm:ss A');
                            if (dte == 'Invalid date') {
                                return '-';
                            } else {
                                return dte;
                            }
                        }
                        $scope.getEmailEvent = function(){
                            $rootScope.showLoader(true);
                            $http.get(apiUrl + '/api/get-email-events/'+id)
                                .then(function(response) {
                                    $rootScope.showLoader(false);
                                    if(!response.data.error){
                                        $scope.events=response.data.results.events;
                                    }else{
                                        Notification.error(response.data.message);
                                    }
                                });
                        }
                        $scope.getEmailEvent();
                    },
                    resolve: {
                        id: function () {
                            return id;
                        }
                    },
                    windowClass: '',
                    keyboard: false,
                    backdrop: false,
                    size: 'lg',
                    close: function () {

                    }
                });
            }

            $scope.openAddCasePopup = function(){
                $scope.noResultscase = false;
                angular.element(document.querySelector('.typeahead-dropdown div[ng-show="noResultscase"]')).addClass('ng-hide');
                
                $rootScope.showLoader(true);
                $scope.addCasePopup =true;
                var addCaseModel = $uibModal.open({
                    templateUrl: "modules/cases/add_case_popup.html",
                    data: {pageTitle: 'Add Case'},
                    controller: 'caseController',
                    windowClass: 'full-width-model',
                    keyboard: false,
                    backdrop: false,
                    size: 'lg',
                    close: function () {
                        console.log("function called");
                    },
                    resolve : {
                        $parent : function(){
                            return $scope;
                        },
                    }
                });
                addCaseModel.opened.then(function () {
                    $rootScope.showLoader(false);
                });
    
                addCaseModel.result.then(function (cbData) {
                }, function (cbData) {
    
                    if(cbData.isChanged){
                        $scope.diaryData.job_case_id = cbData.case;
                        $scope.isSelectCase = true;
                        $scope.diaryData.CAPTION = cbData.case.CAPTION;
                    }
    
                });
            }

            $scope.findCasePopup = function(){

                $scope.addCasePopup =true;
    
                $rootScope.showLoader(true);
    
                var findCaseModel = $uibModal.open({
                    templateUrl: "modules/cases/find_case_popup.html",
                    data: {pageTitle: 'Add Case'},
                    controller: 'findCaseController',
                    windowClass: 'full-width-model',
                    keyboard: false,
                    backdrop: false,
                    size: 'lg',
                    close: function () {
                        console.log("function called");
                    },
                    resolve : {
                        $parent : function(){
                            return $scope;
                        },
                    }
                });
    
                findCaseModel.opened.then(function () {
                    $rootScope.showLoader(false);
                });
    
                findCaseModel.result.then(function (cbData) {
                }, function (cbData) {
    
                    if(cbData.isChanged){
                        $scope.diaryData.job_case_id = cbData.case;
                        $scope.isSelectCase = true;
                        $scope.diaryData.CAPTION = cbData.case.CAPTION;
                        $scope.diaryData.location_id =parseInt(cbData.case.location_id);                   
                        $scope.diaryData.case_type_id =parseInt(cbData.case.case_type_id);                   
                        $scope.diaryData.IndexNumber =cbData.case.IndexNumber;                
                    }
    
                });
            }

            /*
                Get attachment url
             */
            $scope.getEmailLogAttachmentUrl = function(id){
                if(id){
                    $http.get(apiUrl + '/api/get-email-log-attachment-url', {
                        params: {
                            id: id,
                        }
                    }).then(function(response) {
                        if(!response.data.error){
                            window.open(response.data.result,'_blank');
                        }else{
                            Notification.error(response.data.message);
                        }
                    });
                }
            }


            /*
                Get attachment url
             */
            $scope.viewHtml = function(id){
                if(id){

                    var viewEventModal = $uibModal.open({
                        templateUrl: "modules/diary/addcase/email_content.html",
                        controller : function($scope, $uibModalInstance,$http,apiUrl){
                            $scope.html = '';
                            $scope.closePopupWindow = function(){
                                $uibModalInstance.close();
                            }
                            $scope.getDateFormat= function(date){
                                var dte = moment(date).format('MM/DD/YYYY hh:mm:ss A');
                                if (dte == 'Invalid date') {
                                    return '-';
                                } else {
                                    return dte;
                                }
                            }
                            $scope.getEmailEvent = function(){
                                $rootScope.showLoader(true);
                                $http.get(apiUrl + '/api/get-email-html', {
                                    params: {
                                        id: id,
                                    }
                                }).then(function(response) {
                                    $rootScope.showLoader(false);
                                    if(!response.data.error){
                                        var doc = document.getElementById('email_html').contentWindow.document;
                                        doc.open();
                                        doc.write(response.data.result.html);
                                        doc.close();
                                    }else{
                                        $rootScope.showLoader(false);
                                        Notification.error(response.data.message);
                                    }
                                });
                            }
                            $scope.getEmailEvent();
                        },
                        resolve: {
                            id: function () {
                                return id;
                            }
                        },
                        windowClass: 'full-width-model',
                        keyboard: false,
                        backdrop: false,
                        size: 'lg',
                        close: function () {

                        }
                    });

                }
            }

            }])

    .filter('keys', function () {
        return function (obj) {
            return obj.model.foundFromElaw;
        };
    });

    angular.module('deitz').directive('remove_requested_resorces', function() {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                scope.$watch(attrs.ngModel, function() {
                    element.trigger('chosen:updated');
                });
    
              
    
                element.on('change', function(event, params) {
                    if (!params.selected) {
                        scope.$apply(function() {
                            scope.onremove_requested_resorces(params.deselected);
                        });
                    }
                });
            }
        };
    });
   
