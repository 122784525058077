angular.module('deitz').controller('firmsController', [
    '$scope', '$rootScope', 'commonFactory', 'firmFactory', 'Notification', '$stateParams', '$uibModal', '$uibModalInstance', 'invoiceFactory', 'cashReceiptFactory', '$window', '$timeout', '$state', '$http', 'apiUrl', 'attorneyFirmId','DTOptionsBuilder','DTColumnBuilder', '$compile','activeTab',
    function(
        $scope, $rootScope, commonFactory, firmFactory, Notification, $stateParams, $uibModal, $uibModalInstance, invoiceFactory, cashReceiptFactory, $window, $timeout, $state, $http, apiUrl, attorneyFirmId,DTOptionsBuilder,DTColumnBuilder,$compile,activeTab
    ) {

        $scope.formData = {};
        $scope.firmData = {};
        $scope.preferenceFormData = {};
        $scope.commissionForm = {};
        $scope.firm = {};
        $scope.frequency = {
            email_fax : 'ef_set_frequency',
            statement_invoice: 's'
        };
        
        if(!angular.equals({}, $uibModalInstance)) {
            if(activeTab){
                $scope.activeTab = activeTab;
            }
            else{
                $scope.activeTab ='tab-1';
            }
        } 
        $scope.attorneyFirmId = attorneyFirmId || $state.params.id;
        // use $scope.attorneyFirmId instead of $state.params.id because edit firm is used in popup
        $scope.isEditMode=($scope.attorneyFirmId)? true : false;
        $scope.validations = firmFactory.validations;
        $scope.messages = firmFactory.messages;
        $scope.title = 'Edit Firm';
        $scope.signupForm = false;
        $scope.typeStatus =null;
        $scope.isRateChanged = false;
        $scope.currentState = $state.current.name;
        $scope.mergeAttorneys = {};
        $scope.attorneys = [];

        // Open the EBT Tab in modal view.
        $scope.openEBTTab = true;
        $scope.isCard = false ;
        $scope.cardData = {}; 
        $scope.rates = [];
        $scope.LevelOfPayment_arr = [];//'[{ id: 0, label: '0' }, { id: 1, label: '1' }, { id: 3, label: '3' }, { id: 4, label: '4' }, { id: 5, label: '5' }, { id: 6, label: '6' }, { id: 7, label: '7' }, { id: 8, label: '8' }, { id: 9, label: '9' }];
        $scope.firm_designation_arr, $scope.firm_group_arr, $scope.firm_sort1_arr, $scope.firm_sort2_arr  = [];
        $scope.turn_around = [];
        $scope.rate_locked = true;
        $scope.firmCalculation = {
            grossProfit : 0,
            avgMonthsTotalBilled : 0,
            trailing12MonthsTotalBilled : 0
        };
        $scope.translator = [{ id: null, company_name: 'Please select Translator' }];
        $scope.formData.shipping_diff = 'N';
        $scope.$watch('formData.shipping_diff', function (newValue, oldValue) {
            if (newValue === 'N'){
                $scope.formData.shipping_address1 = $scope.formData.address1
                $scope.formData.shipping_address2 = $scope.formData.address2
                $scope.formData.shipping_city = $scope.formData.city
                $scope.formData.shipping_state = $scope.formData.state
                $scope.formData.shipping_zip = $scope.formData.zip
            }
        });
        $scope.$watch('formData.address1', function (newValue, oldValue) {
            $scope.changeValues('shipping_address1', newValue);
        });
        $scope.$watch('formData.address2', function (newValue, oldValue) {
            $scope.changeValues('shipping_address2', newValue);
        });
        $scope.$watch('formData.city', function (newValue, oldValue) {
            $scope.changeValues('shipping_city', newValue);
        });
        $scope.$watch('formData.state', function (newValue, oldValue) {
            $scope.changeValues('shipping_state', newValue);
        });
        $scope.$watch('formData.zip', function (newValue, oldValue) {
            $scope.changeValues('shipping_zip', newValue);
        });

        $scope.changeValues = function (key, newValue) {
            if ($scope.formData.shipping_diff === 'N') {
                $scope.formData[key] = newValue;
            }
        }
        $scope.$on('$stateChangeSuccess', function(event, toState) {
            $scope.currentState = toState.name;
        });

        //Checking user role.
        if($rootScope.havePermission('allow_access_without_password','allow.attorney.firm.records.and.reporting.account.executive')){
            $scope.salesperson_locked = false;
        }else{
            $scope.salesperson_locked = true;
        }

        $scope.source_locked = true;
        if($rootScope.havePermission('allow_access_without_password','allow.attorney.firm.source')){
            $scope.source_locked = false;
        }
        
        //get all BussinesUnit for diary
        if (!($scope.BusinessUnitsList && $scope.BusinessUnitsList.length)) {
            $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
                $scope.BusinessUnitsList = response.data.result.data;
            });   
        }

        /*Use for get client level */
        $http.get(apiUrl + '/api/get_client_level').then(function(response) {            
           $scope.LevelOfPayment_arr = response.data.data;            
        });
        /*Use for get firm Designation, group and sort */
        $http.get(apiUrl + '/api/get_designation_group_sort').then(function(response) {    
            $scope.firm_designation_arr = response.data.firm_designation;
            $scope.firm_group_arr = response.data.firm_group;
            $scope.firm_sort1_arr = response.data.firm_sort1;
            $scope.firm_sort2_arr = response.data.firm_sort2;            
         });

        //get Turn Around list 
        commonFactory.get('/api/all-active-turn-around').success(function (response) {
            if (response.result && response.result.turnAround) {
                $scope.turn_around = response.result.turnAround.map(function (item) {
                    return {
                        id: item.days,     
                        label: item.name 
                    };
                }) || [];
            }
        }).error(function (err) {});


        commonFactory.post('/api/getInsuranceCompany',{fields:['id', 'CompanyName', 'Address1', 'City']})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.InsuranceCompanyList = response.result.data;
            }
            $rootScope.showLoader(false);
        });

        $scope.grades_arr = [];
        /*Use for get grades */
        $http.get(apiUrl + '/api/get_grades').then(function(response) {            
            $scope.grades_arr = response.data.data;            
        });
       
        $http.get(apiUrl + '/api/rates/getFirmRateList').then(function(response) { 
            $scope.RateList = response.data.result.data;  
        });

        
        $scope.getFirmSalesmanTagRequestByFirm = function() {
            $http.post(apiUrl + '/api/getFirmSalesmanTagRequestByFirm',{id:$scope.attorneyFirmId}).then(function(response) { 
                    
                $scope.FirmSalesmanTagRequest = response.data.result.data; 
                if($scope.FirmSalesmanTagRequest) {
                    if($scope.FirmSalesmanTagRequest.bu_manager_status == 1){
                        $scope.FirmSalesmanTagRequest.bu_manager_css_id = 'activerow'
                    }else if($scope.FirmSalesmanTagRequest.bu_manager_status == 2){
                        $scope.FirmSalesmanTagRequest.bu_manager_css_id = 'activerow2'
                    }else{
                        $scope.FirmSalesmanTagRequest.bu_manager_css_id = ''
                    }

                    if($scope.FirmSalesmanTagRequest.sales_manager_status == 1){
                        $scope.FirmSalesmanTagRequest.sales_manager_css_id = 'activerow'
                    }else if($scope.FirmSalesmanTagRequest.sales_manager_status == 2){
                        $scope.FirmSalesmanTagRequest.sales_manager_css_id = 'activerow2'
                    }else{
                        $scope.FirmSalesmanTagRequest.sales_manager_css_id = ''
                    }

                    if($scope.FirmSalesmanTagRequest.salesperson_status == 1){
                        $scope.FirmSalesmanTagRequest.salesperson_css_id = 'activerow'
                    }else if($scope.FirmSalesmanTagRequest.salesperson_status == 2){
                        $scope.FirmSalesmanTagRequest.salesperson_css_id = 'activerow2'
                    }else{
                        $scope.FirmSalesmanTagRequest.salesperson_css_id = ''
                    }
                }
            });
        };
        $scope.updateSalesmanTagRequestStatus = function(id,type = null) {
            $scope.updateStatus = null;
            $scope.typeStatus = type;
           

            console.log(type,'type');
            swal({
                title: "Please Select Status",
                text:'<div class="sa-button-container">  <button class="confirm" id="btnAgree">Agree</button> <button class="cancel" id="btnDiscuss" tabindex="2" style="display: inline-block; box-shadow: none;">Discuss</button> <br> <button class="cancel" tabindex="2" style="display: inline-block; box-shadow: none;">Close</button> <div class="sa-confirm-button-container"> <div class="la-ball-fall"> <div></div> <div></div> <div></div> </div> </div> </div>'                ,
                html: true,
                showConfirmButton: false
            });
             
              
              $(document).on('click', "#btnAgree", function() {
                if(!$scope.updateStatus){
                    console.log( $scope.typeStatus,'type');
                    $scope.updateStatus = 'Agree';
                     $rootScope.showLoader(true); 
                    $http.post(apiUrl+'/api/updateSalesmanTagRequestStatus', {id:id,type: $scope.typeStatus,status:1}).success(function(res){
                        $scope.getFirmSalesmanTagRequestByFirm();
                        $rootScope.showLoader(false);
                        
                        if(!res.error){
                            Notification.success(res.result.message);
                           
                        }else{
                            Notification.error(res.result.message);
                        }
                        if(res.result.data){
                            $scope.getFirmAuditLogs();
                            $scope.formData.reporting_sales_ids = res.result.data;
                            if(res.result.sales_rep_dte_assigned){
                                $scope.formData.sales_rep_dte_assigned = res.result.sales_rep_dte_assigned;
                            }
                        }
                    });
                }
              });
              
              $(document).on('click', "#btnDiscuss", function() {
                if(!$scope.updateStatus){
                    console.log( $scope.typeStatus,'type');
                    $scope.updateStatus = 'Discuss';
                     $rootScope.showLoader(true); 
                    $http.post(apiUrl+'/api/updateSalesmanTagRequestStatus', {id:id,type: $scope.typeStatus,status:2}).success(function(res){
                        $scope.getFirmSalesmanTagRequestByFirm();
                        $rootScope.showLoader(false);
                        if(!res.error){
                            Notification.success(res.result.message);
                           
                        }else{
                            Notification.error(res.result.message);
                        }
                    });
                }
               
              });
          
            // $http.post(apiUrl + '/api/getFirmSalesmanTagRequestByFirm',{id:id,type:type,}).then(function(response) { 
                    
            //     $scope.FirmSalesmanTagRequest = response.data.result.data;  
            // });
        };
        if($scope.isEditMode){
            $scope.getFirmSalesmanTagRequestByFirm();
        }

        $scope.goto_rate = function() {
            if(angular.equals({}, $uibModalInstance)) {
                $state.go('firms.manage.step_two', { id: $scope.attorneyFirmId });
            }else{
                $scope.activeTab = 'tab-2';
            }
        };

        $scope.goto_other = function() {
            if ($scope.isRateChanged && (!$scope.formData.rate_change_reason || $scope.formData.rate_change_reason == '')) {
                document.getElementById('rate_change_reason').focus();
                swal({
                        title: 'Firm rates changed - please provide a reason.',
                        html: true,
                        type: "info",
                        confirmButtonColor: "#09375f",
                        confirmButtonText: "Ok",
                        closeOnConfirm: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            return;
                        }
                    });
                return false;
            }
            if(angular.equals({}, $uibModalInstance)) {
                $state.go('firms.manage.step_three', { id: $scope.attorneyFirmId });
            }else{
                $scope.activeTab = 'tab-3';
            }
        };
        $rootScope.redirectDepoEdit = function(email){
           
            var enscript = encodeURIComponent('attorney_edit.dtop?email='+email);
    
            var auth_user = $rootScope.auth_user;
            var givenUrl = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;
    
            $window.open(givenUrl, '_blank');
        };
        $rootScope.redirectDepoFirmEdit = function(key){
           
            var enscript = encodeURIComponent('attorneyoffice_edit.dtop?key='+key);
    
            var auth_user = $rootScope.auth_user;
            var givenUrl = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;
    
            $window.open(givenUrl, '_blank');
        };
        $rootScope.redirectDepoLaunch = function(uid){
           
            var enscript = 'launch_user.dtop?uid='+uid+'&actionfrom=backend';
            var auth_user = $rootScope.auth_user;

            $http.post(apiUrl + '/api/auth-user-get-token', {uid : uid}).then(function(response) { 
                if(response.data.status){
                    var givenUrl = $rootScope.depoHost +"/"+enscript+"&access_token="+response.data.token;
                    $window.open(givenUrl, '_blank');
                }else{
                    Notification.error('Something went wrong!'); 
                }
            });
    
        };
        $scope.goto_address = function() {
            if(angular.equals({}, $uibModalInstance)) {
                $state.go('firms.manage.step_one', { id: $scope.attorneyFirmId });
            }else{
                $scope.activeTab = 'tab-1';
            }
        };

        $scope.rates = [
            {
                id : "R",
                name : "CLIENT'S DEFAULT"
            },
            {
                id : "O",
                name : 'OTHER'
            }
        ];

        $scope.insuranceRates = [];
         //Getting all Translator.
         $scope.refreshTranslator = function () {
            $rootScope.showLoader(true);
            commonFactory.get('/api/translators')
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response.result && response.result.translator) {
                        $scope.translator = $scope.translator.concat(response.result.translator || []);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        };
        $scope.refreshTranslator();
        // Getting all sources from source table.
        $http.get(apiUrl + '/api/get_source').then(function(response) { 
            $scope.attorneySources=response.data.data;       
        }); 
        // Getting all statement frequency
        $http.get(apiUrl + '/api/get-statement-codes').success(function(res){
            $scope.statement_codes = res.data;
        });
        // Getting all credit cards of this firm
        $http.get(apiUrl + '/api/get-credit-cards/'+$scope.attorneyFirmId).success(function(res){
            $scope.credit_cards = res.result;
        });
        // Getting all terms 
        $http.post(apiUrl + '/api/getAllterms').success(function(res){
            $scope.firm_terms = res.data;
        });
        // Getting all rates from rates table.
        commonFactory.get('/api/rate/getActiveRate')
            .success(function(response) { 
                if (response.result && response.result.rates) {
                    $scope.insuranceRates = response.result.rates;
                }
            })
            .error(function(err) {});

        // Getting all rates card group
        $scope.refreshRateCardGroup = function () {
            $rootScope.showLoader(true);
            commonFactory.get('/api/rateCardGroups').success(function (response) {
                if (response.result) {
                    $scope.rateCardGroups = response.result.rateCardGroup;
                }
                $rootScope.showLoader(false);
            }).error(function (err) {
                $rootScope.showLoader(false);
            });
        }
        $scope.refreshRateCardGroup();

        $scope.$watch('formData.rate_card_group', function (newValue, oldValue) {
            if ((newValue && oldValue) && (newValue.length < oldValue.length)) {
                let diff = oldValue.filter((val) => !newValue.some((e) => e === val));
                let newArray = $scope.insuranceRates.filter((insuranceRate) => diff.indexOf(insuranceRate.rate_card_group_id) !== -1)
                newArray = newArray.map(rate => rate.InsuranceRateID);
                $scope.formData.attorney_rate_details = $scope.formData.attorney_rate_details.filter(function (el) {
                    return newArray.indexOf(el) < 0;
                });
            }
        });

        $scope.groupChange = function () {
            // Get rates based on rate group
            commonFactory.post('/api/rate/getRateGroupActiveRate', {rate_card_group: $scope.formData.rate_card_group})
                .success(function (response) {
                    if (response.result && response.result.rates) {
                        const rates = response.result.rates.map(rate => rate.InsuranceRateID);
                        $scope.formData.attorney_rate_details = [...new Set([...$scope.formData.attorney_rate_details, ...rates])];
                    }
                })
                .error(function (err) {
                });

        };

        // Get the auto Partner
        commonFactory.get('/api/config/get-auto-partner').success(function (response) {
            if (response.result) {
                $scope.auto_partners = response.result;
            }
        });

        commonFactory.get('/api/connection-methods')
        .success(function(response) {
            if(!response.error){
                $scope.connectionMethods = response.result.resources;
            }
        });
        
        // Getting all Collectors 
        $http.get(apiUrl + '/api/collector').success(function(res){
            $scope.collectors = res.result;
        });
        
        // Getting all invoice and transcript handling type 
        $http.get(apiUrl + '/api/invoice-and-transcript-handling').success(function(res){
            $scope.invoice_and_transcript_handling_methods = res.result;
        });

        $scope.searchClaimRepName = function (val) {
            return $http.post(apiUrl + '/api/search-claimrep-name', { query_string: val }).then(function (response) {
                return response.data.result.map(function (item) {
                    return item;
                });
            });
        };

        $scope.refreshFirmtype = function () {
            $rootScope.showLoader(true);
            commonFactory.get('/api/firmTypes').success(function (response) {
                if (response.result) {
                    $scope.firmTypes = response.result.firmtype;
                }
                $rootScope.showLoader(false);
            }).error(function (err) {
                $rootScope.showLoader(false);
            }); 
        };
        
        $scope.refreshFirmtype();

        $scope.firmtypeModal = function (id) {
            $rootScope.showLoader(true);
            if (id) {
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/code_tables/firm_type/add/firmtypeModal.html',
                    controller: 'editfirmtypeController',
                    resolve: {
                        fromModal: function () {
                            return true;
                        },
                        firmtypeId: function () {
                            return id;
                        }
                    }
                });
            }
            else {
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/code_tables/firm_type/add/firmtypeModal.html',
                    controller: 'addfirmtypeController',
                    resolve: {
                        fromModal: function () {
                            return true;
                        }
                    }
                });
            }
            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });

            modalInstance.result.then(function (id) {
                if (id) {
                    $scope.refreshFirmtype();
                    $scope.formData.firm_type_id = id;
                }

            }, function () {
                // console.log("Modal Dismissed!!!");
            });
        };

        $scope.refreshFirmAddressType = function(){
            $rootScope.showLoader(true);
            commonFactory.get('/api/firmAddressTypes').success(function (response) {
                if (response.result) {
                    $scope.firmAddressTypes = response.result.firmAddressType;
                }
                $rootScope.showLoader(false);
            }).error(function (err) {
                $rootScope.showLoader(false);
            }); 
        }
        $scope.refreshFirmAddressType();

        $scope.firmaddresstypeModal = function (id) {
            $rootScope.showLoader(true);
            if (id) {
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/code_tables/firm_address_type/add/firmAddressTypeModal.html',
                    controller: 'editfirmaddresstypeController',
                    resolve: {
                        fromModal: function () {
                            return true;
                        },
                        firmaddresstypeId: function () {
                            return id;
                        }
                    }
                });
            }
            else {
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/code_tables/firm_address_type/add/firmAddressTypeModal.html',
                    controller: 'addfirmaddresstypeController',
                    resolve: {
                        fromModal: function () {
                            return true;
                        }
                    }
                });
            }
            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });

            modalInstance.result.then(function (id) {
                if (id) {
                    $scope.refreshFirmAddressType();
                    $scope.formData.address_type_id = id;
                }

            }, function () {
                // console.log("Modal Dismissed!!!");
            });
        };

        //Get firm statements agin data 
        if($scope.attorneyFirmId){
            var ids = [];
            $scope.agingOfStatemetns = [];
            $scope.isAging = false;
            ids[0] = $scope.attorneyFirmId;

            $http.post(apiUrl + '/api/getStatementData', { ids: ids , type : 'attorneyFirm', onlyAging: true}).then(function(response) {
                $scope.isAging = true;
                $scope.agingOfStatemetns = response.data.result[0].aging;
            });
        }

        //Get Firm Data (EDIT Mode).
        if ($scope.attorneyFirmId) // Make sure we are in edit mode.
        {   
            // Get firm profit metrics data 
            commonFactory.post('/api/firms/check-stats-report/'+$scope.attorneyFirmId)
                .success(function(response) { 
                    if(response.error){
                        Notification.error('Something went wrong!');
                    } else{ 
                        $scope.firmMetrics  = response.result.firmMetrics;
                        $scope.firmCurrentMonthMetrics  = [];
                        // for current month data
                        if(response.result.stats[0] && typeof response.result.stats[0] != undefined && response.result.stats[0].REPORT_DATE == moment().format('YYYY-MM')) {
                            $scope.firmCurrentMonthMetrics  = response.result.stats[0];
                        }
                    }
                }).error(function(err){
                    Notification.error('Something went wrong!'); 
                });

            $scope.reporterlistattorney = function(val) {
                // $rootScope.showLoader(true);
                $scope.reporterSearching = true;
                return $http.post(apiUrl + '/api/search-resource-type', {
                    keyword : val,
                    lookingFor : 'R'
                    // selectedResourceId : ''
                }).then(function(response) {
                    // $rootScope.showLoader(false);
                    $scope.reporterSearching = false;
                    return response.data.result.map(function(item) {
                        return item;
                    });
                });
            };
            $scope.reporterlistattorneyforaviod = function(val) {
                // $rootScope.showLoader(true);
                $scope.reporterSearchingforaviod = true;
                return $http.post(apiUrl + '/api/search-resource-type', {
                    keyword : val,
                    lookingFor : 'R'
                    // selectedResourceId : ''
                }).then(function(response) {
                    // $rootScope.showLoader(false);
                    $scope.reporterSearchingforaviod = false;
                    return response.data.result.map(function(item) {
                        return item;
                    });
                });
            };
            $rootScope.showLoader(true);
            commonFactory.get('/api/attorneys/' + $scope.attorneyFirmId + '/edit')
                .success(function(response) {
                    if (response && response.status == 200 && response.result) {
                        if (response.result.data) { 
                            var resValue = JSON.parse(JSON.stringify(response.result.data));
                            $scope.legacyRecordId = resValue.legacy_id;
                            if(resValue.reporting_salesman.length){
                                var reportingSalesmanIds = [];
                                angular.forEach(resValue.reporting_salesman,function(value, key){
                                    reportingSalesmanIds[key] = value.sales_reptyp_id;
                                });
                                $scope.formData.reporting_sales_ids = reportingSalesmanIds;
                            }
                            if(resValue.records_salesman.length){
                                var recordsSalesmanIds = [];
                                angular.forEach(resValue.records_salesman,function(value, key){
                                    recordsSalesmanIds[key] = value.sales_reptyp_id;
                                });
                                $scope.formData.records_sales_ids = recordsSalesmanIds;
                            }

                            if(resValue.tpa_handling_ins_com.length){
                                var recordsTpaInsIds = [];
                                angular.forEach(resValue.tpa_handling_ins_com,function(value, key){
                                    recordsTpaInsIds[key] = value.ins_com_id;
                                });
                                $scope.formData.inscom_tpa_ids = recordsTpaInsIds;
                            }

                            if(resValue.clientpreference.length){
                                var preference_index = 0
                                var avoid_index = 0
                                $scope.formData.ClientPreferenceAvoidIds = []
                                $scope.formData.ClientPreferenceIds = []
                                angular.forEach(resValue.clientpreference,function(element) {
                                    if(element.flag == 'P'){
                                    $scope.formData.ClientPreferenceIds[preference_index] =element.reporter
                                    preference_index++
                                    }
                                    if(element.flag == 'A'){
                                        $scope.formData.ClientPreferenceAvoidIds[avoid_index] =element.reporter
                                        avoid_index++
                                    }
                                });
                            }

                            $scope.formData.sales_rep_dte_assigned = resValue.sales_rep_dte_assigned ? moment(resValue.sales_rep_dte_assigned).format('MM/DD/YYYY') : '';
                            $scope.ATT_NAME_KEY = resValue.NAME_KEY; 
                            $scope.formData.firm_name = resValue.NAME;
                            // To change page title to firm name
                            $state.$current.data.pageTitle = $scope.formData.firm_name;
                            $rootScope.$broadcast('changeTitle',$state);
                            $scope.formData.short_name = resValue.SHORT_NAME;
                            $scope.formData.nick_name = resValue.nick_name;
                            $scope.formData.address1 = resValue.ADDR_LINE1;
                            $scope.formData.address2 = resValue.ADDR_LINE2;

                            $scope.formData.NOTES_1 = resValue.NOTES_1;
                            $scope.formData.NOTES_2 = resValue.NOTES_2;
                            $scope.formData.NOTES_3 = resValue.NOTES_3;

                            $scope.formData.city = resValue.CITY;
                            $scope.formData.state = resValue.STATE;
                            $scope.formData.email_address = resValue.EmailAddress;
                            $scope.formData.zip = resValue.ZIP;
                            $scope.formData.allow_additional_charge = resValue.AllowAdditionalChargesForAStatment;
                            $scope.formData.exclude_invoice_reprint = resValue.exclude_invoice_reprint;
                            $scope.formData.contact = resValue.CONTACT;
                            $scope.formData.phone = resValue.PHONE;
                            $scope.formData.fax = resValue.FAX;
                            $scope.formData.cellphone = resValue.CELL;
                            $scope.formData.source = resValue.source_id;
                            $scope.formData.sales_force_id = resValue.saleforce_id;
                            $scope.formData.am_law_100 = resValue.am_law_100;
                            $scope.formData.firm_type_id = (resValue.firm_type_id) ? resValue.firm_type_id.toString() : null;
                            $scope.formData.address_type_id = (resValue.address_type_id) ? resValue.address_type_id.toString() : null;
                            // if(resValue && resValue.rate_id != 3)
                            // {
                            $scope.formData.regular_other = parseInt(resValue.REG_OTH);
                            $scope.regular_other = resValue.REG_OTH;
                            // $scope.formData.regular_other = parseInt(resValue.rate_id);
                            $scope.formData.appear_rate = resValue.APP_RATE;
                            $scope.formData.late_appear = resValue.APPL_RATE;
                            $scope.formData.print_statement = resValue.PrintStatementYN;
                            $scope.formData.rate = resValue.RATE;
                            $scope.formData.copy_rate = resValue.COPY_RATE;
                            $scope.formData.no_min_taken_charge = resValue.INV_MIN;
                            $scope.formData.SOR = resValue.SOR;
                            $scope.formData.SORr = resValue.SORr;
                            $scope.formData.word_indexing_flat_rate = resValue.WordIndexingFlatRate;
                            $scope.formData.min_invoice_charge = resValue.INV_MINPG;
                            $scope.formData.postage = resValue.POSTAGE;
                            $scope.formData.web_amount = resValue.WEB_AMOUNT;
                            $scope.formData.mini_script_per_page_rate = resValue.MiniScriptPerPage;
                            $scope.formData.mini_script_flat_rate = resValue.MiniScriptFlatRate;
                            $scope.formData.cd_charge = resValue.CDCharge;
                            $scope.formData.e_transcript = resValue.ETranscript;
                            $scope.formData.word_indexing_page_rate = resValue.WordIndexingPerPage; 
                            $scope.formData.CreatedBy = resValue.created_by ? resValue.created_by.name : 'N/A';
                            $scope.formData.CreatedDate = moment(resValue.CreatedDate).format('MM/DD/YYYY'); 
                            $scope.formData.created_at =  (resValue.created_at && resValue.created_at !== '0000-00-00 00:00:00' && resValue.created_at !== '-0001-11-30 00:00:00') ? moment(resValue.created_at).format('MM/DD/YYYY hh:mm:ss a') : '--/--/----';            
 
                            $scope.formData.parent_firm = resValue.parent_firm ? resValue.parent_firm : '';
                            $scope.formData.child_firms = resValue.child_firms ? resValue.child_firms : null; 
                            $scope.formData.corporate_client = resValue.corporate_client ? resValue.corporate_client : 0; 
                            $scope.formData.corporate_clients =  resValue.corporate_clients ? resValue.corporate_clients?.map((corporate_client) => corporate_client.client_details) : null
                                                         
                            $scope.formData.Room = resValue.Room;
                            $scope.formData.sort1 = resValue.Sort1;
                            $scope.formData.sort2 = resValue.Sort2;
                            $scope.formData.LevelOfPayment = resValue.LevelOfPayment;
                            $scope.formData.turn_around_days = resValue.turn_around_days;
                            $scope.formData.grade_id = resValue.grade_id;

                            $scope.formData.med_rate = resValue.med_rate;
                            $scope.formData.exp_rate = resValue.exp_rate;
                            $scope.formData.daily_rate = resValue.daily_rate;
                            $scope.formData.immed_rate = resValue.immed_rate;

                            $scope.formData.Charge2 = resValue.Charge2;
                            $scope.formData.admin_fee = resValue.admin_fee;
                            $scope.formData.Charge4 = resValue.Charge4;
                            $scope.formData.web_charge_per_witness = resValue.web_charge_per_witness;
                            $scope.formData.video_conference_handling_and_connection_fee = resValue.video_conference_handling_and_connection_fee;
                            $scope.formData.reminder_charge = resValue.reminder_charge;
                            $scope.formData.legalview_charge = resValue.legalview_charge;
                            $scope.formData.deposition_insights_charge1 = resValue.deposition_insights_charge1;
                            $scope.formData.deposition_insights_charge2 = resValue.deposition_insights_charge2;
                            $scope.formData.deposition_insights_charge3 = resValue.deposition_insights_charge3;
                            $scope.formData.appearance_hourly = resValue.appearance_hourly;
                            $scope.formData.appearance_hourly_remote = resValue.appearance_hourly_remote;
                            $scope.formData.appearance_hourly_nonsteno = resValue.appearance_hourly_nonsteno;

                            $scope.formData.appear_rate_remote = resValue.appear_rate_remote;
                            $scope.formData.appear_rate_nonsteno = resValue.appear_rate_nonsteno;
                            $scope.formData.rate_remote = resValue.rate_remote;
                            $scope.formData.rate_nonsteno = resValue.rate_nonsteno;
                            $scope.formData.copy_rate_remote = resValue.copy_rate_remote;
                            $scope.formData.copy_rate_nonsteno = resValue.copy_rate_nonsteno;
                            $scope.formData.word_indexing_page_rate_remote = resValue.word_indexing_page_rate_remote;
                            $scope.formData.word_indexing_page_rate_nonsteno = resValue.word_indexing_page_rate_nonsteno;
                            $scope.formData.non_appearance = resValue.non_appearance;
                          
                            $scope.formData.non_appearance_nonsteno  = resValue.non_appearance_nonsteno 
                            $scope.formData.cancellation = resValue.cancellation
                            $scope.formData.cancellation_nonsteno = resValue.cancellation_nonsteno
                            $scope.formData.video_cancellation = resValue.video_cancellation
                            $scope.formData.medical_technical_expert = resValue.medical_technical_expert
                            $scope.formData.medical_technical_expert_nonsteno = resValue.medical_technical_expert_nonsteno
                            $scope.formData.rough_ascii_nonsteno = resValue.rough_ascii_nonsteno
                            $scope.formData.cna = resValue.cna;
                            $scope.formData.no_write = resValue.no_write;
                            $scope.formData.rough_ascii = resValue.rough_ascii;

                            $scope.formData.realtime = resValue.realtime;
                            $scope.formData.exhibits_per_page = resValue.exhibits_per_page;
                            $scope.formData.expedite_percentage_per_day = resValue.expedite_percentage_per_day;
                            $scope.formData.transcript_repository_storage = resValue.transcript_repository_storage;
                            $scope.formData.videographer_fee = resValue.videographer_fee;
                            $scope.formData.video_archiving = resValue.video_archiving;
                            $scope.formData.digitization_charge = resValue.digitization_charge;
                            $scope.formData.exhibit_charge = resValue.exhibit_charge;
                            $scope.formData.equipment_rental = resValue.equipment_rental;
                            $scope.formData.video_sync = resValue.video_sync;
                            $scope.formData.processing_fee = resValue.processing_fee;
                            $scope.formData.color_exhibit_charge = resValue.color_exhibit_charge;
                            $scope.formData.hyperlinked_exhibit_charge = resValue.hyperlinked_exhibit_charge;
                            $scope.formData.terms = Number(resValue.term_id);
                            $scope.formData.TERMS = resValue.TERMS;
                            $scope.formData.send_job_confirmation_to = resValue.send_job_confirmation_to;

                            $scope.formData.appear_rate_arbitration = resValue.appear_rate_arbitration;
                            $scope.formData.appear_rate_euo = resValue.appear_rate_euo;
                            $scope.formData.rate_arbitration = resValue.rate_arbitration;
                            $scope.formData.rate_euo = resValue.rate_euo;
                            $scope.formData.appearance_hourly_arbitration = resValue.appearance_hourly_arbitration;
                            $scope.formData.appearance_hourly_euo = resValue.appearance_hourly_euo;
                            $scope.formData.copy_rate_arbitration = resValue.copy_rate_arbitration;
                            $scope.formData.copy_rate_euo = resValue.copy_rate_euo;
                            $scope.formData.word_indexing_page_rate_arbitration = resValue.word_indexing_page_rate_arbitration;
                            $scope.formData.word_indexing_page_rate_euo = resValue.word_indexing_page_rate_euo;
                            $scope.formData.no_transcript_depo_appear_rate = resValue.no_transcript_depo_appear_rate;
                            $scope.formData.no_transcript_arbitration_appear_rate = resValue.no_transcript_arbitration_appear_rate;
                            $scope.formData.no_transcript_euo_appear_rate = resValue.no_transcript_euo_appear_rate;
                            
                            // $scope.formData.charge5 = resValue.charge5;
                            // $scope.formData.charge6 = resValue.charge6;
                            // $scope.formData.charge7 = resValue.charge7;
                            // $scope.formData.charge8 = resValue.charge8;
                            //$scope.formData.rep_app_fee = resValue.RepAppFee;
                            $scope.formData.show_calculations = resValue.SHOW_CALCS;
                            $scope.formData.beeper = resValue.BEEPER;  
                            if(!$scope.formData.beeper){
                                $scope.formData.beeper = 'none';
                            }
                            $scope.formData.location = (resValue.LOCATION && resValue.LOCATION != null) ? resValue.LOCATION : 'M';
                            $scope.formData.claim_representative = resValue.ClaimRepresentative ? resValue.ClaimRepresentative : 'N';
                            
                            $scope.formData.status = resValue.STATUS;
                            //$scope.formData.payment_level = resValue.LevelOfPayment;
                            // $scope.formData.inventory_date = resValue.LAST_INVDT;
                            // $scope.formData.inventory_number = resValue.LAST_INVNO;
                            // $scope.formData.inventory_amount = resValue.LST_INVAMT;
                            $scope.formData.YTD_BILLED = resValue.YTD_BILLED;  
                            $scope.formData.bal_due = resValue.BAL_DUE;
                            $scope.formData.LAST_INVDT =resValue.LAST_INVDT;
                            $scope.formData.PYR_BILLED  = resValue.PYR_BILLED;
                            $scope.formData.YTD_PAID = resValue.YTD_PAID;
                            $scope.formData.LAST_INVNO = resValue.LAST_INVNO;
                            $scope.formData.LST_INVAMT = resValue.LST_INVAMT;
                            $scope.formData.PYR2_BILLD = resValue.PYR2_BILLD;
                            // $scope.formData.date_added = resValue.DATE_ADDED;
                            // $scope.formData.last_change = resValue.LAST_CHGD;
                            //$scope.formData.sort1 = resValue.Sort1;
                            //$scope.formData.sort2 = resValue.Sort2;
                            $scope.formData.send_fax = resValue.SEND_FAX;
                            //$scope.formData.send_msg = resValue.SEND_MSG;
                            //$scope.formData.defaults = resValue.DEFAULTS;
                            //}
                            $scope.formData.business_unit_id = resValue.business_unit_id;

                            if($scope.BusinessUnitsList && $scope.BusinessUnitsList.length) {
                                $scope.BusinessUnitsList.forEach(element => {
                                    if(element.id ==  $scope.formData.business_unit_id){
                                        $scope.business_unit_data = element
                                    }
                                });
                            } else {
                                $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
                                    response.data.result.data.forEach(element => {
                                        if(element.id ==  $scope.formData.business_unit_id){
                                            $scope.business_unit_data = element
                                        }
                                    });
                                });
                            }

                            $scope.formData.domain_url = resValue.domain_url;
                            $scope.formData.split_billing_client = resValue.split_billing_client;
                            $scope.formData.attorney_rate_details = resValue.attorney_rate_details || [];
                            $scope.formData.rate_card_group = resValue.rate_card_groups.map((rate_card_group) => rate_card_group.id);
                            $scope.firmData = response.result.data;

                            $scope.formData.shipping_address1 = resValue.shipping_address1;
                            $scope.formData.shipping_address2 = resValue.shipping_address2;
                            $scope.formData.shipping_city = resValue.shipping_city;
                            $scope.formData.shipping_state = resValue.shipping_state;
                            $scope.formData.shipping_zip = resValue.shipping_zip;
                            if (resValue.shipping_diff === 'N'){
                                $scope.formData.shipping_address1 = resValue.ADDR_LINE1;
                                $scope.formData.shipping_address2 = resValue.ADDR_LINE2;
                                $scope.formData.shipping_city = resValue.CITY;
                                $scope.formData.shipping_state = resValue.STATE;
                                $scope.formData.shipping_zip = resValue.ZIP;
                            }
                            $scope.formData.shipping_diff = resValue.shipping_diff;

                            $scope.formData.client_held_at_flag = resValue.client_held_at_flag;
                            $scope.formData.translator_id = resValue.translator_id;
                            $scope.formData.append_invoice_with_proof_of_assignment = resValue.append_invoice_with_proof_of_assignment ? resValue.append_invoice_with_proof_of_assignment : 'N';

                            angular.forEach($scope.statement_codes,function(value,key){
                                
                                if(value.id == Number(resValue.statement_schedules_id)){
                                    
                                    $scope.frequency.email_fax = value.email_fax;
                                    $scope.frequency.statement_invoice = value.statement_invoice;
                                
                                }
                            });  
                            // console.log('resValue.depo_attorney_office.receive_cc', resValue.depo_attorney_office.receive_cc);
                            $scope.formData.master_email_address = resValue.depo_attorney_office ? resValue.depo_attorney_office.master_email_address : '';
                            $scope.formData.receive_cc = resValue.depo_attorney_office ? resValue.depo_attorney_office.receive_cc : 'N';
                            $scope.formData.nowait = resValue.depo_attorney_office ? resValue.depo_attorney_office.nowait : 'N';
                            $scope.formData.auto_attach = resValue.depo_attorney_office ? resValue.depo_attorney_office.auto_attach : 'N';
                            $scope.formData.auto_attach_invoice = resValue.depo_attorney_office ? resValue.depo_attorney_office.auto_attach_invoice : 'N';
                            $scope.formData.smartadvocate_host = resValue.depo_attorney_office ?resValue.depo_attorney_office.smartadvocate_host : '';
                            $scope.formData.depo_attorney_office = resValue.depo_attorney_office ? resValue.depo_attorney_office : '';
                            $scope.formData.is_claim_info_required = resValue.depo_attorney_office ? resValue.depo_attorney_office.is_claim_info_required : 'N';

                            $scope.formData.is_feature_no_required = resValue.depo_attorney_office ? resValue.depo_attorney_office.is_feature_no_required : 'N';
                            $scope.formData.is_firm_file_no_required = resValue.depo_attorney_office ? resValue.depo_attorney_office.is_firm_file_no_required : 'N';
                            $scope.formData.is_insurance_file_no_required = resValue.depo_attorney_office ? resValue.depo_attorney_office.is_insurance_file_no_required : 'N';
                            $scope.formData.is_insurance_claim_no_required = resValue.depo_attorney_office ? resValue.depo_attorney_office.is_insurance_claim_no_required : 'N';

                            $scope.formData.responsibility_center = resValue.responsibility_center;
                            $scope.formData.default_purchase_order = resValue.default_purchase_order;
                            $scope.formData.invoice_no_prefix = resValue.invoice_no_prefix;
                            $scope.formData.department_name = resValue.department_name;
                            $scope.formData.account_number = resValue.account_number;
                            $scope.formData.claim_number_mask = resValue.claim_number_mask;
                            $scope.formData.group_name = resValue.group_name;
                            $scope.formData.auto_partner_mapping_name = resValue.auto_partner_mapping_name;
                            $scope.formData.reporter_note = resValue.reporter_note;
                            $scope.formData.claim_representative_name = resValue.claim_rep_name;
                            $scope.formData.contract = resValue.contract; 
                            $scope.formData.invoice_and_transcript_handling_id = resValue.invoice_and_transcript_handling_id;
                            //attereney List
                            
                            $scope.formData.dep_attorney = resValue.dep_attorney;
                            $scope.formData.collector_id = resValue.collector_id;
                            $scope.formData.depo_caseview = resValue.depo_caseview;
                            $scope.formData.di_job_summary = resValue.di_job_summary;
                            $scope.formData.custom_trigger_hrs = resValue.custom_trigger_hrs;
                            if(resValue.netSuiteId == null || resValue.netSuiteId == 0){
                                 $scope.formData.netSuiteId = '';
                            }else{
                                $scope.formData.netSuiteId =  'NetSuite ID : ' + resValue.netSuiteId;
                            }
                            //$scope.formData.netSuiteId = resValue.netSuiteId;
                            $scope.formData.salesman_commission_type = resValue.salesman_commission_type;
                            $scope.oldCollectorID = angular.copy(resValue.collector_id);
                            $scope.goldenRecordId = resValue.goldenRecordId;
                            $scope.formData.firmratehistory = resValue.attorney_rate_history;
                            $rootScope.showLoader(false);
                            $scope.$watch('formData.rate_change_reason', function(newValue, oldValue) {
                                if (newValue == null || newValue == '' || newValue == 'undefined' || newValue == undefined || (newValue && newValue.length == 0)) {
                                    // $scope.isDisabled = true;
                
                                } else {
                                    $scope.formData.rate_changed_by = $scope.Authuser.name;
                                    $scope.formData.rate_change_date = new Date().toJSON().slice(0,10).split('-').reverse().join('/');
                                    // $scope.isDisabled = false;
                                }
                            });
                            $scope.preferenceFormData.preferred_location_ids = resValue.attorney_preferred_location.map((value) => value.preferred_location_id)
                            $scope.preferenceFormData.unpreferred_location_ids = resValue.attorney_unpreferred_location.map((value) => value.unpreferred_location_id)
                            $scope.preferenceFormData.collection_policy_exception_ids = resValue.attorney_collection_policy_exception.map((value) => value.collection_policy_exception_id)
                            $scope.preferenceFormData.format_exhibit_ids = resValue.attorney_format_exhibit.map((value) => value.format_exhibit_id)
                            $scope.preferenceFormData.format_transcript_ids = resValue.attorney_format_transcript.map((value) => value.format_transcript_id)
                            $scope.preferenceFormData.format_video_ids = resValue.attorney_format_video.map((value) => value.format_video_id)
                            $scope.preferenceFormData.format_statement_ids = resValue.attorney_format_statement.map((value) => value.format_statement_id)
                            $scope.preferenceFormData.remote_connection_preference_ids = resValue.attorney_remote_connection_preference.map((value) => value.remote_connection_preferences_id)
                            $scope.preferenceFormData.preferred_team_members = resValue.attorney_preferred_team_member ? resValue.attorney_preferred_team_member?.map((preferred_team_member) => { preferred_team_member.team_member.lable = preferred_team_member.team_member.name + '-' + preferred_team_member.team_member.email; return preferred_team_member.team_member }) : null
                        }
                    } else { 
                        Notification.error("Not get any response");
                    }
                })
                .error(function(err) {  
                });
        }

        if ($scope.attorneyFirmId) {
            //update firm information (UPDATE) 
            $scope.save = function() {   

                if($scope.isRateChanged && (!$scope.formData.rate_change_reason || $scope.formData.rate_change_reason  == '' )){
                    swal({
                            title: 'Firm rates changed - please provide a reason.',
                            html: true,
                            type: "info", 
                            confirmButtonColor: "#09375f",
                            confirmButtonText: "Ok",
                            closeOnConfirm: true
                        },
                        function (isConfirm) {
                            if(isConfirm) {
                                $scope.goto_rate();
                                return;
                            }
                        }); 
                    return false;
                } 
                $scope.signupForm = true; 
                
                if($scope.frequency.email_fax == 'ef_set_frequency'){
    
                    $scope.formData.statement_schedules_id = null;

                }else{
                    //set statement frequency code from radio button combination
                    angular.forEach($scope.statement_codes,function(value,key){
                        if(value.email_fax == $scope.frequency.email_fax && value.statement_invoice == $scope.frequency.statement_invoice){
                            $scope.formData.statement_schedules_id = Number(value.id);
                        }
                    }); 
                }
 
                if ($scope.formData && $scope.formData.phone) {
                    $scope.formData.phone = $scope.formData.phone.replace(/[()]+/g, '') || "";
                }
                if(!$scope.formData.source){
                    $scope.formData.source = 1;
                } 
                $scope.formData.sales_rep_dte_assigned = $scope.formData.sales_rep_dte_assigned ? moment($scope.formData.sales_rep_dte_assigned ).format('YYYY/MM/DD') : null;
                $scope.formData.sales_rep_dte_assigned = $scope.formData.sales_rep_dte_assigned == 'Invalid date' ? null : $scope.formData.sales_rep_dte_assigned;
            
                angular.forEach($scope.firm_terms,function(value, key){
                    if(value.id == $scope.formData.terms){

                        $scope.formData.TERMS = value.name;
                    }
                });
                if($scope.firmMetrics && $scope.firmCurrentMonthMetrics){
                $scope.formData.skuMatricsData=$scope.firmMetrics.skuReport.groupCharges;
                $scope.formData.firmMetrics={
                    trailing12MonthClientBilled:$scope.firmMetrics.trailing12MonthClientBilled,
                    trailing12MonthJobBilledAvg:$scope.firmMetrics.trailing12MonthJobBilledAvg,
                    trailing12MonthJobBilled:$scope.firmMetrics.trailing12MonthJobBilled,
                    trailing12MonthGrossMargin:$scope.firmMetrics.trailing12MonthGrossMargin,
                    trailing12MonthClientBilledAvg:$scope.firmMetrics.trailing12MonthClientBilledAvg,
                    trailing12MonthPaymentReceived:$scope.firmMetrics.trailing12MonthPaymentReceived,
                    trailing12MonthPaymentReceivedAvg:$scope.firmMetrics.trailing12MonthPaymentReceivedAvg,
                    TOTAL_PAY_REC:$scope.firmCurrentMonthMetrics.TOTAL_PAY_REC,
                    TOTAL_INVOICE_AMT:$scope.firmCurrentMonthMetrics.TOTAL_INVOICE_AMT,
                    TOTAL_BILLED_AMT:$scope.firmCurrentMonthMetrics.TOTAL_BILLED_AMT,
                };
            }

                $scope.formData.parent_firm_id = $scope.formData.parent_firm && $scope.formData.parent_firm.id ? $scope.formData.parent_firm.id : null;
                
                if ($scope.formData.child_firms && $scope.formData.child_firms.length > 0) {
                    $scope.formData.child_firm_ids = $scope.formData.child_firms.map((firm) => { 
                        return firm.id 
                    });   
                }
                if ($scope.formData.corporate_clients && $scope.formData.corporate_clients.length > 0) {
                    $scope.formData.corporate_client_ids = $scope.formData.corporate_clients.map((corporate_client) => { 
                        return corporate_client.id 
                    });   
                }
                
                $rootScope.showLoader(true);
                commonFactory.put('/api/attorneys/' + $scope.attorneyFirmId, $scope.formData)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        $scope.getFirmAuditLogs();
                        if (response) {
                            if (response.status == 200) {
                                
                                $scope.firmData = response.result.data;
                                $scope.firm.client = $scope.formData.NOTES_1;
                                $scope.firm.sales = $scope.formData.NOTES_2;
                                $scope.firm.ar = $scope.formData.NOTES_3;
                                $scope.firm.resource = $scope.formData.reporter_note;
                                $scope.firm.legacy_notes = $scope.formData.legacy_notes;
                                
                                $scope.oldCollectorIDCopy = $scope.oldCollectorID;
                                $scope.oldCollectorID = angular.copy(response.result.data.collector_id);
                                $scope.formData.firmratehistory = response.result?.firmInfo?.attorney_rate_history;
                                $scope.formData.rate_change_reason  = '';
                                $scope.formData.rate_changed_by = '';
                                $scope.formData.rate_change_date = '';
                                $scope.isRateChanged = false;
                                Notification.success("Firm updated successfully.");

                                var oldCollector = '';
                                var newCollector = '';
                                var ar_note = '';
                                
                                if(!$scope.oldCollectorIDCopy)
                                {
                                    oldCollector = 'N/A';
                                }
                                if(!$scope.firmData.collector_id)
                                {
                                    newCollector = 'N/A';
                                }
                                
                                angular.forEach($scope.collectors, function(value, key) {
                                    if(value.id && value.id == $scope.oldCollectorIDCopy)
                                    {
                                        oldCollector = value.name;
                                    }

                                    if(value.id && value.id == $scope.firmData.collector_id)
                                    {
                                        newCollector = value.name;
                                    }
                                });
                                if(oldCollector != newCollector)
                                {
                                    if(oldCollector && newCollector)
                                    {
                                        ar_note = 'Collector has been changed from '+oldCollector+' to '+newCollector+' for this firm.' 
                                    }
                                    else
                                    {
                                        ar_note = 'Collector has been changed for this firm.'
                                    }

                                    $rootScope.showLoader(true);
                                    $scope.formData.AR_ReminderDate = moment().format('YYYY-MM-DD');
                                    $scope.formData.ARNote = ar_note;
                                    $scope.formData.HideNote = 'N';
                                    $scope.formData.AttorneyFirm_FK = $scope.ATT_NAME_KEY;
                                    $scope.formData.attorney_id = $scope.attorneyFirmId;
                                    
                                    var requestUrl = '/api/AccountsReceivableAttorneyNotes';

                                    commonFactory.post( requestUrl , $scope.formData ).success(function(response) {
                                        if (response) {
                                            if (response.status == 200) {
                                                Notification.success("Note created successfully");
                                                $rootScope.showLoader(false);
                                            } else{
                                                $rootScope.showLoader(false);
                                            }
                                        }
                                    })
                                    .error(function(err) {
                                        $rootScope.showLoader(false);
                                    });
                                }

                                if(angular.equals({}, $uibModalInstance)) {
                                    if($rootScope.havePermission('attorney_firm','manage_ebt')){
                                        $state.go("firms.manage.ebt", { id: $scope.attorneyFirmId });
                                    }
                                }else{
                                    $scope.activeTab='tab-4';
                                    $scope.openEBTTab = true;
                                } 
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false); 
                    });
            }
        }
        //Getting Attorney Firm office_code
        $scope.foundFromElaw = false;

        $scope.attorneyofficedata = function(val) {
            return $http.get(apiUrl + '/api/getattorneyfirm', {
                params: {
                    name: val,
                    currentFirm: $scope.attorneyFirmId,
                    parentFirm: $scope.formData.parent_firm ? $scope.formData.parent_firm.id : ''
                }
            }).then(function(response) {
                $scope.foundFromElaw = false;
                if(response.data.foundFromElaw){
                    $scope.foundFromElaw = true;
                }
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $scope.formatRateChangeDate = function (date){
            return moment(date).format('ll');
        }

        $scope.corporateclientdata = function(val) {
            return $http.get(apiUrl + '/api/getattorneyfirm', {
                params: {
                    name: val,
                    conporateClient: 1
                }
            }).then(function(response) {
                $scope.foundFromElaw = false;
                if(response.data.foundFromElaw){
                    $scope.foundFromElaw = true;
                }
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };
        
        $scope.TeamMemberData = function(val) {
            return $http.get(apiUrl + '/api/get-user-lists', {
                params: {
                    name: val,
                }
            }).then(function(response) {
                $scope.foundFromElaw = false;
                if(response.data.foundFromElaw){
                    $scope.foundFromElaw = true;
                }
                return response.data.result.map(function(item) {
                    item.lable = item.name +'-'+ item.email
                    return item;
                });
            });
        };
        
        $scope.resetOrdFirm = function(){
            $scope.diaryData = {};
        }
        $scope.formatLabel = function (firm) {
            if(firm.id)
            {
                var label = firm.NAME +' | ('+ firm.PHONE +') | '+ firm.ADDR_LINE1 +' '+ firm.ADDR_LINE2 +' | '+ firm.CITY +' | '+ firm.source.name +' '+ firm.source.name_2 +' | YTD BILLED ('+ $scope.currencyFormat(firm.YTD_BILLED) + ')';

                if(firm.businessunit)
                {
                    label = label + ' | '+firm.businessunit.name;
                }

                return label;
            }
        };

        $scope.currencyFormat = function(data, type, meta, meta){
            if(parseFloat(data) >= 0){
                return '$'+parseFloat(data).toFixed(2);
            }else if(parseFloat(data) < 0){
                return '-$'+ parseFloat(Math.abs(data)).toFixed(2);
            }
            else{
                return '$'+ '0.00';
            }
        };
        $scope.change_rate_type =  function(is_manually) {

            if(is_manually){
                if($state.params){
                    swal({
                        title: "DO YOU WISH TO PROCEED?",
                        text: "You Are About To Change All Of The Firm's Pricing Fields To The Card Rate Values.",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                        function (isConfirm) {
                            if (isConfirm) {
                               

                                var params = {}; 
                                params.rate_id = "R";
                                params.id = $scope.formData.regular_other
                                $rootScope.showLoader(true);
                
                                commonFactory.getWithParams('/api/rates/getRate', params)
                                    .success(function(response) {
                                        if (response && response.result && response.result.rate_detail) {
                                            $scope.formData.appear_rate = response.result.rate_detail.appear_rate;
                                            $scope.formData.copy_rate = response.result.rate_detail.copy_rate;
                                            $scope.formData.late_appear = response.result.rate_detail.late_appear;
                                            $scope.formData.rate = response.result.rate_detail.rate;
                                            $scope.formData.no_min_taken_charge = response.result.rate_detail.no_min_taken_charge;
                                            $scope.formData.word_indexing_flat_rate = response.result.rate_detail.word_indexing_flat_rate;
                                            $scope.formData.min_invoice_charge = response.result.rate_detail.min_invoice_charge;
                                            $scope.formData.postage = response.result.rate_detail.postage;
                                            $scope.formData.web_amount = response.result.rate_detail.web_amount;
                                            $scope.formData.Room = response.result.rate_detail.Room;
                                            $scope.formData.SOR = response.result.rate_detail.SOR;
                                            $scope.formData.SORr = response.result.rate_detail.SORr;
                                            $scope.formData.mini_script_flat_rate = response.result.rate_detail.mini_script_flat_rate;
                                            $scope.formData.cd_charge = response.result.rate_detail.cd_charge;
                                            $scope.formData.e_transcript = response.result.rate_detail.e_transcript;
                                            $scope.formData.word_indexing_page_rate = response.result.rate_detail.word_indexing_page_rate;
                                            $scope.formData.mini_script_per_page_rate = response.result.rate_detail.mini_script_per_page_rate;
                                            $scope.formData.admin_fee = response.result.rate_detail.admin_fee;
                                            $scope.formData.web_charge_per_witness = response.result.rate_detail.web_charge_per_witness;
                                            $scope.formData.video_conference_handling_and_connection_fee = response.result.rate_detail.video_conference_handling_and_connection_fee;
                                            $scope.formData.reminder_charge = response.result.rate_detail.reminder_charge;
                                            $scope.formData.legalview_charge = response.result.rate_detail.legalview_charge;
                                            $scope.formData.deposition_insights_charge1 = response.result.rate_detail.deposition_insights_charge1;
                                            $scope.formData.deposition_insights_charge2 = response.result.rate_detail.deposition_insights_charge2;
                                            $scope.formData.deposition_insights_charge3 = response.result.rate_detail.deposition_insights_charge3;
                                            $scope.formData.digitization_charge = response.result.rate_detail.digitization_charge;
                                            $scope.formData.exhibit_charge = response.result.rate_detail.exhibit_charge;
                                            $scope.formData.appearance_hourly = response.result.rate_detail.appearance_hourly;
                                            $scope.formData.appearance_hourly_remote = response.result.rate_detail.appearance_hourly_remote;
                                            $scope.formData.appearance_hourly_nonsteno = response.result.rate_detail.appearance_hourly_nonsteno;
                
                                            $scope.formData.appear_rate_remote = response.result.rate_detail.appear_rate_remote;
                                            $scope.formData.appear_rate_nonsteno = response.result.rate_detail.appear_rate_nonsteno;
                                            $scope.formData.rate_remote = response.result.rate_detail.rate_remote;
                                            $scope.formData.rate_nonsteno = response.result.rate_detail.rate_nonsteno;
                                            $scope.formData.copy_rate_remote = response.result.rate_detail.copy_rate_remote;
                                            $scope.formData.copy_rate_nonsteno = response.result.rate_detail.copy_rate_nonsteno;
                                            $scope.formData.word_indexing_page_rate_remote = response.result.rate_detail.word_indexing_page_rate_remote;
                                            $scope.formData.word_indexing_page_rate_nonsteno = response.result.rate_detail.word_indexing_page_rate_nonsteno;
                                            $scope.formData.non_appearance = response.result.rate_detail.non_appearance;
                                            $scope.formData.cna = response.result.rate_detail.cna;
                                            $scope.formData.no_write = response.result.rate_detail.no_write;
                                            $scope.formData.rough_ascii = response.result.rate_detail.rough_ascii;
                                            
                                            $scope.formData.non_appearance_nonsteno  = response.result.rate_detail.non_appearance_nonsteno 
                                            $scope.formData.cancellation = response.result.rate_detail.cancellation
                                            $scope.formData.cancellation_nonsteno = response.result.rate_detail.cancellation_nonsteno
                                            $scope.formData.video_cancellation = response.result.rate_detail.video_cancellation
                                            $scope.formData.medical_technical_expert = response.result.rate_detail.medical_technical_expert
                                            $scope.formData.medical_technical_expert_nonsteno = response.result.rate_detail.medical_technical_expert_nonsteno
                                            $scope.formData.rough_ascii_nonsteno = response.result.rate_detail.rough_ascii_nonsteno

                                            $scope.formData.realtime = response.result.rate_detail.realtime;
                                            $scope.formData.exhibits_per_page = response.result.rate_detail.exhibits_per_page;
                                            $scope.formData.expedite_percentage_per_day = response.result.rate_detail.expedite_percentage_per_day;
                                            $scope.formData.transcript_repository_storage = response.result.rate_detail.transcript_repository_storage;
                                            $scope.formData.videographer_fee = response.result.rate_detail.videographer_fee;
                                            $scope.formData.video_archiving = response.result.rate_detail.video_archiving;
                                            
                                            $scope.formData.med_rate = response.result.rate_detail.med_rate;
                                            $scope.formData.exp_rate = response.result.rate_detail.exp_rate;
                                            $scope.formData.daily_rate = response.result.rate_detail.daily_rate;
                                            $scope.formData.show_calculations = response.result.rate_detail.show_calc;
                                            $scope.formData.Charge2 = response.result.rate_detail.lit_charges;
                                            $scope.formData.Charge4 = response.result.rate_detail.charge1;
                                            $scope.formData.equipment_rental = response.result.rate_detail.equipment_rental;
                                            $scope.formData.video_sync = response.result.rate_detail.video_sync;
                                            $scope.formData.processing_fee = response.result.rate_detail.processing_fee;
                                            $scope.formData.color_exhibit_charge = response.result.rate_detail.color_exhibit_charge;
                                            $scope.formData.hyperlinked_exhibit_charge = response.result.rate_detail.hyperlinked_exhibit_charge;

                                            $scope.formData.appear_rate_arbitration = response.result.rate_detail.appear_rate_arbitration;
                                            $scope.formData.appear_rate_euo = response.result.rate_detail.appear_rate_euo;
                                            $scope.formData.rate_arbitration = response.result.rate_detail.rate_arbitration;
                                            $scope.formData.rate_euo = response.result.rate_detail.rate_euo;
                                            $scope.formData.appearance_hourly_arbitration = response.result.rate_detail.appearance_hourly_arbitration;
                                            $scope.formData.appearance_hourly_euo = response.result.rate_detail.appearance_hourly_euo;
                                            $scope.formData.copy_rate_arbitration = response.result.rate_detail.copy_rate_arbitration;
                                            $scope.formData.copy_rate_euo = response.result.rate_detail.copy_rate_euo;
                                            $scope.formData.word_indexing_page_rate_arbitration = response.result.rate_detail.word_indexing_page_rate_arbitration;
                                            $scope.formData.word_indexing_page_rate_euo = response.result.rate_detail.word_indexing_page_rate_euo;
                                            $scope.formData.no_transcript_depo_appear_rate = response.result.rate_detail.no_transcript_depo_appear_rate;
                                            $scope.formData.no_transcript_arbitration_appear_rate = response.result.rate_detail.no_transcript_arbitration_appear_rate;
                                            $scope.formData.no_transcript_euo_appear_rate = response.result.rate_detail.no_transcript_euo_appear_rate;

                                            // $scope.formData.immed_rate = response.result.rate_detail.immed_rate;
                
                                            $rootScope.showLoader(false);
                                        } else {
                                            $scope.formData.appear_rate = '';
                                            $scope.formData.copy_rate = '';
                                            $scope.formData.late_appear = '';
                                            $scope.formData.rate = '';
                                            $scope.formData.no_min_taken_charge = '';
                                            $scope.formData.word_indexing_flat_rate = '';
                                            $scope.formData.min_invoice_charge = '';
                                            $scope.formData.postage = '';
                                            $scope.formData.web_amount = '';
                                            $scope.formData.mini_script_flat_rate = '';
                                            $scope.formData.cd_charge = '';
                                            $scope.formData.e_transcript = '';
                                            $scope.formData.word_indexing_page_rate = '';
                                            $scope.formData.Room = '';
                                            $scope.formData.SOR = '';
                                            $scope.formData.SORr = '';
                                            $scope.formData.mini_script_per_page_rate = '';
                                            $scope.formData.admin_fee = '';
                                            $scope.formData.web_charge_per_witness = '';
                                            $scope.formData.video_conference_handling_and_connection_fee = '';
                                            $scope.formData.reminder_charge = '';
                                            $scope.formData.legalview_charge = '';
                                            $scope.formData.deposition_insights_charge1 = '';
                                            $scope.formData.deposition_insights_charge2 = '';
                                            $scope.formData.deposition_insights_charge3 = '';
                                            $scope.formData.digitization_charge = '';
                                            $scope.formData.exhibit_charge = '';
                                            $scope.formData.appearance_hourly = '';
                                            $scope.formData.appearance_hourly_remote = '';
                                            $scope.formData.appearance_hourly_nonsteno = '';
                
                                            $scope.formData.appear_rate_remote = '';
                                            $scope.formData.appear_rate_nonsteno = '';
                                            $scope.formData.rate_remote = '';
                                            $scope.formData.rate_nonsteno = '';
                                            $scope.formData.copy_rate_remote = '';
                                            $scope.formData.copy_rate_nonsteno = '';
                                            $scope.formData.word_indexing_page_rate_remote = '';
                                            $scope.formData.word_indexing_page_rate_nonsteno = '';
                                            $scope.formData.non_appearance = '';
                                            $scope.formData.cna = '';
                                            $scope.formData.no_write = '';
                                            $scope.formData.rough_ascii = '';
                                            
                                            $scope.formData.non_appearance_nonsteno  = ''
                                            $scope.formData.cancellation = ''
                                            $scope.formData.cancellation_nonsteno = ''
                                            $scope.formData.video_cancellation = ''
                                            $scope.formData.medical_technical_expert = ''
                                            $scope.formData.medical_technical_expert_nonsteno = ''
                                            $scope.formData.rough_ascii_nonsteno = ''

                                            $scope.formData.realtime = '';
                                            $scope.formData.exhibits_per_page = '';
                                            $scope.formData.expedite_percentage_per_day = '';
                                            $scope.formData.transcript_repository_storage = '';
                                            $scope.formData.videographer_fee = '';
                                            $scope.formData.video_archiving = '';
                
                                            $scope.formData.med_rate = '';
                                            $scope.formData.exp_rate = '';
                                            $scope.formData.daily_rate = '';
                                            $scope.formData.immed_rate = '';
                                            $scope.formData.show_calculations = '';
                                            $scope.formData.Charge2 = '';
                                            $scope.formData.Charge4 = '';
                                            $scope.formData.equipment_rental = '';
                                            $scope.formData.video_sync = '';
                                            $scope.formData.processing_fee = '';
                                            $scope.formData.color_exhibit_charge = '';
                                            $scope.formData.hyperlinked_exhibit_charge = '';

                                            $scope.formData.appear_rate_arbitration = '';
                                            $scope.formData.appear_rate_euo = '';
                                            $scope.formData.rate_arbitration = '';
                                            $scope.formData.rate_euo = '';
                                            $scope.formData.appearance_hourly_arbitration = '';
                                            $scope.formData.appearance_hourly_euo = '';
                                            $scope.formData.copy_rate_arbitration = '';
                                            $scope.formData.copy_rate_euo = '';
                                            $scope.formData.word_indexing_page_rate_arbitration = '';
                                            $scope.formData.word_indexing_page_rate_euo = '';
                                            $scope.formData.no_transcript_depo_appear_rate = '';
                                            $scope.formData.no_transcript_arbitration_appear_rate = '';
                                            $scope.formData.no_transcript_euo_appear_rate = '';

                                            $rootScope.showLoader(false);
                                        }
                                        $scope.firmRateChanged();
                                    })
                                    .error(function(err) { 
                                        $rootScope.showLoader(false);
                                    });
                            } else {
                                $scope.formData.regular_other = $scope.regular_other

                                let element = document.getElementById('rate_type_select');
                                element.value = "number:"+$scope.regular_other;
                                // return false;
                            }
                        });
                }
                else{
                    var params = {}; 
                    params.rate_id = "R";
                    params.id = $scope.formData.regular_other
                    $rootScope.showLoader(true);

                    commonFactory.getWithParams('/api/rates/getRate', params)
                        .success(function(response) {
                            if (response && response.result && response.result.rate_detail) {
                                $scope.formData.appear_rate = response.result.rate_detail.appear_rate;
                                $scope.formData.copy_rate = response.result.rate_detail.copy_rate;
                                $scope.formData.late_appear = response.result.rate_detail.late_appear;
                                $scope.formData.rate = response.result.rate_detail.rate;
                                $scope.formData.no_min_taken_charge = response.result.rate_detail.no_min_taken_charge;
                                $scope.formData.word_indexing_flat_rate = response.result.rate_detail.word_indexing_flat_rate;
                                $scope.formData.min_invoice_charge = response.result.rate_detail.min_invoice_charge;
                                $scope.formData.postage = response.result.rate_detail.postage;
                                $scope.formData.web_amount = response.result.rate_detail.web_amount;
                                $scope.formData.Room = response.result.rate_detail.Room;
                                $scope.formData.SOR = response.result.rate_detail.SOR;
                                $scope.formData.SORr = response.result.rate_detail.SORr;
                                $scope.formData.mini_script_flat_rate = response.result.rate_detail.mini_script_flat_rate;
                                $scope.formData.cd_charge = response.result.rate_detail.cd_charge;
                                $scope.formData.e_transcript = response.result.rate_detail.e_transcript;
                                $scope.formData.word_indexing_page_rate = response.result.rate_detail.word_indexing_page_rate;
                                $scope.formData.mini_script_per_page_rate = response.result.rate_detail.mini_script_per_page_rate;
                                $scope.formData.admin_fee = response.result.rate_detail.admin_fee;
                                $scope.formData.web_charge_per_witness = response.result.rate_detail.web_charge_per_witness;
                                $scope.formData.video_conference_handling_and_connection_fee = response.result.rate_detail.video_conference_handling_and_connection_fee;
                                $scope.formData.reminder_charge = response.result.rate_detail.reminder_charge;
                                $scope.formData.legalview_charge = response.result.rate_detail.legalview_charge;
                                $scope.formData.deposition_insights_charge1 = response.result.rate_detail.deposition_insights_charge1;
                                $scope.formData.deposition_insights_charge2 = response.result.rate_detail.deposition_insights_charge2;
                                $scope.formData.deposition_insights_charge3 = response.result.rate_detail.deposition_insights_charge3;
                                $scope.formData.digitization_charge = response.result.rate_detail.digitization_charge;
                                $scope.formData.exhibit_charge = response.result.rate_detail.exhibit_charge;
                                $scope.formData.appearance_hourly = response.result.rate_detail.appearance_hourly;
                                $scope.formData.appearance_hourly_remote = response.result.rate_detail.appearance_hourly_remote;
                                $scope.formData.appearance_hourly_nonsteno = response.result.rate_detail.appearance_hourly_nonsteno;
    
                                $scope.formData.appear_rate_remote = response.result.rate_detail.appear_rate_remote;
                                $scope.formData.appear_rate_nonsteno = response.result.rate_detail.appear_rate_nonsteno;
                                $scope.formData.rate_remote = response.result.rate_detail.rate_remote;
                                $scope.formData.rate_nonsteno = response.result.rate_detail.rate_nonsteno;
                                $scope.formData.copy_rate_remote = response.result.rate_detail.copy_rate_remote;
                                $scope.formData.copy_rate_nonsteno = response.result.rate_detail.copy_rate_nonsteno;
                                $scope.formData.word_indexing_page_rate_remote = response.result.rate_detail.word_indexing_page_rate_remote;
                                $scope.formData.word_indexing_page_rate_nonsteno = response.result.rate_detail.word_indexing_page_rate_nonsteno;
                                $scope.formData.non_appearance = response.result.rate_detail.non_appearance;

                                $scope.formData.non_appearance_nonsteno  = response.result.rate_detail.non_appearance_nonsteno 
                                $scope.formData.cancellation = response.result.rate_detail.cancellation
                                $scope.formData.cancellation_nonsteno = response.result.rate_detail.cancellation_nonsteno
                                $scope.formData.video_cancellation = response.result.rate_detail.video_cancellation
                                $scope.formData.medical_technical_expert = response.result.rate_detail.medical_technical_expert
                                $scope.formData.medical_technical_expert_nonsteno = response.result.rate_detail.medical_technical_expert_nonsteno
                                $scope.formData.rough_ascii_nonsteno = response.result.rate_detail.rough_ascii_nonsteno
                                
                                $scope.formData.cna = response.result.rate_detail.cna;
                                $scope.formData.no_write = response.result.rate_detail.no_write;
                                $scope.formData.rough_ascii = response.result.rate_detail.rough_ascii;
    
                                $scope.formData.realtime = response.result.rate_detail.realtime;
                                $scope.formData.exhibits_per_page = response.result.rate_detail.exhibits_per_page;
                                $scope.formData.expedite_percentage_per_day = response.result.rate_detail.expedite_percentage_per_day;
                                $scope.formData.transcript_repository_storage = response.result.rate_detail.transcript_repository_storage;
                                $scope.formData.videographer_fee = response.result.rate_detail.videographer_fee;
                                $scope.formData.video_archiving = response.result.rate_detail.video_archiving;
                                $scope.formData.med_rate = response.result.rate_detail.med_rate;
                                $scope.formData.exp_rate = response.result.rate_detail.exp_rate;
                                $scope.formData.daily_rate = response.result.rate_detail.daily_rate;
                                $scope.formData.show_calculations = response.result.rate_detail.show_calc;
                                $scope.formData.Charge2 = response.result.rate_detail.lit_charges;
                                $scope.formData.Charge4 = response.result.rate_detail.charge1;
                                $scope.formData.equipment_rental = response.result.rate_detail.equipment_rental;
                                $scope.formData.video_sync = response.result.rate_detail.video_sync;
                                $scope.formData.processing_fee = response.result.rate_detail.processing_fee;
                                $scope.formData.color_exhibit_charge = response.result.rate_detail.color_exhibit_charge;
                                $scope.formData.hyperlinked_exhibit_charge = response.result.rate_detail.hyperlinked_exhibit_charge;

                                $scope.formData.appear_rate_arbitration = response.result.rate_detail.appear_rate_arbitration;
                                $scope.formData.appear_rate_euo = response.result.rate_detail.appear_rate_euo;
                                $scope.formData.rate_arbitration = response.result.rate_detail.rate_arbitration;
                                $scope.formData.rate_euo = response.result.rate_detail.rate_euo;
                                $scope.formData.appearance_hourly_arbitration = response.result.rate_detail.appearance_hourly_arbitration;
                                $scope.formData.appearance_hourly_euo = response.result.rate_detail.appearance_hourly_euo;
                                $scope.formData.copy_rate_arbitration = response.result.rate_detail.copy_rate_arbitration;
                                $scope.formData.copy_rate_euo = response.result.rate_detail.copy_rate_euo;
                                $scope.formData.word_indexing_page_rate_arbitration = response.result.rate_detail.word_indexing_page_rate_arbitration;
                                $scope.formData.word_indexing_page_rate_euo = response.result.rate_detail.word_indexing_page_rate_euo;
                                $scope.formData.no_transcript_depo_appear_rate = response.result.rate_detail.no_transcript_depo_appear_rate;
                                $scope.formData.no_transcript_arbitration_appear_rate = response.result.rate_detail.no_transcript_arbitration_appear_rate;
                                $scope.formData.no_transcript_euo_appear_rate = response.result.rate_detail.no_transcript_euo_appear_rate;
                                // $scope.formData.immed_rate = response.result.rate_detail.immed_rate;

                                $rootScope.showLoader(false);
                            } else {
                                $scope.formData.appear_rate = '';
                                $scope.formData.copy_rate = '';
                                $scope.formData.late_appear = '';
                                $scope.formData.rate = '';
                                $scope.formData.no_min_taken_charge = '';
                                $scope.formData.word_indexing_flat_rate = '';
                                $scope.formData.min_invoice_charge = '';
                                $scope.formData.postage = '';
                                $scope.formData.web_amount = '';
                                $scope.formData.mini_script_flat_rate = '';
                                $scope.formData.cd_charge = '';
                                $scope.formData.e_transcript = '';
                                $scope.formData.word_indexing_page_rate = '';
                                $scope.formData.Room = '';
                                $scope.formData.SOR = '';
                                $scope.formData.SORr = '';
                                $scope.formData.mini_script_per_page_rate = '';
                                $scope.formData.admin_fee = '';
                                $scope.formData.web_charge_per_witness = '';
                                $scope.formData.video_conference_handling_and_connection_fee = '';
                                $scope.formData.reminder_charge = '';
                                $scope.formData.legalview_charge = '';
                                $scope.formData.deposition_insights_charge1 = '';
                                $scope.formData.deposition_insights_charge2 = '';
                                $scope.formData.deposition_insights_charge3 = '';
                                $scope.formData.appearance_hourly = '';
                                $scope.formData.appearance_hourly_remote = '';
                                $scope.formData.appearance_hourly_nonsteno = '';
    
                                $scope.formData.appear_rate_remote = '';
                                $scope.formData.appear_rate_nonsteno = '';
                                $scope.formData.rate_remote = '';
                                $scope.formData.rate_nonsteno = '';
                                $scope.formData.copy_rate_remote = '';
                                $scope.formData.copy_rate_nonsteno = '';
                                $scope.formData.word_indexing_page_rate_remote = '';
                                $scope.formData.word_indexing_page_rate_nonsteno = '';
                                $scope.formData.non_appearance = '';
                                $scope.formData.cna = '';
                                $scope.formData.no_write = '';
                                $scope.formData.rough_ascii = '';
                                
                                $scope.formData.non_appearance_nonsteno  = ''
                                $scope.formData.cancellation = ''
                                $scope.formData.cancellation_nonsteno = ''
                                $scope.formData.video_cancellation = ''
                                $scope.formData.medical_technical_expert = ''
                                $scope.formData.medical_technical_expert_nonsteno = ''
                                $scope.formData.rough_ascii_nonsteno = ''

                                $scope.formData.realtime = '';
                                $scope.formData.exhibits_per_page = '';
                                $scope.formData.expedite_percentage_per_day = '';
                                $scope.formData.transcript_repository_storage = '';
                                $scope.formData.videographer_fee = '';
                                $scope.formData.video_archiving = '';
                                $scope.formData.digitization_charge = '';
                                $scope.formData.exhibit_charge = '';

                                $scope.formData.med_rate = '';
                                $scope.formData.exp_rate = '';
                                $scope.formData.daily_rate = '';
                                $scope.formData.immed_rate = '';
                                $scope.formData.show_calculations = '';
                                $scope.formData.Charge2 = '';
                                $scope.formData.Charge4 = '';
                                $scope.formData.equipment_rental = '';
                                $scope.formData.video_sync = '';
                                $scope.formData.processing_fee = '';
                                $scope.formData.color_exhibit_charge = '';
                                $scope.formData.hyperlinked_exhibit_charge = '';

                                $scope.formData.appear_rate_arbitration = '';
                                $scope.formData.appear_rate_euo = '';
                                $scope.formData.rate_arbitration = '';
                                $scope.formData.rate_euo = '';
                                $scope.formData.appearance_hourly_arbitration = '';
                                $scope.formData.appearance_hourly_euo = '';
                                $scope.formData.copy_rate_arbitration = '';
                                $scope.formData.copy_rate_euo = '';
                                $scope.formData.word_indexing_page_rate_arbitration = '';
                                $scope.formData.word_indexing_page_rate_euo = '';
                                $scope.formData.no_transcript_depo_appear_rate = '';
                                $scope.formData.no_transcript_arbitration_appear_rate = '';
                                $scope.formData.no_transcript_euo_appear_rate = '';

                                $rootScope.showLoader(false);
                            }
                        })
                        .error(function(err) { 
                            $rootScope.showLoader(false);
                        });
                }
            }

        };

        $scope.setPageTitle = function () {
            $state.$current.data.pageTitle = $scope.formData?.firm_name;
            $rootScope.$broadcast('changeTitle', $state);
        };

        $scope.stepOneInit = function () {
            $scope.setPageTitle();
        }

        $scope.stepTwoInit = function () {
            $scope.setPageTitle();
        }

        $scope.stepThreeInit = function () {
            $scope.setPageTitle();
        }

        $scope.stepEbtInit = function () {
            $scope.setPageTitle();
        }


        //Notes
        $scope.notes = [];
        $scope.noteslist = function() {
            if ($scope.attorneyFirmId) {
                var data = {};
                data.id = $scope.attorneyFirmId;
                $rootScope.showLoader(true);
                commonFactory.getWithPaginate('/api/getattorneynotes', data)
                    .success(function(response) {
                        if (response) {
                            $rootScope.showLoader(false);
                            $scope.notes = response.result;
                            for (var i = 0; i < $scope.notes.length; i++) {
                                $scope.notes[i].created_at = new Date($scope.notes[i].created_at);
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        };

        $scope.notesModal = function() {
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/firms/notes_modal.html',
                controller: 'notesController',
                resolve: {
                    notesdata: function noteFactory() {
                        return $scope.notes;
                    }
                }
            });
        }

        $scope.deleteNote = function(data) {
            swal({
                    title: "Are you sure?",
                    text: "you want to delete this note!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No, cancel note!",
                    closeOnConfirm: false,
                    closeOnCancel: false
                },
                function(isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/deleteattorneynote', data)
                            .success(function(response) {
                                if (response.status == 200) {
                                    $rootScope.showLoader(false);
                                    var index = -1;
                                    var comArr = eval($scope.notes);
                                    for (var i = 0; i < comArr.length; i++) {
                                        if (comArr[i].id === data.id) {
                                            index = i;
                                            break;
                                        }
                                    }
                                    if (index === -1) {
                                        $rootScope.showLoader(false); 
                                        swal('Cancelled', 'Something gone wrong', 'error');
                                    }
                                    $scope.notes.splice(index, 1);
                                }
                                swal('Deleted!', 'Note deleted successfully', 'success');
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                                swal('Cancelled', 'Something gone wrong', 'error');
                            });
                    } else {
                        $rootScope.showLoader(false);
                        swal("Cancelled", "Your Note is safe :)", "error");
                    }
                });
        };

        $scope.editNote = function(data, index) {
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/firms/notes_modal.html',
                controller: 'editnotesController',
                resolve: {
                    note_value: function noteFactory() {
                        return commonFactory.get('/api/editattorneynote/' + data.id);
                    },
                    index: function nodeIndex() {
                        return index;
                    },
                    notes_data: function notesData() {
                        return $scope.notes;
                    }
                }
            });
        };

        $scope.changeSortOrder=function(type){
            $scope.sortBy = type;
            $scope.sortOrder = 'asc';
            descOrderColumn=['LAST_INV_AMT','YTD_BILLED','PYR_BILLED','PYR2_BILLD'];
            if(descOrderColumn.includes(type)){
                $scope.sortOrder = 'desc';
            }
            $scope.attorneyslist();
        }

        $scope.updateAttorneyPermission = function(attorney_no, org_data, key, buttonType){
            var update_data = {};
            var data = Object.assign({}, org_data);
            update_data.attorney_id = attorney_no;

            var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            var error =0;
            
            update_data.designees_1 = null;
            update_data.designees_2 = null;
            update_data.designees_3 = null;
            update_data.designees_4 = null;
            update_data.scheduled_designees = null;
            update_data.mult_firms = null;
            update_data.scheduled_by = null;

            if(data.designees_1){
                data.designees_1 = data.designees_1.replaceAll(/,/g, ";");
                data.designees_1 = data.designees_1.replaceAll(/\;$/g, '');
                update_data.designees_1 = data.designees_1;
                data.designees_1= data.designees_1.split(';');
                angular.forEach(data.designees_1, function(value, key) {
                    if(!value.trim().match(regex)){
                        error++;
                    }
                });
            }
            if(data.designees_2){
                data.designees_2 = data.designees_2.replaceAll(/,/g, ";");
                data.designees_2 = data.designees_2.replaceAll(/\;$/g, '');
                update_data.designees_2 = data.designees_2;
                data.designees_2= data.designees_2.split(';');
                angular.forEach(data.designees_2, function(value, key) {
                    if(!value.trim().match(regex)){
                        error++;
                    }
                });
            }
            if(data.designees_3){
                data.designees_3 = data.designees_3.replaceAll(/,/g, ";");
                data.designees_3 = data.designees_3.replaceAll(/\;$/g, '');
                update_data.designees_3 = data.designees_3;
                data.designees_3= data.designees_3.split(';');
                angular.forEach(data.designees_3, function(value, key) {
                    if(!value.trim().match(regex)){
                        error++;
                    }
                });
            }
            if(data.designees_4){
                data.designees_4 = data.designees_4.replaceAll(/,/g, ";");
                data.designees_4 = data.designees_4.replaceAll(/\;$/g, '');
                update_data.designees_4 = data.designees_4;
                data.designees_4= data.designees_4.split(';');
                angular.forEach(data.designees_4, function(value, key) {
                    if(!value.trim().match(regex)){
                        error++;
                    }
                });
            }

            if(data.mult_firms){
                data.mult_firms = Object.keys(data.mult_firms).join('\n'); 
                update_data.mult_firms = data.mult_firms;
                update_data.uid  = data.uid;
            }
            if(data.scheduled_by){
                update_data.scheduled_by = data.scheduled_by; 
            }
            if(error >0){
                Notification.error('Please enter valid designee emails');
                return false;
            }

            update_data.day_before_input = data.day_before_input || 'N';
            update_data.advance_input = data.advance_input || 'N';
            update_data.trigger_day_before = data.trigger_day_before || 'N';
            update_data.custom_trigger = data.custom_trigger || 'N';

            const concat = (...arrays) =>[].concat(...arrays.filter(Array.isArray));
            const unique = (array) => [...new Set(array)];
            const concated = concat(data.designees_1, data.designees_2, data.designees_3, data.designees_4);
            const uniqued = unique(concated);

            update_data.scheduled_designees = uniqued.join(';');
            org_data.scheduled_designees = uniqued;

            $rootScope.showLoader(true);
            commonFactory.post('/api/update-depo-attorney-permission/' + attorney_no, update_data)
            .success(function(response) {

                $rootScope.showLoader(false);
                if (response) {
                    if (response.status == 200) {
                        if ($scope.modified[key]) $scope.modified[key][buttonType] = false;
                        Notification.success(response.result.message);
                    }else{
                        Notification.error(response.result.message);
                    }
                }
            })
            .error(function(err) {
                $rootScope.showLoader(false);
            });
        }        
        $scope.updateAttorneyPermission_att = function(attorney_no, data,key, buttonType){
        
            var update_data = {};
            var data = Object.assign({}, data);
            attorney = $scope.attorneys[key];           
            
            update_data.attorney_id  = attorney.id;
            if(data.send_job_confirmation){
                update_data.send_job_confirmation = data.send_job_confirmation; 
            } 
            if(data.send_job_confirmation_to){
                update_data.send_job_confirmation_to = data.send_job_confirmation_to; 
            }
            if(data.view_invoice){
                update_data.view_invoice = data.view_invoice; 
            }
            if(data.see_pending){
                update_data.see_pending = data.see_pending; 
            }
            if(data.allow_notification){
                update_data.allow_notification = data.allow_notification; 
            }
            if(data.auto_attach){
                update_data.auto_attach = data.auto_attach; 
            }  
            if(data.auto_attach_invoice){
                update_data.auto_attach_invoice = data.auto_attach_invoice; 
            }
            if(data.view_statement){
                update_data.view_statement = data.view_statement; 
            }
            if(data.pay_cc){
                update_data.pay_cc = data.pay_cc; 
            }
            if(data.pay_check){
                update_data.pay_check = data.pay_check; 
            }   
            
            if(data.mult_firms){               
                update_data.uid  = data.uid;
            }
            
                         
               
             
            
            if(data.send_job_confirmation_to){               
                update_data.send_job_confirmation_to  = data.send_job_confirmation_to;
            }
                    
            $rootScope.showLoader(true);
            commonFactory.post('/api/update-depo-attorney-permission_att/' + attorney_no, update_data)
            .success(function(response) {

                $rootScope.showLoader(false);
                if (response) {
                    if (response.status == 200) {
                        if ($scope.modified[key]) $scope.modified[key][buttonType] = false;
                        Notification.success(response.result.message);
                    }else{
                        Notification.error(response.result.message);
                    }
                }
            })
            .error(function(err) {
                $rootScope.showLoader(false);
            });



        }
        $scope.updatePermissionData_firm = function(key, buttonType){           
            attorney = $scope.attorneys[key];        
            data = {};
            swal({
                title: "Are you sure ?",
                text: "You are going to Firm wise.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Do it",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {

            data.send_job_confirmation_to = $scope.formData.send_job_confirmation_to;
            data.auto_attach = attorney.auto_attach
            data.auto_attach_invoice = attorney.auto_attach_invoice

            data.firm_auto_attach = attorney.firm_auto_attach
            data.firm_auto_attach_invoice = attorney.firm_auto_attach_invoice

            data.transcript_designee_emails = attorney.transcript_designee_emails;
            data.invoice_designee_emails = attorney.invoice_designee_emails;
            data.statement_designee_emails = attorney.statement_designee_emails;

            data.auto_attach_statement= attorney.auto_attach_statement
            data.firm_attach_statement= attorney.firm_attach_statement
                    
            data.transcript_designee= attorney.transcript_designee
            data.invoice_designee= attorney.invoice_designee
            data.statement_designee= attorney.statement_designee

            data.di_job_summary= attorney.di_job_summary;
            data.firm_di_job_summary = $scope.formData.di_job_summary;

            data.webex_timezone= attorney.webex_timezone
            data.password_expiration_date=  moment(attorney.password_expiration_date).format('YYYY-MM-DD');
            data.is_reset_password_enabled= attorney.is_reset_password_enabled


            commonFactory.post('/api/update-attorney-permission/' + attorney.id, data)
            .success(function(response) {
                $rootScope.showLoader(false);
                if (response) {
                    if (response.status == 200) {
                        if ($scope.modified[key]) $scope.modified[key][buttonType] = false;
                        Notification.success("Attorney updated successfully");
                    }else{
                        Notification.error(response.result.message);
                    }
                }
            })
            .error(function(err) {
                $rootScope.showLoader(false);
            });
        }
        });

        }
        $scope.updatePermissionData = function(key, buttonType){    
            
            
            attorney = $scope.attorneys[key];        
            data = {};
            data.auto_attach = attorney.auto_attach
            data.auto_attach_invoice = attorney.auto_attach_invoice

            data.firm_auto_attach = attorney.firm_auto_attach
            data.firm_auto_attach_invoice = attorney.firm_auto_attach_invoice

            data.transcript_designee = attorney.transcript_designee
            data.invoice_designee = attorney.invoice_designee
            data.statement_designee = attorney.statement_designee

            data.transcript_designee_emails = attorney.transcript_designee_emails;
            data.invoice_designee_emails = attorney.invoice_designee_emails;
            data.statement_designee_emails = attorney.statement_designee_emails;
            

            data.auto_attach_statement= attorney.auto_attach_statement
            data.firm_attach_statement= attorney.firm_attach_statement

            data.di_job_summary= attorney.di_job_summary;
            data.firm_di_job_summary = $scope.formData.di_job_summary;
            

            /*data.send_job_confirmation = attorney.send_job_confirmation;
            data.send_job_confirmation_to = attorney.attorney.send_job_confirmation_to;
            data.view_invoice = attorney.view_invoice;
            data.see_pending = attorney.see_pending;
            data.allow_notification = attorney.allow_notification;
            data.auto_attach = attorney.auto_attach;
            data.auto_attach_invoice =attorney.auto_attach_invoice;
            data.view_statement = attorney.view_statement;
            data.pay_cc = attorney.pay_cc;
            data.pay_check = attorney.pay_check;*/
            
            // email validate
            var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            var error =0;
            if(attorney.transcript_designee_emails){
                var emails_transcript = attorney.transcript_designee_emails.replaceAll(/\s/g, "");
                emails_transcript = emails_transcript.replaceAll(/,/g, ";");
                data.transcript_designee_emails = emails_transcript;
                emails_transcript= emails_transcript.split(';');
                angular.forEach(emails_transcript, function(value, key) {
                    if(!value.match(regex)){
                        error++;
                    }
                });
            }
            if(attorney.invoice_designee_emails){
                var email_invoice = attorney.invoice_designee_emails.replaceAll(/\s/g, "");
                email_invoice = email_invoice.replaceAll(/,/g, ";");
                data.invoice_designee_emails = email_invoice;
                email_invoice= email_invoice.split(';');    
                angular.forEach(email_invoice, function(value, key) {
                    if(!value.match(regex)){
                        error++;
                    }
                });
            }
            if(attorney.statement_designee_emails){
                var email_statement = attorney.statement_designee_emails.replaceAll(/\s/g, "");
                email_statement = email_statement.replaceAll(/,/g, ";");
                data.statement_designee_emails = email_statement;
                email_statement= email_statement.split(';');    
                angular.forEach(email_statement, function(value, key) {
                    if(!value.match(regex)){
                        error++;
                    }
                });
            }
            if(error >0){
                Notification.error('Please enter valid email');
                return false;
            }

            data.webex_timezone= attorney.webex_timezone
            data.password_expiration_date=  moment(attorney.password_expiration_date).format('YYYY-MM-DD');
            data.is_reset_password_enabled= attorney.is_reset_password_enabled
            commonFactory.post('/api/update-attorney-permission/' + attorney.id, data)
            .success(function(response) {
                $rootScope.showLoader(false);
                if (response) {
                    if (response.status == 200) {
                        if ($scope.modified[key]) $scope.modified[key][buttonType] = false;
                        Notification.success("Attorney updated successfully");
                    }else{
                        Notification.error(response.result.message);
                    }
                }
            })
            .error(function(err) {
                $rootScope.showLoader(false);
            });



        }

        $http.get(apiUrl+'/api/users/get-timezone-list').success(function(response){
            $scope.timezone_list = response.timezone_list;
        });

        $scope.sortBy ='NAME';
        $scope.sortOrder ='asc';
        $scope.attorneys = {};
        $scope.cnt_attorneys = 0;

        $scope.pagination = {
            totalItems: 0,
            currentPage: 1,
            limit: 10,
            skip: 0,
            order: '',
            search: $scope.formData.searchValue,
            orderColumn:$scope.sortBy,
            orderDir: $scope.sortOrder
        };

        $scope.attorneyslist = function() {
            if ($scope.attorneyFirmId) {
                $scope.spinnerLoad= true;
                $scope.pagination.skip = ($scope.pagination.currentPage - 1) * $scope.pagination.limit;
                $scope.pagination.attorney_id = $scope.attorneyFirmId;
                $scope.pagination.orderColumn = $scope.sortBy;
                $scope.pagination.orderDir = $scope.sortOrder;
                $scope.pagination.search = $scope.formData.searchValue;
                $scope.resetForm();
                commonFactory.get('/api/getattorneylist?'+  $.param($scope.pagination || {}))
                .success(function(response) {                   
                    if (response) {
                        //$rootScope.showLoader(false);                        
                        $scope.attorneys = response.result;
                        $scope.cnt_attorneys = response.result.length;
                        $scope.pagination.totalItems = response.count;
                        $scope.spinnerLoad= false;
                    }
                })
                .error(function(err) {
                    $scope.spinnerLoad= false;
                    //$rootScope.showLoader(false);
                });
            }
        };
        // roles
        $scope.attorneyRoles= [];
        commonFactory.get('/api/get-attorney-type')
        .success(function (response) {
            $scope.attorney_type = response.result.attorneyType || '';
            angular.forEach($scope.attorney_type, function(value, key) {
                $scope.attorneyRoles[value.code]= value.text;
            });
            $rootScope.showLoader(false);
        })
        .error(function (err) {
            $rootScope.showLoader(false);
        });


        //EBT
        $scope.ebt = {};
        $scope.cnt_ebt = 0;
        $scope.ebtlist = function() {
            if ($scope.attorneyFirmId) {
                var data = {};
                data.id = $scope.attorneyFirmId;
                $rootScope.showLoader(true);
                commonFactory.get('/api/getebtclerk/'+ data.id)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response && response.result.ebt) {
                            $scope.cnt_ebt = response.result.ebt.length;
                            $scope.ebt = response.result.ebt;
                            for (var i = 0; i < response.result.ebt.length; i++) {
                                $scope.ebt[i].role = response.result.ebt[i].role ? response.result.ebt[i].role.name : "";
                            }
                        } else{
                            // Notification.error("No EBT found for this firm!");
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        };

        $scope.ebtModal = function() {
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/firms/ebt_modal.html',
                controller: 'ebtController',
                resolve: {
                    ebtdata: function ebtFactory() {
                        return $scope.ebt;
                    }
                }
            });

            modalInstance.result.then(function (isAdded) {
                if(isAdded){
                    $scope.ebtlist();
                }
            }, function () {
                console.log("Modal Dismissed!!!");
            });
        };

        $scope.editEbt = function(data, index) {
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/firms/ebt_modal.html',
                controller: 'editebtController',
                resolve: {
                    ebt_value: function ebtFactory() {
                        return commonFactory.get('/api/editebtclerk/' + data.id);
                    },
                    index: function ebtIndex() {
                        return index;
                    },
                    ebt_data: function ebtData() {
                        return $scope.ebt;
                    }
                }
            });

            modalInstance.result.then(function (isEdited) {
                if(isEdited){
                    $scope.ebtlist();
                }
            }, function () {
                console.log("Modal Dismissed!!!");
            });
        };

        $scope.deleteEbt = function(data) {
            swal({
                    title: "Are you sure?",
                    text: "you want to delete this EBT Clerk!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No, cancel EBT Clerk!",
                    closeOnConfirm: false,
                    closeOnCancel: false
                },
                function(isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/deleteebtclerk', data)
                            .success(function(response) {
                                if (response.status == 200) {
                                    $rootScope.showLoader(false);
                                    var index = -1;
                                    var comArr = eval($scope.ebt);
                                    for (var i = 0; i < comArr.length; i++) {
                                        if (comArr[i].id === data.id) {
                                            index = i;
                                            break;
                                        }
                                    }
                                    if (index === -1) {
                                        $rootScope.showLoader(false); 
                                        swal('Cancelled', 'Something gone wrong', 'error');
                                    }
                                    $scope.ebt.splice(index, 1);
                                }
                                swal('Deleted!', 'EBT Clerk deleted successfully', 'success');
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                                swal('Cancelled', 'Something gone wrong', 'error');
                            });
                    } else {
                        $rootScope.showLoader(false);
                        swal("Cancelled", "Your EBT Clerk is safe :)", "error");
                    }
                });
        };

        //callinby
        
        $scope.callinby = {};
        $scope.cnt_callinby = 0;
        $scope.Callinbylist = function() {
            if ($scope.attorneyFirmId) {
                var data = {};
                data.id = $scope.attorneyFirmId;
                $rootScope.showLoader(true);

                commonFactory.post('/api/get-user-called-in-by-list', { attorney_id : data.id })
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        $scope.job_called_in_by_list = response.result.data;

                    
                    if($scope.job_called_in_by_list && $scope.job_called_in_by_list.dep_attorney){                                             
                        $scope.callinby =  $scope.job_called_in_by_list.dep_attorney;
                        $scope.cnt_callinby = response.result.data.dep_attorney.length;
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });                
            }
        };

        //charges
        $scope.charges = [];
        $scope.editcharges = function() {
            $rootScope.showLoader(true);
            var data = {};
            data.attorney_id = $scope.attorneyFirmId;

            commonFactory.post('/api/editcharges', data)
                .success(function(response) {
                    if (response && response.result && response.result.charge) {
                        for (var i = 0; i < response.result.charge.length; i++) {

                            if (response.result.charge[i] && response.result.charge[i].pivot && response.result.charge[i].pivot.charge_amount) {
                                response.result.charge[i].charge_amount = response.result.charge[i].pivot.charge_amount;
                            } else {
                                response.result.charge[i].charge_amount = '';
                            }
                        }
                        $scope.charges = response.result.charge;
                    }
                    $rootScope.showLoader(false);
                })
                .error(function(err) { 
                    $rootScope.showLoader(false);
                });
        };

        $scope.saveCharge = function(charges) {
            var data = {};
            if ($scope.attorneyFirmId) {
                data.attorney_id = $scope.attorneyFirmId;
                data.charges = charges;

                for(var i=0;i<charges.length;i++){
                    if(charges[i].charge_amount == '' || charges[i].charge_amount == null || charges[i].charge_amount < 0){
                        swal('error', 'Please enter an amount greater than zero.', 'error');
                        return;
                    }
                }
                $rootScope.showLoader(true);

                commonFactory.post('/api/savecharges', data)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response && response.status == 200) {
                            swal('success', 'Charges saved successfully.', 'success');
                        }
                        else{
                            swal('error', response.result.message || 'Charges saved successfully.', 'error');
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }

        };
       
        //Getting all resources by type.
        commonFactory.post('/api/resource-list-by-type',{rep_typ:'S', fields:['id', 'FIRST_NAME', 'LAST_NAME', 'REP_TYP', 'NAME_KEY', 'reptypeID', 'business_unit_id', 'ADDR_LINE1', 'ADDR_LINE2']})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.salesPersonsList = response.result.resources;
            }
        });

        //Getting reporter resources
        commonFactory.post('/api/resource-list-by-type',{rep_typ:'R', fields:['id', 'FIRST_NAME', 'LAST_NAME', 'REP_TYP', 'NAME_KEY', 'reptypeID', 'business_unit_id', 'ADDR_LINE1', 'ADDR_LINE2']})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.reportersList = response.result.resources;
            }
        });

        $scope.open_A_R_Fax_window = function(ATT_KEY){
            var A_R_FaxModal = $uibModal.open({
                templateUrl: "modules/firms/a_r_fax.html",
                controller: function($scope, $uibModalInstance, $sce, $rootScope){
                    $scope.trustSrc = function(src) {
                        return $sce.trustAsResourceUrl(src);
                    };
                    var auth_user = $rootScope.auth_user;
                    var enscript = encodeURIComponent("ar_fax.dtop?r_tablename=ar&r_searchfield=ATTORNEY&r_search="+ATT_KEY+"&searchbutton=Go");

                    $scope.givenUrl = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;   

                    $scope.spinnerLoader= true;
                    $scope.closePopupWindow = function(){
                        $uibModalInstance.close(true);
                    };
                    $scope.iframeLoadedCallBack = function(){
                        // do stuff
                        $scope.spinnerLoader= false;
                        $scope.$apply(); 
                    };
                },
                resolve: {
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });
 
        }; 

        $scope.showFirmInvoices = function(att_id){
            invoiceFactory.searchData = {
                searchValue : {
                    id : att_id,
                    NAME: $scope.formData.firm_name
                },
                selectedValue : "BILLING_FIRM"
            }

            $state.go('invoice.browse');
        };

        $scope.open_payments_received = function(att_id){
            $rootScope.showLoader(true);
            var data = {
                includePaidInvoices: true,
                searchValue : {
                    id : att_id,
                    NAME: $scope.formData.firm_name
                },
                selectedValue : "ATTORNEY_FIRM"
            };

            cashReceiptFactory.findData = data;
            $state.go('cash_receipt.browse');
        };

        $scope.welcomeAtt = function(email){
            var enscript = encodeURIComponent("send_active.php?from=acceptance&email="+email);

            var auth_user = $rootScope.auth_user;
            var givenUrl = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;

            $rootScope.launchIFrame(givenUrl, function(){});
        };

        $scope.launchAtt = function(uid, email){
            $http.post(apiUrl + '/api/auth-user-get-token', {uid : uid}).then(function(response) { 
                if(response.data.status){
                    var givenUrl = $rootScope.depoHost + "/launch_user.dtop?uid="+ uid + "&actionfrom=backend&token="+ btoa(email)+"&access_token="+response.data.token;
                    console.log(givenUrl)

                    // $rootScope.launchIFrame(givenUrl, function(){});
                    $window.open(givenUrl, '_blank');
                }else{
                    Notification.error('Something went wrong!'); 
                }
            });
        };

        $scope.addAtt = function(attorney){           
            attorney.NAME = attorney.First_Name;
            if(attorney.Last_Name){
                attorney.NAME = attorney.NAME+ " " + attorney.Last_Name;
            }            
            if(!attorney.Firm_Key){
                attorney.Firm_Key = $scope.ATT_NAME_KEY;
            }
            if(!attorney.Email_Address){
                attorney.Email_Address = '';
            }

            var enscript = encodeURIComponent("attorney_edit.dtop?from=acceptance&name="+ attorney.NAME + "&office_code="+ attorney.Firm_Key+ "&email="+ attorney.Email_Address);

            var auth_user = $rootScope.auth_user;
            var givenUrl = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;

            $rootScope.launchIFrame(givenUrl, function(){
                $scope.ebtlist();
            });
        };

        $scope.editAtt = function(uid){
            var enscript = encodeURIComponent("attorney_edit.dtop?from=acceptance&key="+ uid);

            var auth_user = $rootScope.auth_user;
            var givenUrl = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;

            $rootScope.launchIFrame(givenUrl, function(){
                $scope.ebtlist();
            });
        };

        $scope.deleteAtt = function(uid){
            swal({
                    title: "Are you sure ?",
                    text: "You are going to delete the Attorney.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Do it",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        var enscript = encodeURIComponent("attorney_edit.dtop?from=acceptance&delete=1&key="+ uid);

                        var auth_user = $rootScope.auth_user;
                        var givenUrl = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;

                        $rootScope.launchIFrame(givenUrl, function () {
                            $scope.ebtlist();
                        });
                    }
                });
        };

        $scope.addAtto = function(attorney){            
            if(!attorney.NAME){
                attorney.NAME = '';
            }
            if(!attorney.ATTORNEY){
                attorney.ATTORNEY = '';
            }
            var enscript = encodeURIComponent("attorney_edit.dtop?from=acceptance&name="+ attorney.NAME + "&office_code="+ attorney.ATTORNEY);

            var auth_user = $rootScope.auth_user;
            var givenUrl = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;

            $rootScope.launchIFrame(givenUrl, function(){
                $scope.attorneyslist();
            });
        };

        $scope.editAtto = function(uid){
            var enscript = encodeURIComponent("attorney_edit.dtop?from=acceptance&key="+ uid);

            var auth_user = $rootScope.auth_user;
            var givenUrl = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;

            $rootScope.launchIFrame(givenUrl, function(){
                $scope.attorneyslist();
            });
        };

        $scope.deleteAtto = function(uid){
            swal({
                title: "Are you sure ?",
                text: "You are going to delete the Attorney.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Do it",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    var enscript = encodeURIComponent("attorney_edit.dtop?from=acceptance&delete=1&key="+ uid);
                    var auth_user = $rootScope.auth_user;
                    var givenUrl = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;
                    $rootScope.launchIFrame(givenUrl, function () {
                        $scope.attorneyslist();
                    });
                }
            });
        };


        $scope.deleteAtt_user = function(id,atid,from){
            swal({
                title: "Are you sure ?",
                text: "You are going to delete the Attorney.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Do it",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {                 
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/deleteattname', {id:id})
                    .success(function (response) {
                        $scope.getFirmAuditLogs();
                        $rootScope.showLoader(false);
                        if(response && response.error == true){
                            Notification.error('Something went wrong while delete attorney!');
                        } else{                            
                            Notification.success('Attorney has been done delete successfully.');
                            $scope.attorneyFirmId = atid;
                            $scope.attorneyslist();                        
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });

                }
            });
        };


        $scope.closePopupWindow = function(){
            swal({
                    title: "Are you sure ?",
                    text: "Are you sure want to close this window?",
                    type: "success",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        //$close(JOB_NO);
                        $uibModalInstance.close($scope.firmData);
                    }
                });
        }; 


        //open credit card model
        $scope.addCreditCard = function(){
            $rootScope.showLoader(true);
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/firms/add_card.html',
                controller: function($scope, $uibModalInstance, $rootScope, firmFactory, $http, apiUrl, $state, Notification){
                    $scope.cardInfo = {}; 
                    $scope.validations = firmFactory.validations;
                    $scope.messages = firmFactory.messages;
                    $scope.closeModal = function(){
                        $uibModalInstance.close();
                    }
                    $scope.save = function(isValid){ 
                        if(isValid){
                            $scope.cardInfo.attorney_id = $scope.attorneyFirmId;
                            // $scope.cardInfo.card_type = $scope.cardInfo.card_type.$ccEagerType;
                            $rootScope.showLoader(true);
                            $http.post(apiUrl+'/api/add-credit-card-info',$scope.cardInfo).success(function(res){
                                if (!res.error) {
                  
                                    Notification.success(res.result.message);  
                                    $uibModalInstance.close(res);
                                
                                }else{
                                    $rootScope.showLoader(false);
                                    Notification.error(res.result.message);
                                
                                }
                            });
                        }
                    }
                },
                backdrop : false 

            });

            modalInstance.opened.then(function(){
                $rootScope.showLoader(false);
            });

            modalInstance.result.then(function (res) {
                $rootScope.showLoader(false);
                if(res){
                  
                    $scope.credit_cards = res.result.cards;
                
                } 
            }, function () {
                console.log("Modal Dismissed!!!");
            });
        } 
         
        $scope.deleteCard = function(card_id){
            var msg = 'You want to delete card information ?';
            if($scope.credit_cards.length == 1){
                msg = "<small><b style='color:red;'>Warning - </b> deleting the only credit card for this attorney firm will also delete their profile at Authorize.Net. To avoid this, add another card first. Is it OK to delete their only credit card.</small>";
            }
            swal({
                title: "Are you sure",
                text: msg,
                type: "warning",
                html: true,
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No, It's clicked by mistake",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) { 
                    $rootScope.showLoader(true); 

                    $http.get(apiUrl+'/api/delete-credit-card-info/'+card_id).success(function(res){
                    
                        $rootScope.showLoader(false);
                        if (!res.error) {
          
                            Notification.success(res.result.message);
                            $scope.credit_cards = res.result.cards || [];
                        
                        }else{

                            Notification.error(res.result.message);
                            
                        }
                    
                    });  
                }
            });
             
        }

        //Unlock salesperson field.
        $scope.unlockSalesPerson = function(){
            if($rootScope.havePermission('allow_access_without_password','allow.attorney.firm.records.and.reporting.account.executive')){
                
                return;

            }else if($scope.salesperson_locked){
                swal({
                    title: "PLEASE ENTER PASSWORD TO UNLOCK",
                    type: "input",
                    inputType: "password",
                    showCancelButton: true,
                    closeOnConfirm: true
                }, function(inputValue) { 
                    if (inputValue === false){
                        return false;
                    } 
                    if (inputValue === "") {
                        Notification.error("You need to write something!");
                        return false
                    }
                    else {
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/auth-salesperson-source-pass', { 'password': inputValue })
                            .success(function(response) {
                                if (response['result']) {
                                    if (response['result']['auth']) {

                                        $rootScope.showLoader(false);
                                        $scope.salesperson_locked = false;
                                        
                                    }else {
                                        $rootScope.showLoader(false);
                                        Notification.error("Error ! Wrong Password");
                                    }
                                }else{
                                    $rootScope.showLoader(false);
                                    Notification.error("Oops ! Something went wrong");
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                                Notification.error("Oops ! Something went wrong");
                            });

                    }
                });        
            }else{
                $scope.salesperson_locked = true;
            }
        }

          //Unlock salesperson field.
          $scope.unlockRateType = function(){
            
            if($rootScope.havePermission('allow_access_without_password','allow.firm.rate.change')){
                $scope.rate_locked =false;
                return;

            }else if($scope.rate_locked){
                swal({
                    title: "PLEASE ENTER PASSWORD TO UNLOCK",
                    type: "input",
                    inputType: "password",
                    showCancelButton: true,
                    closeOnConfirm: true
                }, function(inputValue) { 
                    if (inputValue === false){
                        return false;
                    } 
                    if (inputValue === "") {
                        Notification.error("You need to write something!");
                        return false
                    }
                    else {
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/auth-firm-rates-pass', { 'password': inputValue })
                            .success(function(response) {
                                if (response['result']) {
                                    if (response['result']['auth']) {

                                        $rootScope.showLoader(false);
                                        $scope.rate_locked = false;
                                        
                                    }else {
                                        $rootScope.showLoader(false);
                                        Notification.error("Error ! Wrong Password");
                                    }
                                }else{
                                    $rootScope.showLoader(false);
                                    Notification.error("Oops ! Something went wrong");
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                                Notification.error("Oops ! Something went wrong");
                            });

                    }
                });        
            }else{
                $scope.rate_locked = true;
            }
        }

        $scope.unlockSource = function(){
            if($rootScope.havePermission('allow_access_without_password','allow.attorney.firm.source')){
                
                return;

            }else if($scope.source_locked){
                swal({
                    title: "PLEASE ENTER PASSWORD TO UNLOCK",
                    type: "input",
                    inputType: "password",
                    showCancelButton: true,
                    closeOnConfirm: true
                }, function(inputValue) { 
                    if (inputValue === false){
                        return false;
                    } 
                    if (inputValue === "") {
                        Notification.error("You need to write something!");
                        return false
                    }
                    else {
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/auth-salesperson-source-pass', { 'password': inputValue })
                            .success(function(response) {
                                if (response['result']) {
                                    if (response['result']['auth']) {

                                        $rootScope.showLoader(false);
                                        $scope.source_locked = false;
                                        
                                    }else {
                                        $rootScope.showLoader(false);
                                        Notification.error("Error ! Wrong Password");
                                    }
                                }else{
                                    $rootScope.showLoader(false);
                                    Notification.error("Oops ! Something went wrong");
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                                Notification.error("Oops ! Something went wrong");
                            });

                    }
                });        
            }else{
                $scope.source_locked = true;
            }
        }


        $scope.updateCollector = function(newCollectorID){
            
            var oldCollector = '';
            var newCollector = '';
            var ar_note = '';
            
            if(!$scope.oldCollectorID)
            {
                oldCollector = 'N/A';
            }
            if(!newCollectorID)
            {
                newCollector = 'N/A';
            }
            
            angular.forEach($scope.collectors, function(value, key) {
                if(value.id && value.id == $scope.oldCollectorID)
                {
                    oldCollector = value.name;
                }

                if(value.id && value.id == newCollectorID)
                {
                    newCollector = value.name;
                }
            });

            if(oldCollector && newCollector)
            {
                ar_note = 'Collector has been changed from '+oldCollector+' to '+newCollector+' for this firm.' 
            }
            else
            {
                ar_note = 'Collector has been changed for this firm.'
            }

            swal({
                title: "Are you sure",
                text: "You want to change collector for this firm ?",
                type: "warning",
                html: true,
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) { 
                    $rootScope.showLoader(true); 
                    $http.post(apiUrl+'/api/update-collector',{firmID : $scope.attorneyFirmId, newCollectorID : newCollectorID}).success(function(res){                    
                        $rootScope.showLoader(false);
                        if (!res.error) {
                            $scope.oldCollectorID = newCollectorID;
                            Notification.success(res.result.message);

                            $rootScope.showLoader(true);
                            $scope.formData.AR_ReminderDate = moment().format('YYYY-MM-DD');
                            $scope.formData.ARNote = ar_note;
                            $scope.formData.HideNote = 'N';
                            $scope.formData.AttorneyFirm_FK = $scope.ATT_NAME_KEY;
                            $scope.formData.attorney_id = $scope.attorneyFirmId;
                            
                            var requestUrl = '/api/AccountsReceivableAttorneyNotes';

                            commonFactory.post( requestUrl , $scope.formData ).success(function(response) {
                                if (response) {
                                    if (response.status == 200) {
                                        Notification.success("Note created successfully");
                                        $rootScope.showLoader(false);
                                    } else{
                                        $rootScope.showLoader(false);
                                    }
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                            });

                        }else{
                            Notification.error(res.result.message);
                        }
                    
                    });
                }else{
                    $scope.formData.collector_id = $scope.oldCollectorID;
                    $scope.$apply();
                }
            });
        }

        //open credit card model
        $scope.viewCollectorAudit = function(){
            $rootScope.showLoader(true);
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/firms/view_collector_audit.html',
                controller: function($scope, $uibModalInstance, $rootScope, firmFactory, $http, apiUrl, $state, Notification){
                    
                    //getting collector changed logs
                    $scope.collectorAuditLogs = [];
                    $http.post(apiUrl + '/api/get-collector-audit-logs',{firmID : $scope.attorneyFirmId}).success(function (response) {
                        $scope.collectorAuditLogs = response.result.data;
                    });

                    $scope.closeModal = function(){
                        $uibModalInstance.close();
                    }
                },
                backdrop : false 

            });

            modalInstance.opened.then(function(){
                $rootScope.showLoader(false);
            });
        }
        
        $scope.salesPersonsTagRequest = function(){
            $rootScope.showLoader(true);
            var salesPersonsTagRequestmodalInstance = $uibModal.open({
                templateUrl: 'modules/firms/sales-persons-tag-req-request.html',
                controller: function($scope, $uibModalInstance, $rootScope, firmFactory, $http, apiUrl, $state, Notification,oldSalesPerson,salesPersonsList,business_unit_data){
                    $scope.firmID = $scope.attorneyFirmId
                    if(oldSalesPerson){
                    $scope.oldSalesPerson = oldSalesPerson[0]
                    }
                    else{
                        $scope.oldSalesPerson = "0";
                    }
                    $scope.newSalesPerson 
                    $scope.salesPersonsList = salesPersonsList
                    $scope.business_unit_data = business_unit_data
                    //getting collector changed logs
                    
                    $scope.submit = function(){
                        //check business unit field
                        if(!$scope.business_unit_data.sales_manager_user_id){
                            Notification.error('Please Assign Sales Manager To Business Unit');
                            return;
                        }
                        if(!$scope.business_unit_data.bu_manager_email){
                            Notification.error('Please Assign Bu Manger To Business Unit');
                            return;
                        }
                        if($scope.oldSalesPerson == $scope.newSalesPerson ){
                            Notification.error('Please Assign Different Account Executive');
                            return;
                        }
                        if ( $scope.newSalesPerson ) {
                            $rootScope.showLoader(true);
                            //$close(JOB_NO);
                            $http.post(apiUrl +'/api/firm-salesman-tag-request',{'oldSalesPerson':$scope.oldSalesPerson?$scope.oldSalesPerson:'0','newSalesPerson':$scope.newSalesPerson,'firmId':$scope.firmID,'sales_manager_user_id':$scope.business_unit_data.sales_manager_user_id,'bu_manager_email':$scope.business_unit_data.bu_manager_email}).success(function (res) {
                                $rootScope.showLoader(false);
                                if(!res.error){
                                    $scope.closeModal();
                                    Notification.success(res.result.message);
                                  
                                    
                                }else{
                                    Notification.error(res.result.message);
                                }
                            }).error(function (err) {
                                $rootScope.showLoader(false);
                                Notification.error(res.result.message);
                            }
                                
                            );
                        }
                  
                    }
                    $scope.closeModal = function(){
                        $uibModalInstance.dismiss({ isChanged : true
                        });
                        $uibModalInstance.close();
                    }
                },
                resolve: {
                    oldSalesPerson: function () {
                        return $scope.formData.reporting_sales_ids;
                    },
                    salesPersonsList: function () {
                        return $scope.salesPersonsList;
                    },
                    business_unit_data:function (){
                       
                        return $scope.business_unit_data
                          
                       
                        
                    }
                },
                backdrop : false 

            });

            salesPersonsTagRequestmodalInstance.opened.then(function(){
                $rootScope.showLoader(false);
            });
            salesPersonsTagRequestmodalInstance.result.then(function () {
            }, function () {
               
                $scope.getFirmSalesmanTagRequestByFirm();

            });
        }

        $scope.reportingSalesIdsChange= function () {            
            $scope.reportingSalesman = $scope.reportingSalesman.filter(reportingSalesman => $scope.formData.reporting_sales_ids.includes(reportingSalesman.sales_reptyp_id));
            
            $scope.formData.reporting_sales_ids.forEach(id => {
                const existsInSelected = $scope.reportingSalesman.some(reportingSalesman => reportingSalesman.sales_reptyp_id === id);
                if (!existsInSelected) {
                    const reportingSalesmanToAdd = $scope.salesPersonsList.find(salesPerson => salesPerson.id === id);
                    if (reportingSalesmanToAdd) {
                        const newReportingSalesman = {
                            attorney_id: $scope.attorneyFirmId,
                            commission_percentage: '0.00',
                            overhead_amt: 0.0,
                            reptype: reportingSalesmanToAdd,
                            salesman_type: 'reporting',
                            sales_reptyp_id: id
                        }
                        $scope.reportingSalesman.push(newReportingSalesman);
                    }
                }
            });
        }

        $scope.recordsSalesIdsChange= function () {

            $scope.recordsSalesman = $scope.recordsSalesman.filter(recordsSalesman => $scope.formData.records_sales_ids.includes(recordsSalesman.sales_reptyp_id));

            $scope.formData.records_sales_ids.forEach(id => {
                const existsInSelected = $scope.recordsSalesman.some(recordsSalesman => recordsSalesman.sales_reptyp_id === id);
                
                if (!existsInSelected) {
                    const recordsSalesmanToAdd = $scope.reportersList.find(reporter => reporter.id === id);
                    if (recordsSalesmanToAdd) {
                        const newRecordsSalesman = {
                            attorney_id: $scope.attorneyFirmId,
                            commission_percentage: '0.00',
                            overhead_amt: 0.0,
                            reptype: recordsSalesmanToAdd,
                            salesman_type: "records",
                            sales_reptyp_id: id
                        }
                        $scope.recordsSalesman.push(newRecordsSalesman);
                    }
                }
            });
        }

        $scope.getCommission = function(){

            if ($scope.attorneyFirmId) {
                $scope.reporting_sales_ids = [];
                $scope.records_sales_ids = [];

                $rootScope.showLoader(true);
                commonFactory.get('/api/get-commission/'+ $scope.attorneyFirmId)
                    .success(function(response) {
                
                        $rootScope.showLoader(false);
                        if (response && response.result) {
                            
                            $scope.commissionData = {};
                            $scope.reportingSalesman = response.result.reportingSalesman;
                            $scope.recordsSalesman = response.result.recordsSalesman;
                            $scope.commissionData.send_commission_email = response.result.firmData.send_commission_email;

                            if($scope.reportingSalesman.length){
                                var reportingSalesmanIds = [];
                                angular.forEach($scope.reportingSalesman,function(value, key){
                                    reportingSalesmanIds[key] = value.sales_reptyp_id;
                                });
                                $scope.reporting_sales_ids = reportingSalesmanIds;
                            }
                            
                            if($scope.recordsSalesman.length){
                                var recordsSalesmanIds = [];
                                angular.forEach($scope.recordsSalesman,function(value, key){
                                    recordsSalesmanIds[key] = value.sales_reptyp_id;
                                });
                                $scope.records_sales_ids = recordsSalesmanIds;
                            }

                        }else{
                            Notification.error('Something went wrong.');
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }
        
        $scope.saveCommission = function(){
            var data = {
                firmID : $scope.attorneyFirmId,
                send_commission_email : $scope.commissionData.send_commission_email,
                reportingSalesman : $scope.reportingSalesman,
                recordsSalesman : $scope.recordsSalesman
            }

            swal({
                title: "Are you sure",
                text: "You want to save?",
                type: "warning",
                html: true,
                showCancelButton: true,
                
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
                
            },
            function (isConfirm) {
                if (isConfirm) { 
                    $rootScope.showLoader(true); 
                    commonFactory.put('/api/attorneys/' + $scope.attorneyFirmId, $scope.formData)
                            .success(function (response) {
                            $http.post(apiUrl+'/api/update-commission', data).success(function(res){
                                $scope.getFirmAuditLogs();
                                $rootScope.showLoader(false);
                                // Notification.success("Preference updated successfully.");
                                if(!res.error){
                                    Notification.success(res.result.message);
                                }else{
                                    Notification.error(res.result.message);
                                }
                            })
                    });
                }
            });
        }

        $scope.askCommissionTabPass = function(){
            $scope.setPageTitle();
            if($rootScope.havePermission('allow_access_without_password','allow.attorney.commission')){
                $scope.commissionTabAuthenticated = true;
                $scope.getCommission();
                return;
            }else if($scope.commissionTabAuthenticated){
                $scope.getCommission();
                return;
            }else{
                swal({
                    title: "PLEASE ENTER PASSWORD TO UNLOCK COMMISSION SCREEN",
                    type: "input",
                    inputType: "password",
                    showCancelButton: true,
                    closeOnConfirm: true
                }, function(inputValue) {
                    if (inputValue === false){
                        $state.go("firms.manage.step_one", {id : $scope.attorneyFirmId});
                        return false;
                    }
                    if (inputValue === "") {
                        Notification.error("You need to write something!");
                        $state.go("firms.manage.step_one", {id : $scope.attorneyFirmId});
                        return false
                    }
                    else {
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/authPrivateInfoPass', { 'password': inputValue })
                            .success(function(response){
                                $rootScope.showLoader(false);
                                if(response['result']){
                                    if (response['result']['auth']){
                                        $scope.getCommission();
                                        $scope.commissionTabAuthenticated = true;
                                    }else{
                                        Notification.error("Error !, Wrong Password!");
                                        $state.go("firms.manage.step_one", {id : $scope.attorneyFirmId});
                                    }
                                }else{
                                    Notification.error("Oops !, Something went wrong!");
                                    $state.go("firms.manage.step_one", {id : $scope.attorneyFirmId});
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                                Notification.error("Oops !, Something went wrong!");
                                $state.go("firms.manage.step_one", {id : $scope.attorneyFirmId});
                            });
                    }
                });
            }
        }

        $scope.firmRateChanged = function(){
            $scope.isRateChanged = true;
            $scope.formData.contract = 'yes';
        }

        $scope.getFirmAuditLogs = function(){
            $scope.firmAuditLogs = [];
            $http.get(apiUrl + '/api/get-firm-audit-logs/'+$scope.attorneyFirmId).success(function (response) {
                $scope.firmAuditLogs = response.result.data;
            });
        }
        $scope.getFirmAuditLogs();
        $scope.formatDate = function(date){
            return moment(date).format('MM/DD/YYYY hh:mm:ss a');
        }
        $scope.firmAuditParamsModal = function(params,audit_type){
            $rootScope.showLoader(true);
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/diary/addcase/diary_logs.html',
                controller: function($scope, params, $uibModalInstance){
                    $scope.modal_title = 'Updated Fields';
                    if(audit_type=='firm'){
                        var changes={};
                        angular.forEach(params,function(value, key){
                            changes[value.field_label]={
                                old_val:value.old_value,
                                new_val:value.new_value
                            }
                        });
                        $scope.updated_fields_list = changes;
                    }
                    else{
                        $scope.updated_fields_list = JSON.parse(params);
                    }
                   
                    $scope.cancel = function(isChanged) {
                        $uibModalInstance.dismiss({ isChanged : isChanged});
                    };
                },
                resolve : {
                    params : function(){
                        return params;
                    }
                },
                size: 'lg',
            });
            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });
        };

        $(window).bind('keydown', function(event) {

            if (event.ctrlKey || event.metaKey) {
                switch (String.fromCharCode(event.which).toLowerCase()) {

                    case 's':

                        event.preventDefault();

                        if($state.current.name == "firms.manage.step_one" && !$scope.signupForm.$invalid ){
                            $scope.goto_rate();
                        }
                        if($state.current.name == "firms.manage.step_two" && !$scope.signupForm.$invalid ){
                            $scope.goto_other();
                        }
                        if($state.current.name == "firms.manage.step_three" && !$scope.signupForm.$invalid ){
                            if($rootScope.havePermission('attorney_firm','update',$scope.formData.business_unit_id)){
                                $scope.save();
                            }
                        }
                        // $scope.invoiceGenerateForm.$setSubmitted();
                        $scope.$apply();
                        // $scope.saveJobInvoice($scope.invoiceGenerateForm.$valid,false,false,false);
                        break;
                    case 'n':
                        event.preventDefault();

                        if($state.current.name == "firms.manage.step_one" && !$scope.signupForm.$invalid){
                            $scope.goto_rate();
                        }
                        if($state.current.name == "firms.manage.step_two" && !$scope.signupForm.$invalid){
                            $scope.goto_other();
                        }
                        if($state.current.name == "firms.manage.step_three" && !$scope.signupForm.$invalid){
                            if($rootScope.havePermission('attorney_firm','update',$scope.formData.business_unit_id)){
                                $scope.save();
                            }
                        }
                        // $scope.invoiceGenerateForm.$setSubmitted();
                        $scope.$apply();
                        // $scope.saveJobInvoice($scope.invoiceGenerateForm.$valid,false,true,false);
                        break;
                }
            }
        });

        // notes functionality
        $scope.deletefiles = function(id){
            var data = {
                id : id
            }
            swal({
                title: "Are you sure",
                text: "You want to delete?",
                type: "warning",
                html: true,
                showCancelButton: true,
                
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
                
            },
            function (isConfirm) {
                if (isConfirm) { 
                    $rootScope.showLoader(true); 
                    $http.post(apiUrl+'/api/delete_notes_docs', data)
                    .success(function(res){
                        $scope.showNotes();
                        $scope.getFirmAuditLogs();
                        $rootScope.showLoader(false);
                        if(res.success){
                            Notification.success(res.message);
                        }else{
                            Notification.error(res.message);
                        }
                    });
                }
            });
        }

        // download file type
        $scope.downloadFile = function(id) {
            if(id){
                $http.get(apiUrl + '/api/get-notes-url', {
                    params: {
                        id: id
                    }
                }).then(function(response) {
                    if(!response.data.error){
                        window.open(response.data.result,'_blank');
                    }else{
                        Notification.error(response.data.message);
                    }
                });
            }
        };
        $scope.showNotes = function(){
            $scope.jsonData = {};
            $rootScope.showLoader(true);
            $http.get(apiUrl + '/api/notes', {params: { id: $scope.attorneyFirmId }}).then(function(response) {
                $scope.jsonData = response.data;
                $scope.firm.client = '';
                $scope.firm.sales = '';
                $scope.firm.ar = '';
                $scope.firm.resource = '';
                $scope.firm.legacy_notes = '';
                angular.forEach($scope.jsonData,function(value, key){
                    $scope.firm[key] = value.notes;
                });
            });
            $rootScope.showLoader(false);
          };
          $scope.showNotes();

        $scope.save_notes = function() {
            commonFactory.put('/api/notesupdate/' + $scope.attorneyFirmId, $scope.firm)
            .success(function(response) {
                $scope.jsonData = response.data;
                $scope.formData.NOTES_1 = $scope.firm.client;
                $scope.formData.NOTES_2 = $scope.firm.sales;
                $scope.formData.NOTES_3 = $scope.firm.ar;
                $scope.formData.reporter_note = $scope.firm.resource;
                $scope.formData.legacy_notes = $scope.firm.legacy_notes;
                Notification.success(response.result.message);
                $scope.showNotes();
                $scope.getFirmAuditLogs();
            });
        }
        
        commonFactory.get('/api/all-active-collection-policy-exceptions').success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.collectionPolicyExceptionData = response.result.collectionPolicyException;
            }
        });

        commonFactory.get('/api/all-active-format-exhibits').success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.formatExhibitData = response.result.formatExhibit;
            }
        });

        commonFactory.get('/api/all-active-format-transcripts').success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.formatTranscriptData = response.result.formatTranscript;
            }
        });

        commonFactory.get('/api/all-active-format-videos').success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.formatVideoData = response.result.formatVideo;
            }
        });

        commonFactory.get('/api/all-active-format-statements').success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.formatStatementData = response.result.formatStatement;
            }
        });

        commonFactory.get('/api/all-active-remote-connection-preferences').success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.remoteConnectionPreferenceData = response.result.remoteConnectionPreference;
            }
        });

        commonFactory.get('/api/getLocation').success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.preferredLocationData  = response.result.data;
                $scope.unpreferredLocationData  = response.result.data;
            }
        });

        $scope.save_preference = function () {
            if ($scope.preferenceFormData.preferred_team_members?.length > 0) {
                $scope.preferenceFormData.preferred_team_member_ids = $scope.preferenceFormData.preferred_team_members.map((preferred_team_member) => { 
                    return preferred_team_member.id 
                });   
            }
        //    if ($scope.preferenceFormData.preferred_team_members?.length > 0)  delete $scope.preferenceFormData.preferred_team_members
            swal({
                title: "Are you sure",
                text: "You want to save?",
                type: "warning",
                html: true,
                showCancelButton: true,

                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true

            },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.put('/api/attorneys/' + $scope.attorneyFirmId, $scope.formData)
                            .success(function (response) {
                                commonFactory.put('/api/attorneys/preference/' + $scope.attorneyFirmId, $scope.preferenceFormData)
                                    .success(function (response) {
                                        $rootScope.showLoader(false);
                                        Notification.success("Preference updated successfully.");
                                    })
                                // $rootScope.showLoader(false);
                                // Notification.success("Preference updated successfully.");
                            })
                    }
                });
        }
        
        $scope.uploadFiles = function(files, label) {
            if(files.length >0) {
                var formData = new FormData();
                angular.forEach(files, function(file) {
                    formData.append('files[]', file);
                });
                formData.append('type', label);
                formData.append('attorneyId', $scope.attorneyFirmId);
                var config = {
                    headers: {
                        'Content-Type': undefined
                    }
                };

                $rootScope.showLoader(true); 
                $http.post(apiUrl + '/api/add_notes_docs' ,formData, config)
                .success(function(response) {
                    $scope.showNotes();
                    $scope.getFirmAuditLogs();
                    if(response.message){
                        Notification.success(response.message);
                    }
                    $rootScope.showLoader(false);
                })
                .error(function(error) {
                    $rootScope.showLoader(false);
                    Notification.error(error.error);  
                });
            }
        };
        $scope.openUploadFile = function(label){
            $("#upload-link_" + label).click();
        };
          
        $(document).ready(function() {
            $("html").on("dragover", function(e) { e.preventDefault(); e.stopPropagation(); });  
            $("html").on("drop", function(e) { e.preventDefault(); e.stopPropagation(); });
        });
        // Initialize firms array
        
        $scope.selectedFirm = null;

        // Search function to call API and update firms list
        $scope.searchFirms = function(searchText) {
            return $http.get(apiUrl + '/api/getattorneyfirm', {
                params: {
                    name: searchText
                }
            }).then(function(response) {
                if(Array.isArray(response.data.result)){
                    return response.data.result.map(function(item) {
                        return item;
                    });
                }
            })
            .catch(function(err) {
               return err;
            });
        };

        // Function to add selected firm to the list
        $scope.addFirm1 = function(firm, att) {

            if(att.mult_firms.length == 0){
                att.mult_firms = {};
            }
            if (!att.mult_firms.hasOwnProperty(firm.NAME_KEY)) {
                att.mult_firms[firm.NAME_KEY] = firm.NAME;
            }
            $scope.selectedFirm = '';
        };

        $scope.addFirm = function(firm, att) {
            
            // Ensure multiple_firms is initialized as an array
            if (!Array.isArray(att.multiple_firms)) {
                att.multiple_firms = [];
            }
        
            // Check if the firm already exists in the array
            const exists = att.multiple_firms.some(existingFirm => existingFirm.NAME_KEY === firm.NAME_KEY);
            
            // If the firm does not exist, add it to the array
            if (!exists) {
                const newFirm = {
                    NAME_KEY: firm.NAME_KEY,
                    Name: firm.NAME,
                    id: firm.id
                };
                att.multiple_firms.push(newFirm);
            }          
            if(att.mult_firms.length == 0){
                att.mult_firms = {};
            }
            if (!att.mult_firms.hasOwnProperty(firm.NAME_KEY)) {
                att.mult_firms[firm.NAME_KEY] = firm.NAME;
            }
            
            // Clear the selected firm after adding
            $scope.selectedFirm = '';
        };

        // Function to remove a firm from the list
        $scope.removeFirm1 = function(firmKey, att) {
            if (att.mult_firms.hasOwnProperty(firmKey)) {
                delete att.mult_firms[firmKey];
            }
        };

        $scope.removeFirm = function(firmKey, att) {
            // Find the index of the firm with the matching NAME_KEY
            const index = att.multiple_firms.findIndex(firm => firm.NAME_KEY === firmKey);
            
            // If the firm is found, remove it from the array
            if (index !== -1) {
                att.multiple_firms.splice(index, 1);
            }
            if (att.mult_firms.hasOwnProperty(firmKey)) {
                delete att.mult_firms[firmKey];
            }
        };

        $scope.dtInstanceInvoices = {};
        $scope.dtInstanceCallback = function(dtInstance) {
            $scope.dtInstanceInvoices=dtInstance;
        }
        $scope.reloadInvoicesData = function() {
            $scope.dtInstanceInvoices.rerender();
        };


        $scope.createdRow = function (row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };


        $scope.dtInvoicesOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
            data.attorney_id = $scope.attorneyFirmId
            commonFactory.post('/api/firm-notes-datatable', data).success(function (res) {
                if (res.error) {
                }
                else {
                    callback(res);
                }
            }).error(function (err) {

            });
        })
        .withOption('processing', true)
        .withLanguage({
            "sProcessing": "<img src='img/loading_bar.gif'/>"
        })
        .withOption('serverSide', true)
        .withOption('searchDelay', 500)
        .withOption('order', [1, 'desc'])
        .withPaginationType('simple_numbers')
        .withDisplayLength(10)
        .withOption('lengthMenu', [
            [5, 10, 25, 50],
            [5, 10, 25, 50]
        ])
        .withOption('createdRow', $scope.createdRow)
        .withOption('headerCallback', function (header) {
            if (!$scope.headerCompiled) {
                $compile(angular.element(header).contents())($scope);
            }
        });

        $scope.dtInvoicesColumns = [];
        $scope.dtInvoicesColumns.push(
            DTColumnBuilder.newColumn('notes').withOption('defaultContent', '').withTitle('Note').withOption('width','60%'),
            DTColumnBuilder.newColumn('note_date').withOption('defaultContent', '').withTitle('Note Date').renderWith(function (data, type, full, meta) {
                if(data) {
                    var dte = moment(data).format('MM/DD/YYYY');
                    if(dte == 'Invalid date'){
                        return '-';
                    }else{
                        return dte;
                    }
                } 
                else {
                    return '-';
                }

            }),
            DTColumnBuilder.newColumn('create_user.name').withOption('defaultContent', '').withTitle('Created By'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().withOption('searchable',false)
            .renderWith(function(data, type, full, meta) {
                return '<button class="btn btn-primary btn-circle" ng-click="addExtraNote('+full.id+')" uib-tooltip="Edit"  >' +
                        '<i class="fa fa-pencil"></i>' +
                        '</button>&nbsp;&nbsp'+
                        '<a class="btn btn-circle btn-danger" ng-click="deleteNote('+full.id+')" ><i class="fa fa-trash"></i></a>';

            })
        );

        $scope.deleteNote = function(id){

            swal({
                    title: "Are you sure ?",
                    text: "You are going to delete the note.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Do it",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.delete('/api/firm-note/'+id)
                            .success(function(response) {
                                if (response && response.result) {
                                    Notification.success(response.result.message || 'Note deleted successfully.');
                                    $scope.reloadInvoicesData();
                                    $rootScope.showLoader(false);
                                }else{
                                    Notification.error(response.result.message || 'Something went wrong!');
                                    $rootScope.showLoader(false);
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                            });
                    }
                });
        };


        $scope.addExtraNote =function(id=null){

            var invoiceModal = $uibModal.open({
                templateUrl: "modules/firms/firmnotes/add_firm_notes_modal.html",
                controller: 'addFirmNotesController',
                resolve: {
                    attorney_id: function(){
                        return $scope.attorneyFirmId
                    },
                    id : function(){
                        return id
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

            invoiceModal.result.then(function (cbData) {
            }, function (cbData) {

                if(cbData.isChanged){
                    $scope.reloadInvoicesData();
                    $rootScope.showLoader(false);
                }

            });
        }

        // Open import users model
        $scope.importAttorneyFirmUsers = function() {
            $rootScope.showLoader(true);
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/firms/import_users/import_attorney_firm_users.html',
                controller: 'importAttorneyFirmUsersController',
                resolve: {
                    attorney_id: function() {
                        return $scope.attorneyFirmId
                    }
                },
                keyboard: false,
                backdrop: false,
                size: 'md',
                windowClass: 'middle-width-model',
            });

            modalInstance.opened.then(function() {
                $rootScope.showLoader(false);
            });

            modalInstance.result.then(function () {
                $scope.attorneyslist();       
            });
        }
        $scope.modified = {};
        // Function to mark a specific input as modified
        $scope.markModified = function(key, buttonType) {
            if (!$scope.modified[key]) $scope.modified[key] = {};
            $scope.modified[key][buttonType] = true
        };
        $scope.mergeAttorneySubmit = function(){
            $scope.submitted = true;
            if($scope.mergeAttorneys.deleteAttorneyId && $scope.mergeAttorneys.keepAttorneyId){
                if($scope.mergeAttorneys.deleteAttorneyId == $scope.mergeAttorneys.keepAttorneyId){
                    swal ("Oops" , "You have selected the same attorney for both choices. Please select different attorneys.", "error");
                    return false;
                }

                swal({
                        title: "MERGE THESE ATTORNEYS - ARE YOU SURE?",
                        text: "<span style='color:#e08639;'>Warning</span> - The Attorney merge program should be run during off hours. While it is running, some invoice totals may not be calculated correctly if other people are doing billing at the same time. Would you like to continue?",
                        type: "warning",
                        html: true,
                        showCancelButton: true,
                        confirmButtonColor: "#09375f",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No, Cancel this merge",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            $rootScope.showLoader(true);
                            var data = {};

                            angular.forEach($scope.attorneys, function(value, key){
                                if(value.id == $scope.mergeAttorneys.deleteAttorneyId){
                                    data.deleteAttorney = value;
                                }
                                if(value.id == $scope.mergeAttorneys.keepAttorneyId){
                                    data.keepAttorney = value;
                                }
                            });
                            $http.post(apiUrl + '/api/merge-attorneys',data).success(function (response) {

                                $rootScope.showLoader(false);
                                $scope.attorneyslist();
                                $scope.resetForm();
                                if(response.result.status == 200){
                                    Notification.success(response.result.message);
                                }else{
                                    Notification.error(response.result.message);
                                }

                            }).error(function (error) {
                                $rootScope.showLoader(false);
                                Notification.error(error.message);
                            });
                        }
                    });
            }else{
                swal ( "Oops" ,  "Please fill out all required fields." ,  "warning" );
            }
        }

        $scope.resetForm = function(){
            $scope.mergeAttorneys.keepAttorneyId = null;
            $scope.mergeAttorneys.keepAttorney = null;
            $scope.mergeAttorneys.deleteAttorneyId = null;
            $scope.mergeAttorneys.deleteAttorney = null;
            $('input[name=destinationId]').prop('checked',false);
            $('input[name=deleteId]').prop('checked',false);
        }


        $scope.onSelectAttorney = function (id, firmType) {

            let $item = $scope.attorneys.filter(function (item){
                return item.id == id ;
            });

            if(firmType =='keepAttorney'){
                $scope.mergeAttorneys.keepAttorney = angular.copy($item[0]);
                // $scope.iskeepAttorneySelected = true;
                // $scope.keepAttorneyId = $scope.mergeAttorneys.keepAttorney.id;
            }else{
                $scope.mergeAttorneys.deleteAttorney = angular.copy($item[0]);
                // $scope.isdeleteAttorneySelected = true;
                // $scope.deleteAttorneyId = $scope.mergeAttorneys.deleteAttorney.id;
            }
            $scope.validateMergeAttorney();
        };

        $scope.validateMergeAttorney = function(){
            if(typeof $scope.mergeAttorneys.keepAttorneyId != "undefined" && typeof $scope.mergeAttorneys.deleteAttorneyId != "undefined" && $scope.mergeAttorneys.keepAttorneyId != null && $scope.mergeAttorneys.deleteAttorneyId != null){
                if($scope.mergeAttorneys.deleteAttorneyId === $scope.mergeAttorneys.keepAttorneyId){
                    Notification.error('You have selected the same attorney for both choices. Please select different attorneys.!');
                    return false;
                }
                return true;
            }
            return false;
        }

        $scope.onCheckAttorney = function (id, firmType) {
            if(firmType =='keepAttorney'){
                $scope.mergeAttorneys.keepAttorneyId = parseInt(id);
            }else{
                $scope.mergeAttorneys.deleteAttorneyId = parseInt(id);
            }
            $scope.validateMergeAttorney();
        };

        $scope.onSelectAttorney = function(firmType){
            if(firmType == 'keepAttorney'){
                $scope.onSelectAttorney($scope.mergeAttorneys.keepAttorneyId, firmType);
            }else{
                $scope.onSelectAttorney($scope.mergeAttorneys.deleteAttorneyId, firmType);;
            }
        };

    }]) 
    .directive('dateRangePickerJquery', function($timeout) {
        return {
          restrict: 'A',
          link: function(scope, element, attrs) {
            function initializeDaterangepicker() {
                $(element).daterangepicker({
                  singleDatePicker: true,
                  showDropdowns: true,
                  minYear: 1901,
                  maxYear: parseInt(moment().format('YYYY'), 10),
                }, function(start) {
                  scope.$apply(function() {
                    scope[attrs.ngModel] = start.format('MM/DD/YYYY');
                  });
                });
              }
            // firms.manage.ebt
            // Watch for changes in currentState and initialize Daterangepicker when target state is active
            scope.$watch('currentState', function(newState) {
                if (newState === 'firms.manage.ebt') { // Replace with the target state name
                    $timeout(function() {
                        initializeDaterangepicker();
                        // Watch for changes in ng-model to update the Daterangepicker
                        scope.$watch(attrs.ngModel, function(newValue) {
                            if (newValue && $(element).data('daterangepicker')) {
                            $(element).data('daterangepicker').setStartDate(newValue);
                            }
                        });
                    }, 0);
                }
            });
          }
        };
      });
