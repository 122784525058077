angular.module('deitz').controller('uploadBlankJobsheetController', [
    '$scope', '$rootScope', '$http', 'apiUrl', 'Upload', 'Notification', '$timeout', '$stateParams', '$state', 'configFactory', 'commonFactory', 'codeFactory', '$window',
    function (
        $scope, $rootScope, $http, apiUrl, Upload, Notification, $timeout, $stateParams, $state, configFactory, commonFactory, codeFactory, $window
    ) {

        $scope.formData = {};
        $scope.blankJobSheet = {};

        $rootScope.showLoader(true);

        $scope.clickUpload = function () {
            $("#blank_jobsheet_doc_name").trigger('click');
        }

        $scope.fileChange = function () {
            var files = $('#blank_jobsheet_doc_name')[0].files[0];
            if (files && files.name) {
                $(".upload_h1").html(files.name + " file selected. <br> press the 'save' button.");
            } else {
                $(".upload_h1").html("Drag and Drop file here<br/>Or<br/>Click to select file");
            }
        }

        $scope.addBlankJobsheet = function (blankJobSheet, isValid) {
            $scope.formSubmit = true;
            if ($scope.blankJobSheet.blank_jobsheet_doc_name) {
                $rootScope.showLoader(true);
                Upload.upload({
                    url: apiUrl + '/api/save-blank-jobsheet-data',
                    data: $scope.blankJobSheet
                }).then(function (resp) {
                    $('#blankJobSheetSetupForm')[0].reset();
                    $scope.fileChange();

                    if (resp.data.status == 200) {
                        $rootScope.showLoader(false);
                        $scope.blankJobSheet.blank_jobsheet_doc_name = '';
                        Notification.success('Blank Jobsheet Uploaded Successfully');
                        $state.go('index.upload-blank-jobsheet');
                        $scope.getBlankJobsheet();
                    } else {
                        $rootScope.showLoader(false);
                        Notification.error('Something went wrong!');
                    }
                }, function (err) {
                    $rootScope.showLoader(false);
                    Notification.error('Something went wrong!');
                });
            } else {
                $('#blankJobSheetSetupForm')[0].reset();
                $scope.fileChange();
                $scope.getBlankJobsheet();
                if ($scope.blankJobSheetSetupForm.blank_jobsheet_doc_name.$invalid) {
                    Notification.error('Please Upload PDF/Video File Only.');
                } else {
                    Notification.error('File must be required.');
                }
            }
        }

        $scope.removeBlankJobsheet = function () {

            swal({
                title: "Are you sure want to remove this File?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Do it!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        $http.get(apiUrl + '/api/remove-blank-jobsheet-data').then(function (response) {
                            $rootScope.showLoader(false);
                            $scope.getBlankJobsheet();
                            if (response.data.status) {
                                Notification.success('Removed Blank Jobsheet PDF Successfully.');
                            } else {
                                Notification.error('File Not Exist.');
                            }
                        });
                    }
                });
        }


        $scope.getBlankJobsheet = function () {
            $http.get(apiUrl + '/api/get-blank-jobsheet-data', {
                params: {
                    fileexist: true
                }
            }).then(function (response) {
                $rootScope.showLoader(false);
                if (response.data.status) {
                    $scope.isfilepathexist = true;
                    $scope.filename = response.data.result;

                } else {
                    $scope.isfilepathexist = false;
                    $scope.filename = '';
                }
            });
        }

        $scope.getBlankJobsheet();


    }
]);