angular.module('deitz').controller('editCashReceiptController', [
    '$scope', '$rootScope','$http','apiUrl','cashReceiptFactory','commonFactory','$uibModal','$uibModalInstance','Notification','CASH_RECEIPT_ID',
    function ( $scope,$rootScope,$http,apiUrl,cashReceiptFactory,commonFactory,$uibModal,$uibModalInstance,Notification,CASH_RECEIPT_ID
    ){

        $scope.title = "Edit Case Receipt";
        $scope.validations = cashReceiptFactory.validations;
        $scope.messages = cashReceiptFactory.messages;
        $scope.statusOptions = cashReceiptFactory.statusOptions;
        $scope.adjustmentTypes = cashReceiptFactory.adjustmentTypes;
        $scope.paymentTypes = $rootScope.paymentTypes;
        $scope.assignedPaidBy = false;
        $scope.moreThanTotalAmt = false;
        $scope.totalPaidAmountExludCurrentReceipt = 0;

        if (!$rootScope.paymentTypes || $rootScope.paymentTypes.length === 0) {
            $rootScope.getPaymentTypes().then(function (){
                $scope.paymentTypes = $rootScope.paymentTypes;
            });
        }
        
        $scope.cashReceiptData = {};

        $rootScope.showLoader(true);

        commonFactory.get('/api/CashReceipt/'+CASH_RECEIPT_ID).success(function(response){

            if(!response.error){
                $scope.cashReceiptData = response.result.cashReceipt;
                if($scope.cashReceiptData.PAID_DT == null || $scope.cashReceiptData.PAID_DT === ''){                    
                    $scope.cashReceiptData.PAID_DT = moment().format('MM/DD/YYYY');                        
                }
                $scope.cashReceiptData.AMT = parseFloat(response.result.cashReceipt.AMT) + parseFloat(response.result.cashReceipt.adjustment);
                $scope.cashReceiptData.over_under_amt = response.result.cashReceipt.client_payment_amount - $scope.cashReceiptData.AMT;

                if($scope.cashReceiptData.insurance_id) {
                    $scope.cashReceiptData.invoice_paid_by = $scope.cashReceiptData.insurance_company;
                    $scope.cashReceiptData.invoice_paid_by.resType = 2;
                    $scope.assignedPaidBy = true;
                } else if($scope.cashReceiptData.invoicePaidBy){
                    $scope.cashReceiptData.invoice_paid_by = $scope.cashReceiptData.attorney_firm;
                    $scope.cashReceiptData.invoice_paid_by.resType = 1;
                    $scope.assignedPaidBy = true;
                }

                if($scope.cashReceiptData.invoice){
                    //get the total of invoice amount expecting the current invoice.
                    angular.forEach($scope.cashReceiptData.invoice.cash_receipts,function(cashReceipt,key){
                        // We not calculate or add current record into the total paid amount
                        if(cashReceipt.id != CASH_RECEIPT_ID){
                            $scope.totalPaidAmountExludCurrentReceipt = parseFloat($scope.totalPaidAmountExludCurrentReceipt) + parseFloat(cashReceipt.adjustment) + parseFloat(cashReceipt.AMT);
                        }
                    });
                }

                //manually called the method
                $scope.change_adjustment_type();
                $rootScope.showLoader(false);
            } else{
                $uibModalInstance.close();
                Notification.error(response.result.message || "Something went wrong!");
            }
        }).error(function(){
            $rootScope.showLoader(false);
        });

        //Getting Attorneys list
        $scope.attorneysByName = function(val) {
            return $http.get(apiUrl + '/api/attorneySearchByName', {
                params: {
                    name: val,
                    from_cash_receipt :true
                }
            }).then(function(response) {
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $scope.unSelectPaidBy = function(){
            if($scope.assignedPaidBy){
                $scope.paidBy = '';
                $scope.assignedPaidBy = false;
            }
        };

        $scope.calculateAmount = function(){
            $scope.moreThanTotalAmt = false;
            $scope.totalPaidAmout = parseFloat($scope.totalPaidAmountExludCurrentReceipt) + parseFloat($scope.cashReceiptData.adjustment) + parseFloat($scope.cashReceiptData.AMT);
            
            if($scope.totalPaidAmout > $scope.cashReceiptData.invoice.INV_AMT){
                $scope.moreThanTotalAmt = true;
            }

            $scope.cashReceiptData.over_under_amt = parseFloat($scope.cashReceiptData.client_payment_amount || 0) - parseFloat($scope.cashReceiptData.AMT);
        };

        $scope.selectAttorneyFirm = function($item){
            $scope.cashReceiptData.invoice_paid_by = $item;
            $scope.assignedPaidBy= true;
        };


        $scope.closeThisModal = function ($close) {
            swal({
                    title: "Are you sure ?",
                    text: "You are about to leave the edit view. Any changes you have not saved will be lost.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, close it!",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $close();
                    } else {
                        return false;
                    }
                });

        };

        $scope.change_adjustment_type = function(){
            if($scope.cashReceiptData.adjustment_type == 'none'){
                $scope.cashReceiptData.adjustment = 0;
                $scope.cashReceiptData.reason = '';
            }
        };

        $scope.updateReceiptData = function(isValid){ 
            if(isValid && !$scope.moreThanTotalAmt){

                $rootScope.showLoader(true);
                if(!parseFloat($scope.cashReceiptData.adjustment) >0 && !parseFloat($scope.cashReceiptData.AMT) > 0){
                    Notification.error('Payment amount can not be zero.');
                    return;
                }

                $scope.cashReceiptData.PAID_DT = moment($scope.cashReceiptData.PAID_DT).format('MM/DD/YYYY');

                $scope.cashReceiptData.overOrUnder = 'equal';
                if($scope.cashReceiptData.over_under_amt < 0){
                    $scope.cashReceiptData.overOrUnder = 'under';
                } else if($scope.cashReceiptData.over_under_amt > 0){
                    $scope.cashReceiptData.overOrUnder = 'over';
                }

                commonFactory.put('/api/CashReceipt/'+ CASH_RECEIPT_ID,$scope.cashReceiptData).success(function(response){
                    if(response.status == "200"){
                        $uibModalInstance.close();
                        $rootScope.showLoader(false);
                        Notification.success(response.result.message || 'Transaction has been successfully updated');
                    }
                }).error(function(error){
                    $rootScope.showLoader(false);
                    Notification.error(error.result.message || 'Transaction has failed');
                });

            }
        }
    }
]);