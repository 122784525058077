angular.module('deitz')
    .controller('caseController', ['$scope', '$rootScope', '$http', 'apiUrl', '$state', 'commonFactory', 'Notification', '$filter', '$uibModalInstance', '$uibModal', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile', '$parent', function ($scope, $rootScope, $http, apiUrl, $state, commonFactory, Notification, $filter, $uibModalInstance, $uibModal, DTOptionsBuilder, DTColumnBuilder, $compile, $parent) {

        $scope.title = "Add New Case"
        $scope.case = {};
        $scope.casetypes = [];

        $scope.fromPopup = false;

        if ($parent.addCasePopup) {
            $scope.fromPopup = true;
            $scope.case.CAPTION = $parent.diaryData.job_case_id;
            $scope.case.short_name = $parent.diaryData.job_case_id;
            $scope.case.IndexNumber = $parent.diaryData.IndexNumber;
            $scope.case.business_unit_id = $parent.diaryData.business_unit_id;
        }

        $scope.filterName = "";
        $scope.jobDateFilter = null;
        $scope.jobDateFilter = { date: {startDate: null, endDate: null} };
        $scope.startVal = null;
        $scope.endVal = null;
        $http.get(apiUrl + '/api/casetypes')
            .success(function (response) {
                if (response && response.result && response.result.casetype) {
                    $scope.casetypes = response.result.casetype || '';
                }
            });

        $http.get(apiUrl + '/api/getLocation')
            .success(function (response) {
                if (response.result && response.result.data) {
                    $scope.locations = response.result.data || '';
                }
            });

        $http.get(apiUrl + '/api/jurisdiction')
            .success(function (response) {
                if (response.result) {
                    $scope.jurisdiction = response.result || '';
                }
            });


        $http.get(apiUrl + '/api/vip_level')
            .success(function (response) {
                if (response.result) {
                    $scope.vipLevel = response.result || '';
                    $scope.vipLevel.unshift({name:'', id:null});
                }
            });

        $http.get(apiUrl + '/api/case-group')
            .success(function (response) {
                if (response.result) {
                    $scope.caseGroup = response.result || '';
                    $scope.caseGroup.unshift({name:'', id:null});
                }
            });

        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'', id:null});
        });


        $http.post(apiUrl + '/api/resource-list-by-type', { rep_typ: 'S' })
            .success(function (response) {
                if (response.error) {
                    Notification.error(response.result.message || 'Something went wrong!');
                } else {
                    $scope.salesPersonsList = response.result.resources;
                }
            });

        $scope.formatDate = function (date) {
            return moment(date).format('MM/DD/YYYY hh:mm:ss A');
        }
        $scope.jobDateFilterChange = function() {
            if (!$scope.jobDateFilter.date.startDate) {
                $scope.startVal = moment().format('YYYY-MM-DD');
                $scope.endVal = moment().format('YYYY-MM-DD');
            } else {
                $scope.startVal = moment($scope.jobDateFilter.date.startDate).format('YYYY-MM-DD');
                $scope.endVal = moment($scope.jobDateFilter.date.endDate).format('YYYY-MM-DD');
            } 
            $scope.reloadData()
        };

        $scope.resetJobDate = function() {
            $scope.jobDateFilter.date={startDate:null,endDate:null};
            $scope.startVal = null;
            $scope.endVal = null;

            $scope.reloadData()
        }
        $scope.getCaseAuditLog = function () {
            if ($state.params && $state.params.id) {
                var id = $state.params.id;
                commonFactory.get('/api/case/get-audit-logs/' + id)
                    .success(function (response) {
                        $rootScope.showLoader(false);
                        if (response.status == 200 && response.result && response.result.data) {
                            $scope.caseAuditLogs = response.result.data;
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            }
        }
        $scope.setPageTitle = function(){
            if($scope.case.short_name){
                $state.$current.data.pageTitle = $scope.case.short_name;
                $rootScope.$broadcast('changeTitle', $state);
            }
        };

        $rootScope.$on('$stateChangeSuccess', function() {
            $scope.setPageTitle();
        });

        $scope.getCaseAuditLog();
        $scope.getCaseInformation = function () {
            if ($state.params && $state.params.id && !$scope.fromPopup) {
                var id = $state.params.id;
                commonFactory.get('/api/case/' + id + '/edit')
                    .success(function (response) {
                        $rootScope.showLoader(false);
                        if (response.status == 200 && response.result && response.result.case) {
                            $scope.case = response.result.case
                            $scope.case.created_at = ($scope.case.created_at && $scope.case.created_at !== '0000-00-00 00:00:00' && $scope.case.created_at !== '-0001-11-30 00:00:00') ? moment($scope.case.created_at).format('MM/DD/YYYY hh:mm:ss A') : '--/--/----';

                            var salesIds = [];
                            angular.forEach(response.result.case.selesmans, function (value, key) {
                                salesIds.push(value.sales_reptyp_id);
                            });
                            $scope.case.sales_person_ids = salesIds;
                            
                            $scope.setPageTitle();
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            }

        }

        $scope.getRecentCaseNotes = function () {
            var case_id = $state.params.id;

            commonFactory.getWithParams('/api/case-note', { case_id: case_id })
                .success(function (response) {
                    if (response.result) {
                        $scope.caseNotes = response.result.notes || '';
                    }
                });
        }
        if ($state.params.id) {
            $scope.title = "Case"
            $scope.getCaseInformation();
            $scope.getRecentCaseNotes();
        }else{
            $scope.case.business_unit_id = null;
            $scope.case.case_group_id = null;
            $scope.case.vip_level_id = null;
        }



        $scope.saveCase = function (isValid, saveAndNew) {
            $rootScope.showLoader(true);
            if ($state.params && $state.params.id && !$scope.fromPopup) {
                commonFactory.put('/api/case/' + $state.params.id, $scope.case)
                    .success(function (response) {
                        $rootScope.showLoader(false);
                        $scope.getCaseAuditLog();
                        if (response.error) {
                            Notification.error(response.result.message);
                        }
                        if (response && response.result && response.result.case) {
                            Notification.success(response.result.message);

                            if (saveAndNew == true) {
                                $scope.case = {};
                                $state.go('case.add.step_one',{}, { reload: true });
                            }
                            else {
                                $state.go('case.edit.jobs', { id: response.result.case.id });
                            }
                        }

                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                        console.log(err);
                    });
            }
            else {
                commonFactory.post('/api/case', $scope.case)
                    .success(function (response) {
                        $rootScope.showLoader(false);
                        if (response.error) {
                            Notification.error(response.result.message);
                        }
                        if (response && response.result && response.result.case) {
                            Notification.success(response.result.message);

                            if ($scope.fromPopup) {
                                $uibModalInstance.dismiss({ isChanged: true, case: response.result.case });
                            }
                            else {
                                if (saveAndNew == true) {
                                    $scope.case = {};
                                    $state.go('case.add.step_one',{}, { reload: true });
                                }
                                else {
                                    $state.go('case.edit.jobs', { id: response.result.case.id });
                                }
                            }
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                        console.log(err);
                    });
            }

        }


        $scope.addCaseNote = function (id = null) {

            var invoiceModal = $uibModal.open({
                templateUrl: "modules/cases/add_notes_modal.html",
                controller: 'addCaseNotesController',
                resolve: {
                    case_id: function () {
                        return $state.params.id;
                    },
                    id: function () {
                        return id
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

            invoiceModal.result.then(function (cbData) {
            }, function (cbData) {
                if (cbData.isChanged) {
                    $rootScope.showLoader(false);
                    $scope.getRecentCaseNotes();
                }
            });
        }

        $scope.viewFullNote = function (id) {

            $rootScope.showLoader(true);
            commonFactory.get('/api/case-note/' + id)
                .success(function (response) {
                    $scope.caseNote = response.result.note;
                    var noteModal = $uibModal.open({
                        templateUrl: "modules/cases/view_notes_modal.html",
                        controller: ['$scope', '$rootScope', '$uibModalInstance', 'note', function ($scope, $rootScope, $uibModalInstance, note) {
                            $rootScope.showLoader(false);
                            $scope.caseNote = note;
                            $scope.uibModalInstance = $uibModalInstance;
                            $scope.closePopup = function () {
                                $uibModalInstance.dismiss();
                            }
                        }],
                        resolve: {
                            note: function () {
                                return $scope.caseNote;
                            }
                        },
                        keyboard: true,
                        backdrop: true,
                        size: 'md',
                        windowClass: 'middle-width-model',
                    })
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });

        }

        $scope.deleteCaseNote = function (id) {

            swal({
                title: "Are you sure ?",
                text: "You are going to delete the note.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Do it",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.delete('/api/case-note/' + id)
                            .success(function (response) {
                                if (response && response.result) {
                                    Notification.success(response.result.message || 'Note deleted successfully.');
                                    $scope.getRecentCaseNotes();
                                    $rootScope.showLoader(false);
                                } else {
                                    Notification.error(response.result.message || 'Something went wrong!');
                                    $rootScope.showLoader(false);
                                }
                            })
                            .error(function (err) {
                                $rootScope.showLoader(false);
                            });
                    }
                });
        };

        $scope.getAssociatedJobs = function () {

            $http.get(apiUrl + '/api/case/get-jobs/' + $state.params.id)
                .success(function (response) {
                    if (response.result) {
                        $scope.caseJobs = response.result.jobs || '';
                    }
                });
        }
        $scope.getAssociatedJobs();

        $scope.openJobSelect = function () {
            var jobs = [];
            angular.forEach($scope.caseJobs, function (value, key) {
                jobs.push(value.JOB_NO);
            });
            jobs = [...new Set(jobs)];

            var jobModel = $uibModal.open({
                templateUrl: "modules/cases/select_job.html",
                controller: ['$scope', '$rootScope', '$uibModalInstance', 'jobs', function ($scope, $rootScope, $uibModalInstance, jobs) {
                    $rootScope.showLoader(false);
                    $scope.jobs = jobs;
                    $scope.uibModalInstance = $uibModalInstance;
                    $scope.closePopup = function () {
                        $uibModalInstance.dismiss();
                    }
                }],
                resolve: {
                    jobs: function () {
                        return jobs;
                    }
                },
                keyboard: true,
                backdrop: true,
                size: 'sm',
                // windowClass: 'middle-width-model',
            })

        }

        $scope.getJobInvoices = function () {

            $http.get(apiUrl + '/api/case/get-invoices/' + $state.params.id)
                .success(function (response) {
                    if (response.result) {
                        $scope.caseInvoices = response.result.invoices || '';
                    }
                });
        }

        $scope.reloadFirmsData = function() {
            var table = $('#caseFirmsTbl').DataTable();
            table.ajax.reload();
        };

        $scope.getCaseFirms = function () {

            $http.get(apiUrl + '/api/case/get-firms/' + $state.params.id)
                .success(function (response) {
                    if (response.result) {
                        $scope.caseFirms = response.result.firms || '';
                    }
                });
        }

        $scope.firmPreferences = function (attorneyFirmId) {

            $rootScope.showLoader(true);
            var editAttorneyModal = $uibModal.open({
                templateUrl: "modules/firms/add_attorney_firm_modal.html",
                data: { pageTitle: 'Edit Firm' },
                controller: 'firmsController',
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {
                    console.log("function called");
                },
                resolve: {
                    attorneyFirmId: function () {
                        return attorneyFirmId;
                    },
                    activeTab: function () {
                        return 'tab-7'
                    }
                }
            });


            editAttorneyModal.result.then(function (firmData) {

                if (!angular.equals({}, firmData)) {
                    // $scope.refreshFirm(firmData.id);
                }
            }, function () {
                console.log("Modal Dismissed!!!");
            });

        };

        $scope.addCaseDocument = function (id = null) {
            var modal = $uibModal.open({
                templateUrl: "modules/cases/add_case_documents.html",
                controller: 'addCaseDocumentsController',
                resolve: {
                    case_id: function () {
                        return $state.params.id;
                    },
                    id: function () {
                        return id
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

            modal.result.then(function (cbData) {
            }, function (cbData) {
                $scope.getCaseDocuments();
                $scope.getCaseAuditLog();
            });
        }

        $scope.getCaseRepositories = function () {
            $http.get(apiUrl + '/api/case/get-repositories/' + $state.params.id)
                .success(function (response) {
                    if (response.result) {
                        $scope.repositories = response.result.repositories || '';
                    }
                });
        }

        $scope.getCaseDocuments = function () {
            $http.get(apiUrl + '/api/case/get-documents/' + $state.params.id)
                .success(function (response) {
                    if (response.result) {
                        $scope.documents = response.result.documents || '';
                    }
                });
        }

        $scope.dtInstance = {};

        $scope.filterResourceStatus = [];
        $http.get(apiUrl + '/api/get-active-assignment-status').then(function (response) {
            $scope.assignmentStatuses = response.data.result.assignment_status;
            angular.forEach($scope.assignmentStatuses, function (value, key) {
                $scope.filterResourceStatus.push({ value: value.id, label: value.name });
            });
        });

        $scope.filterAssetTypes = [];
        commonFactory.getWithParams('/api/service-type-list').then(function (response) {
            $scope.asset_types = response.data.result.data;
            angular.forEach($scope.asset_types, function (value, key) {
                $scope.filterAssetTypes.push({ value: value.id, label: value.name });
            });
        });

        $scope.filterAssetStatus = [];
        $http.get(apiUrl + '/api/getServiceStatus').then(function (response) {
            $scope.asset_status = response.data.result.service_status;
            angular.forEach($scope.asset_status, function (value, key) {
                $scope.filterAssetStatus.push({ value: value.id, label: value.name });
            });
        });

        $scope.filterJobStatus = [];
        $http.get(apiUrl + '/api/getJobStatus').then(function (response) {
            $scope.job_status = response.data.result.data;
            angular.forEach($scope.job_status, function (value, key) {
                $scope.filterJobStatus.push({ value: value.id, label: value.name });
            });
        });

        $scope.filterWitnessTypes = [];
        $http.get(apiUrl + '/api/witnesstypes').then(function (response) {
            $scope.witness_type = response.data.result.witnesstype;
            angular.forEach($scope.witness_type, function (value, key) {
                $scope.filterWitnessTypes.push({ value: value.id, label: value.name });
            });
        });

        $scope.filterInvoiceStatus = [
            { value: 'paid', label: 'PAID' },
            { value: 'unpaid', label: 'UNPAID' },
        ];
        
        $scope.dtInstanceCallback = function(instance) {
            $scope.dtInstance = instance;
        };        

        // Function to reload DataTable
        $scope.reloadData = function () {
            if ($scope.dtInstance && $scope.dtInstance.DataTable) {
                $scope.dtInstance.DataTable.ajax.reload(null, false);
            } else {
                console.error("DataTable instance not initialized yet.");
            }
        };

        $scope.compileDatatable = function (row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };


        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                data.startDate = $scope.startVal
                data.endDate = $scope.endVal
                commonFactory.showDataTable('/api/case/get-case-resource/' + $state.params.id, data)
                    .success(function (res) {
                        if (res) {
                            $rootScope.showLoader(false);
                            $scope.filterObj = data;
                            callback(res);
                            $('#resourceTbl').find('thead input').attr('autocomplete', 'off');
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            })
            .withLanguage({
                "sEmptyTable": "NO RESOURCES AVAILABLE",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "All Fields Search:",
                "sZeroRecords": "NO MATCHING RECORD FOUND",
            })
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withOption('stateSave', false)
            .withOption('lengthMenu', [[10, 25, 50, 100, 250, 500], [10, 25, 50, 100, 250, 500]])
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            // .withOption('searching', false)
            .withOption('order', [[0, 'asc'],[3,'asc'],[7,'asc']])
            .withOption('createdRow', $scope.compileDatatable)
            .withOption('initComplete', function () {
                $('#resourceTbl thead tr').each(function () {
                    $(this).find('th:empty').remove();
                  });
            })
            .withLightColumnFilter({

                '0': {
                    type: 'text',
                    time: 600
                },
                '1': {
                    type: 'text',
                    time: 600
                },
                '2': {
                    type: 'text',
                    time: 600
                },
                '3': {
                    type: 'text',
                    time: 600
                },
                '4': {
                    type: 'select',
                    time: 600,
                    values: $scope.filterResourceStatus
                },
                '5': {
                    type: 'select',
                    time: 600,
                    values: $scope.filterAssetTypes
                },
                '6': {
                    type: 'select',
                    time: 600,
                    values: $scope.filterAssetStatus
                },

            });



        $scope.dtColumns = [
            DTColumnBuilder.newColumn('JOB_NO').withOption('defaultContent', '').withTitle('Job Number').renderWith(function (data, type, full, meta) {
                let html = '<a target="_blank" ui-sref="diary.editcase.step_one({id : ' + full.JOB_NO + '})">' + data + '</a>';
                return html;
            }),
            DTColumnBuilder.newColumn('dte_taken').withOption('defaultContent', '').withTitle('Job Date').withOption('name', 'dte_taken').renderWith(function (data, type, full, meta) {
                if (data) {
                    var dte = moment(full.dte_taken).format('MM/DD/YYYY');
                    if (dte == 'Invalid date') {
                        return '-';
                    } else {
                        return dte;
                    }
                }
                else {
                    return '-';
                }

            }),
            DTColumnBuilder.newColumn('witness_name').withOption('defaultContent', '').withTitle('Witness Name').withOption('name', 'WITNESS_tbl.NAME'),
            DTColumnBuilder.newColumn('resource_name').withOption('defaultContent', '').withTitle('Resource Name').withOption('name', 'REPTYP_tbl.FIRST_NAME').renderWith(function (data, type, full, meta) {
                if (full.resource_id) {
                    let html = '<a target="_blank" ui-sref="representative.reporter.step_one({id : ' + full.resource_id + '})">' + data + '</a>';
                    return html;
                }
            }),
            DTColumnBuilder.newColumn('assignment_status').withOption('defaultContent', '').withTitle('Resource Status').withOption('name', 'assignment_statuses.id'),
            DTColumnBuilder.newColumn('service_type').withOption('defaultContent', '').withTitle('Asset Type').withOption('name', 'service_type.id'),
            DTColumnBuilder.newColumn('service_status_name').withOption('defaultContent', '').withTitle('Asset Status').withOption('name', 'service_status.id'),
            DTColumnBuilder.newColumn('witness_status_id').withOption('defaultContent', '').withTitle('witness Status id').withOption('name', 'witness_asset_statuses.id').notVisible(),

        ];


        $scope.dtOptionsJob = DTOptionsBuilder.newOptions()
        .withDataProp('data')
        .withOption('ajax', function (data, callback, settings) {
            data.is_datatable = true;
            commonFactory.showDataTable('/api/case/get-jobs/' + $state.params.id, data)
                .success(function (res) {
                    if (res) {
                        $rootScope.showLoader(false);
                        callback(res);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        })
        .withLanguage({
            "sEmptyTable": "NO JOBS AVAILABLE",
            "sProcessing": "<img src='img/loading_bar.gif'/>",
            "sSearch": "All Fields Search:",
            "sZeroRecords": "NO MATCHING RECORD FOUND",
        })
        .withOption('processing', true)
        .withOption('serverSide', true)
        .withOption('stateSave', false)
        .withOption('lengthMenu', [[10, 25, 50, 100, 250, 500], [10, 25, 50, 100, 250, 500]])
        .withPaginationType('simple_numbers')
        .withOption('searchDelay', 500)
        // .withOption('searching', false)
        .withOption('order', [[0, 'asc'],[3,'asc']])
        .withOption('createdRow', $scope.compileDatatable)
        .withLightColumnFilter({

            '0': {
                type: 'text',
                time: 600
            },
            '1': {
                type: 'text',
                time: 600
            },
            '2': {
                type: 'select',
                time: 600,
                values: $scope.filterJobStatus
            },
            '3': {
                type: 'text',
                time: 600
            },
            '4': {
                type: 'select',
                time: 600,
                values: $scope.filterWitnessTypes
            }
        });
        




    $scope.dtColumnsJob = [
        DTColumnBuilder.newColumn('JOB_NO').withOption('defaultContent', '').withTitle('Job Number').renderWith(function (data, type, full, meta) {
            let html = '<a target="_blank" ui-sref="diary.editcase.step_one({id : ' + full.JOB_NO + '})">' + data + '</a>';
            return html;
        }),
        DTColumnBuilder.newColumn('dte_taken').withOption('defaultContent', '').withTitle('Job Date').withOption('name', 'dte_taken').renderWith(function (data, type, full, meta) {
            if (data) {
                var dte = moment(full.dte_taken).format('MM/DD/YYYY');
                if (dte == 'Invalid date') {
                    return '-';
                } else {
                    return dte;
                }
            }
            else {
                return '-';
            }

        }),
        DTColumnBuilder.newColumn('job_status').withOption('defaultContent', '').withTitle('Job Status').withOption('name', 'job_status.id'),
        DTColumnBuilder.newColumn('witness').withOption('defaultContent', '').withTitle('Witness(es)').withOption('name', 'WITNESS_tbl.NAME'),
        DTColumnBuilder.newColumn('witness_type').withOption('defaultContent', '').withTitle('Witness Type').withOption('name', 'witness_types.id'),

    ];

    $scope.dtOptionsFirms = DTOptionsBuilder.newOptions()
        .withDataProp('data')
        .withOption('ajax', function (data, callback, settings) {
            data.is_datatable = true;
            commonFactory.showDataTable('/api/case/get-firms/' + $state.params.id, data)
                .success(function (res) {
                    if (res) {
                        $rootScope.showLoader(false);
                        callback(res);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        })
        .withLanguage({
            "sEmptyTable": "NO PARTIES/FIRMS AVAILABLE",
            "sProcessing": "<img src='img/loading_bar.gif'/>",
            "sSearch": "All Fields Search:",
            "sZeroRecords": "NO MATCHING RECORD FOUND",
        })
        .withOption('processing', true)
        .withOption('serverSide', true)
        .withOption('stateSave', false)
        .withOption('lengthMenu', [[10, 25, 50, 100, 250, 500], [10, 25, 50, 100, 250, 500]])
        .withPaginationType('simple_numbers')
        .withOption('searchDelay', 500)
        // .withOption('searching', false)
        .withOption('order', [[0, 'asc'],[3,'asc']])
        .withOption('createdRow', $scope.compileDatatable)
        .withLightColumnFilter({

            '0': {
                type: 'text',
                time: 600
            },
            '1': {
                type: 'text',
                time: 600
            },
            '2': {
                type: 'text',
                time: 600
            },
            '3': {
                type: 'text',
                time: 600
            },
            '4': {
                type: 'text',
                time: 600
            }
    });

    $scope.dtColumnsFirms = [
        DTColumnBuilder.newColumn('firm').withOption('defaultContent', '').withTitle('Firm Name').renderWith(function (data, type, full, meta) {
            let html = '<a target="_blank" ui-sref="firms.manage.step_one({id : ' + full.firm_id + '})">' + data + '</a>';
            return html;
        }).withOption('name', 'ATTORNEY_tbl.NAME'),
        DTColumnBuilder.newColumn('witness_type').withOption('defaultContent', '').withTitle('Party').withOption('name', 'job_case_firms.party'),
        DTColumnBuilder.newColumn('attorney_name').withOption('defaultContent', '').withTitle('Attorney Name').withOption('name', 'attorney.name'),
        DTColumnBuilder.newColumn('role').withOption('defaultContent', '').withTitle('Role').withOption('name', 'code.text'),
        DTColumnBuilder.newColumn('matter_id').withOption('defaultContent', '').withTitle('Matter ID<br>Firm File #').withOption('name', 'job_case_firms.firm_matter_no'),
        DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().withOption('searchable', false)
            .renderWith(function(data, type, full, meta) {
                return '<button type="button" ng-click="firmPreferences('+data.firm_id+')" class="btn btn-default btn-sm"> <i class="fa fa-cog" style="font-size: 16px;color: #E57200;"></i></button><button type="button" ng-click="deleteCaseParty('+data.id+')" class="btn btn-default btn-sm"> <i class="fa fa-trash"></i></button><button type="button" ng-click="openAddPartyPopup('+data.id+')" class="btn btn-default btn-sm"> <i class="fa fa-pencil"></i></button>';
            })
    ];


    $scope.dtOptionsInvoices = DTOptionsBuilder.newOptions()
        .withDataProp('data')
        .withOption('ajax', function (data, callback, settings) {
            data.is_datatable = true;
            commonFactory.showDataTable('/api/case/get-invoices/' + $state.params.id, data)
                .success(function (res) {
                    if (res) {
                        $rootScope.showLoader(false);
                        callback(res);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        })
        .withLanguage({
            "sEmptyTable": "NO INVOICES AVAILABLE",
            "sProcessing": "<img src='img/loading_bar.gif'/>",
            "sSearch": "All Fields Search:",
            "sZeroRecords": "NO MATCHING RECORD FOUND",
        })
        .withOption('processing', true)
        .withOption('serverSide', true)
        .withOption('stateSave', false)
        .withOption('lengthMenu', [[10, 25, 50, 100, 250, 500], [10, 25, 50, 100, 250, 500]])
        .withPaginationType('simple_numbers')
        .withOption('searchDelay', 500)
        // .withOption('searching', false)
        .withOption('order', [[0, 'asc'],[3,'asc']])
        .withOption('createdRow', $scope.compileDatatable)
        .withLightColumnFilter({

            '0': {
                type: 'text',
                time: 600
            },
            '1': {
                type: 'text',
                time: 600
            },
            '2': {
                type: 'text',
                time: 600
            },
            '3': {
                type: 'text',
                time: 600
            },
            '4': {
                type: 'text',
                time: 600
            },
            '5': {
                type: 'text',
                time: 600
            },
            '6': {
                type: 'text',
                time: 600
            },
            '7': {
                type: 'select',
                time: 600,
                values: $scope.filterInvoiceStatus
            }
    });

    $scope.dtColumnsInvoices = [
        DTColumnBuilder.newColumn('INV_NO').withOption('defaultContent', '').withTitle('Invoice Number').renderWith(function (data, type, full, meta) {
            let html = '<a target="_blank" ui-sref="invoice.edit({invoice_id : ' + full.invoiceID + '})">' + data + '</a>';
            return html;
        }).withOption('name', 'INV_NO'),

        DTColumnBuilder.newColumn('INV_AMT').withOption('defaultContent', '').withTitle('Invoice Amount').withOption('name', 'INV_AMT').renderWith(function (data, type, full, meta) {
            if (data) {
                return '$' + $filter('number')(data, 2);
            } else {
                return '$' + $filter('number')(0, 2);
            }
        }),

        DTColumnBuilder.newColumn('JOB_NO').withOption('defaultContent', '').withTitle('Job Number').renderWith(function (data, type, full, meta) {
            let html = '<a target="_blank" ui-sref="diary.editcase.step_one({id : ' + full.JOB_NO + '})">' + data + '</a>';
            return html;
        }).withOption('name', 'JOB_NO'),

        DTColumnBuilder.newColumn('billing_firm_name').withOption('defaultContent', '').withTitle('Ordered By Firm').renderWith(function (data, type, full, meta) {
            let html = '<a target="_blank" ui-sref="firms.manage.step_one({id : ' + full.billing_firm_id + '})">' + data + '</a>';
            return html;
        }).withOption('name', 'billing_firm.NAME'),

        DTColumnBuilder.newColumn('billing_contact').withOption('defaultContent', '').withTitle('Ordered By Contact').withOption('name', 'ATTNAME_tbl.NAME'),

        DTColumnBuilder.newColumn('claim_company').withOption('defaultContent', '').withTitle('Billed To Firm').renderWith(function (data, type, full, meta) {
            if(full.claim_rep){
                return '<a target="_blank" ui-sref="insurance.claim_rep_edit({id : ' + full.company_id + '})">' + full.claim_company + '</a>';
            }else{
                return '<a target="_blank" ui-sref="firms.manage.step_one({id : ' + full.billing_firm_id + '})">' + full.billing_firm_name + '</a>';
            }
        }).withOption('name', 'ClaimRepresentativeNameAndAddress_tbl.CR_CompanyName'),

        DTColumnBuilder.newColumn('claim_rep').withOption('defaultContent', '').withTitle('Billed To Contact').renderWith(function (data, type, full, meta) {
            if(full.claim_rep){
                return full.claim_rep;
            }else{
                return full.billing_contact;
            }
        }).withOption('name', 'ClaimRepresentativeNameAndAddress_tbl.ClaimRepFirstName'),

        DTColumnBuilder.newColumn('STATUS').withOption('defaultContent', '').withTitle('Invoice Status').renderWith(function (data, type, full, meta) {
            if(data == 'C'){
                return '<span>PAID</span>';
            }else{
                return '<span style="color:red">UNPAID</span>';
            }
        }).withOption('name', 'STATUS'),
    ];

        $scope.downloadVideo = function (document_no) {
            $http.get(apiUrl + '/api/get-deliverable-document-url', {
                params: {
                    document_no: document_no
                }
            }).then(function (response) {
                if (!response.data.error) {
                    window.open(response.data.result, '_blank');
                } else {
                    Notification.error(response.data.message);
                }
            });
        }

        $scope.downloadDocument = function (id,is_download=false) {
            commonFactory.getWithParams('/api/get-case-document-url/' + id,{is_download:is_download}).then(function (response) {
                if (!response.data.error) {
                    if(is_download){
                        window.open(response.data.result);
                    }
                    else{
                        window.open(response.data.result, '_blank');
                    }
                } else {
                    Notification.error(response.data.message);
                }
            });
        }

        $scope.deleteDocument = function (id) {
            swal({
                title: "Are you sure ?",
                text: "You are going to delete the document.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Do it",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.delete('/api/case-documents/' + id)
                            .success(function (response) {
                                if (response && response.result) {
                                    Notification.success(response.result.message || 'Document deleted successfully.');
                                    $scope.getCaseDocuments();
                                    $scope.getCaseAuditLog();
                                    $rootScope.showLoader(false);
                                } else {
                                    Notification.error(response.result.message || 'Something went wrong!');
                                    $rootScope.showLoader(false);
                                }
                            })
                            .error(function (err) {
                                $rootScope.showLoader(false);
                            });
                    }
                });
        }

        $scope.openDocumentModal = function (document) {
            var jobModel = $uibModal.open({
                templateUrl: "modules/cases/job_transcript_popup.html",
                controller: ['$scope', '$rootScope', '$uibModalInstance', 'document', function ($scope, $rootScope, $uibModalInstance, document) {
                    $rootScope.showLoader(false);
                    $scope.document = document;
                    $scope.uibModalInstance = $uibModalInstance;
                    $scope.closePopup = function () {
                        $uibModalInstance.dismiss();
                    }
                    $scope.downloadDoc = function (document_no) {
                        $http.get(apiUrl + '/api/get-deliverable-document-url', {
                            params: {
                                document_no: document_no
                            }
                        }).then(function (response) {
                            if (!response.data.error) {
                                window.open(response.data.result, '_blank');
                            } else {
                                Notification.error(response.data.message);
                            }
                        });
                    }
                }],
                resolve: {
                    document: function () {
                        return document;
                    }
                },
                keyboard: true,
                backdrop: true,
                size: 'md',
                // windowClass: 'middle-width-model',
            })
        }

        $scope.openDepoDocument = function (document_no) {
            window.open($scope.depoHost + '/autologin.php?userid=' + $scope.auth_user.email + '&password=' + $scope.auth_user.depo_psd + '&script=serve_repository.php?document_no=' + document_no);
        }

        $scope.closePopupWindow = function () {
            if($parent.addCasePopup){
                $parent.diaryData.job_case_id = null;
            }
            $uibModalInstance.close(true);
        };


        $scope.caseAuditHistoryParamsModal = function (params) {
            $rootScope.showLoader(true);

            var modalInstance = $uibModal.open({
                templateUrl: 'modules/diary/addcase/diary_logs.html',
                controller: function ($scope, params, $uibModalInstance) {
                    $scope.modal_title = 'Updated Fields';
                    $scope.updated_fields_list = JSON.parse(params);
                    $scope.cancel = function (isChanged) {
                        $uibModalInstance.dismiss({ isChanged: isChanged });
                    };
                },
                resolve: {
                    params: function () {
                        return params;
                    }
                },
                size: 'lg',
            });
            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });
        };

        $scope.openAddPartyPopup = function(id =null){
            $rootScope.showLoader(true);

            var addPartyModel = $uibModal.open({
                templateUrl: "modules/cases/add_firm_party_popup.html",
                data: {pageTitle: 'Add Case'},
                controller: 'addFirmPartyController',
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'md',
                // windowClass: 'middle-width-model',                
                close: function () {
                    console.log("function called");
                },
                resolve : {
                    case_id : function(){
                        return $state.params.id;
                    },
                    id: function(){
                        return id;
                    }
                }
            });
            addPartyModel.opened.then(function () {
                $rootScope.showLoader(false);
            });

            addPartyModel.result.then(function (cbData) {
            }, function (cbData) {
                if(cbData.isChanged){
                    $scope.reloadFirmsData();
                    $scope.getCaseAuditLog();
                }
            });
        }

        $scope.deleteCaseParty = function(id){
            swal({
                title: "Are you sure ?",
                text: "You are going to delete party from case.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Do it",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.delete('/api/case-party/' + id)
                            .success(function (response) {
                                if (response && response.result) {
                                    Notification.success(response.result.message || 'Party deleted successfully.');
                                    $scope.reloadFirmsData();
                                    $scope.getCaseAuditLog();
                                    $rootScope.showLoader(false);
                                } else {
                                    Notification.error(response.result.message || 'Something went wrong!');
                                    $rootScope.showLoader(false);
                                }
                            })
                            .error(function (err) {
                                $rootScope.showLoader(false);
                            });
                    }
                });
        }

        $scope.editCaseParty = function(id){

        }

    }]);
