angular.module('deitz').controller('statementController', [
    '$scope', '$rootScope', '$http', 'apiUrl', 'Notification', '$timeout', '$stateParams', '$state', 'configFactory', 'commonFactory', '$window',
    function (
        $scope, $rootScope, $http, apiUrl, Notification, $timeout, $stateParams, $state, configFactory, commonFactory, $window
    ) {

        $scope.statementType = 'claimRep';
        $scope.currentDate = moment().format('MM/DD/YYYY');
        $scope.formData = {
            insuranceCompanies: [],
            includeInvoices : false,
            selectedClaimRep : [],
            selectedAttorneys : [],
            excludeBilledToIds : [],
            filterDate : false,
            excludeTPA : false,
            includeTPA : false,
            insCompanyName : "",
            includeExcludeBilledToIds : 'include',
            dateRange : {
                endDate : moment().format('MM-DD-YYYY'),
                startDate : moment().format('MM-DD-YYYY')
            }
        };

        $scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs;
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id);
            });
        }
        
        $scope.isPay = false;
        //get configuration for tax id
        configFactory.getConfiguration({ keys: ['tax_id'] }).then(function (response) {
            $scope.tax_id = response.data.configurations.tax_id;
        });

        // get firms groups
        commonFactory.get('/api/get-firm-groups')
        .success(function(response) {
            $scope.firmGroups = response.result;
        });

        $scope.clearStatements = function (){
            $scope.formData = {
                insuranceCompanies: [],
                includeInvoices : false,
                selectedClaimRep : [],
                selectedAttorneys : [],
                excludeBilledToIds : [],
                selectedIndividuals : [],
                selectedIndividualsIds : [],
                filterDate : false,
                excludeTPA : false,
                includeTPA : false,
                includeCod : false,
                insCompanyName : "",
                includeExcludeBilledToIds : 'include',
                dateRange : {
                    endDate : moment().format('MM-DD-YYYY'),
                    startDate : moment().format('MM-DD-YYYY')
                }
            };
            $scope.Statements = null;
            $scope.billToList = [];
            $scope.isStatmentSearched = false;

            if($state.params.attId && $scope.statementType == 'attorneyFirm'){
                $rootScope.showLoader(true);
                commonFactory.get('/api/attorneys/'+$state.params.attId)
                    .success(function(response) {
                    $rootScope.showLoader(false);
                        $scope.formData.selectedAttorneys.push(response.result.data);
                    $scope.searchStatementData(true);
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
            }
            $scope.formData.firmAttorneys=[];
            if($state.params.attId && $scope.statementType == 'individual'){
                $rootScope.showLoader(true);
                commonFactory.post('/api/attorneybyfirm',{firm_id: $state.params.attId,check_statement_permisssion:true})
                    .success(function(response) {
                    $rootScope.showLoader(false);
                    $scope.formData.firmAttorneys=response.result.attorney_data;
                    angular.forEach(response.result.attorney_data, function(value, key) {
                        $scope.formData.selectedIndividuals.push(value);
                        $scope.formData.selectedIndividualsIds.push(value.id);
                     });
                    $scope.searchStatementData(true);
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
            }
    
        };

        $scope.updateIdInSearch = function(){
            $scope.formData.selectedIndividuals=[];
            angular.forEach($scope.formData.selectedIndividualsIds, function(value, key) {
                let obj = $scope.formData.firmAttorneys.find(o => o.id === value);
                $scope.formData.selectedIndividuals.push(obj);
            });
        }

        //Getting ClaimRepresentative List
        $scope.claimRepresentativeList = function (val) {
            $scope.searchingClaimRep = true;
            return $http.post(apiUrl + '/api/search-claimrep-name', {query_string: val}).then(function(response) {

                $scope.searchingClaimRep = false;
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        //Getting ClaimRepresentative List
        $scope.InsuranceCompanyList = function (val) {
            $scope.searchingInsCompany = true;
            return $http.post(apiUrl + '/api/search-insurance-company', {query_string: val}).then(function(response) {

                $scope.searchingInsCompany = false;
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        //Getting Attorneys list
        $scope.attorneysList = function(val) {
            $scope.searchingAtt = true;
            return $http.get(apiUrl + '/api/attorneySearchByName', {
                params: {
                    name: val
                }
            }).then(function(response) {
                $scope.searchingAtt = false;
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };


        //Getting attname List
        $scope.individualsList = function (val) {
            $scope.searchingIndividual = true;
            return $http.get(apiUrl + '/api/getattorneyslist',  {
                params: {
                    name: val,
                    check_statement_permisssion:true
                }
            }).then(function(response) {

                $scope.searchingIndividual = false;
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        /* Getting statements data to display on the web page*/
        $scope.searchStatementData = function(valid){              
            if(valid){
                $scope.isStatmentSearched = false;
                var ids = [];
                var insCompanies = [];
                if($scope.statementType =='attorneyFirm'){
                    angular.forEach($scope.formData.selectedAttorneys, function(value, key) {
                       ids[key] = value.id;
                    });
                }else if($scope.statementType =='individual'){
                    angular.forEach($scope.formData.selectedIndividuals, function(value, key) {
                        ids[key] = value.id;
                     });
                }
                else{                    
                    angular.forEach($scope.formData.selectedClaimRep, function(value, key) {
                       ids[key] = value.ClaimRepID;
                    });
                    angular.forEach($scope.formData.selectedInsCompany, function(value, key) {                       
                        insCompanies[key] = value.CR_CompanyName;
                    });
                }
                
                var selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);

                $rootScope.showLoader(true);
                $http.post(apiUrl + '/api/getStatementData', { 
                        ids: ids ,
                        insuranceCompanies: insCompanies,
                        type : $scope.statementType, 
                        excludeTPA: $scope.formData.excludeTPA,
                        includeTPA: $scope.formData.includeTPA,
                        excludeBilledToIds : $scope.formData.excludeBilledToIds,
                        filterDate : $scope.formData.filterDate,
                        includeCod : $scope.formData.includeCod,
                        selectedBusinessUnitIDs : selectedBusinessUnitIDs,
						includeExcludeBilledToIds : $scope.formData.includeExcludeBilledToIds,
                        startDate : moment($scope.formData.dateRange.startDate).format('YYYY-MM-DD'), 
                        endDate : moment($scope.formData.dateRange.endDate).format('YYYY-MM-DD') 
                    }).then(function(response) {

                    $rootScope.showLoader(false);
                    $scope.Statements = response.data.result;
                    $scope.billToList = response.data.billToList;                        
                    
                    /*Display pay button based on condition */                    
                    if($scope.Statements.length > 0 && ids.length === 1 && $scope.statementType =='attorneyFirm'){
                        $scope.isPay = true;
                    } else {
                        $scope.isPay = false;
                    } 

                    var insuranceCompaniesList = [];
                    if($scope.statementType =='attorneyFirm'){
                        angular.forEach($scope.billToList, function(value, key) {
                            let insName = value.NAME.split("|")[1];
                            if(typeof insName != "undefined"){
                                insName = insName.trim();
                                if(insuranceCompaniesList.indexOf(insName) === -1) {
                                    insuranceCompaniesList.push(insName);
                                }
                            }
                            
                        });
                    }
                    insuranceCompaniesList.sort(function(a, b){
                        if(a < b) { return -1; }
                        if(a > b) { return 1; }
                        return 0;
                    })
                    $scope.insuranceCompaniesList = insuranceCompaniesList;
                    
                    if($scope.Statements.length){
                        angular.forEach($scope.Statements, function(value,key){ 
                            if(value.invoice.length){ 
                                $scope.isStatmentSearched = true;
                            }
                        });                         
                    }
                    if(!$scope.isStatmentSearched){ 
                        Notification .error('Statements Not Found');
                    } 
                });
            }
        };

        /* On select / Remove any claimrep/claim representative, set flag to false. It will hide print, fax, email buttons.*/
        $scope.tagRemoved = function(){
            $scope.isStatmentSearched = false;
            $scope.formData.selectedInsCompany = null;
        };

        $scope.insCompanyChange = function(){
            $scope.formData.selectedClaimRep = null;
            $scope.isStatmentSearched = false;
        };

        // on change select all representatives from the list of selected insurance companies
        $scope.selectInsCompany = function(event){
            $rootScope.showLoader(true);
            $scope.formData.excludeBilledToIds = [];
            angular.forEach($scope.billToList,function(value, key){
                let insName = value.NAME.split("|")[1];
                if(typeof insName != "undefined"){
                    if($scope.formData.insCompanyName.indexOf(insName.trim()) != -1){
                        $scope.formData.excludeBilledToIds.push(value.id);
                    }
                }
            });
            $rootScope.showLoader(false);
        }

        $scope.printStatements = function (divName) {

            var printContents = $("#" + divName);
            printContents = printContents.html();
            var popupWin = window.open('', '_blank');
            popupWin.document.open();
            popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="css/common.css"><link rel="stylesheet" type="text/css" href="font-awesome/css/font-awesome.css"></head><body onload="window.print()">' + printContents + '</body></html>');
            popupWin.document.close();
        };

        /* Sending statements via Email/FAX for both claimrep and attorney firm */
        $scope.emailOrFaxStatements = function(via){
            var selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
            $rootScope.emailOrFaxStatementsToClaimRepOrAttorney({
                statementsTo : $scope.statementType,
                selectedAttorneys : $scope.formData.selectedAttorneys,
                selectedIndividuals : $scope.formData.selectedIndividuals,
                selectedClaimRep : $scope.formData.selectedClaimRep,
                selectedInsCompany: $scope.formData.selectedInsCompany,
                via: via,
                excludeBilledToIds:$scope.formData.excludeBilledToIds,
                filterDate : $scope.formData.filterDate,
                excludeTPA: $scope.formData.excludeTPA,
                includeTPA: $scope.formData.includeTPA,
                includeCod : $scope.formData.includeCod,
                selectedBusinessUnitIDs : selectedBusinessUnitIDs,
                includeExcludeBilledToIds : $scope.formData.includeExcludeBilledToIds,
                includeInvoices: $scope.formData.includeInvoices,
                startDate : moment($scope.formData.dateRange.startDate).format('YYYY-MM-DD'), 
                endDate : moment($scope.formData.dateRange.endDate).format('YYYY-MM-DD') 
            }, function(){
                console.log('Callback after sent the statemetns');
                if($scope.statementType=='attorneyFirm' && $scope.formData.selectedAttorneys.length==1){
                    url=$state.href('accountReceivableNotes.browse',{attId:$scope.formData.selectedAttorneys[0].id});
                    $window.open(url,'_blank');
                }
            });
        };

        if($state.params.clmId){
            $rootScope.showLoader(true);
            commonFactory.get('/api/claimrepresentativename/' + $state.params.clmId + '/edit')
            .success(function(response) {
                $rootScope.showLoader(false);
                $scope.statementType = 'claimRep';
                $scope.formData.selectedClaimRep = [];
                $scope.formData.selectedClaimRep.push(response.result.claim_rep_name);
                $scope.searchStatementData(true);
            })
            .error(function(err) {
                $rootScope.showLoader(false);
            });
        }

        if($state.params.clamId){
            $rootScope.showLoader(true);
            commonFactory.post('/api/getsearchInsuranceCompany' , { 
                claimid: $state.params.clamId})
            .success(function(response) {
                $rootScope.showLoader(false);
                $scope.statementType = 'claimRep';
                //$scope.formData.selectedInsCompany = [];
                $scope.formData.selectedInsCompany = response.result.ClaimIDs;                
                $scope.searchStatementData(true);              
            })
            .error(function(err) {
                $rootScope.showLoader(false);
            });
        }
        
        if($state.params.attId){
            $rootScope.showLoader(true);
            commonFactory.get('/api/attorneys/'+$state.params.attId)
                .success(function(response) {
                $rootScope.showLoader(false);
                $scope.statementType = 'attorneyFirm';
                    $scope.formData.selectedAttorneys.push(response.result.data);
                $scope.searchStatementData(true);
            })
            .error(function(err) {
                $rootScope.showLoader(false);
            });
        }

        $scope.dateFormat = function(date){
            return  date ? moment(date).format('MM/DD/YYYY hh:mm:ss a') : '--/--/----';
        }
        
        $scope.includeInvoices = function(){
            $rootScope.showLoader(true);
            $('#loaderid').removeClass('pause-loader');
			$('#loaderid').addClass('play-loader');
            $timeout(function() {
                $('#loaderid').removeClass('play-loader');
				$('#loaderid').addClass('pause-loader');
                $rootScope.showLoader(false);
            }, 1000);   
        }

        $scope.selectAllBillTo = function(){
            $rootScope.showLoader(true);
            $scope.formData.insCompanyName = '';
            $scope.formData.excludeBilledToIds = [];
            angular.forEach($scope.billToList,function(value, key){
                $scope.formData.excludeBilledToIds.push(value.id);
            });
            $rootScope.showLoader(false);
        }

        $scope.downloadExcel = function(){  

            $rootScope.showLoader(true);

            var ids = [];
            var insCompanies = [];
            if($scope.statementType =='attorneyFirm'){
                angular.forEach($scope.formData.selectedAttorneys, function(value, key) {
                   ids[key] = value.id;
                });
            }else if($scope.statementType =='individual'){
                    angular.forEach($scope.formData.selectedIndividuals, function(value, key) {
                        ids[key] = value.id;
                     });
                
            }else{
                angular.forEach($scope.formData.selectedClaimRep, function(value, key) {
                   ids[key] = value.ClaimRepID;
                });
                angular.forEach($scope.formData.selectedInsCompany, function(value, key) {
                    insCompanies[key] = value.CR_CompanyName;
                });
            }
            var selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
            commonFactory.post('/api/generate-statements-excel', {
                ids : ids,
                insuranceCompanies: insCompanies,
                type : $scope.statementType,
                excludeBilledToIds : $scope.formData.excludeBilledToIds,
                excludeTPA: $scope.formData.excludeTPA,
                includeTPA: $scope.formData.includeTPA,
                includeCod : $scope.formData.includeCod,
                includeExcludeBilledToIds : $scope.formData.includeExcludeBilledToIds,
                selectedBusinessUnitIDs : selectedBusinessUnitIDs,
                filterDate : $scope.formData.filterDate,
                startDate : moment($scope.formData.dateRange.startDate).format('YYYY-MM-DD'),
                endDate : moment($scope.formData.dateRange.endDate).format('YYYY-MM-DD')
            })
            .success(function(response) {
                $rootScope.showLoader(false);
                if(!response.error){
                    // $window.location.href = apiUrl +'/api/download-statement';
                    $window.open(apiUrl +'/api/download-statement', '_blank');
                }else{
                    Notification.error(response.result.message);
                }
            })
            .error(function(err) {
                $rootScope.showLoader(false);
                Notification.error(err);
            });
        }


        $scope.downloadDetailExcel = function(){  

            $rootScope.showLoader(true);

            var ids = [];
            var insCompanies = [];
            if($scope.statementType =='attorneyFirm'){
                angular.forEach($scope.formData.selectedAttorneys, function(value, key) {
                   ids[key] = value.id;
                });
            }else if($scope.statementType =='individual'){
                angular.forEach($scope.formData.selectedIndividuals, function(value, key) {
                    ids[key] = value.id;
                 });
            
            }else{
                angular.forEach($scope.formData.selectedClaimRep, function(value, key) {
                   ids[key] = value.ClaimRepID;
                });
                angular.forEach($scope.formData.selectedInsCompany, function(value, key) {
                    insCompanies[key] = value.CR_CompanyName;
                });
            }
            var selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
            commonFactory.post('/api/generate-statements-excel-details', {
                ids : ids,
                insuranceCompanies: insCompanies,
                type : $scope.statementType,
                excludeBilledToIds : $scope.formData.excludeBilledToIds,
                excludeTPA: $scope.formData.excludeTPA,
                includeTPA: $scope.formData.includeTPA,
                includeCod : $scope.formData.includeCod,
                selectedBusinessUnitIDs : selectedBusinessUnitIDs,
                includeExcludeBilledToIds : $scope.formData.includeExcludeBilledToIds,
                filterDate : $scope.formData.filterDate,
                startDate : moment($scope.formData.dateRange.startDate).format('YYYY-MM-DD'),
                endDate : moment($scope.formData.dateRange.endDate).format('YYYY-MM-DD')
            })
            .success(function(response) {
                $rootScope.showLoader(false);
                if(!response.error){
                    // $window.location.href = apiUrl +'/api/download-statement';
                    $window.open(apiUrl +'/api/download-statement', '_blank');
                }else{
                    Notification.error(response.result.message);
                }
            })
            .error(function(err) {
                $rootScope.showLoader(false);
                Notification.error(err);
            });
        }


        $scope.groupChange = function(){
            
            var groups = [];    
            angular.forEach($scope.formData.selectedGroups, function(value, key) {
               groups[key] = value.group_name;
            });
            
            // get firms of selected groups
            $rootScope.showLoader(true);
            commonFactory.post('/api/get-firms-of-selected-groups',{groups:groups})
            .success(function(response) {
                $rootScope.showLoader(false);
                $scope.formData.selectedAttorneys = response.result;
            })
            .error(function(err) {
                $rootScope.showLoader(false);
            });

        };
    }
]);
